import React, { useEffect, useMemo, useState } from "react";
import Box from "@material-ui/core/Box";
import { Query } from "@redux-requests/react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import get from "lodash/get";
import { updateFilter } from "redux/slices/adminFilterSlice.js";
import { isFleetAdministrator } from "util";
import { FETCH_SR_QUEUES } from "../../../../redux/constants";
import { fetchSrQueues } from "../../../../redux/actions/services";
import { isPermitted } from "../../../../components/HasPermission";
import CircularLoader from "components/Loaders/CircularLoader/index.js";
import QueueTableHeader from "./QueueTableHeader/index.js";
import DataNotFound from "components/DataNotFound";
import QueueTableRow from "./QueueTableRow/index.js";
import OrchestratorNotFound from "assets/No_Orchestrator.svg";
import CustomPagination from "pages/Services/components/CustomPagination/index.js";
import AdminTableFilter from "../../AdminTable/AdminTableFilter";
import CustomTableContainer from "../../../../components/TableComponents/CustomTableContainer";
import CustomTable from "../../../../components/TableComponents/CustomTable";

const statusList = [
  { code: "ACTIVE", label: "ACTIVE" },
  { code: "DISABLED", label: "DISABLED" },
];

const sortByList = [
  { code: "name", label: "queue.management.name" },
  { code: "createdAt", label: "queue.management.creation-date" },
  { code: "activeSince", label: "queue.management.active-since" },
];
export default function QueueTable({
  resetEntity,
}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const [showTableHeader, setShowTableHeader] = useState(true);
  const [countQueues, setCountQueues] = useState(0);
  const [selected, setSelected] = useState([]);
  const [fetchedData, setFetchedData] = useState([]);
  const filter = useSelector(({ adminFilter }) => adminFilter);
  const currentUser = useSelector(({ requests }) => get(requests, "queries.FETCH_CURRENT_USER.data"));
  const enableDeleteMultiple = isPermitted(currentUser, "Delete SR Queue");
  const fetchEntities = fetchSrQueues;
  const entityBaseUrl = "/robotAdministration/queues";

  const handleSelectedAll = (checked) => {
    const data = fetchedData.map((d) => d.id);
    if (!checked) {
      const filteredData = selected.filter((fd) => !data.includes(fd));
      setSelected(filteredData);
    } else {
      setSelected(data);
    }
  };

  const fetchEntitiesReset = () => {
    const pageNo = (countQueues - 1) % filter[filter.currModule].pageSize === 0
        ? filter[filter.currModule].pageNo - 1
        : filter[filter.currModule].pageNo;
    dispatch(
      updateFilter({
        pageNo: pageNo === -1 ? 0 : pageNo,
      }),
    );
    setSelected([]);
  };
  const isAllSelected = useMemo(
    () => fetchedData?.every((d) => selected?.includes(d.id)),
    [fetchedData, selected],
  );

  useEffect(() => {
    dispatch(fetchEntities({ ...filter[filter.currModule] }));
  }, []);

  useEffect(() => {
    dispatch(fetchEntities({ ...filter[filter.currModule] }));
  }, [dispatch, fetchEntities, filter, history.location.pathname]);

  const fetchEntitiesWithFilter = () => dispatch(fetchEntities({ ...filter[filter.currModule] }));
  const handleChangePage = (event, newPage) => {
    dispatch(
      updateFilter({
        pageNo: newPage,
      }),
    );
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(
      updateFilter({
        pageNo: 0,
        pageSize: parseInt(event.target.value, 10),
      }),
    );
  };

  const handleNext = (page) => {
    dispatch(
      updateFilter({
        pageNo: page + 1,
      }),
    );
  };
  const handlePrevious = (page) => {
    dispatch(
      updateFilter({
        pageNo: page - 1,
      }),
    );
  };

  const handleChangeStatus = (event) => {
    dispatch(
        updateFilter({
          status: event?.map(({ code }) => code),
          pageNo: 0,
        })
    );
  };

  const handleChangeFleet = (values) => {
    dispatch(
        updateFilter({
          fleets: values,
          pageNo: 0,
        })
    );
  };
  const handleChangeSortBy = (value) => {
    dispatch(
        updateFilter({
          sortBy: value?.code,
          pageNo: 0,
        })
    );
  };
  const handleFilterValueChange = (value) => {
    dispatch(
        updateFilter({
          pageNo: 0,
          searchCriteria: value,
        })
    );
  };

  return (
    <Box>
      <AdminTableFilter
        statusList={statusList}
        sortByList={sortByList}
        pageSize={filter.pageSize}
        fetchEntities={fetchEntities}
        searchLabel="queue.management.search"
        searchField="searchCriteria"
        handleChangeStatus={handleChangeStatus}
        handleFilterValueChange={handleFilterValueChange}
        handleChangeSortBy={handleChangeSortBy}
        handleChangeFleets={handleChangeFleet}
        showAddIcon={isPermitted(currentUser, "Add SR Queue") || isFleetAdministrator(currentUser)}
        addButtonText={t("queue.management.new")}
        isQueueAdministrationPage
        entityBaseUrl={entityBaseUrl}
      />
      {showTableHeader && (
        <QueueTableHeader
          selected={selected}
          setSelected={setSelected}
          handleSelectedAll={handleSelectedAll}
          checkedAll={
            fetchedData?.length > 0
            && fetchedData.every((d) => selected.includes(d.id))
          }
          checkedIndeterminate={
            fetchedData?.length > 0
            && !isAllSelected
            && fetchedData.some((d) => selected.includes(d.id))
          }
          fetchedData={fetchedData}
          fetchEntities={fetchEntities}
          page={filter[filter.currModule].pageNo}
          rowsPerPage={filter[filter.currModule].pageSize}
          enableDeleteMultiple={enableDeleteMultiple}
          statusList={statusList}
        />
      )}
      <CustomTableContainer>
        <CustomTable>
          <Query type={FETCH_SR_QUEUES} loadingComponent={CircularLoader}>
            {({ data }) => {
              if (!data?.content?.length) setCountQueues(0);
              setFetchedData(data?.content);
              const length = data.totalElements;
              setCountQueues(length);
              data?.content?.length > 0
                ? setShowTableHeader(true)
                : setShowTableHeader(false);
              return data?.content?.length > 0 ? (
                data.content?.map((row, index) => (
                  <QueueTableRow
                    row={row}
                    index={index}
                    selected={selected}
                    setSelected={setSelected}
                    resetEntity={resetEntity}
                    fetchEntitiesReset={fetchEntitiesReset}
                    enableDeleteMultiple={enableDeleteMultiple}
                    fetchEntities={fetchEntitiesWithFilter}
                    entityBaseUrl={entityBaseUrl}
                  />
                ))
              ) : (
                <DataNotFound
                  message={t("queue.management.no-data")}
                  icon={OrchestratorNotFound}
                />
              );
            }}
          </Query>
        </CustomTable>
      </CustomTableContainer>
      {showTableHeader && (
        <CustomPagination
          rowsPerPageOptions={[5, 10, 25]}
          count={countQueues ?? 0}
          rowsPerPage={filter[filter.currModule].pageSize}
          page={filter[filter.currModule].pageNo}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          onNext={handleNext}
          onPrevious={handlePrevious}
        />
      )}
    </Box>
  );
}
