import { format } from "date-fns";
import React, { Fragment, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DateTimePicker } from "@material-ui/pickers";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CustomSwitch from "../../../Services/components/CustomSwitch";
import Tabs from "@material-ui/core/Tabs";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import MuiDialogActions from "@material-ui/core/DialogActions";
import SaveIcon from "@material-ui/icons/Save";
import CustomDialog from "pages/Services/components/CustomDialog";
import CustomTab, { useIndicatorStyle } from "pages/Services/components/CustomTab";
import CustomCloseButton from "pages/Services/components/CustomCloseButton";
import { Controller, useForm } from "react-hook-form";
import FormControl from "@material-ui/core/FormControl";
import { useDispatch, useSelector } from "react-redux";
import {
  Paper, CircularProgress
} from "@material-ui/core";
import { CalendarToday } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import { formatDatePickerByLanguage, getLocale } from "../../../../util";
import {
  saveSchedule,
  updateSchedule,
  deleteSchedule,
  fetchPlannerProcessesList,
  fetchAssignedRobotsByProcess,
  isProcessAssignedToQueue
} from "../../../../redux/actions/services";
import SchedulePeriod from "../../../Services/components/SchedulePeriod/SchedulePeriod";
import ConfirmMessage from "../../../../components/ConfirmMessage";
import { toast } from "react-toastify";
import CustomButton from "../../../../components/CustomButton";
import useStyles from "./style";

function getDateForm(dateIn) {
  const dt = new Date(dateIn);
  const pattern = "yyyy-MM-dd'T'HH:mm";
  return format(dt, pattern);
}

function CalendarScheduleForm({
  openStart,
  handleClose,
  editMode = false,
  data,
  setModalOpen,
  reloadPlannerData,
  t,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [tabValue, setTabValue] = React.useState(0);
  const [dataToSave, setDataToSave] = React.useState(null);
  const [endDateCheck, setEndDateCheck] = React.useState(false);
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);
  const [process, setProcess] = React.useState([]);
  const [openMsgConfirm, setOpenMsgConfirm] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isRobotsLoading, setIsRobotsLoading] = React.useState(false);
  const [robots, setRobots] = React.useState([]);
  const [isAssignedToQueue, setIsAssignedToQueue] = React.useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
    control,
    clearErrors,
    setValue,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      name: null,
      process: null,
      period: 1,
      startDate: null,
      endDate: null,
    },
  });

  const handleReset = () => {
    reset();
    setStartDate(null);
    setEndDate(null);
  };

  const processes = useSelector(
    ({ requests }) => requests.queries.FETCH_PLANNER_PROCESSES_LIST?.data,
  );

  useEffect(() => {
    if (!processes) dispatch(fetchPlannerProcessesList());
  }, []);

  useEffect(() => {
    if (data && processes) {
      setRobots(
        processes?.find((pr) => pr?.processDisplayName === data?.processName)
          .robots ?? []
      );
    }
  }, [data, processes]);

  useEffect(() => {
    if (data) {
      setValue("name", data.scheduleName);
      setValue("process", {
        id: data.processId,
        name: data.processName,
        processOriginalId: data.processOriginalId,
        priority: data.priority,
        orchestratorId: data.orchestratorId,
        scheduleOrgId: data.scheduleOrgId,
      });
      setValue("resource", {
        robotDisplayName: data.robotDisplayName,
        robotName: data.robotName,
      });
      setValue("period", data.period);
      setTabValue(data.unitType - 1);
      setStartDate(data.originalStart);
      setValue("startDate", data.originalStart);
      setEndDate(data.originalEnd);
      setValue("endDate", data.originalEnd);
      setEndDateCheck(Boolean(data.originalEnd));
      setValue("scheduleOrgId", data.scheduleOrgId);
    }
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  const onSubmit = (data) => {
    if (data && data.name && startDate) {
      const dataToSend = data;
      // editMode && dataToSend.scheduleOrgId = data
      dataToSend.name = data.name.trim();
      dataToSend.startDate = getDateForm(startDate);
      dataToSend.endDate = endDate ? getDateForm(endDate) : null;
      dataToSend.period = data.period;
      dataToSend.unitType = tabValue + 1;
      dataToSend.description = "";
      dataToSend.priority = data.process.priority;
      dataToSend.processId = data.process.id;
      dataToSend.processInputs = "";
      dataToSend.processOriginalId = data.process.processOriginalId;
      dataToSend.orchestratorId = data.process.orchestratorId;
      dataToSend.resource = data.resource?.robotName;
      dataToSend.scheduleOrgId = editMode ? data.scheduleOrgId : null;
      delete dataToSend.process;
      setDataToSave(dataToSend);
      setOpenMsgConfirm(true);
    }
  };

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleChange = (event) => {
    setEndDateCheck(event.target.checked);
    if (!event.target.checked) {
      setEndDate(null);
    }
  };

  const changeStarDate = (value) => {
    setStartDate(value);
    setValue("startDate", value);
    clearErrors("startDate");
  };

  const changeEndDate = (value) => {
    setEndDate(value);
    setValue("endDate", value);
  };

  const cancelConfirm = () => {
    setOpenMsgConfirm(false);
    setOpenDeleteMsgConfirm(false);
  };

  const handleSaveSchedule = (status) => {
    if (status !== 409) {
      handleClose();
      reloadPlannerData();
      handleReset();
      setTabValue(0);
    }
    setOpenMsgConfirm(false);
    setIsLoading(false);
  };

  const confirmSave = () => {
    setIsLoading(true);
    if (editMode) {
      dispatch(updateSchedule(dataToSave)).then((result) => {
        if (result?.status === 200) toast.success(t("Schedule modified successfully"))
        else if (result?.error?.response?.status === 409) toast.error(t(result.error.response.data?.detail))
        else toast.error(t("Failed to update the schedule! Please try again later"))
        const status = result?.status || result?.error?.response?.status;
        setModalOpen && setModalOpen(status !== 200);
        handleSaveSchedule(status);
      });
    } else {
      dispatch(saveSchedule(dataToSave)).then((result) => {
        if (result?.status === 201) toast.success(t("The schedule was created successfully"))
        else if (result?.error?.response?.status === 409) toast.error(t(result.error.response.data?.detail))
        else toast.error(t("Failed to create the schedule! Please try again later"))
        const status = result?.status || result?.error?.response?.status;
        setModalOpen && setModalOpen(status !== 201);
        handleSaveSchedule(status);
      });
    }
  };

  useEffect(() => {
    if (processes) {
      setProcess(
        processes.map((e) => ({
          id: e.id,
          name: e?.processDisplayName,
          processOriginalId: e.originalId,
          orchestratorId: e?.orchestratorId,
          robots: e?.robots,
          // default 0 for priority
          priority: e?.priority ?? 0,
        })),
      );
    }
  }, [processes]);

  const [openDeleteMsgConfirm, setOpenDeleteMsgConfirm] = React.useState(false);

  const handleDeleteSchedule = () => {
    setOpenDeleteMsgConfirm(true);
  };

  const confirmDelete = () => {
    if (data) {
      setIsLoading(true);
      dispatch(deleteSchedule(data.id)).then(() => {
        toast.success(t("Schedule Deleted Successfully"))
        setOpenMsgConfirm(false);
        reloadPlannerData();
        setIsLoading(false);
      });
    }
  };

  /** En premier temps, le changement de process ne déclenche pas un Appel API
   * Et on exploite seulement les données charg&és avec EAGER (process.getRbots())
   * Et ça peut causer des problèmes de SYNC
   * Donc ici on déclenche un Appel API qui prende en compte :
   *    - Vérifier si le process est assigné à un Queue on fait l'intersection des robots de Queue + robots de process
   *    - Sinon, on renvoie tout simplement process.getRobots() [dans l'API pour assurer que les données sont à jours !]
   */
  const handleProcessChange = (process) => {
    // Un spînner de loading est affiché (avec text indiquant au user que les données sont en cours de charg ..)
    setIsRobotsLoading(true);
    setRobots([]);
    setValue("resource", null);

    // Récupèrer les robots de process - API
    dispatch(fetchAssignedRobotsByProcess(process?.id)).then((res) => {
      setRobots(res?.data || []);
      setValue("resource", null);
      setIsRobotsLoading(false);
    });

    // Vérifier s'il est assigné au QUeue ou non afin d'obliger ou non le champ (Robots)
    dispatch(isProcessAssignedToQueue(process?.id)).then((res) => {
      setIsAssignedToQueue(res?.data)
    })
  };

  const indicatorStyle = useIndicatorStyle();

  return (
    <CustomDialog
      open={openStart}
      aria-labelledby="customized-dialog-title"
      maxWidth="lg"
      onClose={handleClose}
    >
      <Paper square>
        <Grid container direction="row" xs={12}>
          <Grid container direction="row" xs={11}>
            <Tabs
              id="process-tabs-page"
              indicatorColor="primary"
              textColor="primary"
              aria-label="switch tabs"
              TabIndicatorProps={{
                style: indicatorStyle,
              }}
            >
              <CustomTab
                id="process-overview-tab"
                label={editMode ? t("Edit Scheduler") : t("create.scheduler")}
              />
            </Tabs>
          </Grid>
          <Grid container direction="row" xs={1} justify="flex-end">
            <CustomCloseButton
                aria-label="close"
                onClick={() => {
                  handleReset();
                  handleClose();
                }}
            />
          </Grid>
        </Grid>
      </Paper>
      <Grid container item>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={classes.root}
          noValidate
          autoComplete="off"
        >
          <Grid container xs={12} className={classes.grid}>
            <Grid item alignItems="left" xs={3}>
              <Typography variant="body1">{t("Choose Process")}</Typography>
            </Grid>
            <Grid item xs={9} style={{ marginBottom: "15px" }}>
              <Controller
                {...register("process", {
                  required: true,
                })}
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    value={getValues("process") ?? null}
                    onChange={(e, item) => {
                      field?.onChange(item);
                      handleProcessChange(item);
                    }}
                    options={process ?? []}
                    getOptionLabel={(option) => option?.name}
                    fullWidth
                    renderInput={(params) => <TextField {...params} />}
                  />
                )}
              />
              {errors.process && (
                <Typography color="error">
                  {t("Your input is required")}
                </Typography>
              )}
            </Grid>
          </Grid>
          <Grid container xs={12} className={classes.grid}>
            <Grid item container alignItems="left" xs={3}>
              <Typography variant="body1">{t("Choose Robot")}</Typography>
            </Grid>
            <Grid item container xs={9} style={{ marginBottom: "15px" }}>
              <Controller
                {...register("resource", {
                  required: !isAssignedToQueue,
                })}
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    loading={isRobotsLoading}
                    loadingText={t("Loading")}
                    noOptionsText={t("start.process.no.robot.assigned")}
                    {...field}
                    value={getValues("resource") ?? null}
                    onChange={(e, item) => field?.onChange(item)}
                    getOptionLabel={(option) => option?.robotDisplayName}
                    options={robots ?? []}
                    fullWidth
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {isRobotsLoading ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                )}
              />
              {errors.resource && (
              <Typography color="error">
                {t("Your input is required")}
              </Typography>
              )}
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={3}>
              <Box mt={2.5}>
                <Typography variant="subtitle1">{t("Schedule Name")}</Typography>
              </Box>
            </Grid>
            <Grid item xs={9}>
              <TextField
                fullWidth
                type="text"
                id="name"
                {...register("name", {
                  required: true,
                  validate: (value) => !!value.trim(),
                })}
              />

              {errors.name && (
                <Typography color="error">
                  {t("Your input is required")}
                </Typography>
              )}
            </Grid>
            <Grid item xs={3}>
              <Box mt={4}>
                <Typography variant="subtitle1">{t("Start Date")}</Typography>
              </Box>
            </Grid>
            <Grid item xs={9} mb={1.5}>
              <MuiPickersUtilsProvider
                utils={DateFnsUtils}
                locale={getLocale()}
              >
                <FormControl margin="normal" fullWidth>
                  <DateTimePicker
                    InputProps={{ endAdornment: <CalendarToday /> }}
                    renderInput={(params) => (
                      <TextField className={classes.input} {...params} />
                    )}
                    {...register("startDate", { required: true })}
                    value={startDate}
                    onChange={changeStarDate}
                    disablePast={!editMode}
                    showTodayButton
                    fullWidth
                    format={formatDatePickerByLanguage()}
                    cancelLabel={t("user.button.cancel")}
                    todayLabel={t("Today")}
                    ampm={false}
                  />
                </FormControl>
              </MuiPickersUtilsProvider>
              {errors.startDate && (
                <Typography color="error">
                  {t("Your input is required")}
                </Typography>
              )}
            </Grid>
            <Grid item xs={3}>
              <Box mt={2.5}>
                <Typography variant="subtitle1">
                  {t("End date")}
                  <FormControlLabel
                    style={{ marginLeft: "8px" }}
                    control={(
                      <CustomSwitch
                        checked={endDateCheck}
                        handleChange={handleChange}
                        name="endDateCheck"
                      />
                    )}
                  />
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={9}>
              <MuiPickersUtilsProvider
                utils={DateFnsUtils}
                locale={getLocale()}
              >
                <FormControl margin="normal" fullWidth>
                  <DateTimePicker
                    InputProps={{ endAdornment: <CalendarToday /> }}
                    renderInput={(params) => (
                      <TextField className={classes.input} {...params} />
                    )}
                    {...register("endDate", {
                      disabled: !endDateCheck,
                      required: endDateCheck,
                      validate: (data) => {
                        const date = new Date(data);
                        return endDateCheck && startDate
                          ? date.getTime() >= new Date(startDate).getTime()
                          : true;
                      },
                    })}
                    value={endDate}
                    onChange={changeEndDate}
                    disablePast={!editMode}
                    showTodayButton
                    minDate={startDate || new Date()}
                    format={formatDatePickerByLanguage()}
                    fullWidth
                    cancelLabel={t("user.button.cancel")}
                    todayLabel={t("Today")}
                    ampm={false}
                  />
                </FormControl>
              </MuiPickersUtilsProvider>
              {errors.endDate && errors.endDate.type === "required" && (
                <Typography color="error">
                  {t("Your input is required")}
                  {" "}
                </Typography>
              )}
              {errors.endDate && errors.endDate.type === "validate" && (
                <Typography color="error">
                  {t("End Date should not be before Start Date")}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12}>
              <SchedulePeriod
                classes={classes}
                value={tabValue}
                handleChangeTab={handleChangeTab}
                register={register}
                errors={errors}
                startDay={startDate ? startDate.getDate() : null}
              />
            </Grid>
          </Grid>
          <MuiDialogActions className={classes.cardActions}>
            <CustomButton
              view="primary"
              size="medium"
              type="submit"
              startIcon={<SaveIcon />}
            >
              {editMode ? t("Edit schedule") : t("Create schedule")}
            </CustomButton>
            {editMode
            && (
            <Box
              ml="1rem"
              component={Button}
              variant="contained"
              size="medium"
              onClick={handleDeleteSchedule}
              className={classes.resetButton}
            >
              {t("Delete")}
            </Box>
            )}
          </MuiDialogActions>
        </form>
      </Grid>
      {openMsgConfirm && (
        <ConfirmMessage
          message={
            editMode
              ? t("Are you sure you want to save the changes?")
              : t("Are you sure you want to create this schedule ?")
          }
          openStart={openMsgConfirm}
          onCancel={cancelConfirm}
          onConfirm={confirmSave}
          buttonConfirm={editMode ? t("Save changes") : t("Create schedule")}
          buttonCancel={t("Cancel")}
          isLoading={isLoading}
        />
      )}
      {openDeleteMsgConfirm && (
        <ConfirmMessage
          message={t("Are you sure you want to delete this schedule ?")}
          openStart={openDeleteMsgConfirm}
          onCancel={cancelConfirm}
          onConfirm={confirmDelete}
          isDelete="true"
          buttonConfirm={t("Delete")}
          buttonCancel="Cancel"
          isLoading={isLoading}
        />
      )}
    </CustomDialog>
  );
}

export default CalendarScheduleForm;
