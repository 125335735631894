import React from "react";
import { Typography, Link, Grid } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { useTranslation } from "react-i18next";
import useStyles from "../../style";

const Copyright = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const VERSION = process.env.REACT_APP_VERSION;

  return (
    <Box
      component={Grid}
      item
      xs={12}
      justify="center"
      alignItems="center"
      py={2}
      className={classes.container}
    >
      <Typography variant="body2" color="textSecondary" align="center">
        SmartRoby v
        {VERSION}
        {" "}
        ©
        {" "}
        {new Date().getFullYear()}
        &nbsp;
        <Link color="inherit" href="https://novelis.io" target="_blank">
          Novelis innovation
        </Link>
        {" - "}
        {t("All rights reserved")}
      </Typography>
    </Box>
  );
};

export default Copyright;
