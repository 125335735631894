import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { format } from "date-fns-tz";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import i18n from "../../../i18n";
import {
  secondsToTime, formatDateByLanguage
} from "../../../util";
import { useQuery } from "@redux-requests/react";
import { FETCH_CURRENT_USER } from "../../../redux/constants";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  fetchProcessesName,
  fetchExecutions,
  fetchAllStatus,
  exportFilteredExecutionsXLSX,
  fetchExportedFiles,
  fetchTagsForFilters,
  fetchExecutionsRobots
} from "../../../redux/actions/services";
import Filter from "./Filter";
import CustomPagination from "pages/Services/components/CustomPagination";
import CustomTableRow from "../../../components/TableComponents/CustomTableRow";
import CustomTableCell from "../../../components/TableComponents/CustomTableCell";
import CustomTableContainer from "../../../components/TableComponents/CustomTableContainer";
import CustomTable from "../../../components/TableComponents/CustomTable";
import ProcessIconName from "../../../components/TableComponents/ProcessIconName";
import DataNotFound from "../../../components/DataNotFound";
import CircularLoader from "../../../components/Loaders/CircularLoader";
import PageHeader from "../../../components/PageHeader";
import StatusButton from "../../../components/StatusButton";
import OccurrencesNotFound from "../../../assets/Process_Overview.svg";
import useStyles from "./style";

const dateFormat = "yyyy/MM/dd";
function ExecutionsPage(props) {
  const classes = useStyles();
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("launchingDate");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [loaded, setLoaded] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [count, setCount] = React.useState();
  const [processName, setProcessName] = React.useState([]);
  const [processes, setProcesses] = React.useState([]);
  const [tagName, setTagName] = React.useState([]);
  const [tags, setTags] = useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [status, setStatus] = React.useState([]);
  const [statusRef, setStatusRef] = React.useState([]);
  const [robotName, setRobotName] = React.useState([]);
  const [robots, setRobots] = React.useState([]);
  const [triggers, setTriggers] = React.useState([]);
  const [executionStartTime, setExecutionStartTime] = React.useState(null);
  const [executionEndTime, setExecutionEndTime] = React.useState(null);
  const [searchText, setSearchText] = React.useState(null);
  const executionsFilter = useSelector(({ executionsFilter }) => executionsFilter);
  const [showCustomDate, setShowCustomDate] = React.useState(false);
  const [exportLoading, setExportLoading] = React.useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const EMPTY_DEFAULT_VALUE = "---";
  const currentUser = useQuery({ type: FETCH_CURRENT_USER }).data;

  useEffect(() => {
    if (!loaded && currentUser) {
      setLoaded(true);
      props.fetchProcessesName().then((result) => {
        setProcesses(result.data);
        props.fetchExecutionsRobots(result.data.map(({ id }) => id)).then((result) => {
          setRobots(result.data);
        })
      });
      props.fetchTagsForFilters().then((result) => {
        setTags(result.data);
      });
      props.fetchAllStatus().then((result) => {
        let statusList = result.data;
        if (result?.data) {
          statusList = result?.data;
          statusList = statusList.sort((a, b) => (`${a.statusLabel}`).localeCompare(b.statusLabel));
        }
        setStatusRef(statusList);
      });
      getExecutions(
        page,
        rowsPerPage,
        executionsFilter.order.id,
        executionsFilter.order.order,
        executionsFilter.process,
        executionsFilter.status,
        executionsFilter.executionFromDate,
        executionsFilter.executionToDate,
        executionsFilter.searchText,
        executionsFilter.robot,
        executionsFilter.trigger,
        executionsFilter.tags
      );
    }
  }, []);

  const getDateDifference = (time) => secondsToTime(time, t);

  const handleChangeProcess = (values) => {
    setProcessName(values);
    setPage(0);
    getExecutions(
      0,
      rowsPerPage,
      orderBy,
      order,
      values,
      status,
      executionStartTime,
      executionEndTime,
      searchText,
      robotName,
      triggers,
      tagName
    );
  };
  const handleChangeTags = (values) => {
    setTagName(values);
    setPage(0);
    getExecutions(
        0,
        rowsPerPage,
        orderBy,
        order,
        processName,
        status,
        executionStartTime,
        executionEndTime,
        searchText,
        robotName,
        triggers,
        values
    );
  }
  const handleChangeDates = (from, to) => {
    if (from && to) {
      const fromString = `${from}`;
      const toString = `${to}`;
      from = fromString.includes("/") ? from : format(from, dateFormat);
      to = toString.includes("/") ? to : format(to, dateFormat);
    }
    setExecutionStartTime(from);
    setExecutionEndTime(to);
    setPage(0);
    getExecutions(
      0,
      rowsPerPage,
      orderBy,
      order,
      processName,
      status,
      from,
      to,
      searchText,
      robotName,
      triggers,
      tagName
    );
  };

  const handleChangeStatus = (values) => {
    setStatus(values);
    setPage(0);
    getExecutions(
      0,
      rowsPerPage,
      orderBy,
      order,
      processName,
      values,
      executionStartTime,
      executionEndTime,
      searchText,
      robotName,
      triggers,
      tagName
    );
  };

  const handleChangeRobot = (values) => {
    setRobotName(values);
    setPage(0);
    getExecutions(
      0,
      rowsPerPage,
      orderBy,
      order,
      processName,
      status,
      executionStartTime,
      executionEndTime,
      searchText,
      values,
      triggers,
      tagName
    );
  };

  const handleChangeTrigger = (values) => {
    setTriggers(values);
    setPage(0);
    getExecutions(
      0,
      rowsPerPage,
      orderBy,
      order,
      processName,
      status,
      executionStartTime,
      executionEndTime,
      searchText,
      robotName,
      values,
      tagName
    );
  };

  const handleRequestSort = (property) => {
    setOrder(property.order);
    setOrderBy(property.id);
    getExecutions(
      page,
      rowsPerPage,
      property.id,
      property.order,
      processName,
      status,
      executionStartTime,
      executionEndTime,
      searchText,
      robotName,
      triggers,
      tagName
    );
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getExecutions(
      newPage,
      rowsPerPage,
      orderBy,
      order,
      processName,
      status,
      executionStartTime,
      executionEndTime,
      searchText,
      robotName,
      triggers,
      tagName
    );
  };

  const handleNext = (page) => {
    setPage(page + 1);
    getExecutions(
      page + 1,
      rowsPerPage,
      orderBy,
      order,
      processName,
      status,
      executionStartTime,
      executionEndTime,
      searchText,
      robotName,
      triggers,
      tagName
    );
    }
    const handlePrevious = (page) => {
      setPage(page - 1);
    getExecutions(
      page - 1,
      rowsPerPage,
      orderBy,
      order,
      processName,
      status,
      executionStartTime,
      executionEndTime,
      searchText,
      robotName,
      triggers,
      tagName
    );
    }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
    getExecutions(
      0,
      event.target.value,
      orderBy,
      order,
      processName,
      status,
      executionStartTime,
      executionEndTime,
      searchText,
      robotName,
      triggers,
      tagName
    );
  };

  const getExecutions = (
    page,
    size,
    sortField,
    sortOrder,
    processes = [],
    status = [],
    executionStartTime = null,
    executionEndTime = null,
    searchText = "",
    robots = [],
    triggers = [],
    tags = []
  ) => {
    setIsLoading(true);
    const processID = processes ? processes.map((e) => e.id) : [];
    const statusId = status ? status.map((e) => e.id) : [];
    const robotId = robots ? robots.map((e) => e.id) : [];
    const tagId = tags ? tags.map((e) => e.id) : [];
    props
      .fetchExecutions(
        page,
        size,
        sortField,
        sortOrder,
        processID,
        statusId,
        executionStartTime,
        executionEndTime,
        searchText,
        robotId,
        triggers,
        tagId
      )
      .then((result) => {
        setData(result?.data?.list);
        setCount(result?.data?.resultsCount ?? 0);
        setIsLoading(false);
      });
  };
  const exportFilteredExecutions = () => {
    localStorage.setItem("lgn", i18n.language);
    setExportLoading(true)
    const processID = processName ? processName.map(({ id }) => id) : [];
    const statusId = status ? status.map(({ id }) => id) : [];
    const robotId = robotName ? robotName.map(({ id }) => id) : [];
    const tagId = tagName ? tagName.map(({ id }) => id) : [];
    dispatch(
        exportFilteredExecutionsXLSX(
            orderBy,
            order,
            processID,
            statusId,
            executionStartTime,
            executionEndTime,
            searchText,
            robotId,
            triggers,
            tagId,
            onSuccesExport,
            onErrorExport
        )
    )
  }
  const onSuccesExport = () => {
    setExportLoading(false);
    dispatch(fetchExportedFiles());
    toast.success(t("export.successful.notification"));
  }
  const onErrorExport = () => {
    setExportLoading(false);
    toast.error(t("something went wrong"));
  }

  const handleChangeSearchText = (value) => {
    setSearchText(value);
    setPage(0);
    getExecutions(
      0,
      rowsPerPage,
      orderBy,
      order,
      processName,
      status,
      executionStartTime,
      executionEndTime,
      value,
      robots,
      triggers,
      tagName
    );
  };
  const handleClearFilter = () => {
    getExecutions(
      0,
      rowsPerPage,
      columns[0].id,
      columns[0].order,
    );
  }
  const showStartDateCell = data?.find(({ executionStartTime }) => !!executionStartTime)
  const showEndDateCell = data?.find(({ executionEndTime }) => !!executionEndTime)
  const showMissedReasonCell = data?.find(({ srStatus }) => srStatus === "MISSED_P" || srStatus === "MISSED")
  const showMissedReason = (row) => (row?.srStatus === "MISSED_P" || row?.srStatus === "MISSED")
  return (
    <Grid
      container
      alignItems="stretch"
      className={classes.rootGlobal}
    >
      <PageHeader title="Executions history" />
      <Grid container item xs={12} direction="row" justify="space-between">
        <Filter
          processes={processes}
          status={statusRef}
          robots={robots}
          tags={tags}
          handleChangeProcess={handleChangeProcess}
          handleChangeStatus={handleChangeStatus}
          handleChangeRobot={handleChangeRobot}
          handleChangeDates={handleChangeDates}
          handleChangetags={handleChangeTags}
          handleRequestSort={handleRequestSort}
          handleChangeTrigger={handleChangeTrigger}
          handleChangeSearchText={handleChangeSearchText}
          handleClearFilter={handleClearFilter}
          columns={columns}
          showCustomDate={showCustomDate}
          setShowCustomDate={setShowCustomDate}
          exportFilteredExecutions={exportFilteredExecutions}
          exportLoading={exportLoading}
          showExportButton={data && data.length > 0}
        />
      </Grid>
      <CustomTableContainer>
        <CustomTable>
          {
        // eslint-disable-next-line no-nested-ternary
        isLoading || !data ? (
          <CircularLoader />
        ) : (data && data.length > 0
              ? data.map((row, index) => (
                <CustomTableRow key={index}>
                  <CustomTableCell verticalAlign="middle">
                    <ProcessIconName
                        imgSrc={row?.process?.icon}
                        avatarName={row.process?.processDescription?.processDisplayName}
                        processName={row?.process?.processDescription?.processDisplayName}
                    />
                  </CustomTableCell>
                  <CustomTableCell title={t("Robot")} text={row?.robot?.robotDisplayName || row?.startingRobot?.robotDisplayName || EMPTY_DEFAULT_VALUE} />
                  <CustomTableCell
title={t("Launching date")}
text={row?.launchingTime
                      ? formatDateByLanguage(row?.launchingTime)
                      : EMPTY_DEFAULT_VALUE} />
                  {row?.executionStartTime && (
                  <CustomTableCell title={t("Start date")} text={formatDateByLanguage(row?.executionStartTime)} />
                   )}
                  {showStartDateCell && !row?.executionStartTime && (
                  <CustomTableCell />
                  )}
                  {row?.executionEndTime && (
                  <CustomTableCell title={t("End date")} text={formatDateByLanguage(row?.executionEndTime)} />
                  )}
                  {showEndDateCell && !row?.executionEndTime && (
                  <CustomTableCell />
                  )}
                  <CustomTableCell title={t("Execution Time")} text={getDateDifference(row?.executionDuration)} />
                  {showMissedReason(row) && <CustomTableCell title={t("missed raison")} text={t(row?.missedReason)} />}
                  {!showMissedReason(row) && showMissedReasonCell && <CustomTableCell />}
                  <CustomTableCell
title={t("Trigger")}
text={row?.executionTrigger
                      ? t(row?.executionTrigger)
                      : EMPTY_DEFAULT_VALUE} />
                  <CustomTableCell verticalAlign="middle">
                    {
                        (row.processStatus || row.srStatus)

                        && <StatusButton
                            status={row.processStatus?.statusCode ? row.processStatus.statusCode : row.srStatus}
                            label={row.processStatus?.statusLabel ? row.processStatus.statusLabel : row.srStatus}
                        />
                    }
                  </CustomTableCell>
                </CustomTableRow>
              ))
              : (
                <DataNotFound
                      message={t("no.executions.message")}
                      icon={OccurrencesNotFound}
                  />
              )
        )
      }
        </CustomTable>
      </CustomTableContainer>

      <Grid container xs={12} direction="row" justify="flex-end">
        {!isLoading && data && data.length > 0 && (
        <CustomPagination
                rowsPerPageOptions={[5, 10, 25]}
                count={count}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                onNext={handleNext}
                onPrevious={handlePrevious}
            />
        )}
      </Grid>
    </Grid>
  );
}

const mapDispatchToProps = {
  fetchExecutions,
  fetchProcessesName,
  fetchAllStatus,
  fetchTagsForFilters,
  fetchExecutionsRobots
};
export default connect(null, mapDispatchToProps)(ExecutionsPage);

const columns = [
  {
    id: "launchingDate",
    label: "Launching date (Descending)",
    order: "desc",
  },
  {
    id: "launchingDate",
    label: "Launching date (Ascending)",
    order: "asc",
  },
  {
    id: "executionDuration",
    label: "Execution Time (Descending)",
    order: "desc",
  },
  {
    id: "executionDuration",
    label: "Execution Time (Ascending)",
    order: "asc",
  },
];
