import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { toast } from "react-toastify";
import get from "lodash/get";
import { isPermitted } from "../../../../components/HasPermission/index.js";
import ConfirmMessage from "../../../../components/ConfirmMessage/index.js";
import CustomTableRow from "../../../../components/TableComponents/CustomTableRow";
import CustomTableCell from "../../../../components/TableComponents/CustomTableCell";
import DetailsIcon from "../../../../components/CustomIcons/DetailsIcon";
import useStyles from "./style.js";

export default function AlertsTableRow({
  row,
  displayFields,
  entityBaseUrl,
  deleteEntity,
  deleteEntityLabel,
  headerCellTitles,
  isDefaultAlertsPage = false,
  isCustomAlertsPage = false,
  fetchEntitiesReset,
}) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [openMsgConfirm, setOpenMsgConfirm] = useState(false);
  const [toDeleteId, setToDeleteId] = useState(null);
  const [isLoading, setIsloading] = useState({
    switch: false,
    deleteAction: false,
  });

  const moduleName = isDefaultAlertsPage ? "default" : "custom";

  const resetLoaders = () => {
    setIsloading({ switch: false, deleteAction: false });
  };

  const cancelConfirm = () => {
    resetLoaders();
    setOpenMsgConfirm(false);
  };

  const confirmDelete = () => {
    setIsloading({ ...isLoading, deleteAction: true });
    dispatch(deleteEntity(toDeleteId)).then(() => {
      fetchEntitiesReset();
      resetLoaders();
      setOpenMsgConfirm(false);
      toast.success(t("alert.management.formControl.delete"))
    });
  };

  const currentUser = useSelector(({ requests }) => get(requests, "queries.FETCH_CURRENT_USER.data"));

  const IconPlaceHolder = () => (
    <Grid item className={classes.rowActionButtons} style={{ width: 40 }}>
      &nbsp;
    </Grid>
  );

  const getFieldData = (valuePath) => get(row, valuePath);

  return (
    <>
      <CustomTableRow>
        {displayFields.map((field, i) => (
            !field.disableSmsFunctionality
            && (field?.customContent ? (
              <CustomTableCell
                        key={i}
                        title={t(headerCellTitles?.fields?.[i]?.label)}
                        text={field?.contentTypeString && (
                            field.customContent({
                              index: get(row, "id"),
                              value: getFieldData(field?.valuePath),
                              field: field.valuePath,
                            })
                        )}
                        verticalAlign={field?.verticalAlign}
                        width={field?.width}
                    >
                {!field?.contentTypeString && field.customContent({
                        field: field.valuePath,
                        value: getFieldData(field?.valuePath),
                        index: get(row, "id"),
                      })}
              </CustomTableCell>
                ) : (
                  <CustomTableCell
                        key={i}
                        title={t(headerCellTitles?.fields?.[i]?.label)}
                        text={getFieldData(field?.valuePath)}
                        verticalAlign={field?.verticalAlign}
                        width={field?.width}
                    />
                )
            )
        ))}
        <CustomTableCell verticalAlign="middle">
          <Grid container xs={12} justify="flex-end" alignItems="center" spacing={1}>
            <Grid item>
              <DetailsIcon
                  key={`${moduleName}-alert-view-btn`}
                  tooltipTitle={t("tooltip.action.view")}
                  onClickIcon={() => history.push(`${entityBaseUrl}/view/${get(row, "id")}`)}
              />
            </Grid>

            {(isDefaultAlertsPage
                && isPermitted(currentUser, "Edit default alerts"))
            || (isCustomAlertsPage
                && isPermitted(currentUser, "Edit custom alerts")) ? (
                  <Grid item>
                    <Tooltip placement="top" title={t("tooltip.action.edit")}>
                      <IconButton
                        id={`${moduleName}-alert-edit-btn`}
                        aria-label="edit"
                        size="small"
                        onClick={() => history.push(`${entityBaseUrl}/edit/${get(row, "id")}`)}
                    >
                        <EditIcon
                         className={classes.rowActionButtons}
                      />
                      </IconButton>
                    </Tooltip>
                  </Grid>
            ) : (
              <IconPlaceHolder />
            )}
            {(isDefaultAlertsPage
                && isPermitted(currentUser, "Delete default alert"))
            || (isCustomAlertsPage
                && isPermitted(currentUser, "Delete custom alert")) ? (
                  <Grid item>
                    <Tooltip placement="top" title={t("tooltip.action.delete")}>
                      <IconButton
                        id={`${moduleName}-alert-delete-btn`}
                        aria-label="delete"
                        size="small"
                        onClick={() => {
                          setToDeleteId(get(row, "id"));
                          setOpenMsgConfirm(true);
                        }}
                    >
                        <DeleteIcon
                          className={(classes.rowActionButtons, classes.deleteIcon)}
                      />
                      </IconButton>
                    </Tooltip>
                  </Grid>
            ) : (
              <IconPlaceHolder />
            )}
          </Grid>
        </CustomTableCell>
      </CustomTableRow>
      <ConfirmMessage
        message={
          typeof deleteEntityLabel === "string"
            ? t(deleteEntityLabel)
            : deleteEntityLabel
        }
        openStart={openMsgConfirm}
        onCancel={cancelConfirm}
        onConfirm={confirmDelete}
        buttonConfirm={t("Delete")}
        buttonCancel={t("Cancel")}
        isDelete="true"
        isLoading={isLoading.deleteAction}
      />
    </>
  );
}
