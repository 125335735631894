export const itemsColumns = [
  {
    id: "loadedDatetime",
    label: "Creation date (Descending)",
    order: "desc",
  },
  {
    id: "loadedDatetime",
    label: "Creation date (Ascending)",
    order: "asc",
  },
  {
    id: "completedDatetime",
    label: "End date (Descending)",
    order: "desc",
  },
  {
    id: "completedDatetime",
    label: "End date (Ascending)",
    order: "asc",
  },
  {
    id: "executionDuration",
    label: "Execution Time (Descending)",
    order: "desc",
  },
  {
    id: "executionDuration",
    label: "Execution Time (Ascending)",
    order: "asc",
  },
];

export default itemsColumns;
