import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import CustomDialog from "pages/Services/components/CustomDialog";
import { Close } from "@material-ui/icons";
import React
  from "react";
import moment from "moment";
import WarningIcon from "@material-ui/icons/Warning";
import useStyles from "./style";
import { EnhancedTableToolbar } from "../../../../components/DataTable/EnhancedTableToolbar";
import EnhancedTableHead from "../../../../components/DataTable/EnhancedTableHead";

function ConflitManagement({
  openStart, handleClose, t, warnings, selectedDay,
}) {
  const classes = useStyles();
  const headCells = [
    {
      id: "Interval",
      label: t("Interval"),
    },
    {
      id: "Number of executions",
      label: t("Number of executions"),
    }, {
      id: "Reasons",
      label: t("Reasons"),
    },
  ];
  return (
    <CustomDialog
      open={openStart}
      maxWidth="lg"
      onClose={handleClose}
    >
      <Paper square>
        <Grid container direction="row" xs={12}>
          <Grid container direction="row" xs={11}>
            <Tabs
              id="process-tabs-page"
              indicatorColor="primary"
              textColor="primary"
            >
              <span style={{ display: "flex", alignItems: "center", marginLeft: "5px" }}>
                <span style={{ color: "orange" }}>
                  <WarningIcon />
                </span>
                <Tab id="process-overview-tab" label={t("Warnings Management")} disabled="true" style={{ opacity: "1" }} />
              </span>
            </Tabs>
          </Grid>
          <Grid container direction="row" xs={1} justify="flex-end">
            <IconButton aria-label="close" onClick={handleClose}>
              <Close />
            </IconButton>
          </Grid>
        </Grid>
      </Paper>
      <EnhancedTableToolbar
        title={`${t("List of conflicts for")} ${moment(selectedDay).format("yyyy-MM-DD")}`}
      />
      <TableContainer className={classes.scheduleTable} style={{ width: "100%" }}>
        <Table stickyHeader aria-label="Actions Table" classes={{ root: classes.table }}>
          <EnhancedTableHead
            rowCount={1}
            classes={classes}
            headCells={headCells}
            align="center"
          />
          <TableBody>
            {warnings
                && warnings.length > 0
                && warnings.map(
                  (row, index) => (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={index}
                    >
                      <TableCell align="center" width="25%">
                        {`${moment(row.startDateRange).format("HH:mm A")} - ${moment(row.endDateRange).format("HH:mm A")}`}
                      </TableCell>
                      <TableCell align="center" width="30%">
                        {row.planners.length}
                      </TableCell>
                      <TableCell>
                        <ul>
                          {row.reason.map((row) => <li>{row}</li>)}
                        </ul>
                      </TableCell>
                    </TableRow>
                  ),
                )}
          </TableBody>
        </Table>
      </TableContainer>
    </CustomDialog>
  );
}
export default ConflitManagement;
