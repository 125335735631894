import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import ButtonBase from "@material-ui/core/ButtonBase";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";
import ReplayIcon from "@material-ui/icons/Replay";
import { updatePageNumber } from "redux/slices/exceptionFilter";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  maxItemDetailsCount,
  minimizeString,
} from "../../../util";
import { HasPermission } from "../../../components/HasPermission";
import { SrWorkflowIcon } from "components/CustomIcons";
import DataNotFound from "../../../components/DataNotFound";
import AttemptChip from "../../../components/AttemptChip";
import WorkflowChip from "../ItemExceptions/Workflow/WorkflowChip";
import CustomTableContainer from "../../../components/TableComponents/CustomTableContainer";
import CustomTable from "../../../components/TableComponents/CustomTable";
import CustomTableRow from "../../../components/TableComponents/CustomTableRow";
import CustomTableCell from "../../../components/TableComponents/CustomTableCell";
import DetailsIcon from "../../../components/CustomIcons/DetailsIcon";
import ProcessIconName from "../../../components/TableComponents/ProcessIconName";
import ExceptionsNotFound from "../../../assets/Exceptions.svg";
import { isDataMappingRetryDisabled } from "../../../util/helpers/filters";
import CopyToClipboardButton from "../../../components/CopyToClipboardButton";
import useStyles from "../style";

const ITEM_CELL_PADDING = {
  paddingTop: "0",
  paddingBottom: "0",
  paddingInline: "5px",
};

function ExceptionsContent({
  isSelected,
  onClickRow,
  data,
  getDate,
  getDateDifference,
  handleShowExceptionDetails,
  deleteException,
  handleClick,
  deletePermission,
  isItemList,
  endDateLabel,
  setRetryItems,
  setWorkflowItem,
  setOriginalItemId,
}) {
  const selectRow = (e, row) => {
    if (e.target.id !== "checkbox") onClickRow(row);
  };
  const [isExceptionReasonTooltipOpen, setIsExceptionReasonTooltipOpen] = useState(false);

  const { t } = useTranslation();
  const classes = useStyles();

  const dispatch = useDispatch();
  const handleAttempClick = (originalItemId) => {
    setOriginalItemId(originalItemId);
    dispatch(updatePageNumber({ pageNo: 0 }));
  };

  // Mapping components

  const processName = (row) => (
    <ProcessIconName
      imgSrc={row?.processDto?.icon}
      avatarName={row?.processDto?.processDescription?.processDisplayName}
      processName={row?.processDto?.processDescription?.processDisplayName}
    />
  );

  const processDetailsObject = (parsedData) => parsedData?.filter(({ displayInItemsList }) => displayInItemsList)?.slice(0, 3);

  const processDetail = (row, key) => (
    <CustomTableCell
      {...(isItemList && ITEM_CELL_PADDING)}
      title={key}
      text={row || "---"}
    />
  );

  const emptyProcessDetails = (row, EmptyComponent) => {
    const maxItemDetailsCountData = maxItemDetailsCount(data);
    const currentItemDetailsCount = processDetailsObject(row?.parsedData)?.length;
    return (
      maxItemDetailsCountData - currentItemDetailsCount > 0
      && Array(maxItemDetailsCountData - currentItemDetailsCount)
        ?.fill("")
        ?.map(() => EmptyComponent)
    );
  };

  const processCreationDate = (row) => (
    <CustomTableCell
      {...(isItemList && ITEM_CELL_PADDING)}
      title={t("Creation date")}
      text={row?.startDateTime ? getDate(row?.startDateTime) : "---"}
    />
  );

  const processEndDate = (row) => (
    <CustomTableCell
      {...(isItemList && ITEM_CELL_PADDING)}
      title={endDateLabel}
      text={row?.endDateTime ? getDate(row?.endDateTime) : "---"}
    />
  );

  const processExecutionTime = (row) => (
    <CustomTableCell
      {...(isItemList && ITEM_CELL_PADDING)}
      title={t("Execution Time")}
      text={
        row?.startDateTime && row?.endDateTime
          ? getDateDifference(row?.executionDuration)
          : "---"
      }
    />
  );

  const processExecutionType = (row) => (
    <CustomTableCell
      {...(isItemList && ITEM_CELL_PADDING)}
      title={t("Exception Type")}
      text={row?.exceptionType ? row?.exceptionType : "---"}
    />
  );

  const processExecutionReason = (row) => (
    <CustomTableCell
      {...(isItemList && ITEM_CELL_PADDING)}
      title={t("Exception Reason")}
    >
      <Tooltip
          placement="left-start"
          classes={{ tooltip: classes.exceptionReasonTooltip }}
          interactive
          open={isExceptionReasonTooltipOpen === row?.id}
          onOpen={() => setIsExceptionReasonTooltipOpen(row?.id)}
          onClose={() => {
            setIsExceptionReasonTooltipOpen(null);
          }}
          title={
            <Box className={classes.exceptionReasonTooltipWrapper}>
              <Typography
                  variant="body2"
                  align="left"
                  component="div"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  className={classes.exceptionReasonText}
              >
                {row?.exceptionReason}
              </Typography>
              {row?.exceptionReason && row?.exceptionReason?.length > 1300 && (
              <CopyToClipboardButton
                      className={classes.iconCopy}
                      text={row?.exceptionReason}
                  />
              )}
            </Box>
          }
      >
        <ButtonBase className={classes.exceptionReasonButton} disableRipple>
          <Typography
              align="left"
              className={classes.exceptionReason}
          >
            {row?.exceptionReason
                ? minimizeString(row?.exceptionReason, 300)
                : "---"}
          </Typography>
        </ButtonBase>
      </Tooltip>
    </CustomTableCell>
  );

  const processActions = (row) => (
    <>
      <Box
        display="flex"
        alignItems="flex-start"
        justifyContent="flex-end"
        height="fit-content"
      >
        {isItemList && (
          <AttemptChip
            attempt={row.attempt}
            handleAttempClick={handleAttempClick}
            originalItemId={row.originalItemId}
          />
        )}
        <Box
          component={Grid}
          item
          display="flex"
          alignItems="flex-start"
          justifyContent="flex-end"
          gridColumnGap={5}
        >
          {row?.data && (
            <DetailsIcon
              tooltipTitle={t("Execution detail")}
              onClickIcon={() => handleShowExceptionDetails(row)}
            />
          )}
          {!isItemList && (
            <HasPermission name={deletePermission}>
              <Tooltip
                title={
                  row.isDeleted
                    ? t("tooltip.action.show")
                    : t("tooltip.action.hide")
                }
              >
                <IconButton
                  className={
                    row.isDeleted ? classes.showIcon : classes.deleteIcon
                  }
                  edge="end"
                  aria-label="view"
                  onClick={(e) => {
                    e.stopPropagation();
                    deleteException(e, row);
                  }}
                  size="small"
                >
                  {row.isDeleted ? (
                    <VisibilityIcon fontSize="small" />
                  ) : (
                    <VisibilityOffIcon fontSize="small" />
                  )}
                </IconButton>
              </Tooltip>
            </HasPermission>
          )}
          {isItemList && (
            <>
              <HasPermission name="Workflow">
                <Tooltip title={t("exception-workflow.open")}>
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      setWorkflowItem(row);
                    }}
                    size="small"
                  >
                    <SrWorkflowIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </HasPermission>
              <HasPermission name="Retry item exceptions">
                {!isDataMappingRetryDisabled(row?.processDto?.orchestrator?.orchestratorType) && (
                  <Tooltip title={t("retry")}>
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        setRetryItems([row]);
                      }}
                      size="small"
                    >
                      <ReplayIcon
                        fontSize="small"
                        className={classes.replayIcon}
                      />
                    </IconButton>
                  </Tooltip>
                )}
              </HasPermission>
            </>
          )}
          <Checkbox
            id="checkbox"
            checked={isSelected(row?.id)}
            onChange={(event) => handleClick(event, row)}
            size="small"
            className={classes.selectCheckbox}
          />
        </Box>
      </Box>
      {isItemList && <WorkflowChip item={row} />}
    </>
  );

  return data.length > 0 ? (
    <CustomTableContainer>
      <CustomTable>
        {data?.map((row, index) => (isItemList ? (
          <Card className={classes.root} onClick={(e) => selectRow(e, row)}>
            <CardContent
                classes={{
                  root: classes.cardContent,
                }}
                key={`item-${index}`}
              >
              <Box
                  display="grid"
                  gridTemplateColumns="1fr 220px"
              >
                <Grid>
                  <Grid item xs={12}>
                    {processName(row)}
                  </Grid>
                  <Box
                      component={Grid}
                      item
                      xs={12}
                      container
                      display="grid !important"
                      gridTemplateColumns="repeat(auto-fit, minmax(100px, 1fr))"
                      pl={5.6}
                    >
                    {row?.parsedData
                        && processDetailsObject(row.parsedData)?.map((parsed, i) => (
                          parsed.displayInItemsList && (
                            <Box key={`item-${i}`}>
                              {processDetail(parsed?.value, parsed?.name)}
                            </Box>
                          )
                        ))}
                    {emptyProcessDetails(row, <Grid />)}
                    <Box>{processCreationDate(row)}</Box>
                    <Box>{processEndDate(row)}</Box>
                    <Box>{processExecutionTime(row)}</Box>
                    <Box>{processExecutionType(row)}</Box>
                    <Box>{processExecutionReason(row)}</Box>
                  </Box>
                </Grid>
                <Grid
                    container
                    justify="flex-end"
                    className={classes.action}
                  >
                  {processActions(row)}
                </Grid>
              </Box>
            </CardContent>
          </Card>
          ) : (
            <CustomTableRow key={index}>
              <CustomTableCell verticalAlign="middle">
                {processName(row)}
              </CustomTableCell>
              {row?.parsedData
                && processDetailsObject(row?.parsedData)?.map((parsed, i) => (
                  <CustomTableCell
                    title={parsed?.name}
                    text={parsed?.value || "---"}
                    key={i}
                  />
                ))}
              {emptyProcessDetails(row, <CustomTableCell />)}

              {processCreationDate(row)}
              {processEndDate(row)}
              {processExecutionTime(row)}
              {processExecutionType(row)}
              {processExecutionReason(row)}
              <CustomTableCell align="right" verticalAlign="middle">
                <Grid container justify="flex-end" className={classes.action}>
                  {processActions(row)}
                </Grid>
              </CustomTableCell>
            </CustomTableRow>
          )))}
      </CustomTable>
    </CustomTableContainer>
  ) : (
    (isItemList && (
      <DataNotFound
        message={t("no.items.exception.message")}
        icon={ExceptionsNotFound}
      />
    ))
      || (!isItemList && (
        <DataNotFound
          message={t("no.process.exception.message")}
          icon={ExceptionsNotFound}
        />
      ))
  );
}

export default ExceptionsContent;
