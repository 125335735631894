import { createMuiTheme } from "@material-ui/core/styles";

export const fontFamily = "Nunito"

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#303167",
    },
    secondary: {
      main: "#303167",
      contrastText: "#FFFFFF",
    },
    background: {
      default: "#FFFFFF",
    },
  },
  button: {
    contained: {
      paddingTop: "8px",
      paddingBottom: "8px",
    },
  },
  typography: {
    fontFamily,
  },
  props: {
    MuiCard: {
      elevation: 0,
    },
  },
  custom: {
    variables: {
      menuLeftWidth: 120,
      headerHeight: 130,
    },
    color: {
      color1: "#28ac47", // Green
      color2: "#303167", // Blue
      color3: "#ff9600", // Orange
      color4: "#ed4949", // Red
      color5: "#733999", // Mauve
      color6: "#ff0688", // SecondaryColor : pink
      color7: "#B2C0FF",
      color8: "#DEE3FF",
      color9: "#96bee0",
      color10: "rgba(48, 49, 103, 0.5)",
      errorChipColor: "#d93025",
      alertDefaultIconColor: "rgba(0, 0, 0, 0.54)",
      lightPurple: "#c7cded",
      bakgroundColor: "#f4f7fa",
      containerBackground: "#F9FAFF",
      pending: "#e2b507",
      pendingBackground: "#fcf9e6",
      running: "#3d6db4",
      runningBackground: "#e7effa",
      unknown: "#e00914",
      unknownBackground: "#fce6e5",
      done: "#63b335",
      doneBackground: "#f1f6e5",
      stopped: "#733999",
      stoppedBackground: "#dcd5e0",
      archived: "#0e0414",
      archivedBackground: "#cfcfcf",
      stopping: "#e00914",
      stoppingBackground: "#f7e4e4",
      warning: "#f4970b",
      warningBackground: "#f7f1e4",
      chartColor1: "#D0D8FF",
      chartColor2: "#7BB0FF",
      chartColor3: "#FF067E",
      chartColor4: "#E0E0E0",
      staticInput: "#EFF2FF",
      containersBorder: "#DEE4FF",
      inputShrink: "#8282a5",
      chartColor2Light: "rgba(123, 176, 255, 0.15)",
      button: {
        primary: "#303167",
        primaryHover: "rgba(48,49,103,0.9)",
        secondary: "#B2C0FF",
        secondaryHover: "rgba(178,192,255,0.9)",
        tertiary: "#FF067E",
        tertiaryHover: "rgba(255,6,126,0.9)",
        cancel: "#E0E0E0",
        cancelHover: "rgba(224,224,224,0.9)",
        disabled: "rgba(224,224,224,0.9)",
        disabledText: "rgba(48,49,103,0.5)",
        textLight: "#FFF",
        textDark: "#303167",
        disabledIcon: "#aeaeae"
      },
      icons: {
        visibility: "#8181A4",
        retry: "#ff0688",
        lock: "#8181A4",
        delete: "#FF067E",
        validate: "#7BB0FF",
        error: "#FF0000",
        valide: "#28AC47"
      },
      dialog: {
        title: "#303167",
        contentText: "#8181A4"
      },
      color6Light: "rgba(255, 6, 126, 0.13) ",
      dividerColor: "#AAA",
      redStatus: "#ef2929",
      blueStatus: "#303167",
      greenStatus: "#31d031",
      orangeStatus: "#ff7600",
    },
    shadow: "0 2px 5px rgba(101, 101, 101, 0.18)",
    shadow2: "0 3px 12px rgba(101, 101, 101, 0.25)",
    shadowMenu: "0 3px 12px rgba(101, 101, 101, 0.25)",
    palette: {
      factors: {
        simple: {
          bgColor: "#8EFFA8",
          textColor: "#13a535",
        },
        medium: {
          bgColor: "#eef161",
          textColor: "#556409",
        },
        complex: {
          bgColor: "#FFC8C8",
          textColor: "#961212",
        },
      },
      status: {
        accepted: "#13a535",
        rejected: "#F65353",
        submitted: "#536ff6",
        roadMap: "#536ff6",
        pending: "#97979a",
        draft: "#97979a",
        notSubmitted: "#97979a",
      },
      status2: {
        accepted: {
          borderColor: "#13a535",
          bgColor: "#aeffbf",
          textColor: "#13a535",
        },
        rejected: {
          bgColor: "#ffdede",
          textColor: "#F65353",
          borderColor: "#F65353",
        },
        submitted: {
          borderColor: "#96bee0",
          bgColor: "#c6e4ff",
          textColor: "#536ff6",
        },
        pending: {
          bgColor: "#eaeaea",
          textColor: "#97979a",
          borderColor: "#97979a",
        },
        draft: {
          bgColor: "#eaeaea",
          textColor: "#97979a",
          borderColor: "#97979a",
        },
        "not submitted": {
          bgColor: "#eaeaea",
          textColor: "#97979a",
          borderColor: "#97979a",
        },
        roadMap: {
          borderColor: "#536ff6",
          bgColor: "#c6e4ff",
          textColor: "#536ff6",
        },
      },
      actions: {
        edit: "#536ff6",
        accept: "#13a535",
        reject: "#F65353",
        delete: "#F65353",
        submit: "#536ff6",
      },
    },
  },
});

theme.overrides = {
  ...theme.overrides,
  MuiCheckbox: {
    colorSecondary: {
      "&$checked": {
        color: theme.custom.color.chartColor2,
      },
    },
  },
  MuiTableHead: {
    root: {
      backgroundColor: "#f4f7fa",
    },
  },
  MuiTableCell: {
    root: {
      fontSize: "0.8rem",
      padding: `10px ${theme.spacing(2)}px`,
    },
    head: {
      color: "#3c4d6f",
    },
  },
  MuiCard: {
    root: {
      borderRadius: "0px",
    },
  },
  MuiCardHeader: {
    title: {
      fontSize: "18px",
    },
  },
  MuiPaper: {
    rounded: {
      borderRadius: "10px",
    },
  },
  MuiTablePagination: {
    caption: {
      fontSize: "0.875rem",
      fontFamily,
      fontWeight: "300",
    },
  },
  MuiAutocomplete: {
    inputRoot: {
      fontSize: "1rem",
      fontFamily,
      fontWeight: "400",
    },
    listbox: {
      fontSize: "1rem",
      fontFamily,
      fontWeight: "400",
    },
  },
  MuiInputLabel: {
    root: {
      fontSize: "1rem",
      fontFamily,
      fontWeight: "600",
    },
  },
  MuiTextField: {
    border: "1px solid #eaeaea",
  },
  MuiInputBase: {
    styleOverrides: {
      input: {
        color: "white",
      },
    },
  },
  MuiButton: {
    label: {
      fontFamily,
    }
  },
  MuiChip: {
    label: {
      fontFamily
    }
  }
};
theme.shadows[24] = "0 1px 10px -1px rgba(0,0,0,.2)";
theme.shadows[25] = "0 1px 15px 1px rgba(69,65,78,.08)";
theme.shadows[26] = "0px 0px 0px 1px rgba(69,65,78,.1)";

export default theme;
