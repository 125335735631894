import React from "react";
import { Tooltip, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useStyles from "./style";

const CloseAlertButton = ({ url }) => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  return (
    <div className={classes.editButtonContainer}>
      <Tooltip placement="bottom" title={t("tooltip.action.close")}>
        <IconButton
          className={classes.editButton}
          aria-label="close"
          onClick={() => {
            history.push(url);
          }}
        >
          <CloseIcon />
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default CloseAlertButton;
