import React from "react";
import { IconButton, Tooltip } from "@material-ui/core";
import { ReactComponent as ShowFiltersIcon } from "../../assets/common/showfilters.svg";
import { useTranslation } from "react-i18next";

const ShowMoreFilters = ({ showAll, handleShowMore, size = 21 }) => {
    const { t } = useTranslation()
    return (
      <Tooltip title={!showAll ? t("Show more filters") : t("Hide filters")} placement="top">
        <IconButton aria-label="more filter" onClick={handleShowMore}>
          <ShowFiltersIcon
width={size}
                                 height={size}
                />
        </IconButton>
      </Tooltip>
    )
};

export default ShowMoreFilters;
