import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Avatar from "@material-ui/core/Avatar";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import FormHelperText from "@material-ui/core/FormHelperText";
import PersonIcon from "@material-ui/icons/Person";
import EditIcon from "@material-ui/icons/Edit";
import { useForm } from "react-hook-form";
import get from "lodash/get";
import {
  fetchAllFleets,
  fetchCivilities,
  fetchCurrentUser,
  updateProfile,
} from "../../../redux/actions/services";
import ImageProfileCmp from "../../Administration/ImageProfile";
import { REGEX } from "../../../util";
import ConfirmMessage from "../../../components/ConfirmMessage";
import { toast } from "react-toastify";
import CustomButton from "../../../components/CustomButton";

const MODE = {
  EDIT: "edit",
  VIEW: "view",
};

const getImageURL = (path) => `${process.env.REACT_APP_DOMAIN}api/admin${path}`;

const ChangePasswordRequest = ({ classes }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [logo, setLogo] = useState(null);
  const [logoPath, setLogoPath] = useState();
  const [openMsgConfirm, setOpenMsgConfirm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [mode, setMode] = useState(MODE.VIEW);

  const currentUser = useSelector(({ requests }) => get(requests, "queries.FETCH_CURRENT_USER.data"));

  const civilities = useSelector(
    ({ requests }) => requests.queries.FETCH_USERS_CIVILITIES?.data,
  );

  const idUser = currentUser?.id;

  const dataLang = [
    {
      key: "en",
      text: "English",
    },
    {
      key: "fr",
      text: "French",
    },
    {
      key: "es",
      text: "Spanish",
    },
    {
      key: "pl",
      text: "Polish",
    },
    {
      key: "de",
      text: "Germany",
    },
    {
      key: "ar",
      text: "العربية",
    },
  ];

  const {
    formState: { errors },
    handleSubmit,
    setValue,
    getValues,
    trigger,
    clearErrors,
    register,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      lastName: "",
      firstName: "",
      civility: "",
      phoneNumber: "",
      communicationLang: "",
    },
  });

  useEffect(() => {
    dispatch(fetchCivilities()).then(() => {
      dispatch(fetchAllFleets());
    });

    if (currentUser) {
      setValue("civility", currentUser?.civility?.code);
      setValue("lastName", currentUser?.lastName);
      setValue("firstName", currentUser?.firstName);
      setValue("phoneNumber", currentUser?.phoneNumber);
      setValue("communicationLang", currentUser?.communicationLang);
      setLogoPath(currentUser?.logo);
    }
  }, [currentUser]);

  const handleSaveClick = async () => {
    const result = await trigger();

    if (result) {
      setOpenMsgConfirm(true);
    }
  };

  const confirmSave = () => {
    handleSubmit(saveUserProfile)();
  };

  const cancelConfirm = () => {
    setOpenMsgConfirm(false);
  };

  const saveUserProfile = (data) => {
    setIsLoading(true);
    const dataToSubmit = {
      ...data,
      civility: civilities.find((civ) => civ.code === data.civility),
    };

    dispatch(
      updateProfile(dataToSubmit, logo, (res) => {
        if (res?.status === 200) {
          dispatch(fetchCurrentUser());
          toast.success(t("user.update.success"))
        } else {
          toast.error(t("user.update.fail"))
        }
        setIsLoading(false);
        setOpenMsgConfirm(false);
        setMode(MODE.VIEW);
      }),
    );
  };

  return (
    <Grid className={classes.app}>
      <form
        className={classes.fleetForm}
        onSubmit={handleSubmit(handleSaveClick)}
      >
        <Grid container alignItems="center" justify="center" xs={12}>
          <Grid container xs={8} item>
            <Grid container item xs={2} />
            <Grid
              item
              xs={8}
              container
              alignItems="center"
              justify="center"
              className={classes.formRow}
            >
              {/* eslint-disable-next-line no-nested-ternary */}
              {mode === "add" || mode === "edit" ? (
                <ImageProfileCmp
                  src={logoPath}
                  changeValue={setLogo}
                  disabled={mode === MODE.VIEW}
                />
              ) : logoPath ? (
                <Avatar
                  alt="Fleet logo"
                  className={classes.large}
                  src={getImageURL(logoPath)}
                />
              ) : (
                <Avatar className={classes.large}>
                  <PersonIcon className={classes.avatarIcon} />
                </Avatar>
              )}
            </Grid>
            <Grid container item xs={2} justify="flex-end">
              <Grid item>
                <Tooltip placement="top" title={t("tooltip.action.edit")}>
                  <IconButton
                    id="user-profile-edit-btn"
                    aria-label="edit"
                    onClick={() => setMode(MODE.EDIT)}
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>

            <Grid item xs={12} container className={classes.formRow}>
              <Grid item xs={6} className={classes.inputsSection}>
                <TextField
                  disabled
                  id="user-profile-fleet-naem"
                  label={t("user.management.formLabel.fleet")}
                  fullWidth
                  InputLabelProps={{
                    shrink: currentUser?.fleet?.companyName?.trim(),
                  }}
                  value={currentUser?.fleet?.companyName}
                />
              </Grid>
              <Grid item xs={6} className={classes.inputsSection}>
                <FormControl className={classes.formControl} fullWidth>
                  <InputLabel id="civility-select">
                    {`${t("user.add.civility")} *`}
                  </InputLabel>
                  <Select
                    disabled={mode === MODE.VIEW}
                    className={classes.initialText}
                    {...register("civility", {
                      required: {
                        value: true,
                        message: t("user.management.formControl.required"),
                      },
                    })}
                    fullWidth
                    labelId="civility-select"
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    }}
                    onChange={(e) => {
                      clearErrors("civility");
                      setValue("civility", e.target.value);
                    }}
                    value={getValues("civility")}
                  >
                    {civilities
                      && civilities.map((elt, i) => (
                        <MenuItem
                          key={`user-management-form-civility-option-${i}`}
                          value={elt.code}
                        >
                          {t(elt.label)}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>

                <p className={classes.validation_error}>
                  {errors.subscription?.message}
                </p>
              </Grid>
            </Grid>
            <Grid item xs={12} container className={classes.formRow}>
              <Grid item xs={6} className={classes.inputsSection}>
                <TextField
                  disabled={mode === MODE.VIEW}
                  {...register("lastName", {
                    required: {
                      value: true,
                      message: t("user.management.formControl.required"),
                    },
                  })}
                  id="lastName"
                  label={`${t("user.add.lastName")} *`}
                  fullWidth
                  InputLabelProps={{
                    shrink: !!getValues("lastName"),
                  }}
                  error={!!errors.lastName?.message}
                  onChange={(event) => {
                    clearErrors("lastName");
                    setValue("lastName", event.target.value);
                  }}
                />

                <p className={classes.validation_error}>
                  {errors.lastName?.message}
                </p>
              </Grid>
              <Grid item xs={6} className={classes.inputsSection}>
                <TextField
                  disabled={mode === MODE.VIEW}
                  id="firstName"
                  {...register("firstName", {
                    required: {
                      value: true,
                      message: t("user.management.formControl.required"),
                    },
                  })}
                  label={`${t("user.add.firstName")} *`}
                  fullWidth
                  InputLabelProps={{
                    shrink: !!getValues("firstName"),
                  }}
                  error={!!errors.firstName?.message}
                  onChange={(event) => {
                    clearErrors("firstName");
                    setValue("firstName", event.target.value);
                  }}
                />

                <p className={classes.validation_error}>
                  {errors.firstName?.message}
                </p>
              </Grid>
            </Grid>
            <Grid item xs={12} container className={classes.formRow}>
              <Grid item xs={6} className={classes.inputsSection}>
                <TextField
                  id="email"
                  label={`${t("user.add.email")} *`}
                  fullWidth
                  InputLabelProps={{
                    shrink: currentUser?.fleet?.email?.trim(),
                  }}
                  disabled
                  value={currentUser?.email}
                />
              </Grid>
              <Grid item xs={6} className={classes.inputsSection}>
                <TextField
                  disabled={mode === MODE.VIEW}
                  id="phoneNumber"
                  {...register("phoneNumber", {
                    pattern: {
                      value: REGEX.TEL,
                      message: t("user.management.formControl.phone"),
                    },
                  })}
                  label={t("fleet.management.formLabel.phoneNumber")}
                  fullWidth
                  error={!!errors.phoneNumber?.message}
                  onChange={(event) => {
                    clearErrors("phoneNumber");
                    setValue("phoneNumber", event.target.value);
                  }}
                  InputLabelProps={{
                    shrink: !!getValues("phoneNumber"),
                  }}
                />
                <p className={classes.validation_error}>
                  {errors.phoneNumber?.message}
                </p>
              </Grid>
            </Grid>
            <Grid item xs={12} container className={classes.formRow}>
              <Grid item xs={6} className={classes.inputsSection}>
                <FormControl className={classes.formControl} fullWidth>
                  <InputLabel id="lang-select">
                    {`${t("user.add.comm.lang")} *`}
                  </InputLabel>
                  <Select
                    disabled={mode === MODE.VIEW}
                    className={classes.initialText}
                    {...register("communicationLang", {
                      required: {
                        value: true,
                        message: t("user.management.formControl.required"),
                      },
                    })}
                    fullWidth
                    labelId="lang-select"
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    }}
                    onChange={(e) => {
                      clearErrors("communicationLang");
                      setValue("communicationLang", e.target.value);
                    }}
                    value={getValues("communicationLang")}
                  >
                    {dataLang.map((lang) => (
                      <MenuItem key={lang.key} value={lang.key}>
                        {t(lang.text)}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    {t("user.add.comm.lang.helperText")}
                  </FormHelperText>
                </FormControl>
                <p className={classes.validation_error}>
                  {errors.subscription?.message}
                </p>
              </Grid>
            </Grid>
            <Grid
              container
              justify="center"
              className={classes.buttonContainer}
            >
              <Grid
                container
                item
                xs={12}
                className={classes.inputsSection}
                justify="center"
              >
                <CustomButton
                  variant="contained"
                  size="medium"
                  disabled={mode === MODE.VIEW}
                  onClick={() => setMode(MODE.VIEW)}
                  style={{ marginLeft: "15px" }}
                >
                  {idUser ? t("user.button.cancel") : t("user.button.save")}
                </CustomButton>
                <CustomButton
                  view="primary"
                  size="medium"
                  type="submit"
                  disabled={mode === MODE.VIEW}
                  style={{ marginLeft: "15px" }}
                >
                  {idUser ? t("user.button.update") : t("user.button.save")}
                </CustomButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
      <ConfirmMessage
        message={
          idUser ? t("user.update.confirmMsg") : t("user.save.confirmMsg")
        }
        openStart={openMsgConfirm}
        onCancel={cancelConfirm}
        onConfirm={confirmSave}
        buttonConfirm={idUser ? t("user.button.update") : t("user.button.save")}
        buttonCancel={t("Cancel")}
        isLoading={isLoading}
      />
    </Grid>
  );
};

export default ChangePasswordRequest;
