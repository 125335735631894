import React from "react";
import { Alert } from "@material-ui/lab";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import Collapse from "@material-ui/core/Collapse";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Input from "@material-ui/core/Input";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { cloneDeep } from "lodash";
import { useDispatch } from "react-redux";
import { useQuery } from "@redux-requests/react";
import { bulkRetryItemException, checkPendingRetry, retryItemException } from "../../../../redux/actions/services";
import { isActionPemitted } from "../../../../components/HasPermission";
import { FETCH_CURRENT_USER } from "../../../../redux/constants";
import CustomDialog from "../../../../components/CustomDialog";
import useStyles from "./style";

function RetryDialog({
  open, onClose, items = [], processIsRetryable, fetchData, resetSelected
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [pendingRetryCheck, setPendingRetryCheck] = React.useState(null);
  const [dataType, setDataType] = React.useState("same");
  const [data, setData] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [loadingMarkAsDone, setLoadingMarkAsDone] = React.useState(false);
  const currentUser = useQuery({ type: FETCH_CURRENT_USER }).data;
  const isRetryableWithData = items[0]?.processDto?.isRetryable && items[0]?.processDto?.isRetryableWithData;
  React.useEffect(() => {
    if (items.length === 1) {
      setData(items[0].parsedData);
      dispatch(checkPendingRetry(items[0].originalItemId)).then((res) => {
        if (res.status === 200) {
          // res.data can either be true or false
          setPendingRetryCheck(res.data);
        } else {
          onClose();
        }
      });
    }
  }, [items]);

  const retry = (markAsDone = false) => {
    changeLoadingState(markAsDone, true)
    if (items.length === 1) {
      dispatch(retryItemException(items[0].id, dataType === "changed", data, markAsDone)).then((res) => {
        if (res.status === 200) {
          if (res.data) {
            toast.success(t("retry-dialog.message.success.one-item"))
            if (markAsDone) {
              resetSelected();
              fetchData();
            }
            onClose();
          } else {
            setPendingRetryCheck(true);
          }
        } else {
          toast.error(t("retry-dialog.message.error.one-item"))
        }
        changeLoadingState(markAsDone, false);
      });
    } else if (items.length > 1) {
      dispatch(bulkRetryItemException(items.map((i) => i.id), markAsDone)).then((res) => {
        if (res.status === 200 && res.data > 0) {
          toast.success(t("retry-dialog.message.success.multiple-items", { count: res.data }))
          if (markAsDone) {
            resetSelected();
            fetchData();
          }
          onClose();
        } else {
          toast.error(t("retry-dialog.message.error.multiple-items"))
        }
        changeLoadingState(markAsDone, false);
      });
    }
  };
  const changeLoadingState = (markAsDone, isLoading) => {
    (markAsDone) ? setLoadingMarkAsDone(isLoading) : setLoading(isLoading);
  }

  const handleChange = (e, row) => {
    const idx = data.findIndex((o) => o.name === row.name);
    const newData = cloneDeep(data);
    newData[idx].value = e.target.value;
    setData(newData);
  };

  const getOneItemContent = () => {
    // pending check request in progress
    if (pendingRetryCheck === null) {
      return (
        <Box px={10} py={1.5} display="flex" alignItems="center" justifyContent="center">
          <CircularProgress />
        </Box>
      );
    }
    return pendingRetryCheck ? (
      <Alert severity="error">{t("retry-dialog.pending-alert")}</Alert>
    ) : (
      <>
        <RadioGroup
          value={dataType}
          onChange={(e) => setDataType(e.target.value)}
        >
          <FormControlLabel
            value="same"
            control={<Radio />}
            label={t("retry-dialog.radio.same-data")}
          />
          {isActionPemitted(
            currentUser,
            "Process Item",
            "Retry item exceptions with changed data"
          )
            && isRetryableWithData && (
              <FormControlLabel
                value="changed"
                control={<Radio />}
                label={t("retry-dialog.radio.changed-data")}
              />
            )}
        </RadioGroup>
        <Collapse in={dataType === "changed"}>
          <Table style={{ marginTop: "8px" }}>
            <TableHead>
              <TableRow>
                <TableCell>{t("retry-dialog.table.name")}</TableCell>
                <TableCell>{t("retry-dialog.table.old-value")}</TableCell>
                <TableCell>{t("retry-dialog.table.new-value")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items[0].parsedData
                .filter((row) => row.editForRetry)
                .map((row, idx) => (
                  <TableRow key={idx}>
                    <TableCell className={classes.nameCell}>
                      <Typography color="textSecondary">{row.name}</Typography>
                    </TableCell>
                    <TableCell className={classes.oldValueCellCell}>
                      <Typography>{row.value}</Typography>
                    </TableCell>
                    <TableCell className={classes.newValueCell}>
                      <Input
                        fullWidth
                        defaultValue={row.value}
                        onChange={(e) => handleChange(e, row)}
                      />
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Collapse>
      </>
    );
  };

  const getContentText = () => {
    if (processIsRetryable) {
      if (items.length === 1) return t("retry-dialog.text.one-item")
      return t("retry-dialog.text.multiple-items", { count: items.length })
    } return items.length === 1 ? t("retry-dialog.title.one-item") : t("retry-dialog.title.multiple-items", { count: items.length })
  }

    return (
      <CustomDialog
          title={!processIsRetryable && getContentText()}
          contentText={processIsRetryable && !pendingRetryCheck ? getContentText() : null}
          open={open}
          disableBackdropClick
          PaperProps={{
            style: {
              maxWidth: 600,
            },
          }}
          actionElements={processIsRetryable ? (
             [{
               label: t("Cancel"),
               onClick: onClose,
               color: "primary",
               disabled: loading,
             },
               {
                 label: t("retry"),
                 onClick: () => retry(),
                 view: "primary",
                 disabled: loading,
                 hidden: !(!pendingRetryCheck || (items.length > 1)),
                 startIcon: loading && <CircularProgress size={20} />
               }, {
                 label: t("retry.mark.done"),
                 onClick: () => retry(true),
                 view: "primary",
                 disabled: loadingMarkAsDone,
                 hidden: !(!pendingRetryCheck || (items.length > 1)),
                 startIcon: loadingMarkAsDone && <CircularProgress size={20} />
               },
             ]
          ) : (
              [
                {
                  label: t("Cancel"),
                  onClick: onClose,
                  color: "primary",
                  disabled: loading,
                }
              ]
          )}
        >
        {processIsRetryable ? (items.length === 1 && getOneItemContent()) : (
          <Alert severity="error">
            {t("retry-dialog.not-retryable")}
          </Alert>
        )}
      </CustomDialog>
    )
}

export default RetryDialog;
