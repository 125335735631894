import React, { useState } from "react";
import downloadPdf from "./exportPdf";
import PdfTemplate from "./pdfTemplate";
import "./modal.css";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useTheme from "@material-ui/core/styles/useTheme";
import CircularProgress from "@material-ui/core/CircularProgress";
import CustomDialog from "pages/Services/components/CustomDialog";
import {
  GetApp as GetAppIcon,
} from "@material-ui/icons";

import clsx from "clsx";
import { useTranslation } from "react-i18next";
import CustomButton from "../../../components/CustomButton";
import useStyle from "./style";

const PdfModal = ({ exportPdf, pdfData }) => {
  const [exportLoading, setExportLoading] = useState(false);
  const { t } = useTranslation();

  const classes = useStyle();
  const theme = useTheme();
  const isMobileXs = useMediaQuery(theme.breakpoints.down("xs"));

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    exportPdf();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleExport = () => {
    setExportLoading(true);
    const profile = pdfData.steps[0];
    let NameProcess = "";
    profile?.criteria.forEach((criteria) => {
      if (criteria.name === "Process name") {
        NameProcess = criteria.type;
      }
    });
    downloadPdf(NameProcess, setExportLoading, handleClose);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <div>
      <CustomButton
        className={clsx(
          classes.marginRight,
          isMobileXs && classes.marginBottom,
        )}
        variant="outlined"
        color="secondary"
        endIcon={<GetAppIcon />}
        onClick={handleClickOpen}
      >
        {t("export")}
      </CustomButton>
      <CustomDialog
        className="pdfDialog"
        open={open}
        onClose={handleClickOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t("Export as a Pdf")}
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
            component="div"
          >
            <PdfTemplate data={pdfData} />
          </DialogContentText>
        </DialogContent>
        <DialogActions className="actionPadding">
          <CustomButton onClick={handleClose}>{t("Cancel")}</CustomButton>
          <CustomButton
            view="primary"
            onClick={handleExport}
            autoFocus
            disabled={exportLoading}
            endIcon={
              exportLoading ? (
                <CircularProgress size={20} />
              ) : (
                <GetAppIcon />
              )
            }
          >
            {t("export")}
          </CustomButton>
        </DialogActions>
      </CustomDialog>
    </div>
  );
};

export default PdfModal;
