import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  view: "list",
};

const settingsSlice = createSlice({
  name: "settings",
  initialState,

  reducers: {
    toggleTheme: (state) => {
      state.theme = state.theme === "light" ? "dark" : "light";
    },
    toggleView: (state) => {
      state.view = state.view === "list" ? "card" : "list";
    },
  },
});

// Settings reducer
export default settingsSlice.reducer;

// Actions
export const { toggleTheme, toggleView } = settingsSlice.actions;
