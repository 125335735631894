import React from "react";
import {
  Container,
} from "@material-ui/core";
import {
  Switch, Route, Redirect, useLocation,
} from "react-router-dom";
import Box from "@material-ui/core/Box";
import { useSelector } from "react-redux";
import clsx from "clsx";
import { useQuery } from "@redux-requests/react";
import { FETCH_CURRENT_USER } from "redux/constants";
import AppBar from "./components/AppBar";
import MenuLeft from "./components/MenuLeft";
import useStyles from "./style.js";
import NewProcessPage from "../pages/ProcessPage/NewProcessPage";
import { isMenuPermitted, isPermitted } from "../components/HasPermission";
import { isFleetAdministrator } from "../util";
import Copyright from "./components/Copyright";
import { getPermissionMenu, getPermissionRoute } from "./components/MenuLeft/helpers";

function WrapperContainer() {
  const location = useLocation();
  const classes = useStyles();
  const menuExpanded = useSelector(({ appState }) => appState.menuExpanded);
  const { data: currentUser } = useQuery({ type: FETCH_CURRENT_USER });
  const plannerState = useSelector(({ planner }) => planner);
  const isLicencePage = location.pathname === "/license";
  // eslint-disable-next-line no-nested-ternary
  const AdminUserRedirectUrl = () => (isFleetAdministrator(currentUser) ? (
    <Redirect
      key="admin-landing"
      exact
      from="/userAdministration"
      to="/userAdministration/groups"
    />
  ) : (isMenuPermitted(currentUser, "User Administration") || isFleetAdministrator(currentUser) ? (
    <Redirect
      key="admin-landing"
      exact
      from="/userAdministration"
      to="/userAdministration/users"
    />
  )
    : <Redirect key="admin-landing" exact from="/userAdministration" to="/" />
  ));
  // eslint-disable-next-line no-nested-ternary
  const AdminRobotRedirectUrl = () => (isFleetAdministrator(currentUser) ? (
    <Redirect
          key="admin-landing"
          exact
          from="/robotAdministration"
          to="/robotAdministration/orchestrators"
    />
  // eslint-disable-next-line no-nested-ternary
  ) : isMenuPermitted(currentUser, "Tags Module") ? (
    <Redirect
        key="admin-landing"
        exact
        from="/robotAdministration"
        to="/robotAdministration/tags"
    />
  ) : isMenuPermitted(currentUser, "SR Queues") ? (
    <Redirect
        key="admin-landing"
        exact
        from="/robotAdministration"
        to="/robotAdministration/queues"
    />
  ) : <Redirect key="admin-landing" exact from="/userAdministration" to="/" />);

  // eslint-disable-next-line no-nested-ternary
  const AlertManagementRedirectUrl = () => (isMenuPermitted(currentUser, "Default Alerts") ? (
    <Redirect
      key="alert-management-landing"
      exact
      from="/alertsManagement"
      to="/alertsManagement/defaultAlerts"
    />) : isMenuPermitted(currentUser, "Custom Alerts") ? (
      <Redirect
      key="alert-management-landing"
      exact
      from="/alertsManagement"
      to="/alertsManagement/customAlerts"
    />
  ) : (
    <Redirect
      key="alert-management-landing"
      exact
      from="/alertsManagement"
      to="/"
    />
  ));
  if (!currentUser) return null;

  return (
    <>
      {!isLicencePage && !plannerState?.isFullScreen && <AppBar />}
      <div className={classes.wrapper}>
        {!isLicencePage && location.pathname !== "/404" && !plannerState?.isFullScreen && <MenuLeft />}
        <main
          className={clsx(
            classes.content,
            menuExpanded && classes.smallContent,
            plannerState?.isFullScreen && classes.isFullScreen,
            isLicencePage ? classes.revertMargin : classes.noMargin
          )}
        >
          <Container maxWidth={false} className={classes.container}>
            <Box>
              <Switch>
                <Redirect exact from="/" to="/dashboard" />
                {AdminUserRedirectUrl()}
                {AdminRobotRedirectUrl()}
                {/* eslint-disable-next-line no-nested-ternary */}
                {isMenuPermitted(currentUser, "Item Exceptions") ? (
                  <Redirect
                        key="admin-landing"
                        exact
                        from="/exceptions"
                        to="/items/itemsExceptions"
                    />
                ) : isMenuPermitted(currentUser, "Process Exceptions") ? (
                  <Redirect
                        key="admin-landing"
                        exact
                        from="/exceptions"
                        to="/exceptions/processes"
                    />
                ) : (
                  <Redirect key="admin-landing" exact from="/exceptions" to="/" />
                )}
                {AlertManagementRedirectUrl()}
                {[...getPermissionMenu(currentUser), ...getPermissionRoute(currentUser)].map(({ path, component, key }, index) => (
                  <Route
                        key={key || index}
                        exact
                        path={path}
                        component={component}
                    />
                ))}
                {isPermitted(currentUser, "Add opportunity") && (
                <Route
                        key="create-process-page"
                        exact
                        path="/processAssessment/rpa/create"
                        component={NewProcessPage}
                    />
                )}
                {location.pathname === "*" ? null
                    : <Redirect to="/404" />}
              </Switch>
            </Box>
            <div>
              <Copyright />
            </div>
          </Container>
        </main>
      </div>
    </>
  );
}

export default WrapperContainer;
