import React from "react";
import Box from "@material-ui/core/Box";
import { useHistory } from "react-router-dom";
import PageHeader from "../../../components/PageHeader";
import DivisionTable from "./DivisionTable";
import CustomDialog from "pages/Services/components/CustomDialog";
import useStyles from "./style";

export default function DivisionManagement(props) {
  const history = useHistory();
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box className={classes.paper}>
        <PageHeader title="divisions.module.title" />
        <DivisionTable />
        {props.showEditModal && (
          <CustomDialog
                  open={props.showEditModal}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                  onBackdropClick={() => {
                    history.push("/userAdministration/divisions");
                  }}
              >
            {props.modalComponent}
          </CustomDialog>
          )}
      </Box>
    </Box>
  );
}
