import Card from "@material-ui/core/Card";
import DialogActions from "@material-ui/core/DialogActions";
import Grid from "@material-ui/core/Grid";
import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";

import get from "lodash/get";
import { useSelector, useDispatch } from "react-redux";
import ConfirmMessage from "../../../../components/ConfirmMessage";
import DefaultAlertInfo from "./DefaultAlertInfo/index";
import {
  createDefaultAlert,
  fetchDefaultAlert,
  fetchUserProcesses,
  updateDefaultAlert,
  fetchFleetUsers,
} from "../../../../redux/actions/services";

import AlertsPageHeader from "../../AlertsPageHeader";
import useStyles from "./style";
import EditAlertButton from "../../components/EditButton";
import CloseAlertButton from "../../components/CloseButton";
import { toast } from "react-toastify";
import { isSRQueuesDisabled } from "../../../../util/constants/PermitedFeature";
import CustomButton from "../../../../components/CustomButton";
import { DISCONNECTED_ROBOT_WHEN } from "../../../../util/constants/alerts";

const menuItems = {
  whenMenuItem: [
    "New opportunity created",
    "Opportunity accepted",
    "Opportunity rejected",
    "Opportunity added to the road map",
    "Opportunity archived",
    "Process activated",
    "Process disabled",
    "Orchestrator enabled",
    "Orchestrator disabled",
    "Schedule added",
    DISCONNECTED_ROBOT_WHEN,
    "A license is one month away from expiry",
    "A license is two months away from expiry",
    ...!isSRQueuesDisabled ? ["execution is deleted", "execution is missed", "deleted by sr policy"] : [],
  ],
};

export default function DefaultAlertForm() {
  const entityBaseUrl = "/alertsManagement/defaultAlerts";

  const classes = useStyles();

  const { t } = useTranslation();

  const history = useHistory();

  const dispatch = useDispatch();

  const { idAlert, mode = "add" } = useParams();

  const [defaultAlert, setDefaultAlert] = React.useState({});

  const [openMsgConfirm, setOpenMsgConfirm] = React.useState(false);

  const [openMsgCancel, setOpenMsgCancel] = React.useState(false);

  const [isLoading, setIsLoading] = React.useState(false);

  const [processMenuItem, setProcessMenuItem] = useState([]);

  const [sameFleetUsers, setSameFleetUsers] = useState();

  const [subscribersIds, SetSubscribersIds] = useState();

  const [
    selectedSubscribers,
    setSelectedSubscribers,
  ] = React.useState([]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    control,
    watch,
  } = useForm();

  const disableFields = !(mode === "add" || mode === "edit");

  // eslint-disable-next-line no-nested-ternary
  const headerTitle = mode === "add"
    ? "alerts.addHeader"
    : mode === "edit"
      ? "alerts.editHeader"
      : "alerts.displayHeader";

  const onSubmit = (data) => {
    setOpenMsgConfirm(true);
    let dataToSend = data;
    let alertSubscribersIds = selectedSubscribers.map((e) => e.id);
    // eslint-disable-next-line no-nested-ternary
    alertSubscribersIds = alertSubscribersIds?.length > 1
        ? alertSubscribersIds?.join(",")
        : alertSubscribersIds?.length === 1
        ? alertSubscribersIds?.join("")
        : "";
    dataToSend = {
      ...data,
      alertSubscribersIds,
    };

    dataToSend = {
      ...dataToSend,
      fleetId: currentUser?.fleet?.id,
      type: "DEFAULT"
    }
    if (dataToSend.when === DISCONNECTED_ROBOT_WHEN)
    {
      dataToSend.robotDtos = dataToSend.robotsIds.map((id) => ({ id }))
      dataToSend.whenVariables = { disconnectionPeriod: dataToSend.disconnectionPeriod }
    }
    setDefaultAlert(dataToSend);
  };

  const currentUser = useSelector(({ requests }) => get(requests, "queries.FETCH_CURRENT_USER.data"));

  useEffect(() => {
    if (currentUser?.fleet?.id) { dispatch(fetchUserProcesses(currentUser?.id)).then((res) => setProcessMenuItem(res.data?.list?.filter((item) => !item.isDisabled))); }
  }, [currentUser]);

  const confirmSave = () => {
    setIsLoading(true);
    if (mode === "add") {
      dispatch(
        createDefaultAlert(defaultAlert, (res) => {
          if (res?.status === 200) {
            history.push({
              pathname: entityBaseUrl,
            });
          }
        }),
      )
        .then((res) => {
          if (res.response.status !== "OK") {
            setOpenMsgConfirm(false);
            setIsLoading(false);
            toast.error(res.response?.data?.message)
          } else {
            toast.success(t("alert.management.formControl.saveSuccess"))
          }
        })
        .catch(() => {});
    } else {
      dispatch(
        updateDefaultAlert(
          idAlert,
          defaultAlert,

          (res) => {
            if (res?.status === 200) {
              history.push({
                pathname: entityBaseUrl,
              });
              toast.success(t("alert.management.formControl.updateSuccess"))
            }
          },
        ),
      ).then((res) => {
          if (res.response.status !== "OK") {
            setOpenMsgConfirm(false);
            setIsLoading(false);
            toast.error(res.response?.data?.message)
          }
        })
        .catch(() => {});
    }
  };

  const handleCancel = () => setOpenMsgCancel(true);

  const cancelConfirm = () => setOpenMsgConfirm(false);

  const handleRejectCancelForm = () => setOpenMsgCancel(false);

  const handleAcceptCancelForm = () => {
    setOpenMsgCancel(false);
    history.push(entityBaseUrl);
  };

  const handleSubscribersChange = (event) => {
    const value = event?.target?.value || event;
    setSelectedSubscribers(value);
    setValue("sub", value);
  }

  useEffect(() => {
    dispatch(fetchFleetUsers(currentUser?.fleet?.id)).then((result) => {
      setSameFleetUsers(result.data);
      const selectedUsers = result?.data?.filter((e) => subscribersIds?.includes(e?.id.toString()))
      setSelectedSubscribers(selectedUsers);
    });
  }, [subscribersIds]);

  useEffect(() => {
    if (mode !== "add") {
      dispatch(fetchDefaultAlert(idAlert)).then((res) => {
        const userIds = !(res?.data?.alertSubscribersIds?.split(",")) ? [] : (res?.data?.alertSubscribersIds?.split(","));
        SetSubscribersIds(userIds);
        setValue("alertName", res?.data?.alertName);
        setValue("alertDescription", res?.data?.alertDescription);
        setValue("when", res?.data?.when);
        setValue("flag", res?.data?.flag);
        setValue("status", res?.data?.status);
        setValue("sub", userIds);
        setValue("robotsIds", res?.data?.robotDtos?.map(({ id }) => id))
        setValue("disconnectionPeriod", res?.data?.whenVariables?.disconnectionPeriod)
        if (res?.data?.processId) setValue("processId", res?.data?.processId);
      });
    }
  }, []);

  return (
    <div className={classes.root}>
      <AlertsPageHeader title={t(headerTitle)} />

      <form className={classes.fleetForm} onSubmit={handleSubmit(onSubmit)}>
        <Grid container className={classes.rootGrid} justify="center">
          <Grid container item xs={12} md={10} lg={8}>
            <Card className={classes.paper} square>
              <Grid container justify="flex-end" spacing={4}>
                <Grid item>
                  <EditAlertButton
                    mode={mode}
                    currentUser={currentUser}
                    url={`${entityBaseUrl}/edit/${idAlert}`}
                  />
                </Grid>
                <Grid item>
                  { mode === "view" && (
                    <CloseAlertButton
                    url={entityBaseUrl}
                  />
                  )}
                </Grid>
              </Grid>
              <DefaultAlertInfo
                classes={classes}
                control={control}
                register={register}
                watch={watch}
                errors={errors}
                disableFields={disableFields}
                menuItems={menuItems}
                clearErrors={clearErrors}
                mode={mode}
                processMenuItem={processMenuItem}
                sameFleetUsers={sameFleetUsers}
                selectedSubscribers={selectedSubscribers}
                setSameFleetUsers={setSameFleetUsers}
                handleSubscribersChange={handleSubscribersChange}
              />
            </Card>
            {!disableFields && (
              <Grid item xs={12} className={classes.inputsSection}>
                <DialogActions className={classes.cardActions}>
                  <CustomButton
                    variant="contained"
                    size="medium"
                    onClick={handleCancel}
                  >
                    {t("alerts.management.button.exit")}
                  </CustomButton>
                  <CustomButton
                    view="primary"
                    size="medium"
                    type="submit"
                  >
                    {idAlert
                      ? t("alerts.management.button.update")
                      : t("alerts.management.button.save")}
                  </CustomButton>
                </DialogActions>
              </Grid>
            )}
          </Grid>
        </Grid>
      </form>
      {openMsgConfirm && (
        <ConfirmMessage
          message={
            idAlert
              ? t("alerts.management.update.confirmMsg")
              : t("alerts.management.save.confirmMsg")
          }
          openStart={openMsgConfirm}
          onCancel={cancelConfirm}
          onConfirm={confirmSave}
          buttonConfirm={
            idAlert
              ? t("alerts.management.button.update")
              : t("alerts.management.button.save")
          }
          buttonCancel={t("alerts.management.button.cancel")}
          isLoading={isLoading}
        />
      )}
      {openMsgCancel && (
        <ConfirmMessage
          message={t("alerts.management.delete.discard")}
          openStart={openMsgCancel}
          onCancel={handleRejectCancelForm}
          onConfirm={handleAcceptCancelForm}
          buttonConfirm={t("alerts.management.button.discard")}
          buttonCancel={t("alerts.management.button.cancel")}
        />
      )}
    </div>
  );
}
