import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  root: {
    width: "100%",
    background: "transparent",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
    padding: theme.spacing(4),
  },
  cardActions: {
    justifyContent: "center",
    marginTop: theme.spacing(2),
    padding: theme.spacing(1),
  },
  rootGrid: {
    marginTop: theme.spacing(5),
  },
  trigger: {
    color: theme.palette.secondary.main,
    fontSize: "1.4rem",
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },

  help: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
  notificationHelper: {
    fontSize: "0.875rem",
    fontFamily: "Roboto",
    fontWeight: "400",
  },
  NotificationIcons: {
    color: theme.palette.secondary.main,
    marginTop: "20px",
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
  flagLabel: {
    fontSize: "1rem",
  },
  flagLabelGrey: {
    fontSize: "1rem",
    color: "grey",
  },
  triggerIcon: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    color: theme.palette.secondary.main,
  },
  triggerGridIcon: {
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(1),
  },
  validation_error: {
    color: "red",
    margin: "0",
    marginTop: theme.spacing(1),
  },
  select: {
    height: "30px",
  },
  error: {
    color: "#FFF",
    backgroundColor: theme.custom.color.errorChipColor,
    marginRight: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
  },
  valid: {
    color: "#FFF",
    backgroundColor: theme.palette.secondary.main,
    marginRight: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
  },
  warningColor: {
    color: theme.custom.color.color3,
  },
  errorColor: {
    color: theme.custom.color.color4,
  },
  infoColor: {
    color: theme.palette.secondary.main,
  },
  personIcon: {
    color: theme.palette.secondary.main,
    marginTop: "10px",
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
}));
