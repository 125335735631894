import { makeStyles } from "@material-ui/core/styles";
import { fade } from "@material-ui/core/styles/colorManipulator";

export default makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: fade(theme.palette.background.default, 0.8),
    overflowX: "hidden",
    overflowY: "hidden",
    padding: "20px",
  },
  grid: {
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "5px",
    margin: "auto",
  },
  contentContainer: {
    maxHeight: 300,
    overflowY: "auto",
    padding: "24px",
  },
  cardActions: {
    margin: 0,
    padding: theme.spacing(1.5),
  },
  typography: {
    textAlign: "center",
    color: "gray",
  },
  paper: {
    padding: "20px",
  },
  dialogPaper: {
    width: "43%",
  },
}));
