import React from "react";
import Box from "@material-ui/core/Box";
import PageHeader from "../../../components/PageHeader";
import RobotsTable from "./RobotsTable";
import useStyles from "../../../components/TableComponents/style"

function RobotManagement() {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Box className={classes.paper}>
        <PageHeader title="robot.management.title" />
        <RobotsTable />
      </Box>
    </Box>
  );
}

export default RobotManagement;
