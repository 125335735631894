/* eslint-disable react/jsx-indent */
import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Radio from "@material-ui/core/Radio";
import FormControl from "@material-ui/core/FormControl";
import InputAdornment from "@material-ui/core/InputAdornment";
import CircularProgress from "@material-ui/core/CircularProgress";
import Alert from "@material-ui/lab/Alert";
import { useDispatch, useSelector } from "react-redux";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import { CalendarToday } from "@material-ui/icons";
import { useForm, Controller } from "react-hook-form";
import DateFnsUtils from "@date-io/date-fns";
import get from "lodash/get";
import moment from "moment";
import {
  updateProcessKpiParameters,
  fetchProcesses,
  processHasSchedules,
} from "../../../../redux/actions/services";
import {
  bePeriods,
  formatDatePickerByLanguage,
  getLocale,
} from "../../../../util";
import ConfirmMessage from "../../../../components/ConfirmMessage";
import InformationIcon from "../../../../components/InformationIcon";
import { toast } from "react-toastify";
import { CustomTextField } from "components/FormFields/CustomAutoComplete";
import CustomSelectField from "components/FormFields/CustomSelectField";
import CustomSwitch from "../CustomSwitch";
import CustomButton from "../../../../components/CustomButton";
import {
  isSchedulableDisabled,
  canBeStartedManually,
  isDataMappingRetryDisabled
} from "../../../../util/helpers/filters";

const KpiParametersForm = ({
 t, classes, _process, setProcess
}) => {
  const dispatch = useDispatch();
  const filterProcess = useSelector(({ filterProcess: i }) => i);
  const processId = get(_process, "id");

  const isSchedulable = {
    value: _process.isSchedulable,
    changed: false,
  };

  const [dateInd, setDateInd] = React.useState(
    get(_process, "processBeSince") ? 0 : 1
  );
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const savedDate = get(_process, "processBeSince")
    ? moment(get(_process, "processBeSince")).format("yyyy-MM-DD")
    : null;
  const [selectedDate, setDate] = React.useState(savedDate);

  const [confirmMsgObj, setConfirmMsgObj] = React.useState({
    deleteMsg: (
      <div>
        <Alert severity="error">{t("kpiParam.schedule.warning")}</Alert>
      </div>
    ),
    open: false,
    onCancel: () => setConfirmMsgObj({ ...confirmMsgObj, open: false, isLoading: false }),
    onConfirm: () => {
      setConfirmMsgObj({ ...confirmMsgObj, isLoading: true });
      handleSubmit(saveData)();
    },
    isLoading: false,
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    watch,
    control
  } = useForm({
    defaultValues: {
      executionsExceptionAccountingRate:
        get(_process, "executionsExceptionAccountingRate") || 0,
      exceptionAccountingRate: get(_process, "exceptionAccountingRate") || 0,
      beCalculationPeriod: _process.beCalculationPeriod,
      isSchedulable: _process.isSchedulable,
      isManualAllowed: _process.isManualAllowed,
      isRetryable: _process.isRetryable,
      isRetryableWithData: _process.isRetryableWithData,
      businessExceptionsAsCompleted: _process.businessExceptionsAsCompleted,
      exceptionAccountingRateSwitch: !!get(_process, "exceptionAccountingRate"),
      executionsExceptionAccountingRateSwicth: !!get(
        _process,
        "executionsExceptionAccountingRate"
      ),
      processBeSince: get(_process, "processBeSince"),
    },
    mode: "onChange",
  });

  const onDateChange = (date) => {
    setDate(date);
    setValue("processBeSince", date);
  };

  const dateFormatter = (str) => str;

  const createToSaveObj = (data) => ({
    id: processId,
    ...data,
  });
  const saveData = (data) => {
    const toSave = createToSaveObj(data)
    dispatch(updateProcessKpiParameters(toSave)).then(
      (response) => {
        setConfirmMsgObj({ ...confirmMsgObj, open: false, isLoading: false });
        setConfirmLoading(false);
        if (response.status === 200) {
          setProcess((prevState) => ({ ...prevState, ...toSave }))
          toast.success(t("kpiParam.update.success"));
        } else toast.error(t("kpiParam.update.success"));
        dispatch(
          fetchProcesses(
            filterProcess.search,
            filterProcess.fleet,
            filterProcess.division,
            filterProcess.showAll,
            filterProcess.tags,
            filterProcess.pageNo,
            filterProcess.pageSize
          )
        );
      }
    );
  };

  const handleApply = (data) => {
    if (isSchedulable.changed && !isSchedulable.value) {
      dispatch(processHasSchedules(processId)).then((res) => {
        if (res.status === 200) {
          if (res.data) {
            // show popup
            setConfirmMsgObj({ ...confirmMsgObj, open: true });
          } else {
            setConfirmLoading(true);
            saveData(createToSaveObj(data));
          }
        }
      });
    } else {
      setConfirmLoading(true);
      saveData(createToSaveObj(data));
    }
  };
  const handleExceptionKpiChange = (event) => {
    if (!event.target.checked) {
      setValue("exceptionAccountingRate", 0);
    }
  };

  const handleExecutionsExceptionKpiChange = (event) => {
    if (!event.target.checked) {
      setValue("executionsExceptionAccountingRate", 0);
    }
  };

  const showIncludeDataFrom = false;

  return (
    <>
      <form onSubmit={handleSubmit(handleApply)}>
        <Grid container direction="row" spacing={2}>
          <Grid className={classes.parametersLeftContainer} spacing={4} xs={6}>
            <Grid container item xs className={classes.slaSectionContainer}>
              {!isSchedulableDisabled(_process?.orchestrator?.orchestratorType) && (
                  <Grid
                      className={classes.optionsContainer}
                      item
                      container
                      direction="row"
                      alignItems="flex-start"
                  >
                    <Grid item>
                      <Controller
                          name="isSchedulable"
                          control={control}
                          render={({ field }) => (
                              <CustomSwitch
                                  checked={field.value}
                                  handleChange={(value) => field.onChange(value)}
                                  name={field.name}
                              />
                          )}
                      />
                    </Grid>
                    <Grid item xs={10}>
                      <Typography color="primary" variant="subtitle1" className={classes.optText}>
                        {t("kpi.parameter.allowScheduling")}
                      </Typography>
                    </Grid>
                  </Grid>
              )}
              {!canBeStartedManually(_process?.orchestrator?.orchestratorType) && (
                  <Grid
                      className={classes.optionsContainer}
                      item
                      container
                      direction="row"
                      alignItems="flex-start"
                  >
                    <Grid item>
                      <Controller
                          name="isManualAllowed"
                          control={control}
                          render={({ field }) => (
                              <CustomSwitch
                                  checked={field.value}
                                  handleChange={(value) => field.onChange(value)}
                                  name={field.name}
                              />
                          )}
                      />
                    </Grid>
                    <Grid item xs={10}>
                      <Typography color="primary" variant="subtitle1" className={classes.optText}>
                        {t("kpi.parameter.allowManual")}
                      </Typography>
                    </Grid>
                  </Grid>
              )}
              <Grid
                className={classes.optionsContainer}
                item
                container
                direction="row"
                alignItems="flex-start"
              >
                <Grid item>
                  <Controller
                    name="businessExceptionsAsCompleted"
                    control={control}
                    render={({ field }) => (
                      <CustomSwitch
                        checked={field.value}
                        handleChange={(value) => field.onChange(value)}
                        name={field.name}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={10}>
                  <Typography color="primary" variant="subtitle1" className={classes.optText}>
                      {t(
                        "kpi.parameter.ConsiderBusinessExceptionsAsCompletedItems"
                      )}
                      <InformationIcon
                        titleContent={t(
                          "kpi.parameter.ConsiderBusinessExceptionsAsCompletedItems.tooltip"
                        )}
                      />
                  </Typography>
                </Grid>
              </Grid>
              {!isDataMappingRetryDisabled(_process?.orchestrator?.orchestratorType) && (
                  <Grid
                      className={classes.optionsContainer}
                      item
                      container
                      direction="row"
                      alignItems="center"
                  >
                    <Grid item>
                      <Controller
                          name="isRetryable"
                          control={control}
                          render={({ field }) => (
                              <CustomSwitch
                                  checked={field.value}
                                  handleChange={(value) => field.onChange(value)}
                                  name={field.name}
                              />
                          )}
                      />
                    </Grid>
                    <Grid item xs={10}>
                      <Typography color="primary" variant="subtitle1" className={classes.optText}>
                        {t("kpi.parameter.retryable")}
                      </Typography>
                    </Grid>
                  </Grid>
              )}
              {!isDataMappingRetryDisabled(_process?.orchestrator?.orchestratorType) && (
                  <Grid
                      className={classes.optionsContainer}
                      item
                      container
                      direction="row"
                      alignItems="flex-start"
                  >
                    <Grid item>
                      <Controller
                          name="isRetryableWithData"
                          control={control}
                          render={({ field }) => (
                              <CustomSwitch
                                  checked={field.value && watch("isRetryable")}
                                  handleChange={(value) => field.onChange(value)}
                                  name={field.name}
                                  disabled={!watch("isRetryable")}
                              />
                          )}
                      />
                    </Grid>
                    <Grid item xs={10}>
                      <Typography color="primary" variant="subtitle1" className={classes.optText}>
                        {t("kpi.parameter.retryableWithData")}
                      </Typography>
                    </Grid>
                  </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item xs={6}>
            {showIncludeDataFrom && (
              <Grid
                className={classes.slaSectionContainer}
                item
                container
                direction="column"
                xs={12}
              >
                <Grid item xs>
                  <Typography variant="subtitle1" className={classes.optText} color="primary">
                    {t("kpi.parameter.be")}
                    <InformationIcon
                      titleContent={t("kpi.parameter.includeSince.tooltip")}
                    />
                  </Typography>
                </Grid>
                <Grid item container xs={12}>
                  <Grid item container xs={12}>
                    <Typography variant="subtitle1" className={classes.optText}>
                      <Radio
                        onChange={() => {
                          setDateInd(1);
                          setDate(null);
                          setValue("processBeSince", null);
                        }}
                        checked={dateInd === 1}
                      />
                      <Typography
                        color="primary"
                        variant="subtitle2"
                        fontWeight="fontWeightBold"
                        display="inline"
                        className={classes.dateContainer}
                      >
                        {t("depuis la premiere execution")}
                      </Typography>
                    </Typography>
                  </Grid>
                  <Grid item container xs={12}>
                    <Typography variant="h4">
                      <Radio
                        onChange={() => {
                          setDateInd(0);
                        }}
                        checked={dateInd === 0}
                      />
                      <MuiPickersUtilsProvider
                        utils={DateFnsUtils}
                        locale={getLocale()}
                      >
                        <FormControl
                          margin="none"
                          className={classes.dateContainer}
                        >
                          <DatePicker
                            {...register("processBeSince")}
                            InputProps={{
                              endAdornment: (
                                <CalendarToday
                                  className={classes.calendarIcon}
                                  fontSize="small"
                                />
                              ),
                            }}
                            className={classes.dateInput}
                            autoOk
                            showTodayButton
                            value={selectedDate}
                            format={formatDatePickerByLanguage()}
                            onChange={onDateChange}
                            rifmFormatter={dateFormatter}
                            disabled={dateInd === 1}
                            disableFuture
                            cancelLabel={t("user.button.cancel")}
                            todayLabel={t("Today")}
                            invalidDateMessage={t(
                              "fleet.management.formControl.invalidDate"
                            )}
                          />
                        </FormControl>
                      </MuiPickersUtilsProvider>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            )}
            <Grid
              container
              item
              xs={12}
              className={classes.slaSectionContainer}
            >
              <Grid item xs={12}>
                <Typography color="primary" variant="subtitle1" className={classes.optText}>
                  {t("fleet.management.formLabel.bePeriod")}
                  <InformationIcon
                  titleContent={t(
                    "kpi.parameter.breakEvenCalculationPeriod.tooltip"
                  )}
                />
                </Typography>
              </Grid>
              <Grid container item xs={12}>
                <Grid container item xs={8}>
                  <CustomSelectField
                    {...register("beCalculationPeriod", {
                      required: {
                        value: true,
                        message: t("fleet.management.formControl.required"),
                      },
                    })}
                    isCustom
                    fullWidth
                    color="primary"
                    defaultValue={getValues("beCalculationPeriod")}
                    value={watch("beCalculationPeriod")}
                    options={bePeriods}
                    optionValue="days"
                    optionLabel="label"
                    customOptionLabel={(row) => t(row.label)}
                    error={!!errors.executionsExceptionAccountingRate}
                    onChange={(event) => setValue("beCalculationPeriod", event.target.value)}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              className={classes.slaSectionContainer}
              container
              direction="column"
              item
              xs={12}
            >
              <Grid className={classes.optSpacing} item xs={12}>
                <Typography color="primary" variant="subtitle1" className={classes.optText}>
                  {t("kpi.parameter.includeException")}
                  <InformationIcon
                  titleContent={t(
                    "kpi.parameter.includeExceptFinancial.tooltip"
                  )}
                />
                </Typography>
              </Grid>
              <Grid container item xs={12}>
                <Grid container item xs={12}>
                  <Grid item>
                    <Controller
                      name="exceptionAccountingRateSwitch"
                      control={control}
                      render={({ field }) => (
                        <CustomSwitch
                          checked={watch("exceptionAccountingRateSwitch")}
                          handleChange={(value) => {
                            field.onChange(value);
                            handleExceptionKpiChange(value);
                          }}
                          name={field.name}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <CustomTextField
                      fullWidth
                      color="primary"
                      id="exceptionAccountingRate-input"
                      type="number"
                      disabled={!watch("exceptionAccountingRateSwitch")}
                      {...register("exceptionAccountingRate", {
                        max: 100,
                        min: 0,
                      })}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            className={classes.inputAdorn}
                            position="end"
                          >
                            %
                          </InputAdornment>
                        ),
                      }}
                    />
                    <p className={classes.validation_error}>
                      {errors.exceptionAccountingRate ? (
                        <Typography color="primary" variant="subtitle1" className={classes.optText}>
                          {t("kpi.parameter.invalidRate")}
                        </Typography>
                      ) : (
                        <Typography> &nbsp;</Typography>
                      )}
                    </p>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              item
              xs={12}
              className={classes.slaSectionContainer}
            >
              <Grid className={classes.optSpacing} item xs={12}>
                <Typography color="primary" variant="subtitle1" className={classes.optText}>
                  {t("kpi.parameter.executionsIncludeException")}
                  <InformationIcon
                  titleContent={t(
                    "kpi.parameter.includeExceptFinancialKPI.tooltip"
                  )}
                />
                </Typography>
              </Grid>
              <Grid container item xs={12}>
                <Grid item>
                  <Controller
                    name="executionsExceptionAccountingRateSwicth"
                    control={control}
                    render={({ field }) => (
                      <CustomSwitch
                        checked={watch(
                          "executionsExceptionAccountingRateSwicth"
                        )}
                        handleChange={(value) => {
                          field.onChange(value);
                          handleExecutionsExceptionKpiChange(value);
                        }}
                        name={field.name}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <CustomTextField
                    fullWidth
                    color="primary"
                    id="executionsExceptionAccountingRate"
                    type="number"
                    disabled={!watch("executionsExceptionAccountingRateSwicth")}
                    {...register("executionsExceptionAccountingRate", {
                      max: 100,
                      min: 0,
                    })}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      ),
                    }}
                  />
                  <p className={classes.validation_error}>
                    {errors.executionsExceptionAccountingRate ? (
                      <Typography>{t("kpi.parameter.invalidRate")}</Typography>
                    ) : (
                      <Typography> &nbsp;</Typography>
                    )}
                  </p>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          container
          justify="flex-end"
          xs={12}
          className={classes.applyBtnContainer}
        >
          <Grid
            item
            container
            direction="row"
            alignItems="flex-end"
            justify="flex-end"
          >
            <Grid item>
              <CustomButton
                view="primary"
                type="submit"
                style={{ marginLeft: "8px" }}
                startIcon={
                  confirmLoading && <CircularProgress color="white" size={20} />
                }
                disabled={confirmLoading}
              >
                {t("apply")}
              </CustomButton>
            </Grid>
          </Grid>
        </Grid>
      </form>
      <ConfirmMessage
        message={confirmMsgObj.deleteMsg}
        openStart={confirmMsgObj.open}
        onCancel={confirmMsgObj.onCancel}
        onConfirm={confirmMsgObj.onConfirm}
        buttonConfirm={t("Delete")}
        buttonCancel={t("Cancel")}
        isDelete="true"
        isLoading={confirmMsgObj.isLoading}
      />
    </>
  );
};

export default KpiParametersForm;
