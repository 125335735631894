import React, { useEffect } from "react";
import Drawer from "@material-ui/core/Drawer";
import { Link, useLocation } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/MenuOutlined";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import clsx from "clsx";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import kebabCase from "lodash/kebabCase";
import { FETCH_CURRENT_USER } from "redux/constants";

import { Tooltip } from "@material-ui/core";
import { useQuery } from "@redux-requests/react";
import useStyles from "./style.js";
import { toogleMenu } from "../../appSlice";
import { getPermissionMenu } from "./helpers.js";

const MenuLeft = () => {
  const classes = useStyles();
  const menuExpanded = useSelector(({ appState }) => appState.menuExpanded);
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { data: currentUser } = useQuery({ type: FETCH_CURRENT_USER });

  const menu = getPermissionMenu(currentUser);

  const tabValue = menu?.map(({ path }) => path.split("/")[1])?.findIndex((path) => path === pathname.split("/")[1])

  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  useEffect(() => {
    scrollToTop()
  }, [tabValue])

  return (
    <Drawer
      variant="permanent"
      classes={{
        paper: clsx(
          classes.menuLeft,
          !menuExpanded && classes.menuLeftClose,
        ),
      }}
      open={menuExpanded}
    >
      <div className={classes.menuButton}>
        <IconButton
          aria-label="open Menu"
          onClick={() => dispatch(toogleMenu())}
          color="inherit"
        >
          <MenuIcon fontSize="small" />
        </IconButton>
      </div>
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={tabValue}
        aria-label="Menu left"
        classes={{
          indicator: classes.indicator,
        }}
        centered
      >
        {menu?.map(
          ({ name, icon, path }, index) => {
            const TabIcon = icon
            return (
              <Tooltip key={index} title={!menuExpanded ? t(name) : ""} placement="right">
                <Tab
                  key={`menu-item-${index}`}
                  id={`menu-item-${kebabCase(name)}`}
                  icon={<TabIcon className={classes.tabIcon} />}
                  label={menuExpanded ? t(name) : ""}
                  component={Link}
                  to={path}
                  className={classes.tab}
              />
              </Tooltip>
            )
          }
        )}
      </Tabs>
    </Drawer>
  );
}

export default MenuLeft;
