import React from "react";
import { IconButton, Tooltip } from "@material-ui/core";
import { ReactComponent as ClearFilterIcon } from "../../assets/common/clearfilter.svg";

const ClearFilter = ({ clearFilter, label, size = 24 }) => (
  <Tooltip placement="top" title={label}>
    <IconButton onClick={clearFilter}>
      <ClearFilterIcon
        width={size}
        height={size}
      />
    </IconButton>
  </Tooltip>
);

export default ClearFilter;
