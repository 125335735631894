import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(() => ({
  listItem: { borderRadius: "20px" },
  icon: {
    width: "15px",
    height: "15px",
    borderRadius: "50%",
  },
  dialogActionsContainer: {
    justifyContent: "center"
  }
}));
