import {
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  withStyles,
} from "@material-ui/core";
import React from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import FlagIcon from "@material-ui/icons/Flag";

const InfoRadio = withStyles({
  root: {
    color: "#0284FE",
  },
})((props) => <Radio color="default" {...props} />);

const WarningRadio = withStyles({
  root: {
    color: "#ff9600",
  },
})((props) => <Radio color="default" {...props} />);

const ErrorRadio = withStyles({
  root: {
    color: "#ed4949",
  },
})((props) => <Radio color="default" {...props} />);

export default function AlertSeverityInput({
  errors,
  control,
  disableFields,
  classes,
}) {
  const { t } = useTranslation();

  const [value, setValue] = React.useState();

  const handleChangeFlag = (event) => {
    setValue(event.target.value);
  };

  return (
    <Grid container item alignContent="center" alignItems="center">
      <Grid item xs={1} className={classes.NotificationIcons}>
        <FlagIcon color="primary" />
      </Grid>
      <Grid item xs={3} style={{ paddingLeft: "8px", paddingTop: "15px" }}>
        <span
          className={disableFields ? classes.flagLabelGrey : classes.flagLabel}
        >
          {t("alerts.management.severity")}
        </span>
      </Grid>
      <Grid container item xs={6} style={{ paddingTop: "15px" }}>
        <Controller
          name="flag"
          control={control}
          defaultValue=""
          rules={{
            required: {
              value: true,
              message: t("alerts.management.required.fields"),
            },
          }}
          render={({ field }) => (
            <FormControl component="fieldset" disabled={disableFields}>
              <RadioGroup
                row
                aria-label="position"
                name="position"
                onChange={(event) => handleChangeFlag(event)}
                value={value}
                {...field}
                disabled={disableFields}
              >
                <FormControlLabel
                  value="INFO"
                  control={<InfoRadio />}
                  label={t("info")}
                  className={classes.infoColor}
                  style={{ marginRight: "30px" }}
                />
                <FormControlLabel
                  value="WARNING"
                  control={<WarningRadio />}
                  label={t("warning")}
                  className={classes.warningColor}
                  style={{ marginRight: "30px" }}
                />
                <FormControlLabel
                  value="ERROR"
                  control={<ErrorRadio />}
                  label={t("error")}
                  className={classes.errorColor}
                />
              </RadioGroup>
            </FormControl>
          )}
        />
      </Grid>
      <Grid item xs={2} style={{ paddingTop: "15px" }}>
        <span className={classes.errorColor}>{errors.flag?.message}</span>
      </Grid>
    </Grid>
  );
}
