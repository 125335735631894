import React from "react";
import DefaultAlertForm from "pages/AlertsManagement/DefaultAlerts/DefaultAlertForm";
import CustomAlertForm from "pages/AlertsManagement/CustomAlerts/CustomAlertForm";
import AlertsManagement from "pages/AlertsManagement";
import ExceptionsPage from "pages/ExceptionsPage";
import ViewProcessPage from "pages/ProcessPage/ViewProcessPage";
import FormPasswordReset from "pages/Profile/ResetPassword/FormPasswordReset";
import Profile from "pages/Profile";
import NewProcessPage from "pages/ProcessPage/NewProcessPage";
import AddUserForm from "pages/Administration/UserManagement/AddUserForm";
import OrchestratorForm from "pages/Administration/OrchestratorManagement/OrchestratorForm";
import FleetForm from "pages/Administration/FleetManagement/FleetForm";
import RobotForm from "pages/Administration/RobotManagement/RobotForm";
import OrchestratorLicense from "pages/Administration/OrchestratorManagement/OrchestratorLicense/OrchestratorLicense";

import { ROLES } from "util/constants";
import QueueForm from "pages/Administration/QueueManagement/QueueForm";
import FinancialParameters from "../pages/Financial";
import UsersAdministration from "../pages/Administration/UsersAdministration";
import RobotsAdministration from "../pages/Administration/RobotsAdministration";
import DivisionManagement from "../pages/Administration/DivisionManagement";
import DivisionModuleForm from "../pages/Administration/DivisionManagement/DivisionModuleForm";
import TagManagement from "pages/Administration/TagManagement";
import TagForm from "../pages/Administration/TagManagement/TagForm";
import ProcessExecutionPage from "pages/ProcessExecutionPage";
import ProcessItemPage from "pages/ProcessItemPage";
import { isSRQueuesDisabled } from "../util/constants/PermitedFeature";

const routes = [
  // Alerts
  {
    key: "update-or-view-default-alert",
    path: "/alertsManagement/defaultAlerts/:mode/:idAlert",
    component: () => <DefaultAlertForm />,
    permissionKey: "Default Alerts",
  },
  {
    key: "add-new-default-alert",
    path: "/alertsManagement/defaultAlerts/add",
    component: () => <DefaultAlertForm />,
    permissionKey: "Default Alerts",
  },
  {
    key: "default-alerts",
    path: "/alertsManagement/defaultAlerts",
    component: () => <AlertsManagement />,
    permissionKey: "Default Alerts",
  },
  {
    key: "update-or-view-custom-alert",
    path: "/alertsManagement/customAlerts/:mode/:idAlert",
    component: () => <CustomAlertForm />,
    permissionKey: "Custom Alerts",
  },
  {
    key: "custon-alerts",
    path: "/alertsManagement/customAlerts",
    component: () => <AlertsManagement />,
    permissionKey: "Custom Alerts",
  },
  {
    key: "add-new-custom-alert",
    path: "/alertsManagement/customAlerts/add",
    component: () => <CustomAlertForm />,
    permissionKey: "Custom Alerts",
  },

  // Exceptions
  {
    key: "processes-exceptions",
    path: "/items/itemsExceptions",
    component: () => <ExceptionsPage />,
    permissionKey: "Item Exceptions",
  },

  // Process
  {
    key: "view-process-page",
    path: "/processAssessment/rpa/view/:id",
    component: () => <ViewProcessPage />,
  },
  {
    key: "create-process-page",
    path: "/processAssessment/rpa/edit/:id",
    component: () => <NewProcessPage />,
  },
  {
    // key: "process-history",
    key: "processes-exceptions",
    path: "/executions/history",
    component: () => <ProcessExecutionPage />,
    permissionKey: "Executions",
  },
  {
    key: "process-exceptions",
    path: "/executions/processesExceptions",
    component: () => <ProcessExecutionPage />,
    permissionKey: "Executions",
  },

  // Profile
  {
    key: "update-user-informations",
    path: "/profile/general",
    component: () => <Profile />,
  },
  {
    key: "update-user-password",
    path: "/profile/security",
    component: () => <Profile />,
  },
  {
    key: "update-user-password",
    path: "/protected/user/profile/:userId",
    component: () => <FormPasswordReset />,
  },

  // Items
  {
    key: "completed-items",
    path: "/items/completedItems",
    component: () => <ProcessItemPage />,
    permissionKey: "Process Item",
  },
  {
    key: "pending-items-list",
    path: "/items/pendingItems",
    component: () => <ProcessItemPage />,
    permissionKey: "Process Item",
  },
  {
    key: "items-exceptions",
    path: "/items/itemsExceptions",
    component: () => <ProcessItemPage />,
    permissionKey: "Item Exceptions",
  },

  // Administration
  {
    key: "add-new-fleet",
    path: "/userAdministration/groups/add",
    component: (props) => (
      <UsersAdministration
        {...props}
        showEditModal
        modalComponent={<FleetForm />}
      />
    ),
    role: ROLES.admin,
  },
  {
    key: "financial-parameters",
    path: "/Financial",
    component: () => <FinancialParameters />,
  },
  {
    key: "admin-fleet-landing",
    path: "/userAdministration/groups/:mode/:idFleet",
    component: (props) => (
      <UsersAdministration
        {...props}
        showEditModal
        modalComponent={<FleetForm />}
      />
    ),
    role: ROLES.admin,
  },
  {
    key: "add-new-Orchestrators",
    path: "/robotAdministration/orchestrators/add",
    component: (props) => (
      <RobotsAdministration
        {...props}
        showEditModal
        modalComponent={<OrchestratorForm />}
      />
    ),
    role: ROLES.admin,
  },
  {
    key: "add-update-queue",
    path: "/robotAdministration/queues/add",
    component: (props) => (
      <RobotsAdministration
        {...props}
        showEditModal
        modalComponent={<QueueForm />}
      />
    ),
    permissionKey: "Add SR Queue",
    role: ROLES.admin
  },
  {
    key: "add-update-queue",
    path: "/robotAdministration/queues/:mode/:queueId",
    component: (props) => (
      <RobotsAdministration
        {...props}
        showEditModal
        modalComponent={<QueueForm />}
      />
    ),
    permissionKey: "Edit SR Queue",
    role: ROLES.admin
  },
  {
    key: "view-robot",
    path: "/robotAdministration/robots/:mode/:idRobot",
    component: (props) => (
      <RobotsAdministration
        {...props}
        showEditModal
        modalComponent={<RobotForm />}
      />
    ),
    role: ROLES.admin,
  },
  {
    key: "Orchestrators-Licence",
    path: "/robotAdministration/orchestrators/licence/:idOrchestrator",
    component: (props) => (
      <RobotsAdministration
        {...props}
        tableView
        modalComponent={<OrchestratorLicense />}
      />
    ),
    permissionKey: "User Administration",
    role: ROLES.admin,
  },
  {
    key: "Orchestrators-Form-Orchestrators",
    path: "/robotAdministration/orchestrators/:mode/:idOrchestrator",
    component: (props) => (
      <RobotsAdministration
        {...props}
        showEditModal
        modalComponent={<OrchestratorForm />}
      />
    ),
    role: ROLES.admin,
  },
  {
    key: "admin-user-landing",
    path: "/userAdministration/users",
    component: () => <UsersAdministration />,
    permissionKey: "User Administration",
    role: ROLES.admin,
  },
  {
    key: "edit-create-user",
    path: ["/userAdministration/users/add", "/userAdministration/users/:mode/:idUser"],
    component: (props) => (
      <UsersAdministration
        {...props}
        showEditModal
        modalComponent={<AddUserForm />}
      />
    ),
    permissionKey: "User Administration",
    role: ROLES.admin,
  },
  {
    key: "view-process-page",
    path: "/items/itemsExceptions/currentUser",
    component: (props) => <ProcessItemPage
    {...props}
    currentUserItems
  />,
    permissionKey: "Item Exceptions",
  },
  {
    key: "add-new-division",
    path: "/userAdministration/divisions/add",
    component: (props) => (
      <DivisionManagement
            {...props}
            showEditModal
            modalComponent={<DivisionModuleForm />}
        />
    ),
    role: ROLES.admin,
  },
  {
    key: "edit-existing-division",
    path: "/userAdministration/divisions/edit/:idDivision",
    component: (props) => (
      <DivisionManagement
            {...props}
            showEditModal
            modalComponent={<DivisionModuleForm isEdit />}
            isEdit
        />
    ),
    role: ROLES.admin,
  },
  {
    key: "view-existing-division",
    path: "/robotAdministration/divisions/view/:id",
    component: (props) => (
      <DivisionManagement
            {...props}
            showEditModal
            modalComponent={<DivisionModuleForm />}
        />
    ),
    role: ROLES.admin,
  },

  {
    key: "add-new-tag",
    path: "/robotAdministration/tags/add",
    component: (props) => (
      <TagManagement
            {...props}
            showEditModal
            modalComponent={<TagForm />}
        />
    ),
    permissionKey: "Add tag",
    role: ROLES.admin
  },
  {
    key: "edit-existing-tag",
    path: "/robotAdministration/tags/:mode/:idTag",
    component: (props) => (
      <TagManagement
            {...props}
            showEditModal
            modalComponent={<TagForm />}
        />
    ),
    permissionKey: "Tags Module",
    role: ROLES.admin
  },
  {
    key: "UserAdministration",
    path: [
      "/userAdministration/groups",
      ...!process.env.REACT_APP_DISABLE_PARTITIONING ? ["/userAdministration/divisions"] : [],
      "/userAdministration/users"
    ],
    component: () => <UsersAdministration />,
    role: ROLES.admin,
  },
  {
    key: "RobotAdministration",
    path: [
      "/robotAdministration/orchestrators",
      "/robotAdministration/robots",
      ...!isSRQueuesDisabled ? ["/robotAdministration/queues"] : [],
      "/robotAdministration/tags"
    ],
    component: () => <RobotsAdministration />,
    role: ROLES.admin,
    moduleEnabled: ["Tags Module", "SR Queues"]
  }
];

export default routes;
