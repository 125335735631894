import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(() => ({
    textField: {
        height: "30px",
    },
    chip: {
        maxWidth: 80,
        height: 25,
        fontSize: 12,
    },
}));
