import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  closeButton: {
    color: theme.custom.color.chartColor3,
  },
  title: {
    color: theme.custom.color.dialog.title,
    minHeight: 60,
    display: "flex",
    alignItems: "center",
    marginLeft: "1rem",
    fontWeight: "bold",
  },
  dialogContent: {
    padding: 25,
  },
  contentContainer: {
    height: "100%",
    maxHeight: 450,
    overflowY: "scroll",
    marginBottom: 20,
  },
}));
