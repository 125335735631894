import React from "react";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import clsx from "clsx";
import { formatImagePath, getInitiales } from "../../../util";
import Box from "@material-ui/core/Box";
import Badge from "@material-ui/core/Badge";
import useStyles from "./style";

export default function ProcessIconName({
  imgSrc,
  avatarName,
  processName,
  badge = null,
  avatarClassName = "",
  disabled = false,
}) {
  const classes = useStyles();
  return (
    <Box container className={classes.processContainer} pl={0.5}>
      <Box className={classes.image}>
        {imgSrc ? (
          <img
            src={formatImagePath(imgSrc)}
            alt="Service"
            className={clsx(classes.image, disabled && classes.disabledAvatar)}
          />
        ) : (
          <Badge
            overlap="circular"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            badgeContent={badge}
          >
            <Avatar
              className={clsx(
                classes.robotAvatar,
                avatarClassName,
                disabled && classes.disabledAvatar,
              )}
              alt="avatar"
            >
              {getInitiales(avatarName)}
            </Avatar>
          </Badge>
        )}
      </Box>
      <Box>
        <Typography variant="body2" align="left">
          <Box
            className={clsx(
              classes.processDisplayName,
              disabled && classes.disabledProcessDisplayName,
            )}
          >
            {processName || "---"}
          </Box>
        </Typography>
      </Box>
    </Box>
  );
}
