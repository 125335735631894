import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from "react-i18next";
import {
  FormControl,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { SearchFilter } from "../../../../components/Filter";
import ClearFilter from "../../../../components/ClearFilter";
import { initFilter } from "../../../../redux/slices/alertFilterSlice";
import { fetchAllFleets, fetchDivisionsForFilters } from "../../../../redux/actions/services";
import { useQuery } from "@redux-requests/react";
import { FETCH_CURRENT_USER } from "../../../../redux/constants";
import NoDataMenu from "../../../../components/NoData/NoDataMenu";
import { isFleetAdministrator } from "../../../../util";
import CustomAutoComplete from "../../../../components/FormFields/CustomAutoComplete";
import Box from "@material-ui/core/Box";
import AddIcon from "@material-ui/icons/Add";
import CustomButton from "../../../../components/CustomButton";
import { useHistory } from "react-router-dom";
import { HasPermission } from "../../../../components/HasPermission";
import useStyles from "../style";
import { isPartitioningDisabled } from "../../../../util/constants/PermitedFeature";

export default function AlertsTableFilter({
  statusList,
  typeList,
  searchLabel,
  handleFilterValueChange,
  handleChangeStatus,
  handleChangeTypes,
  handleChangeDivisions,
  handleChangeFleets,
  entityBaseUrl
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const filter = useSelector(({ filterListAlert }) => filterListAlert);
  const dispatch = useDispatch();
  const currentUser = useQuery({ type: FETCH_CURRENT_USER })?.data;
  const history = useHistory();
  const [userDivision, setUserDivision] = useState();
  const [userFleets, setUserFleets] = useState();
  const handleClear = () => {
    dispatch(initFilter());
  };

    useEffect(() => {
        if (currentUser) { dispatch(fetchDivisionsForFilters()).then((res) => {
            setUserDivision(res?.data) }) }
    }, [currentUser])

    useEffect(() => {
        if (currentUser?.fleet?.instanceOwner && isFleetAdministrator(currentUser) && !isPartitioningDisabled) {
          dispatch(fetchAllFleets()).then((res) => {
            setUserFleets(res.data);
          });
        }
    }, [])

  return (
    <Grid container spacing={5}>
      <Grid item container xs={12} spacing={2}>
        <Box component={Grid} item container xs={2} alignItems="center" pt="12px !important">
          <SearchFilter
            callback={handleFilterValueChange}
            placeHolder={t(searchLabel)}
            style={classes.searchIcon}
            value={filter[filter.currModule]?.searchCriteria}
          />
        </Box>
        <Grid container item xs={2}>
          <FormControl fullWidth>
            <CustomAutoComplete
              multiple
              options={statusList || []}
              optionLabel="label"
              optionUuid="code"
              value={statusList.filter(({ code }) => filter[filter.currModule]?.status?.includes(code))}
              onChange={handleChangeStatus}
              label={t("status")}
          />
          </FormControl>
        </Grid>
        <Grid item xs={2}>
          <FormControl fullWidth className={classes.autoComplete}>
            <CustomAutoComplete
              multiple
              options={typeList}
              optionLabel="label"
              optionUuid="code"
              value={typeList.filter((item) => filter[filter.currModule]?.types.includes(item.code))}
              noOptionsNode={<NoDataMenu message={t("no.type.message")} />}
              onChange={handleChangeTypes}
              label={t("alert.type")}
            />
          </FormControl>
        </Grid>
        {currentUser?.fleet?.instanceOwner
          && isFleetAdministrator(currentUser)
          && !isPartitioningDisabled && (
            <Grid container item xs={2}>
              <FormControl fullWidth className={classes.autoComplete}>
                <CustomAutoComplete
                  multiple
                  options={userFleets || []}
                  optionLabel="companyName"
                  value={
                    userFleets
                      ?.filter(({ id }) => filter[filter.currModule]?.fleets.includes(id))
                      .map(({ id }) => id).id
                  }
                  noOptionsNode={<NoDataMenu message={t("no.fleet.message")} />}
                  onChange={handleChangeFleets}
                  label={t("fleets")}
                />
              </FormControl>
            </Grid>
          )}
        {!process.env.REACT_APP_DISABLE_PARTITIONING && (
          <Grid container item xs={2}>
            <FormControl fullWidth className={classes.autoComplete}>
              <CustomAutoComplete
                multiple
                options={userDivision || []}
                optionLabel="divisionName"
                value={
                  userDivision
                    ?.filter(({ id }) => filter[filter.currModule]?.divisions.includes(id))
                    .map(({ id }) => id).id
                }
                noOptionsNode={
                  <NoDataMenu message={t("no.division.message")} />
                }
                onChange={handleChangeDivisions}
                label={t("divisions.division")}
              />
            </FormControl>
          </Grid>
        )}
        <Grid
            container
            item
            xs={1}
            justify="flex-start"
            alignItems="flex-end"
            className={classes.clearFilterBtn}
        >
          <ClearFilter
              clearFilter={handleClear}
              size="24px"
              label={t("reporting.clear")}
          />
        </Grid>
        <HasPermission name={entityBaseUrl.includes("customAlerts") ? "Add custom alert" : "Add default alert"}>
          <Grid
            container
            item
            xs={3}
            justify="flex-end"
            alignItems="flex-end"
            className={classes.actionButtons}
        >
            <CustomButton
              view="primary"
              type="submit"
              onClick={() => {
                history.push(`${entityBaseUrl}/add`);
              }}
          >
              <AddIcon />
              {t("alerts.default.addButton")}
            </CustomButton>
          </Grid>
        </HasPermission>
      </Grid>

    </Grid>
  );
}
