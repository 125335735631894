import React, { useEffect, useState } from "react";
import { Avatar, Badge, IconButton } from "@material-ui/core";
import PersonIcon from "@material-ui/icons/Person";
import EditIcon from "@material-ui/icons/Edit";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import useStyles from "./style";

export const ImageProfile = (props) => {
  const classes = useStyles();
  const [file, setFile] = useState();
  const { t } = useTranslation();
  const { maxMbSize, allowedExtensions } = props;
  const getImageURL = (path) => `${process.env.REACT_APP_DOMAIN}api/admin${path}`;
  const onChange = () => {
    const tmpFile = document.getElementById("imageUpload")
      && document.getElementById("imageUpload").files[0];
    if (maxMbSize && ((tmpFile.size / 1024) / 1024) > maxMbSize)
    {
        toast.warn(t("max.size.allowed", { size: maxMbSize }));
        return;
    }
    if (allowedExtensions && !allowedExtensions.includes(tmpFile.name.split(".").pop()))
    {
        toast.warn(t("allowed.extensions", { extensions: allowedExtensions }));
        return;
    }
    const reader = new FileReader();
    reader.readAsDataURL(tmpFile);
    reader.onload = () => {
      setFile(reader.result);
      if (props.changeValue) props.changeValue(tmpFile);
    };
  };

  useEffect(() => {
    if (props.src) {
      setFile(getImageURL(props.src));
    }
  }, [props.src]);

  return (
    <Badge
      overlap="circle"
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      classes={{ badge: classes.customBadge }}
      badgeContent={(
        <IconButton className={classes.editIcon} component="label" onChange={onChange}>
          <input
            disabled={props.disabled}
            id="imageUpload"
            accept={allowedExtensions ?? "image/*"}
            type="file"
            hidden
          />
          <EditIcon fontSize="small" />
        </IconButton>
      )}
    >
      {file ? (
        <Avatar alt="supplier" src={file} className={classes.large} />
      ) : (
        <Avatar className={classes.large}>
          <PersonIcon className={classes.avatarIcon} />
        </Avatar>
      )}
    </Badge>
  );
};

export default ImageProfile;
