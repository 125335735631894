import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  rootGlobal: {
    width: "100%",
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(2),

  },
  processContainer: {
    marginBottom: theme.spacing(3)
  },
  content: {
    fontWeight: "600",
    textAlign: "center",
    width: "100%",
  },
  tableLabel: {
    minHeight: "100%"
  },
  executionsTableLabel: {
    minHeight: "100%",
    marginLeft: "1rem"
  },
  priorityTableLabel: {
    minHeight: "100%",
    marginLeft: "0.7rem",
    marginRight: "-0.5rem"
  },
}));
