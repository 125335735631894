import React from "react";
import { useTranslation } from "react-i18next";
import { Box } from "@material-ui/core";
import { getWorkflowStatusColor, workflowStatus as status } from "../workflowUtil";

function WorkflowChip({ item, inDrawer }) {
  const { t } = useTranslation();

  // eslint-disable-next-line consistent-return
  const getWorkflowStatusLabel = () => {
    switch (item.workflowStatus) {
      case status.READY:
        return (
          <Box display="flex" alignItems="center">
            {t("exception-workflow.status.ready")}
          </Box>
        );
      case status.TAKEN:
        return (
          <Box display="flex" alignItems="center">
            {t("exception-workflow.status.taken", { firstname: item.userInCharge?.firstname, lastname: item.userInCharge?.lastname })}
          </Box>
        );
      case status.DONE:
        return (
          <Box display="flex" alignItems="center">
            {t("exception-workflow.status.done")}
          </Box>
        );
      default:
        break;
    }
  };

  return (
    <Box style={{
      ...(!inDrawer) && {
        position: "absolute",
        top: 40,
        right: 6,
        marginLeft: "5px",
      },
      ...(inDrawer) && {
        borderRadius: "4px",
      },
      padding: "3px 6px",
      color: "white",
      fontSize: "0.7rem",
      fontWeight: 600,
      backgroundColor: getWorkflowStatusColor(item.workflowStatus),
    }}
    >
      {getWorkflowStatusLabel()}
    </Box>
  );
}

export default WorkflowChip;
