import React from "react"
import TextField from "@material-ui/core/TextField";
import { Autocomplete } from "@material-ui/lab";
import Popper from "@material-ui/core/Popper";
import Checkbox from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import { useTranslation } from "react-i18next";
import useStyles from "./style";

const MenuPopper = (props) => (
  <Popper {...props} placement="bottom-start">
    {props.children}
  </Popper>
    );

export default function AutoCompleteField({
 options, optionLabel, label, value, noOptionsNode, onChange, multiple, limitTags,
    customRenderOption, disabled, error, errorText, translateLabel = true, ...rest
}) {
    const { t } = useTranslation()
    const classes = useStyles();
    const getOptionLabel = (option) => {
       if (optionLabel) {
           if (typeof optionLabel === "function") {
               return translateLabel ? t(optionLabel(option)) : optionLabel(option);
           }
           if (Array.isArray(optionLabel)) {
               // Concatenate multiple labels
               return optionLabel.map((label) => String(option[label])).join(" ");
           }
           // Check if optionLabel contains a nested property
           if (optionLabel.includes(".")) {
               const nestedProperties = optionLabel.split(".");
               // Access nested property using reduce and check for undefined values
               const nestedValue = nestedProperties.reduce((obj, key) => (obj && obj[key] !== undefined ? obj[key] : ""), option);
               return String(nestedValue);
           }
           return translateLabel ? t(String(option[optionLabel])) : String(option[optionLabel]);
       }
       return translateLabel ? t(String(option)) : String(option);
    };

    return (
      <Autocomplete
              multiple={multiple}
              limitTags={limitTags}
              options={options}
              openOnFocus
              disableCloseOnSelect
              getOptionLabel={getOptionLabel}
              value={value}
              noOptionsText={noOptionsNode}
              onChange={(e, newVal) => onChange(newVal)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label={label}
                  InputProps={{
                    ...params.InputProps,
                    style: { height: "33px", ...params.InputProps.style },
                  }}
                  error={error}
                  helperText={errorText}
                />
              )}
              renderOption={customRenderOption || ((option, { selected }) => (
                <>
                  <Checkbox checked={selected} />
                  <Typography component="subtitle2">
                    {getOptionLabel(option)}
                  </Typography>
                </>
              ))}
              renderTags={(value, getTagProps) => (
                value.map((option, index) => (
                  <Chip
                    key={getOptionLabel(option)}
                    label={getOptionLabel(option)}
                    {...getTagProps({ index })}
                    className={classes.chip}
                  />
                ))
              )}
              ChipProps={{
                  size: "small"
              }}
              PopperComponent={MenuPopper}
              disabled={disabled}
              {...rest}
          />
    )
}
