import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "../../../style/Error.css";

const NotFound = () => {
  const { t } = useTranslation();
  return (

    <div className="error_page">
      <p className="text-error">404</p>
      <p className="error-text">{t("Sorry the page you are looking for could not be found")}</p>
      <Link to="/dashboard" className="error_link">
        {t("Go to the home page")}
        {" "}
      </Link>
    </div>
  );
};
export default NotFound;
