import React, { useEffect } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import { useDispatch } from "react-redux";
import { Switch, Route, useLocation } from "react-router-dom";
import WrapperContainer from "../WrapperContainer";
import { fetchCurrentUser } from "../redux/actions/services";
import SignUp from "../pages/Signup";
import NotFound from "../WrapperContainer/components/NotFound";
import { ToastContainer } from "react-toastify";
import License from "../pages/License";
import i18n from "../i18n";
import supportedLanguages from "../components/Language/supportedLanguages";

function App() {
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
      if (location.pathname !== "/license") dispatch(fetchCurrentUser());
  }, [dispatch]);
  useEffect(() => {
      if (location.search.includes("locale=")) {
          const locale = location.search.split("=")[1];
          if (locale && supportedLanguages.map(({ key }) => key).includes(locale)) {
              i18n.changeLanguage(locale);
              localStorage.setItem("lgn", locale);
          }
      }
  }, [])

  return (
    <>
      <CssBaseline />
      <WrapperContainer />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        closeOnClick
        pauseOnFocusLoss
        pauseOnHover
        theme="colored"
      />
      {location.pathname === "/404" ? <NotFound />
        : null}
    </>
  );
}

const AppWithSignUp = () => (
  <>
    <CssBaseline />
    <Switch>
      <Route key="signup-page" path="/aws/signup" component={SignUp} />
      <Route key="license-page" path="/license" component={License} />
    </Switch>
    <App />
  </>
);

export default AppWithSignUp;
