import {
    CHECK_AWS_USER_EXISTS,
    CREATE_FLEET, CREATE_USER_AWS,
    DELETE_FLEET_BY_ID,
    DELETE_LIST_FLEETS, FETCH_ALL_FLEETS,
    FETCH_FLEET, FETCH_FLEET_TAGS, FETCH_FLEETS, FETCH_PAYMENT_TERMS, FETCH_SUBSCRIPTIONS,
    UPDATE_FLEET,
    UPDATE_FLEET_STATUS
} from "../../../constants";
import { URL_FLEET, URL_FLEET_v2 } from "../../../constants/endpoints";

export const editFleet = (fleetId, fleet, file, onSuccess, onError) => {
    const data = new FormData();
    data.append("file", file);
    data.append("fleet", JSON.stringify(fleet));
    return {
        type: UPDATE_FLEET,
        request: {
            url: `${URL_FLEET}/${fleetId}`,
            method: "put",
            data,
            headers: { "Content-Type": "multipart/form-data" },
        },
        meta: {
            onSuccess: (response) => {
                if (onSuccess) onSuccess(response);
                return response;
            },
            onError: (error) => {
                if (onError) onError(error);
                return error;
            },
        },
    };
};
export const createFleet = (fleet, file, onSuccess, onError) => {
    const data = new FormData();
    data.append("file", file);
    data.append("fleet", JSON.stringify(fleet));
    return {
        type: CREATE_FLEET,
        request: {
            url: `${URL_FLEET}/`,
            method: "post",
            data,
            headers: { "Content-Type": "multipart/form-data" },
        },
        meta: {
            onSuccess: (response) => {
                if (onSuccess) onSuccess(response);
                return response;
            },
            onError: (error) => {
                if (onError) onError(error);
                return error;
            },
        },
    };
};
export const fetchFleet = (id) => ({
    type: `${FETCH_FLEET}`,
    request: {
        url: `${URL_FLEET}/${id}`,
    },
});

export const fetchReducedFleet = (id) => ({
    type: `${FETCH_FLEET}`,
    request: {
        url: `${URL_FLEET_v2}/${id}/reduced`,
    },
});

export const deleteFleets = (idFleets, onSuccess, onError) => ({
    type: DELETE_LIST_FLEETS,
    request: {
        url: `${URL_FLEET}/`,
        method: "delete",
        params: { idFleets: idFleets.join(",") },
    },
    meta: {
        onSuccess: (response) => {
            if (onSuccess) onSuccess();
            return response;
        },
        onError: (error) => {
            if (onError) onError();
            return error;
        },
    },
});

export const deleteFleet = (id, onSuccess, onError) => ({
    type: DELETE_FLEET_BY_ID,
    request: {
        url: `${URL_FLEET}/${id}`,
        method: "delete",
    },
    meta: {
        onSuccess: (response) => {
            if (onSuccess) onSuccess();
            return response;
        },
        onError: (error) => {
            if (onError) onError();
            return error;
        },
    },
});

export const enableOrDisableFleet = (
    id,
    orchestratorStatus,
    onSuccess,
    onError,
) => ({
    type: UPDATE_FLEET_STATUS,
    request: {
        url: `${URL_FLEET}/status/${id}/${orchestratorStatus === "ACTIVE" ? "enable" : "disable"
        }`,
        method: "put",
        headers: { "Content-Type": "application/json" },
    },
    meta: {
        onSuccess: (response) => {
            if (onSuccess) onSuccess();
            return response;
        },
        onError: (error) => {
            if (onError) onError();
            return error;
        },
    },
});

export const fetchAllFleets = () => ({
    type: FETCH_ALL_FLEETS,
    request: {
        url: `${URL_FLEET}/all`,
    },
});
export const fetchFleets = (filter = {}) => {
    const {
        searchCriteria, pageNo, pageSize, status, sortBy,
    } = filter;
    return {
        type: FETCH_FLEETS,
        request: {
            url: `${URL_FLEET}/`,
            params: {
                searchCriteria,
                pageNo,
                pageSize,
                sortBy,
                status: status ? status.join(",") : null,
            },
        },
    };
};
export const fetchSubscriptions = () => ({
    type: FETCH_SUBSCRIPTIONS,
    request: {
        url: `${URL_FLEET}/subscription`,
    },
});
export const fetchPaymentTerms = () => ({
    type: FETCH_PAYMENT_TERMS,
    request: {
        url: `${URL_FLEET}/paymentTerms`,
    },
});
export const createUserAWS = (userFleet, onSuccess, onError) => ({
    type: CREATE_USER_AWS,
    request: {
        url: `${URL_FLEET}/createAwsUser`,
        method: "post",
        data: userFleet,
        headers: { "Content-Type": "application/json" },
    },
    meta: {
        onSuccess: (response) => {
            if (onSuccess) onSuccess(response);
            return response;
        },
        onError: (error) => {
            if (onError) onError(error);
            return error;
        },
    },
});

export const checkAwsUserExists = (data) => ({
    type: CHECK_AWS_USER_EXISTS,
    request: {
        url: `${URL_FLEET}/checkAwsUserExists`,
        method: "post",
        data,
    },
    meta: {
        onSuccess: (response) => response,
        onError: (error) => error,
    },
});

export const fetchFleetTags = (fleetId) => ({
    type: FETCH_FLEET_TAGS,
    request: {
        url: `${URL_FLEET_v2}/${fleetId}/tags`,
    }
});
