import { fade, makeStyles } from "@material-ui/core/styles";

const IMAGE_DIMENSION = 30

export default makeStyles((theme) => ({
    processContainer: {
      display: "grid",
      gridTemplateColumns: `${IMAGE_DIMENSION + 15}px 1FR`,
      alignItems: "center"
    },
    robotAvatar: {
        width: IMAGE_DIMENSION,
        height: IMAGE_DIMENSION,
        marginLeft: "auto",
        marginRight: "auto",
        fontSize: 14,
        fontWeight: 600
    },
    image: {
    width: IMAGE_DIMENSION,
    height: IMAGE_DIMENSION,
    },
    processDisplayName: {
        color: theme.palette.primary.main,
        fontSize: 15,
        fontWeight: 700,
        marginTop: 2
    },
    disabledAvatar: {
       opacity: 0.7
    },
    disabledProcessDisplayName: {
        color: fade(theme.palette.primary.main, 0.6)
    }
}));
