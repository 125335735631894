import React, { useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { fetchDashboardHandlingTime } from "../../../redux/actions/services/dashboard";
import { handleRequestMonitoringMode, MAX_PROCESSES_HANDLING_TIME } from "../dashboardUtils";
import isEmpty from "lodash/isEmpty";
import CircularLoader from "../../../components/Loaders/CircularLoader";
import HandlingTimeItem from "./HandlingTimeItem";
import DataNotFound from "../../../components/DataNotFound";
import useStyles from "../style";

export default function HandlingTime({ xs }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const filterDashboard = useSelector(({ filterDashboard }) => filterDashboard);
  const refreshTime = filterDashboard?.monitoringMode;
  const fetchIntervalRef = React.useRef(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [handlingTimeData, setHandlingTimeData] = React.useState();

  const fetchHandlingTime = () => {
    setIsLoading(true);
    dispatch(
        fetchDashboardHandlingTime({
          ...filterDashboard,
          maxProcess: MAX_PROCESSES_HANDLING_TIME,
        })
    ).then((res) => {
      setHandlingTimeData(res?.data);
      setIsLoading(false);
    });
  }

  useEffect(() => {
    fetchHandlingTime()
  }, [filterDashboard]);

  useEffect(() => {
    handleRequestMonitoringMode(refreshTime, fetchIntervalRef, fetchHandlingTime)
  }, [refreshTime])

  return (
    <Box
      display="flex"
      alignContent="flex-start"
      component={Grid}
      item
      container
      xs={xs}
      className={classes.container}
      gridRowGap={10}
    >
      <Typography className={classes.title} component="h1">
        {t("Average Handling Time")}
      </Typography>
      {
        // eslint-disable-next-line no-nested-ternary
        isLoading || !handlingTimeData ? (
          <CircularLoader height={310} />
      ) : isEmpty(handlingTimeData) ? (
        <DataNotFound
          backgroundColor="transparent"
          boxShadow="none"
          iconHeight={100}
          iconWidth={100}
        />
      ) : (
        handlingTimeData
        && handlingTimeData?.map((handlingTime, index) => (
          <HandlingTimeItem item={handlingTime} key={index} />
        ))
      )
}
    </Box>
  );
}
