import React, { useEffect } from "react";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Grid from "@material-ui/core/Grid";
import Tab from "@material-ui/core/Tab";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import CustomDialog from "pages/Services/components/CustomDialog";
import get from "lodash/get";
import { useHistory } from "react-router-dom";
import { DialogContent, DialogContentText } from "@material-ui/core";
import clsx from "clsx";
import SubPagePanel from "../../../components/SubPagePanel";
import SubPageContainer from "../../../components/SubPageContainer";
import OrchestratorManagement from "../OrchestratorManagement";
import useResizeObserver from "util/tabsWidthRefresh";
import useStyles from "../style";
import { isSubModulePermitted } from "../../../components/HasPermission";
import RobotManagement from "../RobotManagement";
import { isFleetAdministrator } from "../../../util";
import { updateModule } from "../../../redux/slices/adminFilterSlice";
import QueueManagement from "../QueueManagement";
import TagManagement from "../TagManagement";
import { isSRQueuesDisabled } from "util/constants/PermitedFeature"

function a11yProps(index) {
    return {
        id: `administrator-tab-${index}`,
        "aria-controls": `administrator-tabpanel-${index}`,
    };
}

export default function RobotsAdministration(props) {
    const routes = [
        "/robotAdministration/orchestrators",
        "/robotAdministration/robots",
        "/robotAdministration/tags",
        ...!isSRQueuesDisabled ? ["/robotAdministration/queues"] : [],
    ];

    const currentUser = useSelector(({ requests }) => get(requests, "queries.FETCH_CURRENT_USER.data"));
    const currModule = useSelector(({ adminFilter }) => adminFilter.currModule);
    const dispatch = useDispatch();
    const history = useHistory();
    const classes = useStyles();
    const [value, setValue] = React.useState(() => {
        const path = routes.find((element) => history.location.pathname.includes(element));
        return routes.indexOf(path);
    });
    const { t } = useTranslation();
    const { tabsRef } = useResizeObserver();

    const module = history.location.pathname.split("/").slice(2, 3).join("/");

    useEffect(() => {
        if (module !== currModule) {
            dispatch(updateModule({ module }));
        }
    }, [module]);
    const handleChange = (event, newValue) => {
        history.push(routes[newValue]);
        setValue(newValue);
    };

    return (
      <SubPageContainer>
        <div className={classes.root}>
          <AppBar elevation={0} position="static">
            <Tabs
              ref={tabsRef}
              value={value}
              onChange={handleChange}
              aria-label="Assets"
              variant="fullWidth"
              className={classes.tabs}
            >
              {isFleetAdministrator(currentUser) && (
                <Tab
                  className={classes.tab}
                  label={t("orchestrator.management.menu.title")}
                  value={0}
                  {...a11yProps(0)}
                />
              )}
              {isFleetAdministrator(currentUser) && (
                <Tab
                  className={classes.tab}
                  label={t("robot.management.menu.title")}
                  value={1}
                  {...a11yProps(1)}
                />
              )}
              {(isFleetAdministrator(currentUser)
                || isSubModulePermitted(currentUser, "Tags Module")) && (
                <Tab
                  className={classes.tab}
                  label={t("tags")}
                  value={2}
                  {...a11yProps(2)}
                />
              )}
              {(isFleetAdministrator(currentUser)
                || isSubModulePermitted(currentUser, "SR Queues"))
                && !isSRQueuesDisabled && (
                  <Tab
                    className={classes.tab}
                    label={t("SR Queues")}
                    value={3}
                    {...a11yProps(3)}
                  />
                )}
            </Tabs>
          </AppBar>
          {(props.showEditModal || props.tableView) && (
            <CustomDialog
              open={props.showEditModal || props.tableView}
              // onClose={handleClickOpen}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              className={clsx(classes.dialog, classes.dialogContainer)}
            >
              <DialogContent dividers className={classes.dialogContent}>
                <DialogContentText
                  id="scroll-dialog-description"
                  tabIndex={-1}
                  component="div"
                  className={classes.noMargin}
                >
                  {props.modalComponent}
                </DialogContentText>
              </DialogContent>
            </CustomDialog>
          )}
          <Grid className={classes.firstGrid}>
            {isFleetAdministrator(currentUser) && (
              <SubPagePanel value={value} index={0} classes={classes.noPadding}>
                <OrchestratorManagement />
              </SubPagePanel>
            )}
            {isFleetAdministrator(currentUser) && (
              <SubPagePanel value={value} index={1} classes={classes.noPadding}>
                <RobotManagement />
              </SubPagePanel>
            )}
            {(isFleetAdministrator(currentUser)
              || isSubModulePermitted(currentUser, "Tags Module")) && (
              <SubPagePanel value={value} index={2} classes={classes.noPadding}>
                <TagManagement />
              </SubPagePanel>
            )}
            {(isFleetAdministrator(currentUser)
              || isSubModulePermitted(currentUser, "SR Queues"))
              && !isSRQueuesDisabled && (
                <SubPagePanel
                  value={value}
                  index={3}
                  classes={classes.noPadding}
                >
                  <QueueManagement />
                </SubPagePanel>
              )}
          </Grid>
        </div>
      </SubPageContainer>
    );
}
