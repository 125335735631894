import { createSlice } from "@reduxjs/toolkit";

const initialFilter = {
  role: "",
  page: 0,
  limit: 0,
  name: "",
  status: "",
  showArchived: false,
  divisions: [],
  fleets: [],
};
const filterListRpaQual = createSlice({
  name: "rpaQual",
  initialState: initialFilter,
  reducers: {
    updateFilter: (state, action) => action.payload,
    initFilter: () => initialFilter,
  },
});

export const { updateFilter, initFilter } = filterListRpaQual.actions;

export default filterListRpaQual.reducer;
