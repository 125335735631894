import React from "react";
import Box from "@material-ui/core/Box";
import useStyles from "./style";
import { CustomTextField } from "../../../../components/FormFields/CustomAutoComplete";
import processInputTypes from "../../../../util/constants/processInputTypes";
import CustomSwitch from "../CustomSwitch";
import { Controller } from "react-hook-form";
import Typography from "@material-ui/core/Typography";
import FormConsumer from "../../../../components/FormConsumer";
import { useTranslation } from "react-i18next";
import TimeSpanField from "../TimeSpanField";
import TextFieldForm from "../../../../components/ReactHookField/TextFieldForm";
import DateFnsUtils from "@date-io/date-fns";
import { formatDatePickerByLanguage, getLocale } from "../../../../util";
import FormControl from "@material-ui/core/FormControl";
import {
  DatePicker,
  DateTimePicker,
  MuiPickersUtilsProvider,
  TimePicker
} from "@material-ui/pickers";
import { AccessTime, CalendarToday } from "@material-ui/icons";
import TextField from "@material-ui/core/TextField";
import { format } from "date-fns";

function ProcessInputField({
  error,
  helperText,
  InputLabelProps,
  InputProps,
  name,
  type,
  value
}) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <FormConsumer>
      {({ register, control, ...form }) => {
        switch (type) {
          case processInputTypes.TEXT:
            return (
              <Box className={classes.inputBox}>
                <CustomTextField
                  {...register(name)}
                  fullWidth
                  label={t(name.charAt(0).toUpperCase() + name.slice(1))}
                  type="text"
                  value={value}
                  InputLabelProps={InputLabelProps}
                  helperText={helperText}
                  error={error}
                />
              </Box>
            )
          case processInputTypes.NUMBER:
            return (
              <Box className={classes.inputBox}>
                <TextFieldForm
                  fullWidth
                  fieldName={name}
                  label={t(name.charAt(0).toUpperCase() + name.slice(1))}
                  onChange={(v) => form.setValue(name, v)}
                  type="number"
                  InputLabelProps={InputLabelProps}
                  InputProps={InputProps}
                  helperText={helperText}
                  error={error}
                  isCustom
                />
              </Box>
            )
          case processInputTypes.FLAG:
            return (
              <Box className={classes.inputBox}>
                <Controller
                  name={name}
                  control={control}
                  defaultValue={false}
                  render={({ field }) => (
                    <CustomSwitch
                      label={
                        <Typography color="primary" variant="h6" className={classes.flagLabel}>
                          {t(name.charAt(0).toUpperCase() + name.slice(1))}
                        </Typography>
                      }
                      labelPlacement="start"
                      checked={field.value}
                      handleChange={field.onChange}
                      name={field.name}
                    />
                  )}
                />
              </Box>
            )
          case processInputTypes.TIMESPAN:
            return (
              <Box className={classes.inputBox}>
                <TimeSpanField
                  name={name}
                  error={error}
                  value={value}
                  setValue={(v) => form.setValue(name, v)}
                />
              </Box>
            )
          case processInputTypes.DATE:
            return (
              <Box className={classes.inputBox}>
                <MuiPickersUtilsProvider
                  utils={DateFnsUtils}
                  locale={getLocale()}
                >
                  <FormControl fullWidth margin="none">
                    <DatePicker
                      {...register(name)}
                      label={t(name.charAt(0).toUpperCase() + name.slice(1))}
                      showTodayButton
                      value={value || null}
                      onChange={(v) => form.setValue(name, format(v, "yyyy/MM/dd"))}
                      format={formatDatePickerByLanguage(false)}
                      cancelLabel={t("user.button.cancel")}
                      todayLabel={t("Today")}
                      className={classes.input}
                      InputProps={{ endAdornment: <CalendarToday className={classes.popupIcon} fontSize="small" /> }}
                      renderInput={(params) => (
                        <TextField className={classes.input} {...params} />
                      )}
                    />
                  </FormControl>
                </MuiPickersUtilsProvider>
              </Box>
            )
          case processInputTypes.TIME:
            return (
              <Box className={classes.inputBox}>
                <MuiPickersUtilsProvider
                  utils={DateFnsUtils}
                  locale={getLocale()}
                >
                  <FormControl fullWidth margin="none">
                    <TimePicker
                      {...register(name)}
                      label={t(name.charAt(0).toUpperCase() + name.slice(1))}
                      ampm={false}
                      value={value || null}
                      onChange={(v) => form.setValue(name, v)}
                      format="HH:mm:ss"
                      showTodayButton
                      views={["hours", "minutes", "seconds"]}
                      todayLabel={t("Now")}
                      cancelLabel={t("user.button.cancel")}
                      className={classes.input}
                      InputProps={{ endAdornment: <AccessTime className={classes.popupIcon} fontSize="small" /> }}
                      renderInput={(params) => (
                        <TextField className={classes.input} {...params} />
                      )}
                    />
                  </FormControl>
                </MuiPickersUtilsProvider>
              </Box>
            )
          case processInputTypes.DATETIME:
            return (
              <Box className={classes.inputBox}>
                <MuiPickersUtilsProvider
                  utils={DateFnsUtils}
                  locale={getLocale()}
                >
                  <FormControl fullWidth margin="none">
                    <DateTimePicker
                      {...register(name)}
                      label={t(name.charAt(0).toUpperCase() + name.slice(1))}
                      ampm={false}
                      showTodayButton
                      value={value || null}
                      onChange={(v) => form.setValue(name, format(v, "yyyy/MM/dd HH:mm:ss"))}
                      format={formatDatePickerByLanguage()}
                      cancelLabel={t("user.button.cancel")}
                      todayLabel={t("Today")}
                      className={classes.input}
                      InputProps={{ endAdornment: <CalendarToday className={classes.popupIcon} fontSize="small" /> }}
                      renderInput={(params) => (
                        <TextField className={classes.input} {...params} />
                      )}
                      DialogProps={{
                        className: classes.dateTimePicker,
                      }}
                    />
                  </FormControl>
                </MuiPickersUtilsProvider>
              </Box>
            )
          default: return (<span />)
        }
      }}
    </FormConsumer>
  )
}

export default ProcessInputField;
