import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(() => ({
    dialogContent: {
        width: "100%"
    },
    nameCell: {
        width: "20%"
    },
    oldValueCell: {
        width: "35%"
    },
    newValueCell: {
        width: "45%"
    }
}));
