import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Checkbox from "@material-ui/core/Checkbox";
import TrashIcon from "@material-ui/icons/Delete";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "@redux-requests/react";
import {
  checkIfQueuesHaveSchedules,
  deleteQueuesSchedules,
  deleteSrQueues,
  migrateQueuesSchedules,
} from "redux/actions/services";
import {
  triggerQueueAction,
  updateQueueAction,
} from "redux/slices/executionManagerSlice";
import { isFleetAdministrator } from "util";
import { ASK_DELETE_ONE_QUEUE, QUEUE_DELETED } from "util/constants";
import ConfirmMessage from "components/ConfirmMessage/index.js";
import StatusLegend from "../../../../../components/StatusLegend"
import useStyles from "../../../../../components/TableComponents/style";

export default function QueueTableHeader({
  selected,
  setSelected,
  handleSelectedAll,
  checkedAll,
  checkedIndeterminate,
  fetchEntities,
  page,
  rowsPerPage,
  enableDeleteMultiple,
  statusList
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [isPopUpActionLoading, setIsPopUpActionLoading] = useState(false);
  const [popUpMessage, setPopUpMessage] = useState("");
  const [action, setAction] = useState("");
  const [confirmBtnText, setConfirmBtnText] = useState("");

  const handleCloseConfirmationPopup = () => {
    setIsLoading(false);
    setOpenDeletePopup(false);
    setOpenPopup(false);
  };

  const currentUser = useSelector(({ requests }) => get(requests, "queries.FETCH_CURRENT_USER.data"),);
  const { data } = useQuery({ type: fetchEntities().type });
  const { queueAction } = useSelector(
    ({ executionManager }) => executionManager,
  );

  const deleteAll = (newSelected = selected) => {
    dispatch(
      deleteSrQueues(
        newSelected,
        () => {
          setIsLoading(false);
          dispatch(fetchEntities({ pageNo: page, pageSize: rowsPerPage }));
          setSelected([]);
          handleCloseConfirmationPopup();
          toast.success(t("queue deleted successfully"));
        },
        () => {
          handleCloseConfirmationPopup();
          toast.error(t("something went wrong"));
        },
      ),
    );
  };
  React.useEffect(() => {
    if (queueAction === QUEUE_DELETED) {
      handleCloseConfirmationPopup();
      setSelected([]);
      dispatch(updateQueueAction({ queueAction: null }));
      dispatch(fetchEntities({ pageNo: page, pageSize: rowsPerPage }));
    }
  }, [queueAction]);

  const onClick = () => {
    setIsLoading(true);
    const newSelected = selected;
    // if only one queue is selected , trigger action to AdminTableRow to reuse delete one queue logic
    if (newSelected.length === 1) {
      dispatch(
        triggerQueueAction({
          queueAction: ASK_DELETE_ONE_QUEUE,
          id: newSelected[0],
        }),
      );
      return;
    }
    // else check if queues have schedules with robot and without
    dispatch(checkIfQueuesHaveSchedules(selected, handleQueuesCountSchedules));
  };
  const handleQueuesCountSchedules = (response) => {
    const { haveSchedulesWithoutRobots, haveSchedulesWithRobots } = response.data;
    if (!haveSchedulesWithRobots && !haveSchedulesWithoutRobots) {
      // delete all
      deleteAll();
      return;
    }
    if (haveSchedulesWithRobots && !haveSchedulesWithoutRobots) {
      // migrate to bp
      setAction("MIGRATE");
      setConfirmBtnText(t("confirm"));
      setOpenPopup(true);
      setPopUpMessage(
        t("queue.management.unassign.process.part2_plural", {
          nbSchedules: t("some"),
        }),
      );
      return;
    }
    if (haveSchedulesWithoutRobots) {
      // multiple schedules found for multiple queues , cant do multi migration , you might want to go one by one
      setAction("CANT_MIGRATE");
      setConfirmBtnText(t("Continue"));
      setOpenPopup(true);
      setPopUpMessage(t("cant perform multi queue migration"));
    }
  };
  const closePopUp = () => {
    setIsPopUpActionLoading(false);
    setOpenPopup(false);
  };
  const confirm = () => {
    if (action === "MIGRATE") migrateSchedules();
    else {
      deleteAllSchedules();
    }
  };
  const migrateSchedules = () => {
    setIsPopUpActionLoading(true);
    dispatch(
      migrateQueuesSchedules(selected, (res) => {
        // proceed to delete all
        if (res.data) deleteAll();
        else {
          handleCloseConfirmationPopup();
          toast.error(t("something went wrong"));
        }
        closePopUp();
      }),
      () => {
        handleCloseConfirmationPopup();
        closePopUp();
      },
    );
  };

  const deleteAllSchedules = () => {
    setIsPopUpActionLoading(true);
    dispatch(
      deleteQueuesSchedules(selected, () => {
        // proceed to delete all
        deleteAll();
        closePopUp();
      }),
      () => {
        handleCloseConfirmationPopup();
        toast.error(t("something went wrong"));
        closePopUp();
      },
    );
  };

  return (
    <Grid
      container
      xs={12}
      justify="space-between"
      className={classes.tableHeaderContainer}
    >
      <Grid container item xs={6}>
        <StatusLegend
          statues={statusList.map((status) => status.code)}
        />
      </Grid>
      <Grid container item xs={6} justify="flex-end" alignItems="center">
        {enableDeleteMultiple
          && isFleetAdministrator(currentUser)
          && data?.totalElements > 0 && (
            <>
              <Grid item>
                <Tooltip placement="top" title={t("Delete")}>
                  <IconButton
                    aria-label="delete"
                    disabled={isEmpty(selected)}
                    onClick={() => {
                      setOpenDeletePopup(true);
                    }}
                    size="small"
                  >
                    <TrashIcon className={isEmpty(selected) ? classes.rowActionButtons : classes.deleteIcon}
                    />
                  </IconButton>
                </Tooltip>
                <ConfirmMessage
                  message={t("queue.management.bulk-delete.confirm-msg")}
                  openStart={openDeletePopup}
                  onCancel={handleCloseConfirmationPopup}
                  onConfirm={onClick}
                  buttonConfirm={t("Delete")}
                  buttonCancel={t("Cancel")}
                  isLoading={isLoading}
                />
              </Grid>
              <Grid item>
                <Checkbox
                  checked={checkedAll}
                  className={classes.rowActionButtons}
                  indeterminate={checkedIndeterminate}
                  onChange={(e) => {
                    handleSelectedAll(e.target.checked);
                  }}
                  size="small"
                />
              </Grid>
            </>
          )}
        <ConfirmMessage
          message={popUpMessage}
          openStart={openPopup}
          onCancel={() => {
            closePopUp();
            handleCloseConfirmationPopup();
          }}
          onConfirm={confirm}
          buttonConfirm={confirmBtnText}
          buttonCancel={t("Cancel")}
          isLoading={isPopUpActionLoading}
        />
      </Grid>
    </Grid>
  );
}
