import { Grid } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone";
import TextInputWithIcon from "pages/AlertsManagement/components/TextInputWithIcon";
import DescriptionIcon from "@material-ui/icons/Description";
import NotificationImportantIcon from "@material-ui/icons/NotificationImportant";
import DefaultTriggerRow from "./DefaultTriggerRow/index";
import AlertSeverityInput from "../../../components/AlertSeverityInput";
import SubscribersInput from "pages/AlertsManagement/components/SubscribersInput";

export default function DefaultAlertInfo({
  disableFields,
  register,
  errors,
  classes,
  menuItems,
  control,
  watch,
  emailList,
  smsList,
  mode,
  processMenuItem,
  sameFleetUsers,
  selectedSubscribers,
  handleSubscribersChange,
}) {
  const { t } = useTranslation();
  return (
    <Grid item container xs={12} direction="column" spacing={7}>
      <Grid container item direction="column" spacing={2}>
        <TextInputWithIcon
          Icon={NotificationImportantIcon}
          name="alertName"
          label={t("alerts.management.name.label")}
          placeholder={t("alerts.management.name.placeholder")}
          disableFields={disableFields}
          errors={errors}
          classes={classes}
          control={control}
          InputLabelProps={{
            shrink: !!watch("alertName"),
          }}
          rules={{
            required: {
              value: true,
              message: t("alerts.management.required.fields"),
            },
            maxLength: {
              value: 55,
              message: t("alerts.management.alertName.maxLength.error"),
            },
          }}
        />
        <TextInputWithIcon
          fullWidth
          Icon={DescriptionIcon}
          name="alertDescription"
          label={t("alerts.management.alertDescription.label")}
          placeholder={t("alerts.management.alertDescription.placeholder")}
          disableFields={disableFields}
          errors={errors}
          classes={classes}
          control={control}
          rules={{
            maxLength: {
              value: 65535,
              message: t("alerts.management.alertDescription.maxLength.error"),
            },
          }}
        />
        <AlertSeverityInput
          classes={classes}
          control={control}
          errors={errors}
          disableFields={disableFields}
        />
      </Grid>
      <Grid container item alignContent="center" alignItems="center">
        <Grid item>
          <span className={classes.trigger}>
            <InfoOutlinedIcon />
            <span style={{ marginLeft: "20px" }}>
              {t("alerts.management.trigger")}
            </span>
          </span>
        </Grid>
        <Grid container item spacing={2}>
          <DefaultTriggerRow
            classes={classes}
            register={register}
            control={control}
            errors={errors}
            disableFields={disableFields}
            menuItems={menuItems}
            processMenuItem={processMenuItem}
          />
        </Grid>
      </Grid>
      {(mode !== "view" || emailList?.length > 0 || smsList?.length > 0) && (
        <Grid container item spacing={2}>
          <Grid item>
            <span className={classes.trigger}>
              <NotificationsNoneIcon />
              <span style={{ marginLeft: "20px" }}>
                {t("alerts.management.subscribers")}
              </span>
            </span>
          </Grid>
          <SubscribersInput
            sameFleetUsers={sameFleetUsers}
            mode={mode}
            disableFields={disableFields}
            selectedSubscribers={selectedSubscribers}
            handleSubscribersChange={handleSubscribersChange}
            control={control}
            errors={errors}
            classes={classes}
          />
        </Grid>
      )}
    </Grid>
  );
}
