import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import TextField from "@material-ui/core/TextField"
import Typography from "@material-ui/core/Typography"
import MenuItem from "@material-ui/core/MenuItem"
import Select from "@material-ui/core/Select"
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { timeSlots } from "../../utils";
import DataNotFound from "../../../../components/DataNotFound";
import OccurrencesNotFound from "../../../../assets/Process_Overview.svg"
import CustomDialog from "../../../../components/CustomDialog";
import useStyles from "./style";

function RiskManagement({
  openStart,
  handleClose,
  dispatch,
  t,
  reloadPlannerData,
  updateProcessRiskTime,
  processes,
  fetchProcesses,
}) {
  const classes = useStyles();
  const {
    register, handleSubmit, setValue, getValues,
  } = useForm({
    mode: "onChange",
  });

  const onSubmit = (data) => {
    const newData = data.riskManagement.map((e) => ({
      ...e,
      riskTime: e.riskTime
        ? e.riskTime
        : 0,
    }));
    dispatch(updateProcessRiskTime(newData)).then((result) => {
      if (result?.status === 200) {
        toast.success(t("calendar.formControl.saveRiskSuccess"));
        reloadPlannerData();
        handleClose();
        dispatch(fetchProcesses());
      }
      else
      {
        toast.error(t("Unexpected Error"));
      }
    });
  };
  useEffect(() => {
    if (processes) {
      processes.map((row, index) => (setValue(
        `riskManagement[${index}].riskTime`,
        row.riskTime,
      )));
    }
  }, [processes]);

  return (
    <CustomDialog
      title={t("Risk management")}
      open={openStart}
      aria-labelledby="customized-dialog-title"
      maxWidth="lg"
      onClose={handleClose}
      classes={{
        paper: classes.dialogPaper,
      }}
      actionElements={[
          {
             label: t("Cancel"),
             onClick: handleClose,
             color: "primary",
          },
          {
            label: t("apply"),
            onClick: handleSubmit(onSubmit),
            disabled: !processes?.length,
            view: "primary",
            type: "submit",
          }
      ]}
    >
      <Grid container item xs={12}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={classes.root}
          noValidate
          autoComplete="off"
        >
          {processes?.length ? (
            <div className={classes.contentContainer}>
              <Grid container xs={12} className={classes.grid}>
                <Grid item alignItems="center" xs={9}>
                  <Typography variant="body2" className={classes.typography}>
                    {t("Process name")}
                  </Typography>
                </Grid>
                <Grid item container xs={3}>
                  <Typography variant="body2" className={classes.typography}>
                    {t("Risk management")}
                  </Typography>
                </Grid>
              </Grid>
              <Paper className={classes.paper}>
                {processes
                  && processes.map((row, index) => (
                    <Grid container xs={12} className={classes.grid}>
                      <Grid item xs={9}>
                        <TextField
                          value={row.title}
                          style={{ width: "23rem" }}
                          onChange={setValue(
                            `riskManagement[${index}].processId`,
                            row.processId,
                          )}
                          InputProps={{
                            readOnly: true,
                            disableUnderline: true,
                          }}
                        />
                      </Grid>
                      <Grid item container xs={3}>
                        <Select
                          {...register(`riskManagement[${index}].riskTime`)}
                          fullWidth
                          defaultValue={getValues(
                            `riskManagement[${index}].riskTime`,
                          )}
                          style={{ width: "10rem" }}
                          onChange={(e) => {
                            setValue(
                              `riskManagement[${index}].riskTime`,
                              e.target.value,
                            );
                          }}
                        >
                          {timeSlots
                            && timeSlots.map((e, index) => (
                              <MenuItem
                                key={`timeSlots-${index}`}
                                value={e.value}
                              >
                                {e.label}
                              </MenuItem>
                            ))}
                        </Select>
                      </Grid>
                    </Grid>
                  ))}
              </Paper>
            </div>
          ) : (
            <DataNotFound
              message={t("ERRORS_NO_DATA")}
              icon={OccurrencesNotFound}
              boxShadow="none"
            />
          )}
        </form>
      </Grid>
    </CustomDialog>
  );
}
export default RiskManagement;
