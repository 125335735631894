import { makeStyles } from "@material-ui/core/styles";

const ANALYTICS_TABLE_ITEMS = 4
export const ANALYTICS_TABLE_ITEMS_GAP = 20

export default makeStyles((theme) => ({
  rootGlobal: {
    width: "100%",
    paddingTop: theme.spacing(0),
    display: "grid",
    gridTemplateColumns: `repeat(${ANALYTICS_TABLE_ITEMS}, calc((100% - ${ANALYTICS_TABLE_ITEMS_GAP * (ANALYTICS_TABLE_ITEMS - 1)}px)/${ANALYTICS_TABLE_ITEMS}))`,
    columnGap: `${ANALYTICS_TABLE_ITEMS_GAP}px`,
  },
  root: {
    position: "relative",
    marginBottom: "1%",
    background: "white !important",
    overflow: "revert",
    transition: theme.transitions.create(["transform", "filter"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.short,
    }),
    "&:hover": {
      filter: "drop-shadow(0 0 3px #DEE4FF)",
      transform: "scale(1.0025)",
    },
    marginTop: theme.spacing(5),
    padding: theme.spacing(2),
  },
  content: {
    marginTop: theme.spacing(3),
  },
  kpiElement: {
    padding: theme.spacing(1.5, 1.5, 0, 0),
  },
  customKpiElement: {
    display: "flex",
    flexDirection: "row",
    maxWidth: "100%",
    flexBasis: "50%",
    padding: theme.spacing(1.5, 1.5, 0, 0),
  },
  customKpiSubElement: {
    padding: theme.spacing(0, 1.5, 0, 1.5),
    maxWidth: "70%",
    flexBasis: "70%",
  },
  kpiElementOneLine: {

    padding: theme.spacing(0, 1.5, 0, 0),
  },
  analyticsTableLabel: {
    display: "flex",
    flexDirection: "row",
    maxWidth: "70%",
    flexBasis: "70%",
    overflow: "hidden",
    alignItems: "center",
    fontSize: "0.8rem",
    fontWeight: 700,
    color: theme.palette.primary.main,
  },
  analyticsCustomTableLabel: {
    display: "flex",
    flexDirection: "row",
    maxWidth: "70%",
    flexBasis: "70%",
    overflow: "hidden",
    alignItems: "center",
    padding: theme.spacing(1.5, 0, 0, 0),
    color: theme.palette.primary.main,
  },
  analyticsTableValue: {
    display: "flex",
    flexDirection: "row",
    maxWidth: "30%",
    flexBasis: "30%",
    alignItems: "flex-end",
    paddingLeft: theme.spacing(0.5),
    color: theme.palette.primary.main,
  },
  processNameWrapper: {
    overflow: "hidden",
    color: theme.palette.primary.main,
    fontSize: 15,
    fontWeight: 900,
    marginTop: 2
  },
  displayName: {
    padding: theme.spacing(0, 2),
    overflow: "hidden",
  },
  robotAvatar: {
    width: 50,
    height: 50
  },
  itemAvatar: {
    position: "absolute",
    top: -25,
    left: "50%",
    transform: "translateX(-50%)"
  },
  kpiLabel: {
    fontWeight: 800,
    fontSize: "0.8rem",
  },
  kpiValue: {
    fontSize: "0.8rem",
    fontWeight: 600,
    width: "100%",
  },
  customAnalyticsTableLabel: {
    color: theme.custom.color.chartColor2,
    fontSize: "0.8rem",
    fontWeight: 800,
  },
  textTruncated: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap"
  },
  analyticsButtonLabel: {
    cursor: "default",
  }
}));
