import React, { useCallback } from "react";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import { SearchFilter } from "../../../components/Filter";
import Box from "@material-ui/core/Box";
import debounce from "lodash/debounce";
import { useDispatch, useSelector } from "react-redux";
import { initFilter, updateFilter } from "../../../redux/slices/processSlice";
import { useTranslation } from "react-i18next";
import CustomAutoComplete from "../../../components/FormFields/CustomAutoComplete";
import NoDataMenu from "../../../components/NoData/NoDataMenu";
import ClearFilter from "../../../components/ClearFilter";
import ShowMoreFilters from "../../../components/ShowMoreFilters";
import CustomSelectField from "../../../components/FormFields/CustomSelectField";
import useStyles from "../style";

const sortColumns = [
  {
    id: "moduleName",
    label: "audit.module.ascending",
    order: "asc",
  },
  {
    id: "moduleName",
    label: "audit.module.descending",
    order: "desc",
  },
  {
    id: "userName",
    label: "audit.user.ascending",
    order: "asc",
  },
  {
    id: "userName",
    label: "audit.user.descending",
    order: "desc",
  },
];

export default function AuditFilter() {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const auditFilters = useSelector(({ auditFilters }) => auditFilters);
  const [searchText, setsearchText] = React.useState("");
  const [showAllFilters, setshowAllFilters] = React.useState(false);

  const searchProcesses = (keyWord) => {
    dispatch(
      updateFilter({
        ...auditFilters,
        pageNo: 0,
        search: keyWord,
      })
    );
  };
  const debouncer = useCallback(
    debounce((nextValue) => searchProcesses(nextValue), 500),
    [auditFilters]
  );
  const handleSearchChange = (event) => {
    setsearchText(event.target.value);
    debouncer(event.target.value);
  };
  const handleChangeFilter = (event, key) => {
    dispatch(
      updateFilter({
        ...auditFilters,
        [key]: event?.map(({ id }) => id),
      })
    );
  };
  const handleClear = () => {
    dispatch(initFilter());
    setshowAllFilters("");
  };
  return (
    <Grid
      container
      item
      xs={12}
      className={classes.filter}
      justify="flex-start"
    >
      <Box
        component={Grid}
        item
        container
        xs={2}
        alignItems="center"
        pt={showAllFilters ? "16px" : "3px"}
      >
        <FormControl fullWidth>
          <SearchFilter
            callback={handleSearchChange}
            fullWidth
            placeHolder={t("audit.search.logs")}
            value={searchText}
            queryParams={auditFilters.search}
          />
        </FormControl>
      </Box>
      <Grid item xs={2}>
        <CustomAutoComplete
          multiple
          options={[]}
          optionLabel="name"
          value={[]}
          noOptionsNode={<NoDataMenu message={t("no.object.message")} />}
          onChange={(e) => handleChangeFilter(e, "objects")}
          label={t("audit.object")}
        />
      </Grid>
      <Grid item xs={2}>
        <CustomAutoComplete
          multiple
          options={[]}
          optionLabel="name"
          value={[]}
          noOptionsNode={<NoDataMenu message={t("no.user.message")} />}
          onChange={(e) => handleChangeFilter(e, "users")}
          label={t("audit.user")}
        />
      </Grid>
      <Grid item xs={2}>
        <CustomAutoComplete
          multiple
          options={[]}
          optionLabel="name"
          value={[]}
          noOptionsNode={<NoDataMenu message={t("no.action.message")} />}
          onChange={(e) => handleChangeFilter(e, "actions")}
          label={t("audit.action")}
        />
      </Grid>
      {showAllFilters && (
        <>
          <Grid item xs={2}>
            <CustomAutoComplete
              multiple
              options={[]}
              optionLabel="name"
              value={[]}
              noOptionsNode={
                <NoDataMenu message={t("no.time.filter.message")} />
              }
              onChange={(e) => handleChangeFilter(e, "timeFilter")}
              label={t("audit.time.filter")}
            />
          </Grid>
          <Grid item xs={2}>
            <CustomSelectField
              options={sortColumns}
              optionLabel="label"
              value={null}
              onChange={(e) => handleChangeFilter(e, "sortBy")}
              variant="standard"
              label={t("audit.sort.by")}
              isCustom
            />
          </Grid>
        </>
      )}
      <Grid
        item
        container
        xs={2}
        className={classes.toggleMenu}
        direction="row"
      >
        <Grid item>
          <ShowMoreFilters
            handleShowMore={() => setshowAllFilters((prevState) => !prevState)}
            showAll={!showAllFilters}
          />
        </Grid>
        <Grid item>
          <ClearFilter clearFilter={handleClear} label={t("reporting.clear")} />
        </Grid>
      </Grid>
    </Grid>
  );
}
