import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  inputBox: {
    border: `1px solid ${theme.custom.color.containersBorder}`,
    padding: "0.75rem 1.5rem",
    margin: "0.75rem 1rem",
  },
  flagLabel: {
    fontWeight: "bold"
  },
  input: {
    fontSize: 14,
    fontWeight: "400",
    "& .MuiInput-underline:before": {
      borderBottomColor: theme.custom.color.chartColor1,
      borderWidth: 3,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: theme.custom.color.chartColor2,
      borderWidth: 3,
    },
  },
  popupIcon: {
    color: theme.custom.color.chartColor2
  },
  dateTimePicker: {
    "& .MuiPickersModal-dialogRootWider": {
      minWidth: "333px",
    },
  },
}));
