import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import useStyles from "../style.js";
import { useTranslation } from "react-i18next";
import { FormProvider, useForm } from "react-hook-form";
import DivisionInfo from "./DivisionInfo";
import DivisionProcess from "./DivisionProcess";
import DivisionUser from "./DivisionUser";
import { useHistory, useParams } from "react-router-dom";
import { Tooltip } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import {
  addDivision,
  fetchDivisionById,
  updateDivision,
} from "../../../../redux/actions/services";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import IconButton from "@material-ui/core/IconButton";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogContent from "@material-ui/core/DialogContent";
import { get, isUndefined } from "lodash";
import ConfirmMessage from "components/ConfirmMessage/index.js";
import CustomCloseButton from "pages/Services/components/CustomCloseButton/index.js";
import CustomButton from "../../../../components/CustomButton";

const steps = [
  "division.formSection.divisionInfo",
  "division.formSection.divisionProcess",
  "division.formSection.divisionUser",
  "division.formSection.divisionAdmin",
];

const DIVISION_MODULE_PATH = "/userAdministration/divisions";

export default function DivisionModuleForm({ isEdit = false }) {
  const classes = useStyles();
  const currentUser = useSelector(({ requests }) => get(requests, "queries.FETCH_CURRENT_USER.data"));
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { idDivision, id } = useParams();
  const isViewMode = !isUndefined(id);
  const form = useForm();
  const [activeStep, setActiveStep] = React.useState(0);

  const [openMsgCancel, setOpenMessageCancel] = useState(false);

  const handleStepClick = async (step) => {
    if (activeStep !== step) {
      const isResultValid = await form.trigger();
      if (isResultValid) setActiveStep(step);
    }
  };

  const handleRejectCancelForm = () => setOpenMessageCancel(false);

  const handleAcceptCancelForm = () => {
    setOpenMessageCancel(false);
    history.push({
      pathname: DIVISION_MODULE_PATH,
    });
  }

  const getFormField = (target) => form.watch(target);

  const setFormField = (target, value) => form.setValue(target, value);

  const onSuccess = () => {
    history.push({
      pathname: DIVISION_MODULE_PATH,
    });
    toast.success(
      t(isEdit ? "division.form.updateSuccess" : "division.form.addSuccess")
    );
  };

  const onError = (res) => {
    toast.error(t(res.response?.data?.message ?? t("Unexpected Error")));
  };

  const handleNextClick = (data) => {
    const dataToSend = {
      ...data,
      processes: data?.processes?.map(({ id }) => id),
      users: data?.users?.map(({ id }) => id),
      admins: data?.admins?.map(({ id }) => id),
    };
    if (activeStep === steps.length - 1) {
      isEdit
        ? dispatch(
            updateDivision(idDivision, dataToSend, onSuccess, (res) => onError(res))
          )
        : dispatch(addDivision(dataToSend, onSuccess, (res) => onError(res)));
    } else setActiveStep((prevState) => prevState + 1);
  };
  const handlePreviousClick = () => {
    if (activeStep === 0) setOpenMessageCancel(true);
    else setActiveStep((prevState) => prevState - 1);
  };

  const handleEditData = () => {
    dispatch(fetchDivisionById(isViewMode ? id : idDivision)).then((res) => {
      const { data } = res;
      form.setValue("fleetId", data?.fleet?.fleetId);
      form.setValue("divisionName", data?.divisionName);
      form.setValue("description", data?.description);
      form.setValue("processes", data?.processes);
      form.setValue("users", data?.users);
      form.setValue("admins", data?.admins);
    });
  };

  useEffect(() => {
    if (isEdit || isViewMode) handleEditData();
    else form.setValue("fleetId", currentUser?.fleet?.id);
  }, [isEdit, isViewMode]);

  const stepContent = [
    {
      key: "divisionInfo",
      component: (
        <DivisionInfo isViewMode={isViewMode} getFormField={getFormField} isEdit={isEdit} />
      ),
    },
    {
      key: "divisionProcess",
      component: (
        <DivisionProcess
          isViewMode={isViewMode}
          selectedFleetId={getFormField("fleetId")}
          isEdit={isEdit || isViewMode}
          assignedProcessesData={getFormField("processes")}
          setProcessesField={setFormField}
        />
      ),
    },
    {
      key: "divisionUser",
      component: (
        <DivisionUser
          selectorName="users"
          key="divisionUser"
          isViewMode={isViewMode}
          selectedFleetId={getFormField("fleetId")}
          isEdit={isEdit || isViewMode}
          assignedUsersData={getFormField("users")}
          setUsersField={setFormField}
          availableLabel="usersSelector.availableUsers"
          assignedLabel="usersSelector.assignedUsers"
        />
      ),
    },
    {
      key: "divisionAdmin",
      component: (
        <DivisionUser
          selectorName="admins"
          key="divisionAdmin"
          availableAdmins={getFormField("users")}
          isViewMode={isViewMode}
          isEdit={isEdit || isViewMode}
          assignedUsersData={getFormField("users")}
          setUsersField={setFormField}
          availableLabel="usersSelector.availableAdmins"
          assignedLabel="usersSelector.assignedAdmins"
        />
      ),
    },
  ];
  return (
    <div>
      <FormProvider {...form}>
        <Grid
            container
            direction="row"
            xs={12}
            justify="flex-end"
            className={classes.closebutton}
          >
          <CustomCloseButton
          aria-label="close"
          className={classes.closeButton}
          onClick={() => history.push({
            pathname: DIVISION_MODULE_PATH,
          })}
          />
        </Grid>
        <DialogContent>
          <DialogContentText id="scroll-dialog-description" component="div">
            <Grid
                container
                component="form"
                onSubmit={form.handleSubmit((newData) => {
                  handleNextClick(newData);
                })}
              >
              <Grid item xs={12}>
                <Stepper
                    activeStep={activeStep}
                    alternativeLabel
                    className={classes.stepper}
                  >
                  {steps.map((label, index) => (
                    <Step key={label}>
                      <StepLabel
                          style={{ cursor: "pointer" }}
                          onClick={() => handleStepClick(index)}
                        >
                        {t(label)}
                      </StepLabel>
                    </Step>
                    ))}
                </Stepper>
              </Grid>
              <Grid
                container
                justify="flex-end"
                className={classes.editContainer}
              >
                <Tooltip title={t("Edit")}>
                  { !isEdit ? (
                    <IconButton
                      aria-label="modify"
                      onClick={() => (
                        history.push(
                          `/userAdministration/divisions/edit/${id}`,
                        )
                      )}
                    >
                      <EditIcon />
                    </IconButton>
                  )
                  : <IconButton
                    aria-label="modify"
                    color="primary"
                  >
                    <EditIcon />
                    {/* eslint-disable-next-line react/jsx-indent */}
                    </IconButton>}
                </Tooltip>
              </Grid>
              <Grid
                  item
                  xs={12}
                  container
                  justify="center"
                  className={classes.formContainer}
                >
                {stepContent[activeStep]?.component}
              </Grid>

              <Grid
                  item
                  xs={12}
                  justify="flex-end"
                  spacing={2}
                  className={classes.formActions}
                >
                <CustomButton
                    view="cancel"
                    size="medium"
                    onClick={handlePreviousClick}
                  >
                  {activeStep <= 0
                      ? t("division.cancel")
                      : t("division.previous")}
                </CustomButton>
                <CustomButton
                    view="primary"
                    size="medium"
                    type="submit"
                    disabled={isViewMode && activeStep === steps.length - 1}
                  >
                  {activeStep === steps.length - 1
                      ? t("division.save")
                      : t("division.next")}
                </CustomButton>
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
      </FormProvider>
      {openMsgCancel && (
        <ConfirmMessage
          message={t("user.delete.discard")}
          openStart={openMsgCancel}
          onCancel={handleRejectCancelForm}
          onConfirm={handleAcceptCancelForm}
          buttonCancel={t("Cancel")}
          buttonConfirm={t("Discard")}
          isLoading={false}
        />
      )}
    </div>
  );
}
