import React from "react";
import Box from "@material-ui/core/Box";
import { useHistory } from "react-router-dom";
import clsx from "clsx";
import CustomDialog from "pages/Services/components/CustomDialog";
import TagTable from "./TagTable";
import PageHeader from "../../../components/PageHeader";
import useStyles from "../../../components/TableComponents/style";

function TagManagement(props) {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Box className={classes.root}>
      <Box className={classes.paper}>
        <PageHeader title="tag.management.title" />
        <TagTable />
        {props.showEditModal && (
          <CustomDialog
            open={props.showEditModal}
            aria-labelledby="tag-dialog-title"
            aria-describedby="tag-dialog-description"
            onBackdropClick={() => {
              history.push("/robotAdministration/tags");
            }}
          className={clsx(classes.dialog, classes.dialogContainer)}
              >
            {props.modalComponent}
          </CustomDialog>
        )}
      </Box>
    </Box>
  );
}

export default TagManagement;
