import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(() => ({
    title: {
        fontWeight: 800
    },
    text: {
        fontWeight: 700,
        fontSize: "0.8rem",
        wordBreak: "break-word",
        "& .MuiTypography-root": {
            fontWeight: 700,
            fontSize: "0.8rem",
            wordBreak: "break-word",
            fontFamily: "inherit !important"
        }
    }
}));
