import
{
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import TextField from "@material-ui/core/TextField";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Controller, useWatch } from "react-hook-form";
import { isSRQueuesDisabled } from "../../../../../../util/constants/PermitedFeature";
import { fetchRobotList } from "../../../../../../redux/actions/services";
import { FETCH_CURRENT_USER } from "../../../../../../redux/constants";
import NoDataMenu from "../../../../../../components/NoData/NoDataMenu";
import { DISCONNECTED_ROBOT_WHEN } from "../../../../../../util/constants/alerts";
import { isEmpty } from "lodash";
import { useQuery } from "@redux-requests/react";

export default function DefaultTriggerRow({
  classes,
  errors,
  disableFields,
  menuItems,
  control,
  processMenuItem,
  register
}) {
    const dispatch = useDispatch();
  const { t } = useTranslation();
  const watch = useWatch({ control });
  const [robots, setRobots] = useState([]);
  const currentUser = useQuery({ type: FETCH_CURRENT_USER })?.data;
  const ofArr = ["A license is one month away from expiry",
      ...!isSRQueuesDisabled ? ["execution is deleted", "execution is missed", "deleted by sr policy"] : [],
      "A license is two months away from expiry"]
  useEffect(() => {
    if (watch.when === DISCONNECTED_ROBOT_WHEN)
    {
        dispatch(fetchRobotList({ fleets: [{ id: currentUser?.fleet?.id }] })).then((res) => {
            setRobots(res.data.content)
        })
    }
  }, [watch.when])
  return (
    <>
      <Grid item xs={1} className={classes.triggerGridIcon}>
        <span className={classes.triggerIcon}>
          <NotificationsActiveIcon />
        </span>
      </Grid>
      <Grid item xs={5}>
        <Controller
          name="when"
          control={control}
          rules={{
            required: {
              value: true,
              message: t("alerts.management.required.fields"),
            },
          }}
          defaultValue=""
          render={({ field }) => (
            <FormControl fullWidth size="small">
              <InputLabel id="when" error={errors?.when}>
                {t("alerts.management.when.placeholder")}
              </InputLabel>
              <Select
                {...field}
                id="when"
                className={classes.select}
                error={errors?.when}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
                disabled={disableFields}
              >
                {menuItems.whenMenuItem.map((item, index) => (
                  <MenuItem key={index} value={item}>
                    <Tooltip title={t(item)}>
                      <span>
                        {" "}
                        {t(item)}
                        {" "}
                      </span>
                    </Tooltip>
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText className={classes.validation_error}>
                {errors?.when?.message}
              </FormHelperText>
            </FormControl>
          )}
        />
      </Grid>
      {ofArr.includes(watch.when) && (
        <Grid item xs={5}>
          <Controller
            name="processId"
            control={control}
            rules={{
              required: {
                value: true,
                message: t("alerts.management.required.fields"),
              },
            }}
            defaultValue=""
            render={({ field }) => (
              <FormControl fullWidth size="small" error={errors?.processId}>
                <InputLabel id="processId">
                  {t("alerts.management.of.placeholder")}
                </InputLabel>
                <Select
                  {...field}
                  id="processId"
                  className={classes.select}
                  error={errors?.processId}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                  }}
                  disabled={disableFields}
                >
                  {isEmpty(processMenuItem) ? (
                    <NoDataMenu message={t("no.process.message")} marginX={8} />
                  ) : processMenuItem.map((item, index) => (
                    <MenuItem key={index} value={item?.id}>
                      <Tooltip
                        title={item?.processDescription?.processDisplayName}
                      >
                        <span>
                          {item?.processDescription?.processDisplayName}
                        </span>
                      </Tooltip>
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText className={classes.validation_error}>
                  {errors?.processId?.message}
                </FormHelperText>
              </FormControl>
            )}
          />
        </Grid>
      )}

      {watch.when === DISCONNECTED_ROBOT_WHEN && (
      <>
        <Grid item xs={5}>
          <Controller
            name="robotsIds"
            control={control}
            rules={{
              required: {
                value: true,
                message: t("alerts.management.required.fields"),
              },
            }}
            defaultValue={[]}
            render={({ field }) => (
              <FormControl fullWidth size="small" error={errors?.robotsIds}>
                <InputLabel id="robotsIds">
                  {`${t("robot.management.menu.title")} *`}
                </InputLabel>
                <Select
                  {...field}
                  id="robotsIds"
                  className={classes.select}
                  error={errors?.robotsIds}
                  defaultValue={[]}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                  }}
                  disabled={disableFields}
                  multiple
                  renderValue={(opt) => robots?.filter((robot) => opt.includes(robot.id)).map(({ robotDisplayName }) => robotDisplayName).join(", ")}
                >
                  {robots?.map((item, index) => (
                    <MenuItem key={index} value={item.id}>
                      <Tooltip
                        title={item?.robotDisplayName}
                      >
                        <span>
                          {item?.robotDisplayName}
                        </span>
                      </Tooltip>
                    </MenuItem>
                  ))}
                  {
                        robots?.length === 0
                        && <NoDataMenu message={t("no.robot.message")} />
                    }
                </Select>
                <FormHelperText className={classes.validation_error}>
                  {errors?.robotsIds?.message}
                </FormHelperText>
              </FormControl>
            )}
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={1} />
        <Grid item xs={5}>
          <TextField
              fullWidth
              name="disconnectionPeriod"
              label={t("disconnection.period")}
              placeholder={t("disconnection.period")}
              disableFields={disableFields}
              error={errors?.disconnectionPeriod}
              classes={classes}
              {...register(
                   "disconnectionPeriod",
                  {
                  required: {
                  value: true,
                  message: t("alerts.management.required.fields"),
                    },
                  }
              )}
              type="number"
          />
          <FormHelperText className={classes.validation_error}>
            {errors?.disconnectionPeriod?.message}
          </FormHelperText>
        </Grid>
      </>
      )}

    </>
  );
}
