import React from "react";
import CustomDialog from "../CustomDialog";

function ConfirmMessage({
  title,
  message,
  openStart,
  onConfirm,
  onCancel,
  buttonConfirm,
  buttonCancel,
  isLoading,
}) {
  return (
    <CustomDialog
      title={title}
      contentText={message}
      open={openStart}
      actionElements={[
          {
        label: buttonCancel,
        onClick: onCancel,
        color: "primary",
        disabled: isLoading,
      },
        {
        label: buttonConfirm,
        onClick: onConfirm,
        view: "primary",
        disabled: isLoading,
        isLoading
      }
      ]}
      aria-labelledby="confirm_message-dialog-title"
      maxWidth="200px"
    />
  );
}

export default ConfirmMessage;
