import React from "react";
import { Avatar, Grid, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import useStyles from "../style";
import { formatImagePath, getInitiales } from "../../../../../util";

function RobotsCard({ robots, t }) {
  const classes = useStyles();

  return robots?.length > 0 ? (
    robots.map((robot) => (
      <Grid
        container
        directiom="row"
        alignItems="center"
        style={{ padding: "0 30px 0 30px", margin: "unset" }}
        spacing="2"
      >
        <Grid item xs={3}>
          {robot?.icon ? (
            <img
              src={formatImagePath(robot.icon)}
              alt="Service"
              className={classes.image}
            />
          ) : (
            <Avatar
              className={`${classes.avatar} ${classes.avatarColor}`}
              alt="Robot icon"
            >
              {getInitiales(robot?.robotDisplayName)}
            </Avatar>
          )}
        </Grid>
        <Grid item xs={9} style={{ paddingRight: "40px" }}>
          <Typography variant="subtitle1" className={classes.content}>
            {robot?.robotDisplayName}
          </Typography>
        </Grid>
      </Grid>
    ))
  ) : (
    <Alert severity="warning" className={classes.alert}>
      <Typography variant="subtitle1">
        {t("administration.orchestrator.robot.list.not.available")}
      </Typography>
    </Alert>
  );
}

export default RobotsCard;
