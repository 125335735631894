import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  searchIcon: {
    padding: "0",
    color: theme.custom.color.chartColor2
  },
  actionButtons: {
    margin: "auto",
    marginRight: 0,
    maxHeight: 37,
  },
}));
