import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import FormControl from "@material-ui/core/FormControl"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Grid from "@material-ui/core/Grid"
import NativeSelect from "@material-ui/core/NativeSelect"
import Radio from "@material-ui/core/Radio"
import RadioGroup from "@material-ui/core/RadioGroup"
import Tab from "@material-ui/core/Tab"
import Tabs from "@material-ui/core/Tabs"
import Typography from "@material-ui/core/Typography"
import TextField from "@material-ui/core/TextField"
import CustomDialog from "pages/Services/components/CustomDialog";
import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import range from "lodash/range";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { CalendarToday } from "@material-ui/icons";
import DateFnsUtils from "@date-io/date-fns";
import MuiDialogActions from "@material-ui/core/DialogActions";
import SaveIcon from "@material-ui/icons/Save";
import RefreshIcon from "@material-ui/icons/Refresh";
import CloseIcon from "@material-ui/icons/Close";
import ConfirmMessage from "../../../../../../components/ConfirmMessage";
import { formatDatePickerByLanguage, getLocale } from "../../../../../../util";
import QuestionMarkIcon from "../../../../../../components/QuestionMarkIcon";
// eslint-disable-next-line import/no-cycle
import { USE_CUMULATIVE, USE_INTERVALS } from "../..";
import CustomButton from "../../../../../../components/CustomButton";
import { makeDay } from "./utils";

function TabPanel(props) {
  const {
    children, value, index, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3} style={{ alignItems: "left", justifyContent: "left" }}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export default function ScheduleForm({
  classes,
  value,
  handleChangeTab,
  open,
  handleClose,
  register,
  handleChangeEndDate,
  handleChangeStartDate,
  endDate,
  startDate,
  disableFields,
  handleCloseDialog,
  handleChangeFrequency,
  discardSchedulerChanges,
  frequency,
  confirmSaveScheduler,
  submittedScheduler,
  useInterval,
  handleChangeUseInterval,
  disableSchedulerInfo,
  errors,
}) {
  const { t } = useTranslation();
  const [openMsgConfirm, setOpenMsgConfirm] = React.useState(false);
  const [openMsgDiscard, setOpenMsgDiscard] = React.useState(false);
  const [startDateError, setStartDateError] = React.useState();

  const showMsgDiscard = () => {
    setOpenMsgDiscard(true);
  };

  const confirmDiscard = () => {
    discardSchedulerChanges();
    setOpenMsgDiscard(false);
    handleCloseDialog();
  };

  const cancelConfirm = () => {
    setOpenMsgConfirm(false);
  };
  const cancelDiscard = () => {
    setOpenMsgDiscard(false);
  };

  const confirmSave = () => {
    if (startDate) {
        confirmSaveScheduler();
        setOpenMsgConfirm(false);
        if (
          !errors.alertScheduler?.fromSelectedDate
          && !errors.alertScheduler?.toSelectedDate
        ) {
          handleCloseDialog();
        }
        }
    else setStartDateError("alerts.management.required.fields")
  };

  const handleSchedulerSubmit = () => {
    if (startDate) {
      setOpenMsgConfirm(true);
    } else setStartDateError("alerts.management.required.fields")
  }

  return (
    <CustomDialog
      onClose={handleClose}
      fullWidth
      open={open}
      disableBackdropClick
      maxWidth="md"
    >
      <Grid className={classes.schedulerRoot}>
        <Grid container item xs={12} spacing={2}>
          <Grid container item xs={12}>
            <Grid item xs={4} align="center">
              <FormControl
                component="fieldset"
                disabled={disableFields || disableSchedulerInfo}
              >
                <RadioGroup
                  column
                  aria-label="position"
                  name="position"
                  {...register("alertScheduler.useInterval")}
                  onChange={handleChangeUseInterval}
                  value={useInterval}
                  disabled={disableFields || disableSchedulerInfo}
                >
                  <FormControlLabel
                    checked={useInterval === USE_INTERVALS}
                    value={USE_INTERVALS}
                    control={<Radio />}
                    label={(
                      <span className={classes.radioSchedulerTitles}>
                        {t("use intervals")}
                        <QuestionMarkIcon titleContent={t("The calculation of the KPIs will be based on an interval")} />
                      </span>
                    )}
                  />
                  <FormControlLabel
                    checked={useInterval === USE_CUMULATIVE}
                    value={USE_CUMULATIVE}
                    control={<Radio />}
                    label={(
                      <span className={classes.radioSchedulerTitles}>
                        {t("use cumulative")}
                        {" "}
                        <QuestionMarkIcon titleContent={t("Calculation of the KPIs for the whole time")} />
                      </span>
                    )}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container item xs={12}>
            <Grid item xs={3} align="center">
              <Box mt={2.6}>
                <Typography
                  variant="subtitle1"
                  className={classes.schedulerTitles}
                >
                  {t("Start Date")}
                  <QuestionMarkIcon titleContent={t("Receive alerts from the following date")} />
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={9} mb={1.5}>
              <MuiPickersUtilsProvider
                utils={DateFnsUtils}
                locale={getLocale()}
              >
                <FormControl margin="normal" fullWidth>
                  <DatePicker
                    InputProps={{
                      endAdornment: <CalendarToday />,
                      className: classes.input,
                    }}
                    renderInput={(params) => (
                      <TextField className={classes.input} {...params} />
                    )}
                    {...register("alertScheduler.startDate")}
                    clearable
                    onChange={handleChangeStartDate}
                    value={startDate || null}
                    disablePast={false}
                    format={formatDatePickerByLanguage(false)}
                    placeholder={t("Start Date")}
                    disabled={disableFields || disableSchedulerInfo}
                    helperText={!startDate && t(startDateError)}
                    error={!startDate && startDateError}
                    minDate={new Date().getTime() + 24 * 3600 * 1000}
                    maxDate={endDate}
                  />
                </FormControl>
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid item xs={3} align="center">
              <Box mt={2} mb={1.5}>
                <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
                  {t("Alert Frequency")}
                </Typography>
              </Box>
            </Grid>
            <Grid container>
              <Tabs
                item
                xs={3}
                orientation="vertical"
                variant="scrollable"
                value={value}
                onChange={handleChangeTab}
                className={classes.tabs}
              >
                <Tab
                  label={t("Hourly")}
                  value={0}
                  disabled={disableFields || disableSchedulerInfo}
                />
                <Tab
                  label={t("Daily")}
                  value={1}
                  disabled={disableFields || disableSchedulerInfo}
                />
                <Tab
                  label={t("Weekly")}
                  value={2}
                  disabled={disableFields || disableSchedulerInfo}
                />
                <Tab
                  label={t("Monthly")}
                  value={3}
                  disabled={disableFields || disableSchedulerInfo}
                />
              </Tabs>
              <TabPanel item xs={8} value={value} index={0}>
                <Grid container className={classes.hourly}>
                  <Box mt={2.5} mr={1}>
                    <Typography variant="subtitle1">{t("Every")}</Typography>
                  </Box>
                  <FormControl item xs={6} className={classes.formControl}>
                    <NativeSelect
                      className={classes.selectEmpty}
                      inputProps={{ "aria-label": "age" }}
                      {...register("alertScheduler.period", {})}
                      onChange={handleChangeFrequency}
                      value={frequency}
                      disabled={disableFields || disableSchedulerInfo}
                    >
                      <option value="" disabled>
                        {t("Choose frequency")}
                      </option>
                      {hours
                        && hours.map((row, index) => (
                          <option key={index} value={row}>
                            {row}
                          </option>
                        ))}
                    </NativeSelect>
                  </FormControl>

                  <Box mt={2.5}>
                    <Typography variant="subtitle2">{t("Hour(s)")}</Typography>
                  </Box>
                </Grid>
              </TabPanel>
              <TabPanel item xs={9} value={value} index={1}>
                <Grid container className={classes.daily}>
                  <Box mt={2.5} mr={1}>
                    <Typography variant="subtitle2">{t("Every")}</Typography>
                  </Box>
                  <FormControl item xs={6} className={classes.formControl}>
                    <NativeSelect
                      className={classes.selectEmpty}
                      inputProps={{ "aria-label": "age" }}
                      {...register("alertScheduler.period", {})}
                      onChange={handleChangeFrequency}
                      value={frequency}
                      disabled={disableFields || disableSchedulerInfo}
                    >
                      <option value="" disabled>
                        {t("Choose frequency")}
                      </option>
                      {days
                        && days.map((row, index) => (
                          <option key={index} value={row}>
                            {row}
                          </option>
                        ))}
                    </NativeSelect>
                  </FormControl>
                  <Box mt={2.5} ml={1}>
                    <Typography variant="subtitle2">
                      {" "}
                      {t("Day(s)")}
                    </Typography>
                  </Box>
                </Grid>
              </TabPanel>
              <TabPanel item xs={9} value={value} index={2}>
                <Grid container className={classes.weekly}>
                  <Box mt={2.5} mr={1}>
                    <Typography variant="subtitle2">{t("Every")}</Typography>
                  </Box>
                  <FormControl item xs={6} className={classes.formControl}>
                    <NativeSelect
                      className={classes.selectEmpty}
                      inputProps={{ "aria-label": "age" }}
                      {...register("alertScheduler.period", {})}
                      onChange={handleChangeFrequency}
                      value={frequency}
                      disabled={disableFields || disableSchedulerInfo}
                    >
                      <option value="" disabled>
                        {t("Choose frequency")}
                      </option>
                      {weeks.map((row, index) => (
                        <option key={index} value={row}>
                          {row}
                        </option>
                      ))}
                    </NativeSelect>
                  </FormControl>
                  <Box item xs={3} mt={2.5} ml={1}>
                    <Typography variant="subtitle2">
                      {" "}
                      {t("Week(s)") + makeDay(startDate, t, true)}
                    </Typography>
                  </Box>
                </Grid>
              </TabPanel>
              <TabPanel item xs={9} value={value} index={3}>
                <Grid container className={classes.monthly}>
                  <Box mt={2.5} mr={1}>
                    <Typography variant="subtitle2">{t("Every")}</Typography>
                  </Box>
                  <FormControl item xs={6} className={classes.formControl}>
                    <NativeSelect
                      className={classes.selectEmpty}
                      defaultValue="1"
                      {...register("alertScheduler.period", {})}
                      onChange={handleChangeFrequency}
                      value={frequency}
                    >
                      <option value="" disabled>
                        {t("Choose frequency")}
                      </option>
                      {months
                        && months.map((row, index) => (
                          <option key={index} value={row}>
                            {row}
                            {" "}
                          </option>
                        ))}
                    </NativeSelect>
                  </FormControl>
                  <Box mt={2.5} ml={1}>
                    <Typography variant="subtitle2">{t("Month(s)") + makeDay(startDate, t)}</Typography>
                    {" "}
                  </Box>
                </Grid>
              </TabPanel>
            </Grid>
          </Grid>
          <Grid container item xs={12}>
            <Grid item xs={3} align="center">
              <Box mt={2.6}>
                <Typography
                  variant="subtitle1"
                  className={classes.schedulerTitles}
                >
                  {t("End Date")}
                  <QuestionMarkIcon titleContent={t("Stop receiving alerts from the following date")} />
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={9} mb={1.5}>
              <MuiPickersUtilsProvider
                utils={DateFnsUtils}
                locale={getLocale()}
              >
                <FormControl margin="normal" fullWidth>
                  <DatePicker
                    InputProps={{
                      endAdornment: <CalendarToday />,
                      className: classes.input,
                    }}
                    renderInput={(params) => (
                      <TextField className={classes.input} {...params} />
                    )}
                    {...register("alertScheduler.endDate")}
                    clearable
                    onChange={handleChangeEndDate}
                    value={endDate || null}
                    disablePast={false}
                    format={formatDatePickerByLanguage(false)}
                    placeholder={t("End Date")}
                    disabled={disableFields || disableSchedulerInfo}
                    minDate={startDate}
                  />
                </FormControl>
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
        </Grid>
        <MuiDialogActions className={classes.cardActions}>
          {!disableFields && !disableSchedulerInfo ? (
            <>
              <Box
                ml="1rem"
                component={Button}
                variant="contained"
                size="medium"
                onClick={showMsgDiscard}
                className={classes.resetButton}
                startIcon={<RefreshIcon />}
              >
                {t("Discard")}
              </Box>
              <CustomButton
                view="primary"
                size="medium"
                onClick={submittedScheduler.frequency ? handleSchedulerSubmit : confirmSave}
                startIcon={<SaveIcon />}
              >
                {submittedScheduler.frequency
                  ? t("Update frequency")
                  : t("Confirm frequency")}
              </CustomButton>
            </>
          ) : (
            <CustomButton
              view="primary"
              size="medium"
              onClick={() => {
                handleCloseDialog();
              }}
              startIcon={<CloseIcon />}
            >
              {t("alerts.management.button.exit")}
            </CustomButton>
          )}
        </MuiDialogActions>
      </Grid>
      {openMsgConfirm && (
        <ConfirmMessage
          message={t("Are you sure you want to save the changes?")}
          openStart={openMsgConfirm}
          onCancel={cancelConfirm}
          onConfirm={confirmSave}
          buttonConfirm={t("Save changes")}
          buttonCancel={t("Cancel")}
        />
      )}
      {openMsgDiscard && (
        <ConfirmMessage
          message={t("Are you sure you want to disregard the changes ?")}
          openStart={openMsgDiscard}
          onCancel={cancelDiscard}
          onConfirm={confirmDiscard}
          buttonConfirm={t("Disregard changes")}
          buttonCancel={t("Cancel")}
        />
      )}
    </CustomDialog>
  );
}

const hours = range(0.5, 24, 0.5);
const days = range(1, 31);
const weeks = range(1, 52);
const months = range(1, 12);
