import React, { useEffect, useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import { useTranslation } from "react-i18next";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { useSelector, useDispatch } from "react-redux";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import {
  Checkbox,
  CircularProgress,
  Badge,
  Tooltip,
} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { get, debounce, isEmpty } from "lodash";
import { subDays, isAfter } from "date-fns";
import { format } from "date-fns-tz";
import { useHistory, useLocation } from "react-router-dom";
import ReplayIcon from "@material-ui/icons/Replay";
import { SrWorkflowIcon } from "components/CustomIcons";
import {
  exceptionCurrentModule,
  formatDatePickerByLanguage,
  handleDisplayedLabel,
  isFleetAdministrator,
  STATUS,
  selectDurationValue,
} from "../../../util";
import { HasPermission } from "../../../components/HasPermission";

import { SearchFilter, useQuery } from "../../../components/Filter";
import { initFilter } from "../../../redux/slices/exceptionFilter";

import ClearFilter from "../../../components/ClearFilter";
import NoDataMenu from "../../../components/NoData/NoDataMenu";
import i18n from "../../../i18n";
import DateFilter from "../../Services/components/DateFilter";
import {
  fetchAllFleets, fetchDivisionsForFilters, fetchTagsForFilters,
} from "../../../redux/actions/services";
import CustomSelectField from "../../../components/FormFields/CustomSelectField";
import CustomAutoComplete from "../../../components/FormFields/CustomAutoComplete";
import ShowMoreFilters from "../../../components/ShowMoreFilters";
import CustomButton from "../../../components/CustomButton";
import { ReactComponent as GraphIcon } from "../../../assets/common/graph.svg";
import CustomSwitch from "../../Services/components/CustomSwitch";
import ExportButton from "../../../components/ExportButton";
import { Duration, EXPORT_LIMIT } from "../../../util/constants";
import useStyles from "../style";
import { isPartitioningDisabled } from "../../../util/constants/PermitedFeature";

const dateFormat = "yyyy/MM/dd HH:mm";
const TagFilter = ({
 tags, filterExceptionList, currModule, handleChangeTagsFilter, classes
}) => {
  const { t } = useTranslation();
  return (
    <Grid item xs={2}>
      <FormControl fullWidth className={classes.autoComplete}>
        <CustomAutoComplete
              multiple
              options={tags || []}
              optionLabel="name"
              value={tags?.filter(({ id }) => filterExceptionList[currModule].tags.includes(id))}
              noOptionsNode={<NoDataMenu message={t("no.tags.message")} />}
              onChange={handleChangeTagsFilter}
              label={t("Tags")}
          />
      </FormControl>
    </Grid>
  )
}
export default function Filter(props) {
  const {
    processes = [],
    exceptionsType,
    exceptions,
    onSelectAllClick,
    numSelected,
    rowCount,
    onDelete,
    onShow,
    processName,
    exportExceptionXLSX,
    deletePermission,
    setExportLoading,
    exportLoading,
    analyticsLoading,
    setAnalyticsLoading,
    setShowAnalyticsContent,
    showAnalyticsContent,
    sortColumns,
    handleChangeShowHidden,
    showHidden,
    exceptionsToShow,
    exceptionsToHide,
    checkedAll,
    setRetryItems,
    isItemList,
    indeterminateCheck,
    openWorkflowBulkDrawer,
    currModule,
    exceptionWorkFlowStatus = [],
    assigners,
    setOriginalItemId,
    setExportConfirmationOpen,
    totalCount = 0,
    selectedExceptionWorkFlowStatusFilter,
    setSelectedExceptionWorkFlowStatusFilter
  } = props;
  const classes = useStyles();
  const unassignedItems = { userId: -1, firstname: "Unassigned", lastname: "" };
  const dispatch = useDispatch();
  const location = useLocation();
  const { t } = useTranslation();
  const history = useHistory();
  const [isAdmin, setIsAdmin] = React.useState(false);
  const [tempExceptionType, setTempExceptionType] = React.useState([]);
  const [tempExceptions, setTempExceptions] = React.useState([]);
  const [searchText, setSearchText] = React.useState(null);
  const query = useQuery();
  const [fromSelectedDate, setFromDate] = React.useState(
    format(subDays(new Date(), 1), dateFormat),
  );
  const [toSelectedDate, setToDate] = React.useState(
    format(new Date(), dateFormat),
  );
  const [userDivision, setUserDivision] = useState();
  const [userFleet, setUserFleet] = useState();
  const [exceptionWorkFlowStatusFilter, setExceptionWorkFlowStatusFilter] = React.useState([]);
  const [selectedExceptionAssignersFilter, setSelectedExceptionAssignersFilter] = React.useState([]);
  const [exceptionAssignersFilter, setExceptionAssignersFilter] = React.useState([]);

  const [showCustomDate, setshowCustomDate] = React.useState(false);

  const defaultSortColumn = sortColumns[2];

  const currentUser = useSelector(({ requests }) => get(requests, "queries.FETCH_CURRENT_USER.data"));

  const filterExceptionList = useSelector(
    ({ filterListException }) => filterListException,
  );
  const filterDashboard = useSelector(({ filterDashboard }) => filterDashboard);
  const [showAll, setShowAll] = React.useState(false);
  const [tags, setTags] = useState([]);

  const [invalidStartDate, setInvalidStartDate] = useState(false)
  const [invalidEndDate, setInvalidEndDate] = useState(false)
  const [futureEndDate, setFutureEndDate] = useState(false)
  const [futureStartDate, setFutureStartDate] = useState(false)

  const handleShowMore = () => {
    setShowAll(!showAll);
  };
  useEffect(() => {
    const fdash = query.get("fdash");
    if (fdash) {
      const processMap = processes?.filter((p) => Boolean(filterDashboard.process.find((fp) => fp === p.id)));
      props.handleChangeProcess(processMap);
      setFromDate(filterDashboard.fromDate)
      setToDate(filterDashboard.toDate)
      props.handleChangeDates(filterDashboard.fromDate, filterDashboard.toDate, filterDashboard.selectedDurationValue)
      if (filterDashboard.selectedDurationValue !== Duration.CUSTOM) onSelectDurationValue(filterDashboard.selectedDurationValue);
      props.handleChangeTag(filterDashboard.tags);
    }
  }, [processes.map((p) => p.id).join()]);

  useEffect(() => {
    if (
      filterExceptionList[currModule].exceptionsSelectedDurationValue === "CUSTOM"
    ) {
      setshowCustomDate(true);
    } else setshowCustomDate(false);
  }, [processes.map((p) => p.id).join(), filterExceptionList[currModule]]);

  useEffect(() => {
    setExceptionWorkFlowStatusFilter(exceptionWorkFlowStatus);
    if (setSelectedExceptionWorkFlowStatusFilter) {
      setSelectedExceptionWorkFlowStatusFilter(
          filterExceptionList[currModule]?.workflowStatus,
      );
    }
    setSelectedExceptionAssignersFilter(
        filterExceptionList[currModule]?.usersInCharge,
    )
  }, [exceptionWorkFlowStatus.join()]);

  useEffect(() => {
    if (setSelectedExceptionWorkFlowStatusFilter) {
      setSelectedExceptionWorkFlowStatusFilter(
          filterExceptionList[currModule]?.workflowStatus,
      );
    }
  }, []);

  useEffect(() => {
    const onlyReadyIsSelected = (selectedExceptionWorkFlowStatusFilter && selectedExceptionWorkFlowStatusFilter.length === 1) && (selectedExceptionWorkFlowStatusFilter.includes(STATUS.READY));
    if (onlyReadyIsSelected) {
      setExceptionAssignersFilter([
        unassignedItems,
      ]);
    } else if (assigners) {
      setExceptionAssignersFilter([
        unassignedItems,
        ...assigners,
      ]);
    }

    const currentUserArray = assigners?.filter((element) => element.userId.toString() === currentUser.id);
    if (props.currentUserSet) {
      handleChangeExceptionAssigners(currentUserArray);
    }
  }, [assigners?.map((a) => a.userId).join(), selectedExceptionWorkFlowStatusFilter]);

  useEffect(() => {
    const onlyReadyIsSelected = (selectedExceptionWorkFlowStatusFilter && selectedExceptionWorkFlowStatusFilter.length === 1) && (selectedExceptionWorkFlowStatusFilter.includes(STATUS.READY));
    if (onlyReadyIsSelected) {
      handleChangeExceptionAssigners([unassignedItems]);
    } else handleChangeExceptionAssigners([]);
  }, [exceptionAssignersFilter.map((exp) => exp.id).join()]);

  React.useEffect(() => {
    setTempExceptionType(
      exceptionsType?.map((e, i) => ({ id: i, desc: e }))
    );
  }, [exceptionsType]);

  React.useEffect(() => {
    setTempExceptions(
      exceptions?.map((e, i) => ({ id: i, exceptionReason: e }))
    );
  }, [exceptions]);

  const handleChangeProcess = (value) => {
    const processIds = value?.map(({ id }) => id);
    const matchedProcesses = processes.filter(({ id }) => processIds.includes(id));
    props.handleChangeProcess(matchedProcesses);
  };

  const handleChangeDivision = (value) => {
    const divisionIds = value?.map(({ id }) => id);
    const matchedDivision = userDivision.filter(({ id }) => divisionIds.includes(id));
    props.handleChangeDivision(matchedDivision);
  };

  const handleChangeFleet = (value) => {
    const fleetIds = value?.map(({ id }) => id);
    const matchedFleets = userFleet.filter(({ id }) => fleetIds.includes(id));
    props.handleChangeFleet(matchedFleets);
  };

  const handleChangeProcessType = (value) => {
    props.handleChangeExceptionType(value);
  };

  const handleChangeException = (value) => {
    props.handleChangeException(value);
  };

  const handleChangeExceptionWorkflowStatus = (event) => {
    const value = event?.target?.value || event;
    if (setSelectedExceptionWorkFlowStatusFilter) {
      setSelectedExceptionWorkFlowStatusFilter(value);
    }
    props.handleChangeExceptionWorkflowStatus(value);
  };

  const handleChangeExceptionAssigners = (value) => {
    setSelectedExceptionAssignersFilter(value);
    if (props.currentUserSet && !isEmpty(value)) props.resetCurrentUserFilter();
    if (props.handleChangeExceptionAssigners) props.handleChangeExceptionAssigners(value);
  };
  React.useEffect(() => {
    if (currentUser && isFleetAdministrator(currentUser)) setIsAdmin(true);
  }, [currentUser]);

  const onSelectDurationValue = (value) => {
    selectDurationValue(
      value,
      setToDate,
      setshowCustomDate,
      setFromDate,
      props.handleChangeDates,
      dateFormat,
    );
  };
  const onFromDateChange = (date) => {
    const currentDate = new Date();
    if (isAfter(date, currentDate)) {
      setFutureStartDate(true)
    } else if (format(date, dateFormat) > toSelectedDate) {
      setInvalidStartDate(true)
    } else {
      setInvalidStartDate(false)
      setFutureStartDate(false)
      setFromDate(date);
      props.handleChangeDates(
        date,
        toSelectedDate,
        filterExceptionList[currModule]?.exceptionsSelectedDurationValue,
      );
    }
  };
  const onToDateChange = (date) => {
    const currentDate = new Date();
    if (isAfter(date, currentDate)) {
      setFutureEndDate(true)
    } else if (format(date, dateFormat) < fromSelectedDate) {
      setInvalidEndDate(true)
    } else {
      setFutureEndDate(false)
      setInvalidEndDate(false)
      setInvalidStartDate(false)
      setToDate(date);
      props.handleChangeDates(
        fromSelectedDate,
        date,
        filterExceptionList[currModule]?.exceptionsSelectedDurationValue,
      );
    }
  };

  const handleShowAnalytics = () => {
    setAnalyticsLoading(!showAnalyticsContent);
    setShowAnalyticsContent(!showAnalyticsContent);
  };

  const handleOnClickExport = () => {
    localStorage.setItem("lgn", i18n.language);
    if (isItemList) {
      setExportConfirmationOpen(true)
    } else {
      const ids = processName
      && processName.map((p) => p?.id);
      setExportLoading(true);
      exportExceptionXLSX(
        ids,
        filterExceptionList[currModule]?.exceptionType,
        filterExceptionList[currModule]?.exception,
      );
    }
  };

  const handleFilterValueChange = (e) => {
    const { value } = e.target;
    setSearchText(value);
    debouncer(value, filterExceptionList);
  };
  const debouncer = React.useCallback(
    debounce((nextValue, currFilter) => {
      props.handleChangeSearchText(nextValue, currFilter);
    }, 500),
    [currModule],
  );

  useEffect(() => {
    setSearchText(filterExceptionList[currModule].searchText);
  }, [currModule]);

  useEffect(() => {
    setSearchText(filterExceptionList[currModule].searchText);
  }, []);
  const clearFilter = () => {
    props.currentUserSet && props.resetCurrentUserFilter();
    dispatch(initFilter());
    setSearchText("");
    setshowCustomDate(false);
    handleChangeProcess([]);
    if (setSelectedExceptionWorkFlowStatusFilter) {
      setSelectedExceptionWorkFlowStatusFilter([]);
    }
    setSelectedExceptionAssignersFilter([]);
    if (props.handleChangeExceptionWorkflowStatus) props.handleChangeExceptionWorkflowStatus([]);
    if (setOriginalItemId) setOriginalItemId("");

    const queryParams = new URLSearchParams(location.search);
    if (queryParams.has("processExecution")) {
      queryParams.delete("processExecution");
      history.replace({
        search: queryParams.toString(),
      });
    }
  };

  useEffect(() => {
    if (currentUser && !isPartitioningDisabled) { dispatch(fetchDivisionsForFilters()).then((res) => {
      setUserDivision(res?.data) }) }
  }, [currentUser])

  useEffect(() => {
    if (!isPartitioningDisabled) {
      dispatch(fetchAllFleets()).then((res) => {
        setUserFleet(res.data);
      });
    }
  }, [])
  useEffect(() => {
    dispatch(fetchTagsForFilters()).then((res) => {
      setTags(res.data);
    });
  }, [])

  const handleChangeTagsFilter = (value) => {
    props.handleChangeTag(value.map(({ id }) => id))
  };

  return (
    <Grid container className={classes.filterRoot}>
      <Grid container item xs={12} className={classes.filter} spacing={1}>
        <Grid item container xs={2} alignItems="flex-end">
          <FormControl fullWidth className={classes.autoComplete}>
            <SearchFilter
              callback={handleFilterValueChange}
              fullWidth
              placeHolder={t("Search")}
              value={searchText}
            />
          </FormControl>
        </Grid>
        {
            currModule === exceptionCurrentModule.PROCESSES
            && (<TagFilter
                    handleChangeTagsFilter={handleChangeTagsFilter}
                    classes={classes}
                    tags={tags}
                    filterExceptionList={filterExceptionList}
                    currModule={currModule}
                />)
        }
        <Grid item xs={2}>
          <FormControl fullWidth className={classes.autoComplete}>
            <CustomAutoComplete
                multiple
                options={processes || []}
                optionLabel="processDescription.processDisplayName"
                value={processes?.filter(({ id }) => filterExceptionList[currModule].process.map(({ id }) => id).includes(id))}
                noOptionsNode={<NoDataMenu message={t("no.process.message")} />}
                onChange={handleChangeProcess}
                label={t("process")}
            />
          </FormControl>
        </Grid>
        {currentUser?.fleet?.instanceOwner && isFleetAdministrator(currentUser) && !isPartitioningDisabled && (
        <Grid item xs={2}>
          <FormControl fullWidth className={classes.autoComplete}>
            <CustomAutoComplete
                multiple
                options={userFleet || []}
                optionLabel="companyName"
                value={userFleet?.filter(({ id }) => filterExceptionList[currModule].fleet.includes(id)).map(({ id }) => id).id}
                noOptionsNode={<NoDataMenu message={t("no.fleet.message")} />}
                onChange={handleChangeFleet}
                label={t("fleets")}
            />
          </FormControl>
        </Grid>
            )}
        {!isPartitioningDisabled && (
        <Grid item xs={2}>
          <FormControl fullWidth className={classes.autoComplete}>
            <CustomAutoComplete
                multiple
                options={userDivision || []}
                optionLabel="divisionName"
                value={userDivision?.filter(({ id }) => filterExceptionList[currModule].divisions.includes(id)).map(({ id }) => id).id}
                noOptionsNode={<NoDataMenu message={t("no.division.message")} />}
                onChange={handleChangeDivision}
                label={t("divisions.division")}
            />
          </FormControl>
        </Grid>
        )}
        {currModule === exceptionCurrentModule.ITEMS && (
          <Grid item xs={2}>
            <FormControl fullWidth className={classes.autoComplete}>
              <CustomAutoComplete
                  multiple
                  options={exceptionWorkFlowStatusFilter || []}
                  value={exceptionWorkFlowStatusFilter?.filter((fd) => selectedExceptionWorkFlowStatusFilter.includes(fd))}
                  onChange={(event) => handleChangeExceptionWorkflowStatus(event)}
                  label={t("Exception Workflow Status")}
              />
            </FormControl>

          </Grid>
        )}
        <Grid item xs={2}>
          <FormControl fullWidth className={classes.autoComplete}>
            <CustomAutoComplete
                multiple
                options={tempExceptionType || []}
                optionLabel="desc"
                value={tempExceptionType?.filter(({ id }) => filterExceptionList[currModule].exceptionType.map(({ id }) => id).includes(id)) ?? []}
                noOptionsNode={<NoDataMenu message={t("no.exception.message")} />}
                onChange={handleChangeProcessType}
                label={t("Exception Type")}
            />
          </FormControl>
        </Grid>
        {showAll && (
          <>
            {currModule === exceptionCurrentModule.ITEMS
               && (<TagFilter
                      handleChangeTagsFilter={handleChangeTagsFilter}
                      classes={classes}
                      tags={tags}
                      filterExceptionList={filterExceptionList}
                      currModule={currModule}
                   />)}
            <Grid item xs={2}>
              <FormControl fullWidth className={classes.autoComplete}>
                <CustomAutoComplete
                    multiple
                    options={tempExceptions || []}
                    optionLabel="exceptionReason"
                    value={tempExceptions?.filter(({ id }) => filterExceptionList[currModule].exception.map(({ id }) => id).includes(id)) ?? []}
                    noOptionsNode={<NoDataMenu message={t("no.exception.message")} />}
                    onChange={handleChangeException}
                    label={t("Exception Reason")}
                />
              </FormControl>
            </Grid>
            {currModule === exceptionCurrentModule.ITEMS && (
            <Grid item xs={2}>
              <FormControl fullWidth className={classes.autoComplete}>
                <CustomAutoComplete
                    multiple
                    options={exceptionAssignersFilter || []}
                    optionUuid="userId"
                    optionLabel={["firstname", "lastname"]}
                    value={exceptionAssignersFilter?.filter((fd) => selectedExceptionAssignersFilter.includes(fd))}
                    noOptionsNode={<NoDataMenu message={t("no.exception.message")} />}
                    onChange={handleChangeExceptionAssigners}
                    label={t("Exception Assignee")}
                />
              </FormControl>
            </Grid>
            )}
            <DateFilter
              onFromDateChange={onFromDateChange}
              fromDate={filterExceptionList[currModule]?.exceptionsFromDate}
              onToDateChange={onToDateChange}
              toDate={filterExceptionList[currModule]?.exceptionsToDate}
              dateFormat={formatDatePickerByLanguage()}
              classes={classes}
              selectedDuration={
              filterExceptionList[currModule]?.exceptionsSelectedDurationValue
            }
            onSelectDurationValue={onSelectDurationValue}
            formControlClassName={classes.autoComplete}
            showCustomDate={showCustomDate}
            isAdmin={isAdmin}
            type="exception"
            invalidEndDate={invalidEndDate}
            invalidStartDate={invalidStartDate}
            futureEndDate={futureEndDate}
            futureStartDate={futureStartDate}
            isCustom
            xs={2}
          />
            <Grid item xs={2}>
              <CustomSelectField
                  options={sortColumns}
                  optionLabel="label"
                  value={sortColumns?.find(({ label }) => label === filterExceptionList[currModule]?.order?.label)}
                  onChange={(e) => props.handleRequestSort(
                      e.target.value ? e.target.value : defaultSortColumn
                  )}
                  variant="standard"
                  label={t("Sort By")}
                  formControlClassName={classes.autoComplete}
                  customOptionLabel={(option) => handleDisplayedLabel(t(option?.label))}
                  isCustom
              />
            </Grid>
          </>
        )}
        {isItemList && (!showCustomDate || !showAll) ? (
          <Grid container item xs={showAll ? 6 : 4} justify="space-between">
            <Grid item container xs={3} alignItems="center">
              <Grid item>
                <ShowMoreFilters handleShowMore={handleShowMore} showAll={showAll} />
              </Grid>
              <Grid item>
                <ClearFilter
                    clearFilter={clearFilter}
                    label={t("filter.clear")}
                />
              </Grid>
            </Grid>
            {rowCount > 0 && (
              <Box component={Grid} item container xs={showAll ? 5 : 8} className={classes.actionButtons}>
                <CustomButton
                    view="secondary"
                    onClick={() => handleShowAnalytics()}
                    startIcon={<GraphIcon height={16} />}
                >
                  {analyticsLoading ? (
                    <CircularProgress size={18} color="inherit" />
                  ) : (t("filter.graph"))}
                </CustomButton>
                <ExportButton
                    loading={exportLoading}
                    onClick={() => handleOnClickExport()}
                    withWarning={currModule === exceptionCurrentModule.ITEMS && totalCount > EXPORT_LIMIT}
                    warningMessage="export.limit"
                />
              </Box>
            )}
          </Grid>) : (
            <>
              <Grid item container xs={isItemList ? 2 : 4} alignItems="flex-end">
                {!isItemList
                    && (
                    <Grid item>
                      <Tooltip title={t("tooltip.action.showHiddenItems")} placement="top">
                        <CustomSwitch
                            label={t("exceptions.show.hidden")}
                            labelPlacement="start"
                            checked={showHidden}
                            handleChange={handleChangeShowHidden}
                        />
                      </Tooltip>
                    </Grid>)}
                <Grid item>
                  <ShowMoreFilters handleShowMore={handleShowMore} showAll={showAll} />
                </Grid>
                <Grid item>
                  <ClearFilter
                      clearFilter={clearFilter}
                      label={t("filter.clear")}
                  />
                </Grid>
              </Grid>
              {rowCount > 0 && (
                <Box component={Grid} item container xs={3} className={classes.actionButtons}>
                  <CustomButton
                      view="secondary"
                      onClick={() => handleShowAnalytics()}
                      startIcon={<GraphIcon height={16} />}
                  >
                    {analyticsLoading ? (
                      <CircularProgress size={18} color="inherit" />
                    ) : (t("filter.graph"))}
                  </CustomButton>
                  <ExportButton
                      loading={exportLoading}
                      onClick={() => handleOnClickExport()}
                  />
                </Box>
              )}
            </>
            )}
        <Box component={Grid} container xs={12} alignItems="flex-end" justify="flex-end">
          <Box component={Grid} className={classes.actions} pr={0.8}>
            {!isItemList && showHidden && rowCount > 0 && (
              <HasPermission name={deletePermission}>
                <Tooltip title={t("tooltip.action.show")} placement="top">
                  <IconButton
                    className={classes.showIcon}
                    edge="end"
                    aria-label="view"
                    onClick={onShow}
                    disabled={
                      numSelected === 0 || exceptionsToShow.length === 0
                    }
                  >
                    <Badge
                      badgeContent={
                        exceptionsToShow.length > 0 && exceptionsToShow.length
                      }
                    >
                      <VisibilityIcon />
                    </Badge>
                  </IconButton>
                </Tooltip>
              </HasPermission>
            )}

            {!isItemList && rowCount > 0 && (
              <HasPermission name={deletePermission}>
                <Tooltip title={t("tooltip.action.hide")} placement="top">
                  <IconButton
                    className={classes.deleteIcon}
                    edge="end"
                    aria-label="view"
                    onClick={onDelete}
                    disabled={
                      numSelected === 0 || exceptionsToHide.length === 0
                    }
                  >
                    <Badge
                      badgeContent={
                        exceptionsToHide.length > 0 && exceptionsToHide.length
                      }
                    >
                      <VisibilityOffIcon />
                    </Badge>
                  </IconButton>
                </Tooltip>
              </HasPermission>
            )}
            {isItemList && numSelected > 0 && (
              <HasPermission name="Workflow">
                <Tooltip title={t("exception-workflow.open-bulk")} placement="top">
                  <IconButton onClick={openWorkflowBulkDrawer}>

                    <Badge badgeContent={numSelected}>
                      <SrWorkflowIcon />
                    </Badge>
                  </IconButton>
                </Tooltip>
              </HasPermission>
            )}
            {numSelected > 0 && rowCount > 0 && (
              isItemList && (
                <HasPermission name="Retry item exceptions">
                  <Tooltip title={t("retry")} placement="top">
                    <IconButton onClick={setRetryItems}>
                      <Badge badgeContent={numSelected}>
                        <ReplayIcon className={classes.replayIcon} />
                      </Badge>
                    </IconButton>
                  </Tooltip>
                </HasPermission>
              )
            )}
            {rowCount > 0 && (
            <Checkbox
              indeterminate={
                numSelected > 0 && indeterminateCheck && !checkedAll
              }
              checked={checkedAll}
              onChange={onSelectAllClick}
              inputProps={{ "aria-label": "select all rows" }}
              size="small"
            />)}
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
