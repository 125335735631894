import { FETCH_LICENSE, SAVE_LICENSE } from "../../constants";
import { URL_LICENSES } from "../../constants/endpoints";

export const licenseActivation = (licence, onSuccess, onError) => {
    const data = new FormData();
    data.append("licFile", licence);
    return {
        type: SAVE_LICENSE,
        request: {
            url: `${URL_LICENSES}`,
            method: "post",
            data,
            headers: { "Content-Type": "application/json" },
        },
        meta: {
            onSuccess: (response) => {
                if (onSuccess) onSuccess(response);
                return response;
            },
            onError: (error) => {
                if (onError) onError(error);
                throw error;
            },
        },
    };
};

export const getLicense = (onSuccess, onError) => (
    {
        type: FETCH_LICENSE,
        request: {
            url: `${URL_LICENSES}`,
            method: "get",
        },
        meta: {
            onSuccess: (response) => {
                if (onSuccess) onSuccess(response);
                return response;
            },
            onError: (error) => {
                if (onError) onError(error);
                throw error;
            },
        },
    }
);
