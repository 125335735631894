import React from "react";
import { Chip } from "@material-ui/core";
import useStyle from "./style"

const PriorityChip = ({
  priority, onClick, hidden,
}) => {
  const classes = useStyle()
  return (
    <Chip
          label={priority}
          className={classes.priorityChip}
          classes={{
            label: classes.priorityLabel
          }}
          variant="outlined"
          onClick={onClick}
          disabled={hidden}
      />
  )
};

export default PriorityChip;
