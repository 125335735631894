import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import UsersSelector from "../../UsersSelector";
import { fetchFleetUsers } from "../../../../../redux/actions/services";
import { useDispatch } from "react-redux";

export default function DivisionUser({
  selectorName,
  selectedFleetId,
  isEdit,
  assignedUsersData,
  isViewMode,
  setUsersField,
  availableAdmins,
  availableLabel,
  assignedLabel
}) {
  const dispatch = useDispatch();
  const [assignedUsers, setAssignedUsers] = React.useState(
    assignedUsersData ?? []
  );
  const [availableUsers, setAvailableUsers] = React.useState([]);

  useEffect(() => {
    if (isEdit) setAssignedUsers(assignedUsersData);
  }, [isEdit]);

  useEffect(() => {
    setUsersField(selectorName, assignedUsers);
  }, [assignedUsers]);

  useEffect(() => {
    const currentUsersIds = assignedUsersData?.map(({ id }) => id);
    if (availableAdmins) {
      setAvailableUsers(availableAdmins?.filter((user) => !user.divisionAdmin))
      setAssignedUsers(assignedUsersData?.filter((user) => user.divisionAdmin) ?? []);
    }
    else if (selectedFleetId) {
      dispatch(fetchFleetUsers(selectedFleetId)).then((res) => setAvailableUsers(
        res?.data?.filter(
            ({ id }) => !currentUsersIds?.includes(id)
        ) ?? []
    ));
    }
  }, [selectedFleetId, availableAdmins]);

  return (
    <Grid item xs={12} spacing={1}>
      <UsersSelector
        availableUsers={availableUsers}
        selectedUsers={assignedUsers}
        isDisabled={isViewMode}
        setAvailableUsers={setAvailableUsers}
        setSelectedUsers={setAssignedUsers}
        availableLabel={availableLabel}
        assignedLabel={assignedLabel}
      />
    </Grid>
  );
}
