import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    root: {
        width: "100%",
        background: "transparent",
    },
    paper: {
        width: "100%",
        marginBottom: theme.spacing(2),
        padding: theme.spacing(0, 4, 2, 4),
    },
    tableHeaderContainer: {
        margin: theme.spacing(3, 0, 1),
        paddingRight: theme.spacing(2),
    },
    rowActionButtons: {
        fontSize: 18,
        color: theme.custom.color.icons.visibility,
    },
    deleteIcon: {
        color: theme.custom.color.icons.delete,
        fontSize: 18,
    },
    switchLabel: {
        marginRight: 0,
        marginLeft: theme.spacing(0.5)
    },
    hideIcon: {
        color: theme.custom.color.icons.error,
        fontSize: 18,

    },
    showIcon: {
        color: theme.custom.color.icons.valide,
        fontSize: 18,
    },
    avatarColor: {
        backgroundColor: theme.palette.primary.main,
    },
    alertContainer: {
        marginBottom: theme.spacing(2)
    },
    dialog: {
        "& .MuiDialog-paper": {
            width: "830px",
            maxWidth: "unset",
        },
    },
    dialogContainer: {
        "& .MuiDialog-paperScrollPaper": {
            maxHeight: "calc(100% - 20px)",
        },
    },
    disableDelete: {
        color: "rgba(0, 0, 0, 0.26)",
        backgroundColor: "transparent"
    },
}));
