import React from "react";
import { ResponsiveScatterPlot } from "@nivo/scatterplot";
import { Paper, useTheme } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { formtNumberByLanguage } from "../../util";
import useStyle from "./style"

const AreaLayer = ({ xScale, yScale }) => (
  <svg width="100%" height="100%">
    <rect
      x={0}
      y={0}
      width={xScale(5) / 2}
      height={yScale(0) / 2}
      style={{ fill: "rgba(238, 241, 97, 1)" }}
    />
    <rect
      x={xScale(5) / 2}
      y={yScale(0) / 2}
      width={xScale(5) / 2}
      height={yScale(0) / 2}
      style={{ fill: "rgba(238, 241, 97, 1)" }}
    />
    <rect
      x={xScale(5) / 2}
      y={0}
      width={xScale(5) / 2}
      height={yScale(0) / 2}
      style={{ fill: "rgba(37, 202, 118, 1)" }}
    />
    <rect
      x={0}
      y={yScale(0) / 2}
      width={xScale(5) / 2}
      height={yScale(0) / 2}
      style={{ fill: "rgba(232, 88, 88, 1)" }}
    />
  </svg>
);
const Chart = ({ data }) => {
  const classes = useStyle();

  const theme = useTheme();

  const { t } = useTranslation();

  const themeChart = {
    axis: {
      // tickColor: theme.palette.type === 'light' ? theme.palette.grey[900] : "#fff",
      ticks: {
        text: {
          fill:
            theme.palette.type === "light" ? theme.palette.grey[900] : "#ccc",
        },
      },
      legend: {
        text: {
          fill:
            theme.palette.type === "light" ? theme.palette.grey[900] : "#ccc",
        },
      },
    },
    grid: {
      line: {
        // stroke: theme.palette.type === 'light' ? "#555555" : '#555555',
      },
    },
  };
  return (
    <Paper elevation={0} className={classes.chartContainer}>
      <ResponsiveScatterPlot
        theme={themeChart}
        nodeSize={20}
        colors={
          theme.palette.type === "light" ? theme.palette.grey[900] : "#ccc"
        }
        data={data}
        margin={{
          top: 60, right: 30, bottom: 70, left: 50,
        }}
        xScale={{ type: "linear", min: 0, max: 5 }}
        xFormat={(e) => e}
        yScale={{ type: "linear", min: 0, max: 5 }}
        yFormat={(e) => e}
        tooltip={({ node }) => (
          <div
            className={classes.tooltip}
          >
            <span
              className={classes.tooltipIndicator}
            />
            {`${node.data.serieId}:`}
            <span style={{ fontWeight: "bold", display: "block" }}>
              {`x:${
                formtNumberByLanguage(node.data.x)
              } - y:${
                formtNumberByLanguage(node.data.y)}`}
            </span>
          </div>
        )}
        blendMode="normal"
        axisTop={null}
        layers={[
          "grid",
          "axes",
          AreaLayer,
          "nodes",
          "markers",
          "mesh",
          "legends",
          "annotations",
        ]}
        axisRight={null}
        axisBottom={{
          orient: "bottom",
          tickValues: [0, 1, 2, 3, 4, 5],
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: t("Feasibility"),
          legendPosition: "middle",
          legendOffset: 30,
        }}
        axisLeft={{
          orient: "left",
          tickSize: 5,
          tickValues: [0, 1, 2, 3, 4, 5],
          tickPadding: 5,
          tickRotation: 0,
          legend: t("Impact"),
          legendPosition: "middle",
          legendOffset: -30,
        }}
        legends={[
          {
            anchor: "top-left",
            direction: "column",
            justify: false,
            translateX: 0,
            translateY: -35,
            itemWidth: 100,
            itemHeight: 12,
            itemsSpacing: 5,
            itemDirection: "left-to-right",
            symbolSize: 12,
            symbolShape: "circle",
            itemTextColor:
              theme.palette.type === "light" ? theme.palette.grey[900] : "#ccc",
            effects: [
              {
                on: "hover",
                style: {
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
      />
    </Paper>
  );
};
export default Chart;
