import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import { format } from "date-fns-tz";
import { useQuery } from "@redux-requests/react";
import { FETCH_CONNECTED_USER_PROCESSES, FETCH_CURRENT_USER } from "redux/constants";
import {
  fetchAllFleets,
  fetchConnectedUserProcesses,
  fetchDivisionsForFilters,
  fetchProcessesKpi, fetchTagsForFilters
} from "redux/actions/services";
import { Duration } from "util/constants";
import { formatDatePickerByLanguage } from "util";

import DateFilter from "../../../Services/components/DateFilter";
import NoDataMenu from "../../../../components/NoData/NoDataMenu";
import {
  updateDateFilter,
  updateDivisionFilter,
  updateProcessFilter,
  updateTagsFilter,
  updateFleetsFilter
} from "../../../../redux/slices/dashboardFilterSlice";
import { isFleetAdministrator } from "../../../../util";
import AutoCompleteField from "../../../../components/FormFields/AutoCompleteField";
import CustomButton from "../../../../components/CustomButton";
import { isPartitioningDisabled } from "../../../../util/constants/PermitedFeature";

const dateFormat = "yyyy/MM/dd HH:mm";

function KpiFilter({ loading }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const filterDashboard = useSelector(({ filterDashboard }) => filterDashboard);
  const userProcesses = useQuery({ type: FETCH_CONNECTED_USER_PROCESSES })?.data?.list;
  const currentUser = useQuery({ type: FETCH_CURRENT_USER })?.data;
  const [isRefreshTimeSelectOpen, setIsRefreshTimeSelectOpen] = React.useState(false);
  const [refreshTime, setRefreshTime] = React.useState();
  const fetchIntervalRef = React.useRef(null);
  const [userDivision, setUserDivision] = useState();
  const [userFleets, setUserFleets] = useState();
  const [tags, setTags] = useState([]);

  useEffect(() => {
    dispatch(fetchConnectedUserProcesses());
  }, []);

  React.useEffect(() => {
    if (refreshTime) {
      fetchIntervalRef.current = setInterval(() => dispatch(
        fetchProcessesKpi(filterDashboard),
      ), refreshTime);
    } else {
      clearInterval(fetchIntervalRef.current);
    }
    return () => clearInterval(fetchIntervalRef.current);
  }, [refreshTime, filterDashboard]);

  const handleChangeProcessesFilter = (value) => {
    dispatch(updateProcessFilter(value?.map(({ id }) => id)));
  };

  const handleChangeDivisionsFilter = (value) => {
    dispatch(updateDivisionFilter(value?.map(({ id }) => id)));
  };

  const handleChangeFleetsFilter = (value) => {
    dispatch(updateFleetsFilter(value?.map(({ id }) => id)));
  };

  const onFromDateChange = (date) => {
    dispatch(
      updateDateFilter({
        selectedDurationValue: filterDashboard.selectedDurationValue,
        fromDate: format(date, dateFormat),
        toDate: filterDashboard.toDate,
      }),
    );
  };

  const onToDateChange = (date) => {
    dispatch(
      updateDateFilter({
        selectedDurationValue: filterDashboard.selectedDurationValue,
        fromDate: filterDashboard.fromDate,
        toDate: format(date, dateFormat),
      }),
    );
  };

  const onSelectDurationValue = (value) => {
    const date = value === Duration.CUSTOM ? format(new Date(), "yyyy/MM/dd HH:mm:ss") : null;
    dispatch(
      updateDateFilter({
        selectedDurationValue: value,
        fromDate: date,
        toDate: date,
      }),
    );
  };

  useEffect(() => {
    if (currentUser && !isPartitioningDisabled) {
      dispatch(fetchDivisionsForFilters()).then((res) => {
        setUserDivision(res?.data)
      })
    }
  }, [currentUser])

  useEffect(() => {
    if (!isPartitioningDisabled) {
      dispatch(fetchAllFleets()).then((res) => {
        setUserFleets(res.data);
      });
    }
  }, [])
  useEffect(() => {
    dispatch(fetchTagsForFilters()).then((res) => {
      setTags(res.data);
    });
  }, [])

  const handleChangeTagsFilter = (value) => {
    dispatch(updateTagsFilter(value?.map(({ id }) => id)));
  };

  return (
    <Grid
      item
      container
      justify="flex-end"
      direction="row"
      spacing={2}
    >
      {loading && (
      <Grid
        item
        style={{ alignSelf: "center" }}
      >
        <CircularProgress color="inherit" size={25} />
      </Grid>
      )}
      <Box
        item
        xs={1}
        component={Grid}
        maxWidth="300px"
      >
        <FormControl fullWidth>
          <InputLabel>
            {t("dashboar.refresh")}
          </InputLabel>
          <Select
            onOpen={() => setIsRefreshTimeSelectOpen(true)}
            onClose={() => setIsRefreshTimeSelectOpen(false)}
            open={isRefreshTimeSelectOpen}
            labelId="refreshTime"
            label="Refresh"
            id="tags-standard-refreshTime"
            onChange={(e) => setRefreshTime(e.target.value)}
            value={refreshTime}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              getContentAnchorEl: null,
            }}
          >
            {refreshTimeData.map(({
              id, time, value, unit,
            }) => (
              <MenuItem
                key={`refresh-time-filter-${id}`}
                value={time}
              >
                {t("dashboard.refresh.desc", { time: value, timeUnit: t(unit).toLocaleLowerCase() })}
              </MenuItem>
            ))}
            <Box
              textAlign="center"
              m={1}
            >
              <CustomButton
                color="primary"
                variant="outlined"
              >
                {t("reset")}
              </CustomButton>
            </Box>
          </Select>
        </FormControl>
      </Box>
      <Box
          component={Grid}
          item
          xs={2}
          maxWidth="300px">
        <AutoCompleteField
              multiple
              options={tags || []}
              optionLabel="name"
              value={tags?.filter(({ id }) => filterDashboard.tags?.includes(id))}
              noOptionsNode={<NoDataMenu message={t("no.tags.message")} />}
              onChange={handleChangeTagsFilter}
              label={t("Tags")}
          />
      </Box>
      <Box
        component={Grid}
        item
        xs={2}
        maxWidth="300px"
      >
        <AutoCompleteField
            multiple
            options={userProcesses || []}
            optionLabel="processDescription.processDisplayName"
            value={userProcesses?.filter(({ id }) => filterDashboard.process?.includes(id))}
            noOptionsNode={<NoDataMenu message={t("no.process.message")} />}
            onChange={handleChangeProcessesFilter}
            label={t("processes")}
        />
      </Box>
      {currentUser?.fleet?.instanceOwner && isFleetAdministrator(currentUser) && !isPartitioningDisabled && (
        <Box
            component={Grid}
            item
            xs={2}
            maxWidth="300px"
        >
          <AutoCompleteField
              multiple
              options={userFleets || []}
              optionLabel="companyName"
              value={userFleets?.filter(({ id }) => filterDashboard.fleets?.includes(id))}
              noOptionsNode={<NoDataMenu message={t("no.fleet.message")} />}
              onChange={handleChangeFleetsFilter}
              label={t("fleets.fleet")}
          />
        </Box>
    )}
      {!isPartitioningDisabled && (
      <Box
        component={Grid}
        item
        xs={2}
        maxWidth="300px"
      >
        <AutoCompleteField
            multiple
            options={userDivision || []}
            optionLabel="divisionName"
            value={userDivision?.filter(({ id }) => filterDashboard.division?.includes(id))}
            noOptionsNode={<NoDataMenu message={t("no.division.message")} />}
            onChange={handleChangeDivisionsFilter}
            label={t("divisions.division")}
        />
      </Box>
      )}
      <DateFilter
        t={t}
        onFromDateChange={onFromDateChange}
        fromDate={filterDashboard.fromDate}
        onToDateChange={onToDateChange}
        toDate={filterDashboard.toDate}
        dateFormat={formatDatePickerByLanguage()}
        selectedDuration={filterDashboard.selectedDurationValue}
        onSelectDurationValue={onSelectDurationValue}
        showCustomDate={filterDashboard.selectedDurationValue === Duration.CUSTOM}
        type="dashboard"
      />
    </Grid>
  );
}

export const refreshTimeData = [
  {
    id: 1,
    time: 30 * 1000,
    value: 30,
    unit: "Seconds",
  },
  {
    id: 2,
    time: 60 * 1000,
    value: 1,
    unit: "Minute",
  },
  {
    id: 3,
    time: 5 * 60 * 1000,
    value: 5,
    unit: "Minutes",
  },
  {
    id: 4,
    time: 10 * 60 * 1000,
    value: 10,
    unit: "Minutes",
  },
];

export default KpiFilter;
