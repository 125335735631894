import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./en";
import fr from "./fr";
import es from "./es";
import pl from "./pl";
import de from "./de";
import ar from "./ar.js";

const getLanguage = () => {
  if (
    localStorage.getItem("lgn")
    && ["en", "fr", "es", "pl", "de", "ar"].includes(localStorage.getItem("lgn"))
  ) {
    return localStorage.getItem("lgn");
  }

  const navigatorLanguage = navigator.language.split("-")[0];
  if (["en", "fr", "es", "pl", "de", "ar"].includes(navigatorLanguage)) {
    return navigatorLanguage;
  }

  return "en";
};

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: {
    translation: en,
  },
  fr: {
    translation: fr,
  },
  es: {
    translation: es,
  },
  pl: {
    translation: pl,
  },
  de: {
    translation: de,
  },
  ar: {
    translation: ar,
  },
};
i18n.use(initReactI18next).init({
  resources,
  lng: getLanguage(),
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
