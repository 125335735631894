import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  avatarIcon: {
    width: "65%",
    height: "65%",
  },
  editIcon: {
    padding: 5,
    backgroundColor: theme.palette.primary.main,
    color: "white",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: "white",
    }

  },
  large: {
    width: theme.spacing(8),
    height: theme.spacing(8),
  },
  avatarColor: {
    backgroundColor: theme.palette.primary.main,
    color: "white",
  },
}));
