import React from "react"
import { withStyles } from "@material-ui/core/styles";
import TableContainer from "@material-ui/core/TableContainer";

export const StyledTableContainer = withStyles(() => ({
    root: {
        overflowX: "hidden",
    }
}))(TableContainer);

export default function CustomTableContainer(props) {
    return (
      <StyledTableContainer {...props}>
        {props?.children}
      </StyledTableContainer>
    )
}
