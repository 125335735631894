import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { InfoOutlined as Help, CalendarToday } from "@material-ui/icons";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import Menu from "@material-ui/core/Menu";
import Typography from "@material-ui/core/Typography";
import ExportButton from "../../../../components/ExportButton";
import Tooltip from "@material-ui/core/Tooltip";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import DeleteIcon from "@material-ui/icons/Delete";
import DateFnsUtils from "@date-io/date-fns";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import { format } from "date-fns-tz";
import { useDispatch, useSelector } from "react-redux";
import { get } from "react-hook-form";
import {
  arrIsNullEmpty,
  formatDatePickerByLanguage,
  getLocale,
  handleDisplayedLabel,
} from "../../../../util/index.js";
import clsx from "clsx";
import ClearFilter from "../../../../components/ClearFilter/index.js";
import CreatePresetDialog from "./CreatePresetDialog";
import DeletePresetDialog from "./DeletePresetDialog";
import CreateKpiDialog from "./CreateKpiDialog";
import DeleteKpiDialog from "./DeleteKpiDialog/DeleteKpiDialog.js";
import NoDataMenu from "../../../../components/NoData/NoDataMenu";
import { fetchTagsForFilters } from "../../../../redux/actions/services";
import CustomSelectField from "../../../../components/FormFields/CustomSelectField";
import CustomAutoComplete from "../../../../components/FormFields/CustomAutoComplete";
import CustomButton from "../../../../components/CustomButton";
import { ReactComponent as DeleteKpiIcon } from "../../../../assets/common/deletekpi.svg";
import { ReactComponent as EditKpiIcon } from "../../../../assets/common/editkpi.svg";
import { kpiTypes } from "util";
import useStyles from "../../style.js";

const dateFormat = formatDatePickerByLanguage();

const StyledMenuItem = withStyles(() => ({
  root: {},
}))(MenuItem);

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

function Filter({
  processes,
  reportTypes,
  kpi,
  selectedReportType,
  setSelectedReportType,
  selectedProcess,
  setSelectedProcess,
  selectedKpi,
  setSelectedKpi,
  handleSaveReport,
  handleCopyToClipboard,
  handleExportPdf,
  handleClear,
  fromSelectedDate,
  setFromDate,
  toSelectedDate,
  setToDate,
  toInputValue,
  setAnchorEl,
  anchorEl,
  exportLoading,
  validReportTypes,
  filterPresets,
  handleDeletePreset,
  handleCreatePreset,
  customKpis,
  handleCreateOrUpdateCustomKpi,
  handleDeleteCustomKpi,
  selectedTags,
  setSelectedTags,
  exceptionsTypes,
  selectedExceptionsTypes,
  setSelectedExceptionsTypes
}) {
  const { t } = useTranslation();
  const classes = useStyles();

  const [anchorPreset, setAnchorPreset] = useState(null);
  const [anchorLoadedPresets, setAnchorLoadedPresets] = useState(null);
  const [showCreatePreset, setShowCreatePreset] = useState(false);
  const [deletePresetId, setDeletePresetId] = useState(-1);
  const [showCreateKpi, setShowCreateKpi] = useState(false);
  const [editedCustomKpi, setEditedCustomKpi] = useState(null);
  const [customKpiDialogMode, setCustomKpiDialogMode] = useState(null);
  const [deleteKpiId, setDeleteKpiId] = useState(-1);
  const [tags, setTags] = useState([]);
  const [showExceptionTypeFilter, setShowExceptionTypeFilter] = useState(false);

  const dispatch = useDispatch();
  const fromFormattedDate = (d) => {
    let res = null;
    try {
      res = format(d, dateFormat);
    } catch (e) {
      res = d;
    }
    return res;
  };

  const ANALYSIS_DATA = useSelector(({ requests }) => get(requests, "queries.FETCH_ANALYSIS_DATA.data"));
  const ANALYSIS_TABLE = useSelector(({ requests }) => get(requests, "queries.FETCH_ANALYSIS_TABLE_DATA.data"));

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeProcess = (newVal) => {
      setSelectedProcess(newVal)
  }

  const handleChangeKpi = (newKpi) => {
    setSelectedKpi(newKpi);
  };

  const handleChangeReportType = (event) => {
    setSelectedReportType(event.target.value);
  };

  const dateFormatter = (str) => str;

  const onFromDateChange = (date) => {
    setFromDate(date);
  };

  const onToDateChange = (date) => {
    setToDate(date);
  };

  const handleSavePreset = () => {
    setShowCreatePreset(true);
    setAnchorPreset(null);
  };

  const handleDeletePresetClick = (preset) => {
    setDeletePresetId(preset.id)
  }

  const handleLoadPresets = (event) => {
    setAnchorLoadedPresets(event.currentTarget);
  };

  const onPresetSubmit = (presetName) => {
    if (presetName) {
      handleCreatePreset(presetName);
      setShowCreatePreset(false);
    }
  };

  const onDeletePresetSubmit = (presetId) => {
    if (presetId) {
      handleDeletePreset(presetId);
      setDeletePresetId(-1);
    }
  }

  const onCreateKpi = (e) => {
    e.stopPropagation();
    setEditedCustomKpi(null);
    setCustomKpiDialogMode("create");
    setShowCreateKpi(true);
  };

  const handleDeleteKpiClick = (e, kpi) => {
    e.stopPropagation();
    setDeleteKpiId(kpi.id)
  }
  const handleChangeExceptionType = (newVal) => {
    setSelectedExceptionsTypes(newVal)
  }
  const onDeleteCustomKpi = (customKpiId) => {
    if (customKpiId) {
      handleDeleteCustomKpi(customKpiId)
      setDeleteKpiId(-1);
    }
  };

  const onEditCustomKpi = (e, kpi) => {
    e.stopPropagation();
    setEditedCustomKpi(kpi);
    setCustomKpiDialogMode("edit");
    setShowCreateKpi(true);
  };

  const onPresetClick = (presetId) => {
    const selectedPreset = filterPresets.filter((preset) => preset.id === presetId);
    if (selectedPreset) {
      const loadedProcesses = processes.filter((process) => selectedPreset[0]?.selectedProcesses.split(",").includes(process.id.toString()));
      const loadedKpi = kpi.filter((kpi) => selectedPreset[0]?.kpi.split(",").includes(kpi.id.toString()));
      const loadedType = reportTypes.filter((type) => selectedPreset[0]?.type.includes(type.id.toString()))[0];
      const loadedFromDate = new Date(selectedPreset[0]?.fromTime);
      const loadedToDate = new Date(selectedPreset[0]?.toTime);
      loadedProcesses && setSelectedProcess(loadedProcesses);
      loadedKpi && setSelectedKpi(loadedKpi);
      loadedType && setSelectedReportType(loadedType);
      loadedFromDate && setFromDate(loadedFromDate);
      loadedToDate && setToDate(loadedToDate);
      setAnchorPreset(null);
      setAnchorLoadedPresets(null);
    }
  };

  const isEmptySelectedProcesses = arrIsNullEmpty(selectedProcess);
  const isEmptySelectedTags = arrIsNullEmpty(selectedTags);
  const isEmptySelectedKpi = arrIsNullEmpty(selectedKpi.filter((kpi) => kpi.code !== "exceptionType"));
  const isEmptySelectedExceptionType = arrIsNullEmpty(selectedExceptionsTypes);
  useEffect(() => {
    dispatch(fetchTagsForFilters()).then((res) => {
      setTags(res.data);
    });
  }, [])

  useEffect(() => {
    if (isEmptySelectedKpi && isEmptySelectedExceptionType) {
      setSelectedReportType(null)
    }
  }, [isEmptySelectedKpi, isEmptySelectedExceptionType])
  useEffect(() => {
  if (isEmptySelectedProcesses && isEmptySelectedTags) {
     setSelectedKpi([])
     }
  }, [isEmptySelectedProcesses, isEmptySelectedTags])
  const handleChangeTagsFilter = (newVal) => {
    setSelectedTags(newVal)
  }

  useEffect(() => {
    const exceptionTypeOptionSelected = selectedKpi.some((option) => option.code === "exceptionType");
    setShowExceptionTypeFilter(exceptionTypeOptionSelected);

    if (!exceptionTypeOptionSelected) {
      setSelectedExceptionsTypes([]);
    }
  }, [selectedTags, selectedReportType, selectedKpi]);

  const customPaperComponent = (options) => {
    const { containerProps, children } = options;
    return (
      <Paper className={classes.paper} {...containerProps}>
        {children}
        <CustomButton
            onMouseDown={(e) => onCreateKpi(e)}
            fullWidth
        >
          <Typography variant="body2" color="primary">
            <strong>{t("reporting.createKpi")}</strong>
          </Typography>
        </CustomButton>
      </Paper>
    )
  };

  return (
    <Grid
        container
        alignItems="center"
        justify="space-between"
    >
      <Grid
        container
        item
        xs={9}
        className={classes.filter}
        direction="row"
        alignItems="flex-start"
        justify="flex-start"
      >
        <Grid item xs={4}>
          <FormControl fullWidth className={clsx(classes.autoComplete, classes.autoCompleteProcesses)}>
            {isEmptySelectedTags
                && <Tooltip title={t("help.tags")} placement="top"><Help className={classes.help} /></Tooltip>}
            <CustomAutoComplete
                multiple
                options={tags ?? []}
                optionLabel="name"
                value={selectedTags}
                noOptionsNode={<NoDataMenu message={t("no.tags.message")} />}
                onChange={handleChangeTagsFilter}
                label={t("Tags")}
            />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl fullWidth className={clsx(classes.autoComplete, classes.autoCompleteProcesses)}>
            {isEmptySelectedProcesses
                && <Tooltip title={t("help.process")} placement="top"><Help className={classes.help} /></Tooltip>}
            <CustomAutoComplete
                multiple
                options={processes ?? []}
                optionLabel="processDisplayName"
                value={selectedProcess}
                noOptionsNode={<NoDataMenu message={t("no.process.message")} />}
                onChange={handleChangeProcess}
                label={t("reporting.process")}
            />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl
            fullWidth
            className={clsx(
              classes.autoComplete,
              classes.autoCompleteProcesses
            )}
          >
            {(!isEmptySelectedProcesses || !isEmptySelectedTags) && (
              <Tooltip title={t("help.kpi")} placement="top">
                <Help className={classes.help} />
              </Tooltip>
            )}
            <CustomAutoComplete
              disabled={isEmptySelectedProcesses && isEmptySelectedTags}
              label={t("reporting.kpi")}
              id="tags-standard-kpi"
              multiple
              value={selectedKpi}
              onChange={handleChangeKpi}
              options={kpi.sort((a, b) => (t(a.label).localeCompare(t(b.label)))).concat(customKpis) ?? []}
              optionLabel={(option) => option.label || option.name}
              showBadge
              customRenderOption={(option) => {
                if (customKpis?.includes(option)) {
                return (
                  <Grid container xs={9} justify="space-between" alignItems="center">
                    <Grid item xs={7} zeroMinWidth wrap="nowrap" className={classes.customRenderOptionText}>
                      <Tooltip title={handleDisplayedLabel(option?.name)} placement="top">
                        <Typography className={classes.chipText}>
                          {handleDisplayedLabel(option?.name)}
                        </Typography>
                      </Tooltip>
                    </Grid>
                    <Box component={Grid} container item xs={5} spacing={1} justify="flex-end" pl={1.5}>
                      <Grid item xs={4}>
                        <IconButton
                            size="small"
                            onClick={(e) => onEditCustomKpi(e, option)}
                        >
                          <EditKpiIcon />
                        </IconButton>
                      </Grid>
                      <Grid item xs={4}>
                        <IconButton
                            size="small"
                            onClick={(e) => handleDeleteKpiClick(e, option)}
                        >
                          <DeleteKpiIcon />
                        </IconButton>
                      </Grid>
                      {kpiTypes?.[option?.kpiType] && (
                      <Grid item xs={4} container alignItems="flex-end">
                        <Tooltip title={t(option?.kpiType)}>
                          <IconButton size="small">
                            {kpiTypes?.[option?.kpiType]?.icon}
                          </IconButton>
                        </Tooltip>
                      </Grid>
                      )}
                    </Box>
                  </Grid>
                );
                }
                return (
                  <Grid container xs={9} justify="space-between" alignItems="center">
                    <Grid item xs={11} zeroMinWidth wrap="nowrap" className={classes.customRenderOptionText}>
                      <Tooltip title={handleDisplayedLabel(t(option.label))} placement="top">
                        <Typography className={classes.chipText}>
                          {handleDisplayedLabel(t(option.label))}
                        </Typography>
                      </Tooltip>
                    </Grid>
                    <Grid container item xs={1} spacing={1} justify="flex-end" alignItems="flex-end">
                      {kpiTypes?.[option?.kpiType] && (
                        <Tooltip title={t(option?.kpiType)}>
                          <IconButton size="small">
                            {kpiTypes?.[option?.kpiType]?.icon}
                          </IconButton>
                        </Tooltip>
                        )}
                    </Grid>
                  </Grid>
                );
                }}
              PaperComponent={customPaperComponent}
            />
          </FormControl>
        </Grid>
        {showExceptionTypeFilter
        && (
        <Grid item xs={4}>
          <FormControl fullWidth className={clsx(classes.autoComplete, classes.autoCompleteProcesses)}>
            {(!isEmptySelectedProcesses || !isEmptySelectedTags) && (
              <Tooltip title={t("help.exception.type")} placement="top">
                <Help className={classes.help} />
              </Tooltip>
            )}
            <CustomAutoComplete
                disabled={isEmptySelectedProcesses && isEmptySelectedTags}
                multiple
                options={exceptionsTypes ?? []}
                value={selectedExceptionsTypes}
                noOptionsNode={<NoDataMenu message={t("no.exception.message")} />}
                onChange={handleChangeExceptionType}
                label={t("exception.type")}
            />
          </FormControl>
        </Grid>)}
        <Grid
          container
          item
          xs={4}
          className={classes.autoComplete}
          alignItems="flex-end"
        >
          {!isEmptySelectedKpi && (
          <Tooltip title={t("help.reportType")} placement="top">
            <Help className={classes.helpAutoComplete} />
          </Tooltip>
            ) }

          <CustomSelectField
              options={validReportTypes || []}
              optionLabel="label"
              value={selectedReportType || ""}
              onChange={handleChangeReportType}
              label={t("report.type")}
              customOptionLabel={(option) => handleDisplayedLabel(t(option?.label))}
              disabled={isEmptySelectedKpi && isEmptySelectedExceptionType}
              isCustom
          />
        </Grid>
        <Grid
            item
            xs={4}
            className={classes.autoComplete}
            alignItems="flex-end"
        >
          <MuiPickersUtilsProvider
              utils={DateFnsUtils}
              locale={getLocale()}
          >
            <FormControl margin="none" fullWidth className={classes.datesContainer}>
              <DatePicker
                  label={t("reporting.from")}
                  autoOk
                  showTodayButton
                  value={fromSelectedDate}
                  format={formatDatePickerByLanguage()}
                  onChange={onFromDateChange}
                  disableFuture
                  cancelLabel={t("user.button.cancel")}
                  todayLabel={t("Today")}
                  invalidDateMessage={t(
                      "fleet.management.formControl.invalidDate",
                  )}
                  maxDateMessage={t("reporting.date.errorPast", {
                    dateValue: fromFormattedDate(toSelectedDate),
                  })}
                  className={classes.input}
                  InputProps={{ endAdornment: <CalendarToday className={classes.popupIcon} fontSize="small" /> }}
                  renderInput={(params) => (
                    <TextField className={classes.input} {...params} />
                  )}
              />
            </FormControl>
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid
            item
            xs={4}
            className={classes.autoComplete}
            alignItems="flex-end"
        >
          <MuiPickersUtilsProvider
              utils={DateFnsUtils}
              locale={getLocale()}
          >
            <FormControl margin="none" fullWidth className={classes.datesContainer}>
              <DatePicker
                  label={t("reporting.to")}
                  autoOk
                  showTodayButton
                  value={toSelectedDate}
                  format={formatDatePickerByLanguage()}
                  inputValue={toInputValue}
                  onChange={onToDateChange}
                  rifmFormatter={dateFormatter}
                  minDate={new Date(fromSelectedDate)}
                  cancelLabel={t("user.button.cancel")}
                  todayLabel={t("Today")}
                  invalidDateMessage={t(
                      "fleet.management.formControl.invalidDate",
                  )}
                  minDateMessage={t("reporting.date.errorFuture", {
                    dateValue: fromFormattedDate(fromSelectedDate),
                  })}
                  className={classes.input}
                  InputProps={{ endAdornment: <CalendarToday className={classes.popupIcon} fontSize="small" /> }}
                  renderInput={(params) => (
                    <TextField className={classes.input} {...params} />
                  )}
              />
            </FormControl>
          </MuiPickersUtilsProvider>
        </Grid>

      </Grid>
      <Grid
          container
          item
          xs={3}
          className={classes.filterActions}
          alignItems="flex-start"
          justify="flex-end"
      >
        <Grid
              container
              item
              xs={4}
              alignItems="center"
              justify="flex-end"
              className={classes.clearFilterContainer}
          >
          <ClearFilter
                clearFilter={handleClear}
                size="24px"
                label={t("reporting.clear")}
            />
        </Grid>
        <Grid
            container
            item
            xs={8}
            spacing={2}
            className={classes.filter}
            direction="row"
            alignItems="flex-start"
            justify="flex-end">
          <Grid
              container
              item
              xs={12}
              alignItems="flex-end"
              justify="flex-end"
              className={classes.exportButtonContainer}
          >
            <CustomButton
                aria-controls="customized-menu"
                aria-haspopup="true"
                view="primary"
                className={classes.exportBtn}
                onClick={(event) => setAnchorPreset(event.currentTarget)}
                endIcon={<ArrowDropDownIcon />}
                fullWidth
            >
              {t("analytics.preset.button.label")}
            </CustomButton>
            <Grid item>
              <StyledMenu
                  id="customized-preset-menu"
                  anchorEl={anchorPreset}
                  keepMounted
                  open={Boolean(anchorPreset)}
                  onClose={() => setAnchorPreset(null)}
              >
                <StyledMenuItem onClick={handleLoadPresets}>
                  <ListItemText primary={t("analytics.preset.load")} />
                  <ArrowRightIcon />
                </StyledMenuItem>
                {
                  // eslint-disable-next-line operator-linebreak
                    (!((!ANALYSIS_DATA && !ANALYSIS_TABLE) || !selectedReportType || isEmptySelectedKpi)) &&
                    <StyledMenuItem onClick={handleSavePreset}>
                      <ListItemText primary={t("analytics.preset.dialog.field.submit")} />
                    </StyledMenuItem>
                }
              </StyledMenu>
            </Grid>
            <Grid item>
              <StyledMenu
                  id="customized-preset-menu"
                  anchorEl={anchorLoadedPresets}
                  anchorOrigin={{ vertical: "top", horizontal: "right" }}
                  transformOrigin={{ vertical: "top", horizontal: "left" }}
                  keepMounted
                  open={Boolean(anchorLoadedPresets)}
                  onClose={() => setAnchorLoadedPresets(null)}
                  onMouseLeave={() => { setAnchorLoadedPresets(null); }}
                  disableElevation
                  disableRipple
              >
                {(filterPresets?.length > 0) ? filterPresets.map((item) => (
                  <Grid container direction="row" alignItems="center" justify="space-between">
                    <Grid item xs>
                      <StyledMenuItem onClick={() => onPresetClick(item.id)}>
                        <ListItemText
                                  primary={item?.presetName}
                              />
                      </StyledMenuItem>
                    </Grid>
                    <Grid item xs="auto">
                      <DeleteIcon
                                onClick={() => handleDeletePresetClick(item)}
                                color="error"
                                className={classes.deleteButton}
                            />
                    </Grid>
                  </Grid>
                    ))
                    : (
                      <StyledMenuItem disabled>

                        <Typography>{t("analytics.preset.noPresets")}</Typography>

                      </StyledMenuItem>
                    )}
              </StyledMenu>
            </Grid>
          </Grid>
          <Grid
              container
              item
              xs={12}
              justify="center"
              alignItems="flex-end"
              className={classes.exportButtonContainer}
          >
            <ExportButton
                loading={exportLoading}
                onClick={handleClick}
                disabled={(!ANALYSIS_DATA && !ANALYSIS_TABLE) || !selectedReportType || isEmptySelectedKpi}
                className={classes.exportBtn}
            />
            <Grid item>
              <StyledMenu
                  id="customized-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
              >
                <StyledMenuItem onClick={handleSaveReport}>
                  <ListItemText primary={t("reporting.save")} />
                </StyledMenuItem>
                <StyledMenuItem onClick={handleExportPdf}>
                  <ListItemText primary={t("reporting.exportPDF")} />
                </StyledMenuItem>
                <StyledMenuItem onClick={handleCopyToClipboard}>
                  <ListItemText primary={t("reporting.copyClipboard")} />
                </StyledMenuItem>
              </StyledMenu>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <CreatePresetDialog open={showCreatePreset} onClose={() => setShowCreatePreset(false)} onSubmit={onPresetSubmit} />
      <DeletePresetDialog presetId={deletePresetId} onClose={() => setDeletePresetId(-1)} onSubmit={onDeletePresetSubmit} />
      <CreateKpiDialog
        open={showCreateKpi}
        key={customKpiDialogMode === "edit" ? editedCustomKpi?.id : -1}
        onClose={() => setShowCreateKpi(false)}
        handleCreateOrUpdateCustomKpi={handleCreateOrUpdateCustomKpi}
        editedCustomKpi={editedCustomKpi}
        customKpiDialogMode={customKpiDialogMode} />
      <DeleteKpiDialog onClose={() => setDeleteKpiId(-1)} onSubmit={onDeleteCustomKpi} kpiId={deleteKpiId} />
    </Grid>
  );
}

export default Filter;
