import React from "react";
import Box from "@material-ui/core/Box";
import PageHeader from "../../../components/PageHeader";
import OrchestratorsTable from "./components/OrchestratorsTable";
import useStyles from "../../../components/TableComponents/style";

export default function OrchestratorManagement() {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box className={classes.paper}>
        <PageHeader title="orchestrator.management.title" />
        <OrchestratorsTable />
      </Box>
    </Box>
  );
}
