import React from "react";
import useStyles from "./style"
import VisibilityIcon from "@material-ui/icons/Visibility";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";

export default function DetailsIcon({ onClickIcon, tooltipTitle }) {
    const classes = useStyles();
    return (
      <Tooltip title={tooltipTitle}>
        <IconButton
                edge="end"
                aria-label="view"
                onClick={onClickIcon}
                size="small"
            >
          <VisibilityIcon className={classes.visibilityIcon} fontSize="small" />
        </IconButton>
      </Tooltip>
    )
}
