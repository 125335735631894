import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  menuLeft: {
    position: "fixed",
    whiteSpace: "nowrap",
    width: theme.custom.variables.menuLeftWidth,
    backgroundColor: theme.palette.background.default,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    border: 0
  },
  menuButton: {
    textAlign: "center",
    paddingTop: theme.spacing(1),
    color: "#737a81",
    "& > img": {
      width: "100%",
      maxWidth: "95px",
    },
    ...theme.mixins.toolbar,
  },
  menuLeftClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(8),
  },
  tab: {
    minWidth: 0,
    textTransform: "inherit",
    padding: theme.spacing(2),
    lineHeight: "1.3rem",
    color: theme.palette.primary.main,
    opacity: 1,
  },
  tabIcon: {
    color: theme.palette.primary.main
  },
  indicator: {
    left: "0",
    backgroundColor: theme.custom.color.chartColor3,
    width: 5,
  },
}));
