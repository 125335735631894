export const Duration = {
  DAY: "DAY",
  WEEK: "WEEK",
  MONTH: "MONTH",
  YEAR: "YEAR",
  ALL_TIME: "ALL_TIME",
  CUSTOM: "CUSTOM",
  list: [
    {
      label: "All time",
      value: "ALL_TIME",
    },
    {
      label: "Today",
      value: "DAY",
    },
    {
      label: "This Week",
      value: "WEEK",
    },
    {
      label: "This Month",
      value: "MONTH",
    },
    {
      label: "This Year",
      value: "YEAR",
    },
    {
      label: "process-occurence.cutsomdate",
      value: "CUSTOM",
    },
  ],
};

export const TimeGranularity = {
  Seconds: "seconds",
  Minutes: "minutes",
  Hours: "hours",
  Days: "days",
  Weeks: "weeks",
  Months: "months",
  Years: "years",
};

export const TimeSpentCriteria = {
  TimeSpentForOneExection: "Time spent for one execution",
  TimeGranularity: "Time granularity",
  OccurenceTimeGranularity: "Occurence time granularity",
  Occurrence: "Frequency of occurence",
  Frequency: "Frequency",
  TotalTimeSpentInHoursPerYear: "Total time spent in hours per year",
};

export const DEFAULT_DATE_FORMAT = "DD/MM/YYYY";
