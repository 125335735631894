import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Checkbox from "@material-ui/core/Checkbox";
import EditIcon from "@material-ui/icons/Edit";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import get from "lodash/get";
import { toast } from "react-toastify";
import clsx from "clsx";
import {
  editStatusRobot,
  enableOrDisableRobot,
} from "../../../../../redux/actions/services";
import { isFleetAdministrator, robotStatus } from "../../../../../util";
import ConfirmMessage from "../../../../../components/ConfirmMessage";
import CustomTableCell from "../../../../../components/TableComponents/CustomTableCell";
import CustomTableRow from "../../../../../components/TableComponents/CustomTableRow";
import StatusBadge from "../../../../../components/StatusBadge";
import PermissionManagement from "../../../PermissionsManagement/PermissionsManagement";
import useStyles from "../../../../../components/TableComponents/style";

export default function RobotTableRow({
  row,
  selected,
  setSelected,
  entityBaseUrl,
  fetchEntities,
  fetchEntitiesReset,
}) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const getFieldData = (valuePath) => get(row, valuePath);
  const active = getFieldData("isActive") === "ACTIVE";
  const [openConfirmationPopup, setOpenConfirmationPopup] = useState(false);
  const [openMsgConfirm, setOpenMsgConfirm] = useState(false);
  const [toDeleteId, setToDeleteId] = useState(null);
  const [isPermissionPageOpen, setIsPermissionPageOpen] = useState(false);
  const [isLoading, setIsLoading] = useState({
    switch: false,
    deleteAction: false,
  });

  const deleteConfirmButton = getFieldData("isActive") === "ACTIVE"
      ? t("tooltip.action.hide")
      : t("tooltip.action.show");
  const displayFields = [
    {
      id: "displayStatus",
      verticalAlign: "middle",
      width: 80,
      title: "",
      customContent: <StatusBadge level={getFieldData("displayStatus")} />,
    },
    {
      id: "robotDisplayName",
      title: "robot.management.robotName",
      valuePath: "robotDisplayName",
    },
    {
      id: "processesRunning",
      title: "robot.management.processesRunning",
      valuePath: "processesRunning",
    },
    {
      id: "displayStatus",
      title: "robot.management.displayStatus",
      valuePath: "displayStatus",
      defaultValue: "Offline",
      translate: true,
    },
    {
      id: "orchestratorName",
      title: "robot.management.orchestratorName",
      valuePath: "orchestrator.name",
    },
    {
      id: "attributeName",
      title: "robot.management.attributeName",
      valuePath: "attributeName",
    },
  ];

  const resetLoaders = () => setIsLoading({ switch: false, deleteAction: false });

  const handleChangeStatus = () => {
    const id = getFieldData("id");
    setIsLoading({ ...isLoading, switch: true });
    dispatch(enableOrDisableRobot(id, active ? "DISABLE" : "ACTIVE")).then(
      (err) => {
        resetLoaders();
        if (err?.response?.data?.detail) {
          toast.error(t(err?.response?.data?.detail));
        } else {
          fetchEntities();
        }
      },
    );
  };

  const cancelConfirm = () => {
    resetLoaders();
    setOpenMsgConfirm(false);
  };

  const snackBarMessageOfRobot = () => {
    const message = row.isActive === robotStatus.ACTIVE ? t("hidden") : t("unhidden");
    toast.success(t("robot.hidden.success", { status: message }));
  };

  const confirmDelete = () => {
    onDeleteEntity();
  };

  const onDeleteEntity = () => {
    setIsLoading({ ...isLoading, deleteAction: true });
    dispatch(editStatusRobot(toDeleteId)).then((res) => {
      if (res?.status === 200) {
        snackBarMessageOfRobot();
        setTimeout(() => fetchEntitiesReset(), 1500);
      } else if (res?.response?.data?.detail) toast.error(t(res?.response?.data?.detail));
      else toast.error(t("Unexpected Error"));

      setOpenMsgConfirm(false);
      resetLoaders();
    });
  };

  const onCancel = () => {
    resetLoaders();
    setOpenConfirmationPopup(false);
  };

  const currentUser = useSelector(({ requests }) => get(requests, "queries.FETCH_CURRENT_USER.data"),);
  const isCurrentUser = Number(currentUser?.id) === row?.id;

  const isRobotActive = row?.isActive === "ACTIVE";
  const deleteMsg = isRobotActive
    ? t("robot.management.hide.confirmMsg")
    : t("robot.management.show.confirmMsg");

  return (
    <>
      <CustomTableRow
        onClick={() => history.push(`${entityBaseUrl}/view/${getFieldData("id")}`)}
      >
        {displayFields.map((field, i) => (
          <CustomTableCell
            key={i}
            title={t(field?.title)}
            text={(field.translate ? (t(getFieldData(field?.valuePath) ?? field.defaultValue)) : getFieldData(field?.valuePath))}
            verticalAlign={field?.verticalAlign}
            width={field?.width}
          >
            {field.customContent}
          </CustomTableCell>
        ))}
        <CustomTableCell verticalALign="middle">
          <Grid container xs={12} justify="flex-end" alignItems="center">
            {isFleetAdministrator(currentUser) && (
              <Grid item>
                <Tooltip placement="top" title={t("tooltip.action.edit")}>
                  <IconButton
                    id="admin-edit-btn"
                    aria-label="edit"
                    onMouseDown={(e) => e.stopPropagation()}
                    onClick={(e) => {
                      e.stopPropagation();
                      history.push(`${entityBaseUrl}/edit/${getFieldData("id")}`);
                    }}
                    size="small"
                  >
                    <EditIcon className={classes.rowActionButtons} />
                  </IconButton>
                </Tooltip>
              </Grid>
            )}
            {isFleetAdministrator(currentUser) && (
              <Grid item>
                <Tooltip
                  placement="top"
                  title={
                    isRobotActive
                      ? t("tooltip.action.hide")
                      : t("tooltip.action.show")
                  }
                >
                  <IconButton
                    disabled={isCurrentUser}
                    aria-label="delete"
                    onMouseDown={(e) => e.stopPropagation()}
                    onClick={(e) => {
                      e.stopPropagation();
                      setToDeleteId(getFieldData("id"));
                      setOpenMsgConfirm(true);
                    }}
                    size="small"
                  >
                    {isRobotActive ? (
                      <VisibilityOffIcon
                        className={clsx(
                          classes.hideIcon,
                          classes.rowActionButtons,
                        )}
                      />
                    ) : (
                      <VisibilityIcon
                        className={clsx(
                          classes.showIcon,
                          classes.rowActionButtons,
                        )}
                      />
                    )}
                  </IconButton>
                </Tooltip>
              </Grid>
            )}
            {isFleetAdministrator(currentUser) && (
              <Grid item>
                <Checkbox
                  className={classes.rowActionButtons}
                  checked={
                    !isCurrentUser && selected.includes(parseInt(row.id, 10))
                  }
                  disabled={isCurrentUser}
                  onMouseDown={(e) => e.stopPropagation()}
                  onClick={(e) => e.stopPropagation()}
                  onChange={(e) => {
                    const list = [...selected];
                    e.target.checked
                      ? list.push(parseInt(getFieldData("id"), 10))
                      : list.splice(
                          list.indexOf(parseInt(getFieldData("id"), 10)),
                          1,
                        );
                    setSelected(list);
                  }}
                  size="small"
                />
              </Grid>
            )}
          </Grid>
        </CustomTableCell>
      </CustomTableRow>
      <ConfirmMessage
        message={
          active
            ? t("robot.management.disable.confirmMsg")
            : t("robot.management.enable.confirmMsg")
        }
        openStart={openConfirmationPopup}
        onCancel={onCancel}
        onConfirm={handleChangeStatus}
        buttonConfirm={
          active
            ? t("robot.management.disable.button.confirm")
            : t("robot.management.enable.button.confirm")
        }
        buttonCancel={t("Cancel")}
        isLoading={isLoading.switch}
      />

      <ConfirmMessage
        message={deleteMsg}
        openStart={openMsgConfirm}
        onCancel={cancelConfirm}
        onConfirm={confirmDelete}
        buttonConfirm={deleteConfirmButton}
        buttonCancel={t("Cancel")}
        isDelete="true"
        isLoading={isLoading.deleteAction}
      />
      {isPermissionPageOpen && (
        <PermissionManagement
          t={t}
          dispatch={dispatch}
          setIsOpen={setIsPermissionPageOpen}
          isOpen={isPermissionPageOpen}
          classes={classes}
          row={row}
          fetchEntities={fetchEntities}
        />
      )}
    </>
  );
}
