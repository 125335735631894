import React, { useEffect, useRef } from "react"
import { withStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import { minimizeString } from "../../../util";
import useStyles from "./style"

export const StyledTableCell = withStyles(() => ({
    root: {
        borderWidth: 0,
        verticalAlign: ({ verticalAlign }) => verticalAlign || "top",
        width: ({ width }) => width,
        paddingTop: ({ paddingTop }) => paddingTop || 10,
        paddingBottom: ({ paddingBottom }) => paddingBottom || 10,
        paddingInline: ({ paddingInline }) => paddingInline || 10
    }
}))(TableCell);

export default function CustomTableCell(props) {
    const { children, title, text } = props
    const classes = useStyles()
    const tableCellRef = useRef()

    useEffect(() => {
        if (tableCellRef?.current) tableCellRef?.current?.removeAttribute("title")
    }, []);
    return (
      <StyledTableCell ref={tableCellRef} {...props}>
        {title && (
        <Typography
                variant="subtitle2"
                align={props?.align ?? "left"}
                color="primary"
                className={classes.title}
            >
          {title}
        </Typography>)}
        { typeof text !== "string" ? (
          <Typography
                variant="body2"
                align={props?.align ?? "left"}
                color="primary"
                className={classes.text}
            >
            {text}
          </Typography>
        ) : (
          <Tooltip title={text} interactive placement="bottom-start">
            <Typography
                    variant="body2"
                    align={props?.align ?? "left"}
                    color="primary"
                    className={classes.text}
                >
              {minimizeString(text, 300)}
            </Typography>
          </Tooltip>
        )}
        {children}
      </StyledTableCell>
    )
}
