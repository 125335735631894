import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import Input from "@material-ui/core/Input";
import Checkbox from "@material-ui/core/Checkbox";
import React from "react";
import Person from "@material-ui/icons/Person";
import { useTranslation } from "react-i18next";
import { Controller } from "react-hook-form";
import { FormHelperText, InputLabel } from "@material-ui/core";
import NoDataMenu from "../../../../components/NoData/NoDataMenu";

export default function SubscribersInput({
  sameFleetUsers,
  disableFields,
  selectedSubscribers,
  handleSubscribersChange,
  control,
  errors,
  classes,
}) {
  const { t } = useTranslation();
  return (
    <Grid container item alignItems="center" alignContent="center">
      <Grid item xs={1}>
        <Person color="primary" className={classes.personIcon} />
      </Grid>
      <Grid item xs={10} lg={8}>
        <Controller
          name="sub"
          control={control}
          defaultValue=""
          rules={{
            required: {
              value: true,
              message: t("alerts.management.required.fields"),
            },
          }}
          render={({ field }) => (
            <FormControl fullWidth>
              <InputLabel id="sub" error={selectedSubscribers && selectedSubscribers.length <= 0 && errors?.sub}>
                {t("alerts.management.subscribers")}
              </InputLabel>
              {selectedSubscribers && (
                <Select
                  {...field}
                  disabled={disableFields}
                  input={<Input />}
                  multiple
                  value={selectedSubscribers}
                  onChange={(event) => handleSubscribersChange(event)}
                  renderValue={(selected) => selected
                      .map((s) => `${s?.firstName} ${s?.lastName}`)
                      .join(", ")
                      .substring(0, 50)}
                  error={selectedSubscribers && selectedSubscribers.length <= 0 && errors?.sub}
                >
                  {sameFleetUsers?.length > 0 ? (sameFleetUsers.map((e) => (
                    <MenuItem value={e} key={e}>
                      <Checkbox checked={selectedSubscribers.includes(e)} />
                      <ListItemText
                        primary={`${e.firstName} ${e.lastName.toUpperCase()}`}
                      />
                    </MenuItem>
                    ))) : <NoDataMenu message={t("no.users.message")} />}
                </Select>
              )}
              {selectedSubscribers && selectedSubscribers.length <= 0 && (
                <FormHelperText className={classes.validation_error}>
                  {errors?.sub?.message}
                </FormHelperText>
              )}
            </FormControl>
          )}
        />
      </Grid>
    </Grid>
  );
}
