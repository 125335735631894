import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Visibility from "@material-ui/icons/Visibility";
import AddBoxOutlinedIcon from "@material-ui/icons/AddBoxOutlined";
import Edit from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import isEmpty from "lodash/isEmpty";
import { formtNumberByLanguage, formatDateByLanguage } from "util";
import { useTranslation } from "react-i18next";
import { HasPermission } from "components/HasPermission";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import DataNotFound from "../../../components/DataNotFound";
import CircularLoader from "../../../components/Loaders/CircularLoader";
import { SvgIcon } from "@material-ui/core";
import { PaymentRounded } from "@material-ui/icons";
import CustomButton from "../../../components/CustomButton";
import { useQuery } from "@redux-requests/react";
import { FETCH_CURRENT_USER } from "../../../redux/constants";
import { getCurrencySymbole } from "../../../util";
import useStyles from "../style";

const TABLE_CELL_ALIGN = "left"
export default function FinancialTable({
  sharedData,
  allocationsData,
  handleEdit,
  handleDelete,
  handleClickOpen,
  disabaleAddCost,
  selectedProcess,
  sharedLoading,
  allocationLoading
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [processesTotalCoast, setProcessesTotalCoast] = useState(0)
  const currentUser = useQuery({ type: FETCH_CURRENT_USER })?.data;
  // temporary
  const handleTotalCost = () => {
    const tempCostsValues = sharedData.map((item) => item.sharedData.find((shared) => shared.processId === selectedProcess.id)?.allocatedAmount ?? 0)
    allocationsData.forEach((item) => {
      if (item.type === "exclusive") tempCostsValues.push(item.allocatedAmount)
    })
    let tempTotal = 0
    for (let i = 0; i < tempCostsValues.length; i += 1) {
      tempTotal += +tempCostsValues[i];
    }
    setProcessesTotalCoast(tempTotal)
  }
  const showProcessesTotalCost = sharedData && allocationsData && (!isEmpty(sharedData) || !isEmpty(allocationsData))
  const isListEmpty = isEmpty(sharedData?.filter((item) => item.costAllocations.type === "shared")) && isEmpty(allocationsData?.filter((item) => item.type !== "shared"))
  useEffect(() => {
   if (showProcessesTotalCost) {
     handleTotalCost()
   }
  }, [showProcessesTotalCost, sharedData, allocationsData])

  return (
    <Grid container className={classes.table} spacing={2} justify="space-between">
      <Grid item xs={12}>
        <Typography
            variant="h2"
            color="secondary"
            className={classes.title}
          >
          {t("Cost Allocation")}
        </Typography>
      </Grid>
      <HasPermission name="Add cost">
        <Grid item xs={12} container justify="space-between" alignItems="center">
          <Grid item xs={8} alignItems="center" container>
            <Grid>
              <SvgIcon className={classes.totalCostIcon}>
                <PaymentRounded />
              </SvgIcon>
            </Grid>
            <Grid alignItems="left" direction="column">
              <Grid item>
                <Typography variant="h2" color="secondary" className={classes.totalCost}>
                  {showProcessesTotalCost ? formtNumberByLanguage(processesTotalCoast) : 0}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h5" component="span" className={classes.timeLabel}>
                  {t("process.total.cost")}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <CustomButton
                onClick={handleClickOpen}
                view="primary"
                size="small"
                disabled={disabaleAddCost}
                fullWidth
                startIcon={<AddBoxOutlinedIcon />}
                className={classes.addButton}
            >
              {t("Add cost")}
            </CustomButton>
          </Grid>
        </Grid>
      </HasPermission>

      <Grid item xs={12}>
        {/* eslint-disable-next-line no-nested-ternary */}
        {sharedLoading || allocationLoading
          ? <CircularLoader size={50} />
            : isListEmpty ? (
              <DataNotFound
                    backgroundColor="transparent"
                    boxShadow="none"
                    iconHeight={150}
                    iconWidth={150}
                    width="100%"
                />
            )
            : (
              <TableContainer component={Paper} className={classes.costsTable}>
                <Table aria-label="simple table" size="small" align="center" stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.costsTableHeaderCell} align={TABLE_CELL_ALIGN}>{t("Name")}</TableCell>
                      <TableCell className={classes.costsTableHeaderCell} align={TABLE_CELL_ALIGN}>{t("Category")}</TableCell>
                      <TableCell className={classes.costsTableHeaderCell} align={TABLE_CELL_ALIGN}>{t("Start Date")}</TableCell>
                      <TableCell className={classes.costsTableHeaderCell} align={TABLE_CELL_ALIGN}>{t("End date")}</TableCell>
                      <TableCell className={classes.costsTableHeaderCell} align={TABLE_CELL_ALIGN}>{t("Type")}</TableCell>
                      <TableCell className={classes.costsTableHeaderCell} align={TABLE_CELL_ALIGN}>{t("Allocation Amount")}</TableCell>
                      <TableCell className={classes.costsTableHeaderCell} align="center">{t("Actions")}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {sharedData.map((item, index) => (
                  item.costAllocations.type === "shared" ? (
                    <TableRow key={index}>
                      <TableCell align={TABLE_CELL_ALIGN}>{item.costAllocations.name}</TableCell>
                      <TableCell align={TABLE_CELL_ALIGN}>{item.costAllocations.category}</TableCell>
                      <TableCell
                            align={TABLE_CELL_ALIGN}>
                        {item.costAllocations.category === "recurring" ? formatDateByLanguage(item.costAllocations.startDate, false) : "--"}
                      </TableCell>
                      <TableCell
                            align={TABLE_CELL_ALIGN}>
                        {item.costAllocations.category === "recurring" ? formatDateByLanguage(item.costAllocations.endDate, false) : "--"}
                      </TableCell>
                      <TableCell align={TABLE_CELL_ALIGN}>{item.costAllocations.type}</TableCell>
                      <TableCell align={TABLE_CELL_ALIGN}>

                        { formtNumberByLanguage(item.sharedData.find((shared) => shared.processId === selectedProcess.id)?.allocatedAmount)}
                        {" "}
                        {getCurrencySymbole(currentUser?.fleet?.currency)}
                      </TableCell>
                      <TableCell align="center">
                        <Tooltip title={t("tooltip.action.view")} placement="top">
                          <IconButton>
                            <Visibility className={classes.visibilityIcon} onClick={() => handleEdit(item, index)} />
                          </IconButton>
                        </Tooltip>
                        <HasPermission name="Edit cost">
                          <Tooltip title={t("tooltip.action.edit")} placement="top">
                            <IconButton>
                              <Edit className={classes.editIcon} onClick={() => handleEdit(item, index, true)} />
                            </IconButton>
                          </Tooltip>
                        </HasPermission>
                        <HasPermission name="Delete cost">
                          <Tooltip title={t("tooltip.action.delete")} placement="top">
                            <IconButton>
                              <DeleteIcon
                                    className={classes.deleteIcon}
                                    onClick={() => handleDelete(item.costAllocations.id, index)}
                                />
                            </IconButton>
                          </Tooltip>
                        </HasPermission>
                      </TableCell>
                    </TableRow>
                  ) : null
              ))}
                    {allocationsData.map((item, index) => (
                  item.type !== "shared" ? (
                    <TableRow key={index}>
                      <TableCell align={TABLE_CELL_ALIGN}>{item.name}</TableCell>
                      <TableCell align={TABLE_CELL_ALIGN}>{item.category}</TableCell>
                      <TableCell
                            align={TABLE_CELL_ALIGN}>
                        {item.category === "recurring" ? formatDateByLanguage(item.startDate, false) : "--"}
                      </TableCell>
                      <TableCell
                            align={TABLE_CELL_ALIGN}>
                        {item.category === "recurring" ? formatDateByLanguage(item.endDate, false) : "--"}
                      </TableCell>
                      <TableCell align={TABLE_CELL_ALIGN}>{item.type}</TableCell>
                      <TableCell align={TABLE_CELL_ALIGN}>
                        {formtNumberByLanguage(item.allocatedAmount)}
                        {" "}
                        {getCurrencySymbole(currentUser?.fleet?.currency)}
                      </TableCell>
                      <TableCell align="center">
                        <Tooltip title="View" placement="top">
                          <IconButton>
                            <Visibility
                                className={classes.visibilityIcon}
                                onClick={() => handleEdit({ costAllocations: item, sharedData: [] }, index)} />
                          </IconButton>
                        </Tooltip>
                        <HasPermission name="Edit cost">
                          <Tooltip title="Edit" placement="top">
                            <IconButton>
                              <Edit
                                  className={classes.editIcon}
                                  onClick={() => handleEdit({ costAllocations: item, sharedData: [] }, index, true)} />
                            </IconButton>
                          </Tooltip>
                        </HasPermission>
                        <HasPermission name="Delete cost">
                          <Tooltip title="Delete" placement="top">
                            <IconButton>
                              <DeleteIcon
                                    className={classes.deleteIcon}
                                    onClick={() => handleDelete(item.id, index)}
                                />
                            </IconButton>
                          </Tooltip>
                        </HasPermission>
                      </TableCell>
                    </TableRow>
                  ) : null
              ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
      </Grid>
    </Grid>
  );
}
