import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import ButtonBase from "@material-ui/core/ButtonBase";
import Tooltip from "@material-ui/core/Tooltip";
import Divider from "@material-ui/core/Divider";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import CircularLoader from "../../../../components/Loaders/CircularLoader";
import useStyles from "../../style";

export default function StateItem({ state, isLoading, isLast }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const pushTo = () => {
    if (state?.pushTo) {
      history.push("/items/itemsExceptions?fdash=true")
    }
  }
  return (
    <Tooltip title={state.tooltipTitle}>
      <ButtonBase component={Grid} item container xs={state.xs} alignItems="center" disableRipple={!state?.pushTo} onClick={pushTo}>
        <Grid item xs={3}>
          <img src={state.icon} alt={state.label} className={classes.stateIcon} />
        </Grid>
        <Grid item xs={8}>
          {isLoading ? (
            <CircularLoader justifyContent="flex-start" height={20} size={15} />
                  ) : (
                    <Typography component="h4" className={classes.stateTitle}>
                      {state.state}
                    </Typography>
                  )}
          <Typography component="h6" className={classes.stateSubtitle}>
            {t(state.label)}
          </Typography>
        </Grid>
        {!isLast && (<Divider orientation="vertical" className={classes.divider} />)}
      </ButtonBase>
    </Tooltip>

  );
}
