import Box from "@material-ui/core/Box";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import NativeSelect from "@material-ui/core/NativeSelect";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Typography from "@material-ui/core/Typography";
import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import range from "lodash/range";
import { makeDay } from "../../../AlertsManagement/CustomAlerts/CustomAlertForm/CustomAlertInfo/ScheduleForm/utils";

function TabPanel(props) {
  const {
    children, value, index, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3} style={{ alignItems: "left", justifyContent: "left" }}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export default function SchedulePeriod({
  classes,
  handleChangeTab,
  register,
  value,
  errors,
  startDate,
}) {
  const { t } = useTranslation();

  return (
    <Grid container>
      <Tabs
        item
        xs={3}
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChangeTab}
        aria-label="Vertical tabs example"
        className={classes.tabs}
      >
        <Tab
          style={{ alignItems: "left", justifyContent: "left" }}
          label={t("Hourly")}
          {...a11yProps(0)}
        />
        <Tab
          style={{ alignItems: "left", justifyContent: "left" }}
          label={t("Daily")}
          {...a11yProps(1)}
        />
        <Tab
          style={{ alignItems: "left", justifyContent: "left" }}
          label={t("Weekly")}
          {...a11yProps(2)}
        />
        <Tab
          style={{ alignItems: "left", justifyContent: "left" }}
          label={t("Monthly")}
          {...a11yProps(3)}
        />
        <Tab
          style={{ alignItems: "left", justifyContent: "left" }}
          label={t("Yearly")}
          {...a11yProps(4)}
        />
      </Tabs>
      <TabPanel item xs={9} value={value} index={0}>
        <Grid container className={classes.hourly}>
          <Box mt={1.9} mr={1}>
            <Typography variant="subtitle2">{t("Every")}</Typography>
          </Box>
          <FormControl item xs={6} className={classes.formControl}>
            <NativeSelect
              defaultValue="1"
              {...register("period")}
              className={classes.selectEmpty}
              inputProps={{ "aria-label": "age" }}
            >
              <option value="" disabled>
                {t("Choose frequency")}
              </option>
              {hours
                && hours.map((row, index) => (
                  <option key={index} value={row}>
                    {row}
                    {" "}
                  </option>
                ))}
            </NativeSelect>
            {errors?.period && (
              <Typography color="error">
                {t("Your input is required")}
              </Typography>
            )}
          </FormControl>

          <Box mt={1.9}>
            <Typography variant="subtitle2">{t("Hour(s)")}</Typography>
            {" "}
          </Box>
        </Grid>
      </TabPanel>
      <TabPanel item xs={9} value={value} index={1}>
        <Grid container className={classes.daily}>
          <Box mt={1.9} mr={1}>
            <Typography variant="subtitle2">{t("Every")}</Typography>
          </Box>
          <FormControl item xs={6} className={classes.formControl}>
            <NativeSelect
              defaultValue="1"
              {...register("period")}
              className={classes.selectEmpty}
              inputProps={{ "aria-label": "age" }}
            >
              <option value="" disabled>
                {t("Choose frequency")}
              </option>
              {days
                && days.map((row, index) => (
                  <option key={index} value={row}>
                    {row}
                    {" "}
                  </option>
                ))}
            </NativeSelect>
            {errors?.period && (
              <Typography color="error">
                {t("Your input is required")}
              </Typography>
            )}
          </FormControl>
          <Box mt={1.9} ml={1}>
            {" "}
            <Typography variant="subtitle2">
              {" "}
              {t("Day(s)")}
            </Typography>
          </Box>
        </Grid>
      </TabPanel>
      <TabPanel item xs={9} value={value} index={2}>
        <Grid container className={classes.weekly}>
          <Box mt={1.9} mr={1}>
            <Typography variant="subtitle2">{t("Every")}</Typography>
          </Box>
          <FormControl item xs={6} className={classes.formControl}>
            <NativeSelect
              defaultValue="1"
              {...register("period")}
              className={classes.selectEmpty}
              inputProps={{ "aria-label": "age" }}
            >
              <option value="" disabled>
                {t("Choose frequency")}
              </option>
              {weeks
                && weeks.map((row, index) => (
                  <option key={index} value={row}>
                    {row}
                    {" "}
                  </option>
                ))}
            </NativeSelect>
            {errors?.period && (
              <Typography color="error">
                {t("Your input is required")}
              </Typography>
            )}
          </FormControl>
          <Box item xs={3} mt={1.9} ml={1}>
            <Typography variant="subtitle2" className={classes.weeklySchedule}>
              {t("Week(s)") + makeDay(startDate, t, true)}
            </Typography>
          </Box>
        </Grid>
      </TabPanel>
      <TabPanel item xs={9} value={value} index={3}>
        <Grid container className={classes.monthly}>
          <Box mt={1.9} mr={1}>
            <Typography variant="subtitle2">{t("Every")}</Typography>
          </Box>
          <FormControl item xs={6} className={classes.formControl}>
            <NativeSelect
              className={classes.selectEmpty}
              defaultValue="1"
              {...register("period")}
            >
              <option value="" disabled>
                {t("Choose frequency")}
              </option>
              {months
                && months.map((row, index) => (
                  <option key={index} value={row}>
                    {row}
                    {" "}
                  </option>
                ))}
            </NativeSelect>
            {errors?.period && (
              <Typography color="error">
                {t("Your input is required")}
              </Typography>
            )}
          </FormControl>
          <Box mt={1.9} ml={1}>
            <Typography variant="subtitle2">{t("Month(s)") + makeDay(startDate, t)}</Typography>
            {" "}
          </Box>
        </Grid>
      </TabPanel>
      <TabPanel item xs={9} value={value} index={4}>
        <Grid container className={classes.yearly}>
          <Box mt={1.9} mr={1}>
            <Typography variant="subtitle2">{t("Every")}</Typography>
          </Box>
          <FormControl item xs={6} className={classes.formControl}>
            <NativeSelect
              className={classes.selectEmpty}
              defaultValue="1"
              {...register("period")}
            >
              <option value="" disabled>
                {t("Choose frequency")}
              </option>
              {years
                && years.map((row, index) => (
                  <option key={index} value={row}>
                    {row}
                    {" "}
                  </option>
                ))}
            </NativeSelect>
            {errors?.period && (
              <Typography color="error">
                {t("Your input is required")}
              </Typography>
            )}
          </FormControl>
          <Box mt={1.9} ml={1}>
            {" "}
            <Typography variant="subtitle2">{t("Year(s)")}</Typography>
          </Box>
        </Grid>
      </TabPanel>
    </Grid>
  );
}

const hours = range(1, 24);
const days = range(1, 31);
const weeks = range(1, 52);
const months = range(1, 12);
const years = range(1, 11);
