import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  pageHeaderContainer: {
    padding: theme.spacing(2, 0),
    paddingBottom: 0
  },
  pageHeaderTitle: {
    fontWeight: 700,
  }
}));
