import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  chip: {
    borderRadius: "4px",
    marginRight: theme.spacing(1),
    fontSize: 10
  },
  paper: {
    width: "500px",
    height: "500px",
  },
}));
