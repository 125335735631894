import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Typography from "@material-ui/core/Typography";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { fetchRobotList } from "../../redux/actions/services";
import { useDispatch } from "react-redux";
import MuiDialogActions from "@material-ui/core/DialogActions";
import CustomDialog from "pages/Services/components/CustomDialog";
import CustomButton from "../CustomButton";
import useStyles from "../../pages/Administration/QueueManagement/QueueForm/style";

function ChooseRobot({
  openChooseRobotDialog, setOpenChooseRobotDialog, orchId, setRobot
}) {
  const [robotRequired, setRobotRequired] = useState(false);
  const { t } = useTranslation();
  const [selectedRobot, setSelectedRobot] = React.useState(null);
  const [robots, setRobots] = useState([]);
  const classes = useStyles();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchRobotList({ orchestrator: orchId })).then((res) => {
      setRobots(res.data.content)
    })
  }, [orchId])

  const pickRobot = () => {
    if (!selectedRobot) {
      setRobotRequired(true);
      return;
    }
    setRobot(selectedRobot)
  }

  return (
    <CustomDialog open={openChooseRobotDialog} maxWidth="xs">
      <DialogContent>
        <DialogContentText>
          <Grid container xs={12} justify="center">
            <Grid item xs={10}>
              {t("queue.management.choose.robot")}
            </Grid>
            <Grid item xs={10}>
              <Select
                fullWidth
                value={selectedRobot}
                onChange={(e) => {
                  setRobotRequired(false)
                  setSelectedRobot(e.target.value)
                }}
              >
                {
                  robots?.map(({ id, robotDisplayName }) => <MenuItem value={id}>{robotDisplayName}</MenuItem>)
                }
              </Select>
            </Grid>
            {
            robotRequired
            && <Grid item xs={10}><Typography color="error">{t("Field must not be empty")}</Typography></Grid>
            }

          </Grid>
        </DialogContentText>
        <MuiDialogActions>
          <Grid container direction="row-reverse" spacing={1}>
            <Grid item>
              <CustomButton
                ml="1rem"
                view="primary"
                size="medium"
                onClick={pickRobot}
              >
                {t("fleet.button.save")}
              </CustomButton>
            </Grid>
            <Grid item>
              <CustomButton
                view="cancel"
                ml="1rem"
                size="medium"
                className={classes.deleteButton}
                onClick={() => setOpenChooseRobotDialog(false)}
              >
                {t("fleet.button.cancel")}
              </CustomButton>
            </Grid>
          </Grid>

        </MuiDialogActions>
      </DialogContent>
    </CustomDialog>
  );
}

export default ChooseRobot
