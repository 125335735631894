import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    customButton: {
        transition: "0.3s",
        color: theme.custom.color.button.textLight,
        fontWeight: 600,
        fontSize: "0.8rem",
        paddingInline: "20px",
        "&:hover": {
            boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.1), 0px 4px 5px 0px rgba(0,0,0,0.07), 0px 1px 10px 0px rgba(0,0,0,0.06)"
        }
    },
    button: {
        fontWeight: 700,
        fontSize: "0.8rem"
    },
    primary: {
        backgroundColor: theme.custom.color.button.primary,
        "&:hover": {
            backgroundColor: theme.custom.color.button.primaryHover,
        }
    },
    secondary: {
        backgroundColor: theme.custom.color.button.secondary,
        "&:hover": {
            backgroundColor: theme.custom.color.button.secondaryHover,
        }
    },
    tertiary: {
        backgroundColor: theme.custom.color.button.tertiary,
        "&:hover": {
            backgroundColor: theme.custom.color.button.tertiaryHover,
        }
    },
    cancel: {
        backgroundColor: theme.custom.color.button.cancel,
        color: theme.custom.color.button.textDark,
        "&:hover": {
            backgroundColor: theme.custom.color.button.cancelHover,
        }
    },
    disabled: {
        backgroundColor: theme.custom.color.button.disabled,
        color: theme.custom.color.button.disabledText,
    },
}));
