import React from "react"
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useTranslation } from "react-i18next";
import isArray from "lodash/isArray";
import CustomButton from "../CustomButton";

const StyledDialog = withStyles(() => ({
    root: {},
    paper: {
        borderRadius: 0,
        padding: 24,
    }
}))(Dialog);

const StyledDialogTitle = withStyles((theme) => ({
    root: {
        color: theme.custom.color.dialog.title,
        fontSize: "1.2rem",
        fontWeight: 700,
        padding: 0,
        paddingBottom: 16,
    }
}))(DialogTitle);

const StyledDialogContent = withStyles(() => ({
    root: {
        padding: 0,
        overflow: "hidden",
        "&:first-child ": {
            paddingTop: 0
        }
    }
}))(DialogContent);

const StyledDialogContentText = withStyles((theme) => ({
    root: {
        color: theme.custom.color.dialog.contentText,
        fontSize: "1rem",
        fontWeight: 600,
        paddingBottom: 16,
        margin: 0
    }
}))(DialogContentText);

const StyledDialogActions = withStyles(() => ({
    root: {
        padding: 0,
        paddingTop: 16
    }
}))(DialogActions);

export default function CustomDialog({
 title,
 contentText,
 actionElements,
 children,
 ...rest
}) {
    const { t } = useTranslation()
    return (
      <StyledDialog aria-describedby="dialog-description" {...rest}>
        {title && (
        <StyledDialogTitle disableTypography>
          {title}
        </StyledDialogTitle>
            )}
        {contentText && (
        <StyledDialogContentText id="dialog-description">
          {contentText}
        </StyledDialogContentText>
            )}
        {children && (
        <StyledDialogContent>
          {children}
        </StyledDialogContent>
            )}
        {actionElements && (
        <StyledDialogActions>
          { isArray(actionElements) ? actionElements?.map(({
 label, view, onClick, className, variant, isLoading, hidden, ...rest
}) => (
                        hidden ? null : (
                          <CustomButton
                                view={view}
                                variant={variant}
                                onClick={onClick}
                                className={className}
                                {...rest}
                            >
                            {isLoading && <CircularProgress size={14} />}
                            {t(label)}
                          </CustomButton>
                        )
                    )) : (
                        actionElements
                    )}
        </StyledDialogActions>
            )}
      </StyledDialog>
    )
}
