import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  root: {
    width: "100%",
    background: "transparent",
    // margin: -theme.spacing(3),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
  paper: {
    width: "100%",
    padding: theme.spacing(0),
  },
  avatar: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    color: theme.palette.getContrastText(theme.palette.primary.main),
    backgroundColor: theme.palette.primary.main,
  },
  avatarIcon: {
    width: "65%",
    height: "65%",
  },
  buttonContainer: {
    padding: theme.spacing(1, 0),
  },
  inputsSection: {
    padding: theme.spacing(1, 2),
  },
  select: {
    width: "100%",
  },
  cardActions: {
    justifyContent: "center",
    marginTop: theme.spacing(2),
    padding: theme.spacing(1),
  },
  validation_error: {
    color: "red",
    margin: "0",
    marginTop: theme.spacing(1),
    position: "absolute",
  },
  fullWidth: {
    "&.MuiFormControl-root": {
      width: "100%",
    },
  },
  customBadge: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: "50%",
    height: "30px",
    width: "30px",
  },
  large: {
    width: theme.spacing(8),
    height: theme.spacing(8),
  },
  formRow: {
    paddingBottom: theme.spacing(2),
  },
  formSection: {
    padding: theme.spacing(1),
  },
  backButton: {
    marginLeft: theme.spacing(1),
  },
  nopadding: {
    padding: 0,
  },
  stepper: {
    "& .MuiStepIcon-active": {
      color: "#27ae60 !important",
    },
    "& .MuiStepIcon-completed": {
      color: "#27ae60 !important",
    },
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(4),
  },
  paddingRight: {
    paddingRight: theme.spacing(2),
  },
  inputsContainer: {
    height: "430px",
    display: "flex",
    alignContent: "flex-start",
    overflow: "auto",
    "& .Mui-disabled": {
      color: "#0000008a",
      "&:before": {
        // borderBottomStyle: "solid",
      }

    },
    "& .MuiFormLabel-root.Mui-disabled": {
      color: "#0000008a"
    },
    "& .MuiIconButton-root.Mui-disabled": {
      color: "#8c8c8c"
    },
    "& .MuiSelect-icon.Mui-disabled": {
      color: "#8c8c8c"
    }
  },
  actionButtons: {
    padding: theme.spacing(1.5),
  },
  avatarContainer: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(3),
  },
  modalActionButtons: {
    padding: 0,
    paddingTop: theme.spacing(2),
  },
  formContainer: {
    padding: theme.spacing(2),
  },
  closebutton: {
    marginLeft: "auto",
  },
  closeIcon: {
    padding: theme.spacing(1),
    margin: theme.spacing(0.5),
  },
  boxTitle: {
    paddingLeft: theme.spacing(4),
    paddingTop: theme.spacing(2)
  },
  editContainer: {
    marginBottom: theme.spacing(1.5),
  },
  pramFleet__formRow: {
    display: "flex",
    alignItems: "center",
    padding: "8px 0px 8px 0px"
  },
  resetButton: {
    marginLeft: theme.spacing(1)
  },
  button: {
      margin: theme.spacing(0),
      padding: theme.spacing(0),
  },
  cancelButton: {
      paddingBottom: theme.spacing(0),
      paddingTop: theme.spacing(0),
      backgroundColor: theme.custom.color.color4,
      color: "white"

  },
  saveButton: {
      paddingBottom: theme.spacing(0),
      paddingTop: theme.spacing(0),
      backgroundColor: theme.custom.color.color1,
      color: "white",

  },

  dialogContent: {
      marginTop: theme.spacing(1)
  },

  deleteButton: {
      color: "white",
      background: theme.palette.error.main,
  }
}));
