import React from "react";
import FormControl from "@material-ui/core/FormControl"
import Grid from "@material-ui/core/Grid"
import IconButton from "@material-ui/core/IconButton"
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import Select from "@material-ui/core/Select"
import TextField from "@material-ui/core/TextField"
import { useTranslation } from "react-i18next";
import Add from "@material-ui/icons/Add";
import Box from "@material-ui/core/Box";
import { Delete as DeleteIcon } from "@material-ui/icons";
import { useForm } from "react-hook-form";
import { kpiTypes } from "util";
import CustomDialog from "../../../../../components/CustomDialog";
import useStyles from "../../../style.js";

const forEachValues = [
  { key: "custom.kpi.item", value: "item" },
  { key: "custom.kpi.processExecution", value: "processExecution" },
  { key: "custom.kpi.process", value: "process" },
];

const detailValues = [
  { key: "custom.kpi.pending", value: "pending", uses: ["item", "processExecution"] },
  { key: "custom.kpi.completed", value: "completed", uses: ["item", "processExecution"] },
  { key: "custom.kpi.completedWithException", value: "completedWithException", uses: ["item", "processExecution"] },
  { key: "custom.kpi.completedWithBusinessException", value: "completedWithBusinessException", uses: ["item"] },
  { key: "custom.kpi.completedWithIntSysException", value: "completedWithIntSysException", uses: ["item"] },
  { key: "custom.kpi.executionTimeHours", value: "executionTimeHours", uses: ["process", "item", "processExecution"] },
  { key: "custom.kpi.timeSavedHours", value: "timeSavedHours", uses: ["process", "item", "processExecution"] },
];

function CreateKpiDialog({
  open, onClose, handleCreateOrUpdateCustomKpi, editedCustomKpi, customKpiDialogMode,
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [kpiName, setKpiName] = React.useState(customKpiDialogMode === "edit" ? editedCustomKpi?.name : "");
  const [kpiType, setKpiType] = React.useState(editedCustomKpi?.kpiType ?? null);
  const [errorHelperText, setErrorHelperText] = React.useState("");
  const [uniteOfMeasure, setUniteOfMeasure] = React.useState(editedCustomKpi?.customKpiDetails[0].unitOfMeasure || "");
  const [uniteOfMeasureError, setUniteOfMeasureError] = React.useState("");
  const [kpis, setKpis] = React.useState(
    customKpiDialogMode === "edit"
        ? editedCustomKpi?.customKpiDetails?.map((kpi) => ({ ...kpi }))
      : [
          {
            element: "",
            detail: "",
            allocation: "",
            unitOfMeasure: "",
          },
        ]
  );

  const {
    formState: { errors }, register, handleSubmit, reset,
  } = useForm();

  const onSave = () => {
    if (uniteOfMeasure.length === 0) {
      setUniteOfMeasureError("Required field");
    }
    if (kpiName.length === 0) {
      setErrorHelperText("Required field");
    }
    handleSubmit(onSubmit)();
    };

  const onSubmit = () => {
    if (kpiName.length === 0 || uniteOfMeasure.length === 0) {
      return
    }
    const kpiData = {
      name: kpiName,
      kpiType,
      id: customKpiDialogMode === "edit" ? editedCustomKpi?.id : null,
      customKpiDetails: kpis?.map((kpi) => ({
        id: kpi?.id || null,
        element: kpi.element,
        detail: kpi.detail,
        allocation: kpi.allocation,
        unitOfMeasure: uniteOfMeasure,
      })),
    }
    handleCreateOrUpdateCustomKpi(kpiData);
    setErrorHelperText("")
    setKpiName("")
    setUniteOfMeasure(uniteOfMeasure || "")
    setKpis(customKpiDialogMode === "edit" ? kpis : [{
      element: "",
      detail: "",
      allocation: "",
      unitOfMeasure: "",
    }])
    reset();
    onClose();
  }

  const onTextChange = (event, label, index) => {
    const updatedKpis = [...kpis];
    updatedKpis[index][label] = event.target.value;
    setKpis(updatedKpis);
    reset();
    if (event.currentTarget.value.length !== 0) setErrorHelperText("")
  }

  const onKpiRemove = (index) => {
    const updatedKpis = [...kpis];
    updatedKpis.splice(index, 1);
    reset();
    setKpis(updatedKpis);
  }

  const onKpiNameChange = (event) => {
    setKpiName(event.currentTarget.value);
    if (event.currentTarget.value.length !== 0) setErrorHelperText("")
  }

  const handleAllocationChange = (event, index) => {
    const newValue = event.target.value;
    // Will update text only if it passes regex test
    if (/^[0-9]*$/.test(newValue)) {
      onTextChange(event, "allocation", index);
    }
  };

  React.useEffect(() => {
    if (customKpiDialogMode === "edit" && editedCustomKpi) {
      setKpiName(editedCustomKpi.name);
      setKpis(editedCustomKpi.customKpiDetails.map((kpi) => ({ ...kpi })));
    }
  }, [customKpiDialogMode, editedCustomKpi, open]);

  const handleAddClick = () => {
    setKpis([...kpis, {
        element: "",
        detail: "",
        allocation: "",
        unitOfMeasure: "",
    }])
  };

  const handleCancelClick = () => {
    setErrorHelperText("")
    setKpiType(null)
    setKpiName(editedCustomKpi?.name || "")
    setUniteOfMeasure(editedCustomKpi?.customKpiDetails[0].unitOfMeasure || "")
    setKpis(editedCustomKpi?.customKpiDetails || [{
      element: "",
      detail: "",
      allocation: "",
      unitOfMeasure: "",
    }])
    reset();
    onClose();
  }

  return (
    <CustomDialog
      title={customKpiDialogMode === "create" ? t("custom.kpi.dialog.create.label") : t("custom.kpi.dialog.create.label.edit")}
      actionElements={[
        {
          label: t("Cancel"),
          onClick: handleCancelClick,
          color: "primary",
        },
        {
          label: customKpiDialogMode === "create" ? t("custom.kpi.dialog.create.field.submit") : t("custom.kpi.dialog.create.field.edit"),
          onClick: onSave,
          view: "primary",
        }
      ]}
      open={open}
      onClose={handleCancelClick}
      disableBackdropClick
      fullWidth
      maxWidth={900}
      className={classes.createKpiDialog}
    >
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <TextField
              error={errorHelperText.length !== 0}
              label={t("custom.kpi.dialog.field.name")}
              fullWidth
              onChange={(event) => onKpiNameChange(event)}
              required
              helperText={errorHelperText}
              defaultValue={kpiName}
              className={classes.kpiNameTextField}
          />
        </Grid>
        <Box component={Grid} item xs={6}>
          <FormControl fullWidth>
            <InputLabel id="for-each-select-label">{`${t("custom.kpi.dialog.field.type")}`}</InputLabel>
            <Select
                labelId="for-each-select-label"
                id="kpiType"
                value={kpiType}
                onChange={(e) => setKpiType(e?.target?.value)}
            >
              {Object.keys(kpiTypes)?.map((key) => (
                <MenuItem value={kpiTypes?.[key]?.code}>{t(kpiTypes?.[key]?.code)}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Grid>
      {kpis?.map((kpi, index) => (
        <Grid container spacing={3}>
          <Grid item xs={2}>
            <FormControl fullWidth>
              <InputLabel id="for-each-select-label">{`${t("custom.kpi.dialog.field.element")} *`}</InputLabel>
              <Select
                    {...register(`kpis.${index}.element`, {
                        required: {
                        value: true,
                        message: "custom.kpi.formControl.required",
                        },
                    })}
                    labelId="for-each-select-label"
                    id="element"
                    value={kpi.element}
                    onChange={(event) => onTextChange(event, "element", index)}
                    error={!!errors?.kpis?.[index]?.element}
                >
                {forEachValues?.map((item) => (
                  <MenuItem value={item.value}>{t(item.key)}</MenuItem>
                  ))}
              </Select>
              <p className={classes.validation_error}>
                {t(errors?.kpis?.[index]?.element?.message)}
              </p>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl fullWidth>
              <InputLabel id="detail-select-label">{`${t("custom.kpi.dialog.field.detail")} *`}</InputLabel>
              <Select
                    {...register(`kpis.${index}.detail`, {
                        required: {
                        value: true,
                        message: "custom.kpi.formControl.required",
                    },
                    })}
                    labelId="detail-select-label"
                    id="detail"
                    value={kpi.detail}
                    onChange={(event) => onTextChange(event, "detail", index)}
                    error={!!errors?.kpis?.[index]?.detail}
                    disabled={kpi.element === ""}
                >
                {detailValues?.map((detail) => (
                    detail.uses.includes(kpi.element) && (<MenuItem value={detail.value}>{t(detail.key)}</MenuItem>)
                  ))}
              </Select>
              <p className={classes.validation_error}>
                {t(errors?.kpis?.[index]?.detail?.message)}
              </p>
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <TextField
                {...register(`kpis.${index}.allocation`, {
                    required: {
                    value: true,
                    message: "custom.kpi.formControl.required",
                },
                pattern: {
                    value: /^[0-9]*$/,
                    message: "custom.kpi.formControl.pattern",
                },
                })}
                value={kpi.allocation}
                id="allocation"
                label={t("custom.kpi.dialog.field.allocation")}
                fullWidth
                onChange={(event) => handleAllocationChange(event, index)}
                required
                defaultValue={kpi.allocation}
                error={!!errors?.kpis?.[index]?.allocation}
              />
            <p className={classes.validation_error}>
              {t(errors?.kpis?.[index]?.allocation?.message)}
            </p>
          </Grid>
          <Grid item xs={3}>
            <TextField
                id="unitOfMeasure"
                disabled={index > 0}
                label={t("custom.kpi.dialog.field.unitofmesure")}
                fullWidth
                onChange={(event) => {
                  setUniteOfMeasure(event.target.value)
                  if (event.target.value !== "") setUniteOfMeasureError("")
                }}
                required
                value={uniteOfMeasure}
                error={uniteOfMeasureError !== ""}
              />
            <p className={classes.validation_error}>
              {t(uniteOfMeasureError)}
            </p>
          </Grid>
          <Grid item xs={1}>
            <IconButton
                onClick={() => {
                  onKpiRemove(index) }}
                disabled={kpis.length === 1}>
              <DeleteIcon
                    color={kpis.length !== 1
                        ? "error"
                        : "disabled"}
                />
            </IconButton>
          </Grid>
          {kpis.length === index + 1 && (
            <Grid item xs={1}>
              <IconButton onClick={handleAddClick} className={classes.addKpiButton}>
                <Add />
              </IconButton>
            </Grid>)}
        </Grid>
        ))}
    </CustomDialog>
  );
}

export default (CreateKpiDialog);
