import React, { useState } from "react";
import CustomDialog from "../CustomDialog";
import { Grid, Paper } from "@material-ui/core";
import CustomCloseButton from "../CustomCloseButton";
import { useTranslation } from "react-i18next";
import useStyles from "./style";
import Typography from "@material-ui/core/Typography";
import DialogContent from "@material-ui/core/DialogContent";
import ProcessInputField from "../ProcessInputField";
import { FormProvider, useForm } from "react-hook-form";
import isUndefined from "lodash/isUndefined";
import isNull from "lodash/isNull";
import { isEmpty } from "lodash";
import CircularProgress from "@material-ui/core/CircularProgress";
import HighlightOff from "@material-ui/icons/HighlightOff";
import CustomButton from "../../../../components/CustomButton";
import processInputTypes from "../../../../util/constants/processInputTypes";
import { format } from "date-fns";
import ConfirmMessage from "../../../../components/ConfirmMessage";

function InputsDialog({
  openStart,
  onConfirm,
  onClose,
  inputs,
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const form = useForm();
  const [saveLoading, setSaveLoading] = useState(false);
  const [openConfirmWithBlankValues, setOpenConfirmWithBlankValues] = useState(false);

  const isFieldFull = (fieldKey) => !(isUndefined(form.watch(fieldKey)) || isNull(form.watch(fieldKey)) || isEmpty(form.watch(fieldKey)))
  const onError = () => form.errors[Object.keys(form.errors)[0]]?.ref?.focus
  const handleReset = () => form.reset();
  const onSubmit = (data) => {
    setSaveLoading(true);
    inputs.forEach((input) => {
      switch (input.type) {
        case processInputTypes.FLAG:
          if (data[input.name]) input.value = "True";
          else input.value = "False";
          break;
        case processInputTypes.TIME:
          if (!isUndefined(data[input.name])) input.value = format(data[input.name], "HH:mm:ss");
          else input.value = "";
          break;
        case processInputTypes.TEXT:
        case processInputTypes.NUMBER:
        case processInputTypes.DATE:
        case processInputTypes.DATETIME:
        case processInputTypes.TIMESPAN:
          if (!isUndefined(data[input.name])) input.value = data[input.name];
          else input.value = "";
          break;
        default: break;
      }
    })
    inputs.forEach((input) => {
      if (isEmpty(input.value)) {
        setOpenConfirmWithBlankValues(true);
      }
    });
  };
  const handleCancelInputSave = () => {
    setOpenConfirmWithBlankValues(false);
    setSaveLoading(false);
  }
  const handleConfirmInputSave = () => {
    setOpenConfirmWithBlankValues(false);
    onConfirm(inputs);
    setSaveLoading(false);
  }

  return (
    <CustomDialog
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      maxWidth="sm"
      fullWidth
      open={openStart}
      disableBackdropClick
      className={{ overflow: "unset" }}
      PaperProps={{
        square: true
      }}
      >
      <Paper square>
        <Grid container direction="row">
          <Grid container direction="row" xs={11} alignItems="center">
            <Typography className={classes.title} variant="h6">{t("process.start.require.input")}</Typography>
          </Grid>
          <Grid container direction="row" xs={1} justifyContent="flex-end">
            <CustomCloseButton
              aria-label="close"
              className={classes.closeButton}
              onClick={onClose}
            />
          </Grid>
        </Grid>
      </Paper>
      <DialogContent classes={{ root: classes.dialogContent }}>
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(onSubmit, onError)}>
            <div className={classes.contentContainer}>
              {inputs?.map((i) => (
                <ProcessInputField
                  error={!!form.errors?.[i.name]}
                  helperText={
                    form.errors?.[i.name]
                    && form.errors?.[i.name]?.message
                  }
                  InputLabelProps={{
                    shrink: isFieldFull(i.name),
                  }}
                  key={i.id}
                  name={i.name}
                  type={i.type}
                  value={form.getValues(i.name)}
                />))}
            </div>
            <Grid container direction="row-reverse" spacing={1}>
              <Grid item>
                <CustomButton
                  ml="1rem"
                  view="primary"
                  size="large"
                  type="submit"
                  startIcon={saveLoading && <CircularProgress size={20} />}
                  disabled={saveLoading}
                >
                  {t("process.inputs.button.save")}
                </CustomButton>
              </Grid>
              <Grid item>
                <CustomButton
                  view="cancel"
                  ml="1rem"
                  size="large"
                  startIcon={<HighlightOff />}
                  className={classes.deleteButton}
                  disabled={saveLoading}
                  onClick={handleReset}
                >
                  {t("process.inputs.button.reset")}
                </CustomButton>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </DialogContent>
      <ConfirmMessage
        title={t("process.inputs.save.blank.values.title")}
        message={t("process.inputs.save.blank.values.message")}
        openStart={openConfirmWithBlankValues}
        onCancel={handleCancelInputSave}
        onConfirm={handleConfirmInputSave}
        buttonConfirm={t("Discard")}
        buttonCancel={t("Cancel")}
      />
    </CustomDialog>
  )
}

export default InputsDialog;
