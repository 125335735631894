import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import VpnKeyRoundedIcon from "@material-ui/icons/VpnKeyRounded";
import IconButton from "@material-ui/core/IconButton";
import { useTranslation } from "react-i18next";
import {
  formatDateByLanguage,
  secondsToTime,
} from "util";
import CustomTableCell from "../../../../../components/TableComponents/CustomTableCell";
import AttemptChip from "../../../../../components/AttemptChip";
import DetailsIcon from "../../../../../components/CustomIcons/DetailsIcon";
import ProcessIconName from "../../../../../components/TableComponents/ProcessIconName";
import useStyles from "../../style";

const WorkqueueitemRow = ({
  row,
  handleOpenOccurenceDetails,
  isPendingItemsPage,
  maxDetailsCount = 0
}) => {
  const { t } = useTranslation()
  const classes = useStyles();
  const getDateDifference = (s) => secondsToTime(s, t);
  const processDetailsObject = (parsedData) => parsedData?.filter(({ displayInItemsList }) => displayInItemsList)?.slice(0, 3);
  const getEndDate = (row) => {
    if (row?.completedDatetime) return formatDateByLanguage(row?.completedDatetime)

      if (row?.exceptionTime) return formatDateByLanguage(row?.exceptionTime)
      return "---"
}

    return (
      <>
        <CustomTableCell verticalAlign="middle" width={300}>
          <ProcessIconName
              imgSrc={row?.processExecution?.process?.icon}
              avatarName={row.processExecution?.process?.processDescription?.processDisplayName}
              processName={row?.processExecution?.process?.processDescription?.processDisplayName}
          />
        </CustomTableCell>
        {row?.parsedData && processDetailsObject(row.parsedData)?.map((parsed, i) => (
          <CustomTableCell title={parsed?.name} text={parsed?.value || "---"} key={i} />
        ))}
        {maxDetailsCount - processDetailsObject(row.parsedData).length > 0 && Array(maxDetailsCount - processDetailsObject(row.parsedData).length)?.fill("")?.map((item, index) => (
          <CustomTableCell key={index} />
            ))}
        <CustomTableCell title={t("Creation date")} text={row?.loadedDatetime ? formatDateByLanguage(row?.loadedDatetime) : "---"} />
        {!isPendingItemsPage && (
        <CustomTableCell title={t("End date")} text={getEndDate(row)} />
      )}
        {!isPendingItemsPage && (<CustomTableCell title={t("Execution Time")} text={getDateDifference(row?.worktime)} />)}
        <CustomTableCell verticalAlign="middle" width={140} align="right">
          <AttemptChip attempt={row?.attempt} />
          {isPendingItemsPage && row?.isLocked && (
          <Tooltip title={t("items.pending.locked")}>
            <IconButton
                         edge="end"
                         aria-label="view"
                         size="small"
                         className={classes.lockIcon}
                      >
              <VpnKeyRoundedIcon fontSize="small" />
            </IconButton>
          </Tooltip>
              )}
          {row?.data && (
          <DetailsIcon
                      tooltipTitle={t("Execution detail")}
                      onClickIcon={() => handleOpenOccurenceDetails(row)}
                  />
              )}
        </CustomTableCell>
      </>
  );
};

export default WorkqueueitemRow;
