import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import CustomTableRow from "../../../components/TableComponents/CustomTableRow";
import CustomTableCell from "../../../components/TableComponents/CustomTableCell";
import AvatarTableCell from "../../../components/TableComponents/AvatarTableCell";
import useStyles from "../style";

export default function Audit({ row }) {
  const classes = useStyles();
  const tableCellProps = {
    paddingTop: 15,
    paddingBottom: 15,
    verticalAlign: "middle",
  };

  return (
    <CustomTableRow>
      <CustomTableCell title={row?.module} {...tableCellProps} />
      <CustomTableCell text={row?.action} {...tableCellProps} />
      <CustomTableCell text={row?.object} {...tableCellProps} />
      <CustomTableCell
        text={
          <Box
            display="grid"
            gridTemplateColumns="32px 1fr"
            alignItems="center"
            gridGap="7px"
            className={classes.userName}
          >
            <AvatarTableCell
              indexRow={0}
              valPath={null}
              valAvatarPath={null}
              indexField={null}
            />
            <Typography component="span">
              {row?.user}
            </Typography>
          </Box>
        }
        {...tableCellProps}
      />
      <CustomTableCell text={row?.timeStamp} {...tableCellProps} />
    </CustomTableRow>
  );
}
