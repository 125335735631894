import { isFleetAdministrator, getUserPermissions } from "util";
import menu from "WrapperContainer/menu";
import routes from "WrapperContainer/routes";

export const getPermissionMenu = (user) => {
  if (!user) {
    return null;
  }

  const isAdminUser = isFleetAdministrator(user);
  const userPermissions = getUserPermissions(user);

  const menuPermissions = userPermissions.find(({ name }) => name === "Smart Robot")?.modules
    .filter(({ enabled }) => enabled).map(({ name }) => name);
  // TODO check this condition: pourquoi l'exception sur la page Administration ?
  const menuItems = menu
    .filter(
      ({
           name, permissionKey, moduleEnabled
      }) => {
          const havePermission = menuPermissions.includes(permissionKey) || menuPermissions.includes(name);
          const haveRobotAdminEnabled = moduleEnabled && menuPermissions.some((name) => isAdminUser || moduleEnabled.includes(name))
          return havePermission || haveRobotAdminEnabled
      }
    );
  return menuItems;
};
export const getPermissionRoute = (user) => {
  if (!user) {
    return null;
  }

  const userPermissions = getUserPermissions(user);
  const enabledModules = userPermissions.find(({ name }) => name === "Smart Robot")?.modules
      .filter(({ enabled }) => enabled);
  const enabledSubModules = [];
  enabledModules.forEach((module) => {
      module.actions.forEach((action) => {
          if (action.enabled) enabledSubModules.push(action.name)
      })
  })
  const menuPermissions = enabledModules.map(({ name, label }) => ({ name, label }));
  return routes
    .filter(
      ({ permissionKey, role, moduleEnabled }) => {
        const havePermission = !permissionKey
            || menuPermissions.some(({ name, label }) => name === permissionKey || label === permissionKey)
            || enabledSubModules.some((name) => name === permissionKey);
        const haveRole = !role || user?.roles?.includes(role);
        const haveRobotAdminEnabled = moduleEnabled && menuPermissions.some(({ name, label }) => moduleEnabled.includes(name) || moduleEnabled.includes(label))
        return havePermission || haveRole || haveRobotAdminEnabled;
      },
    );
};
