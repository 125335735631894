import React from "react";
import PropTypes from "prop-types";

import Box from "@material-ui/core/Box";

export default function SubPagePanel(props) {
  const {
    children, value, index, classes, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`sub-pagepanel-${index}`}
      aria-labelledby={`sub-page-${index}`}
      className={classes}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

SubPagePanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
