import React from "react";
import { useTranslation, withTranslation } from "react-i18next";
import Chip from "@material-ui/core/Chip";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import CustomDialog from "pages/Services/components/CustomDialog";
import PublicIcon from "@material-ui/icons/Public";
import { getTimezoneOffset } from "date-fns-tz";
import useStyles from "./style";

const getLocalizedTimezoneName = (locale, timezone) => {
  const today = new Date();
  const short = today.toLocaleDateString(locale, { timeZone: timezone });
  const full = today.toLocaleDateString(locale, { timeZone: timezone, timeZoneName: "long" });

  const shortIndex = full.indexOf(short);
  const trimmed = full.substring(0, shortIndex) + full.substring(shortIndex + short.length);

  return trimmed.replace(/^[\s,.\-:;]+|[\s,.\-:;]+$/g, "");
};

const formatTimezone = (timezone, offset = null, locale = null, reduced = true) => {
  if (offset === null) {
    offset = getTimezoneOffset(timezone, new Date()) / 3.6e6;
  }
  const offsetLabel = `UTC${offset >= 0 ? "+" : ""}${offset}`;
  if (reduced) return offsetLabel;
  return `${offsetLabel} • ${getLocalizedTimezoneName(locale, timezone)}`;
};

function TimezonePicker({ i18n }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const currentTimezone = localStorage.getItem("sr-tz");
  const [timezones, setTimezones] = React.useState([]);

  React.useEffect(() => {
    if (open) {
      setTimeout(() => {
        const timezones_ = Intl.supportedValuesOf("timeZone").map((t) => ({
          name: t,
          offset: getTimezoneOffset(t, new Date()) / 3.6e6,
        }))
          .map((e) => ({
            ...e,
            label: formatTimezone(e.name, e.offset, i18n.language, false),
          }))
          .sort((a, b) => (a.offset - b.offset) || a.label.localeCompare(b.label));
        setTimezones(timezones_);
      }, 50);
    }
    return () => setTimezones([]);
  }, [open]); // eslint-disable-line react-hooks/exhaustive-deps

  const changeTimezone = (timezone) => {
    localStorage.setItem("sr-tz", timezone);
    setOpen(false);
  };

  return (
    <>
      <Tooltip
        title={currentTimezone}
        placement="bottom"
      >
        <Chip
          label={formatTimezone(currentTimezone)}
          onClick={() => setOpen(true)}
          variant="outlined"
          size="small"
          className={classes.chip}
          color="primary"
          icon={<PublicIcon />}
        />
      </Tooltip>
      <CustomDialog
        open={open}
        onClose={() => setOpen(false)}
        classes={{
          paper: classes.paper,
        }}
      >
        <DialogTitle disableTypography>
          <Typography style={{ fontWeight: "bold" }}>{t("Choose your timezone")}</Typography>
        </DialogTitle>
        <DialogContent style={{ padding: 0 }}>
          <List>
            {timezones.map((timezone, idx) => (
              <ListItem
                button
                onClick={() => changeTimezone(timezone.name)}
                key={idx}
                selected={timezone.name === currentTimezone}
                autoFocus={timezone.name === currentTimezone}
                dense
              >
                <ListItemIcon>
                  <PublicIcon color={timezone.name === currentTimezone ? "primary" : "disabled"} />
                </ListItemIcon>
                <ListItemText primary={timezone.label} secondary={timezone.name.replaceAll("_", " ")} />
              </ListItem>
            ))}
          </List>
        </DialogContent>
      </CustomDialog>
    </>
  );
}

export default withTranslation()(TimezonePicker);
