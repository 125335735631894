import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
// import Paper from "@material-ui/core/Paper";
import { useTranslation } from "react-i18next";
import processNotFound from "../../assets/dataNotFound.svg";
import useStyles from "./style"

const DataNotFound = ({
  backgroundColor, boxShadow, message = "ERRORS_NO_DATA_AVAILABLE", padding = 100, width, height = "100%", iconWidth = 200, iconHeight = 200
}) => {
    const { t } = useTranslation()
    const classes = useStyles()

    return (
      <Grid item xs={12}>
        <div
                style={{
                    height,
                    width,
                    margin: "auto",
                    textAlign: "center",
                    padding: `${padding}px 0px ${padding}px 0px`,
                    backgroundColor,
                    boxShadow
                }}
            >
          <Box>
            <img
                        alt=""
                        src={processNotFound}
                        style={{
                            paddingBottom: 10,
                            width: iconWidth,
                            height: iconHeight,
                        }}
                    />
          </Box>
          <Typography className={classes.errorMessage} variant="h6" align="center">
            {t(message)}
          </Typography>
        </div>
      </Grid>
    )
}

export default DataNotFound;
