import React, { useEffect, useState } from "react";
import ButtonBase from "@material-ui/core/ButtonBase";
import Box from "@material-ui/core/Box";
import Chip from "@material-ui/core/Chip";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MenuItem from "@material-ui/core/MenuItem";
import Tooltip from "@material-ui/core/Tooltip";
import { Clear, Done } from "@material-ui/icons";
import map from "lodash/map";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "@redux-requests/react";
import { fade, useTheme } from "@material-ui/core/styles";
import {
  saveProcessRestriction,
  checkIfPrioriryAlreadyExists,
  fetchProcesses,
  fetchProcessesDetails,
  fetchUnavailablePriorities
} from "../../../../../redux/actions/services";
import {
  formtNumberByLanguage,
  getInitiales,
  minimizeString,
  numberRoiFloatFormat,
  rateFloatFormat,
} from "../../../../../util";
import clsx from "clsx";
import ConfirmMessage from "../../../../../components/ConfirmMessage";
import CustomizedDialogs from "../../ModalService";
import { roiInSufficientData } from "../../../../../WrapperContainer/components/AppBar/components/SRCards";
import { isPermitted } from "../../../../../components/HasPermission";
import PriorityChip from "../components/PriorityChip";
import QuestionMarkIcon from "../../../../../components/QuestionMarkIcon";
import StatusButton from "../../../../../components/StatusButton";
import { FETCH_CURRENT_USER } from "../../../../../redux/constants/index"
import { isSRQueuesDisabled } from "../../../../../util/constants/PermitedFeature";
import CustomTableCell from "../../../../../components/TableComponents/CustomTableCell";
import CustomTableRow from "../../../../../components/TableComponents/CustomTableRow";
import ProcessIconName from "../../../../../components/TableComponents/ProcessIconName";
import CustomSelectField from "../../../../../components/FormFields/CustomSelectField";
import CustomTooltip from "../../../../../components/CustomTooltip";
import { ReactComponent as UiPathLogo } from "../../../../../assets/OrchestratorTypes/UiPath.svg";
import { ReactComponent as BluePrismLogo } from "../../../../../assets/OrchestratorTypes/BluePrism.svg";
import useStyles from "./style.js";

function TableCard(props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { process: _process, statusData } = props;
  const { editId, setEditId } = props;
  const theme = useTheme();
  const currentUser = useQuery({ type: FETCH_CURRENT_USER }).data;
  const [processState, setProcessState] = useState(null);
  const breakEvenContent = ({ breakEvenUnit, breakEvenValue }) => {
    if (breakEvenUnit === undefined || breakEvenUnit === null) {
      return "--";
    }
    return breakEvenValue > 0
      ? `${t("ago", {
        value: numberRoiFloatFormat(breakEvenValue),
        unit: `${t(breakEvenUnit)}`,
      })}`
      : `${t("point.amortissage.negative", {
        value: numberRoiFloatFormat(breakEvenValue),
        unit: `${t(breakEvenUnit)}`,
      })}`;
  };

  const title = _process?.processDisplayName;
  const content = _process?.processDescription;
  const disabled = _process?.disabled;
  const hiddenProcess = _process.isDisabled || !_process?.isOrchestratorEnabled;
  const priority = (_process?.priority === 0) ? "--" : _process?.priority;
  const insuffisantData = roiInSufficientData(_process?.processDetail?.roi);
  const roiValue = rateFloatFormat(_process?.processDetail?.roi);
  const roi = roiValue && roiValue !== "--" && !insuffisantData && !hiddenProcess ? (
    <>
      {roiValue}
      %
    </>
  ) : (
    <>
      --
      <QuestionMarkIcon titleContent={t("kpi.header.roi.tooltip.nodata")} />
    </>
  );

  const breakEvenValue = breakEvenContent(
    _process?.processDetail?.processBreakEven || {},
  );
  const breakEven = breakEvenValue
    && breakEvenValue !== "--"
    && !insuffisantData
    && !hiddenProcess ? (
      breakEvenValue
    ) : (
      <>
        --
        <QuestionMarkIcon
          titleContent={t("kpi.header.breakEven.tooltip.nodata")}
        />
      </>
    );

  const classes = useStyles(props.width)();
  const [open, setOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [value, setValue] = React.useState(0);
  const [openSlide, setOpenSlide] = React.useState(false);
  const [newPriority, setNewPriority] = React.useState(priority);
  const filterProcess = useSelector(({ filterProcess: i }) => i);

  const priorityArray = Array.from({ length: 100 }, (v, k) => k + 1).map(
    String,
  );
  const [availablePriorities, setAvailablePriorities] = React.useState(priorityArray);

  const handleChange = (event) => {
    setNewPriority(event.target.value);
  };

  const handleCloseSlide = () => {
    setOpenSlide(false);
  };

  const closeDialog = () => {
    setOpen(false);
    setEditId(0);
    setProcessState(null)
    dispatch(
      fetchProcesses(
        filterProcess.search,
        filterProcess.fleet,
        filterProcess.division,
        filterProcess.showAll,
        filterProcess.tags,
        filterProcess.pageNo,
        filterProcess.pageSize
      ),
    );
  };
  const openAndResetValue = () => {
    setIsLoading(true)
    dispatch(fetchProcessesDetails(_process?.id)).then((res) => {
      setValue(0);
      setIsLoading(false)
      setProcessState(res.data);
      setOpen(true);
    });
  };
  const changePriority = (reset = true) => {
    const dataToSend = { priority: null };
    if (_process?.priority) dataToSend.priority = parseInt(_process?.priority);
    dataToSend.priority = reset ? null : parseInt(newPriority);
    dispatch(saveProcessRestriction(_process?.id, dataToSend)).then(() => {
      setEditId(0);
      dispatch(
        fetchProcesses(
          filterProcess.search,
          filterProcess.fleet,
          filterProcess.division,
          filterProcess.showAll,
          filterProcess.tags,
          filterProcess.pageNo,
          filterProcess.pageSize
        ),
      );
    });
    if (reset) setNewPriority(priority);
  };

  const updatePriority = () => {
    dispatch(
      checkIfPrioriryAlreadyExists(_process.id, parseInt(newPriority)),
    ).then((result) => {
      if (result?.data === false) {
        changePriority(false);
      } else {
        setOpenSlide(true);
        setNewPriority(priority);
      }
    });
  };
  const disableBgColor = hiddenProcess ? fade(theme.custom.color.chartColor1, 0.25) : false;

  const onPriorityClick = () => {
    if (isPermitted(currentUser, "SLA")) {
      setEditId(_process.id);
      dispatch(fetchUnavailablePriorities(_process.id)).then((result) => {
        setAvailablePriorities(priorityArray.filter((p) => !result?.data.includes(Number(p))));
      })
    }
  };

  const cellProps = {
    verticalAlign: "middle",
    paddingTop: 11,
    paddingBottom: 11
  }
  const getTagLabel = () => {
    const firstTagName = _process?.tags?.[0]?.name
    if (_process.tags && _process.tags.length > 1) {
       const result = map(_process?.tags, "name").reduce((accumulator, currentTag, index) => {
           const combinedLength = accumulator.length + currentTag.length + 2;
       if (index === 0 || combinedLength <= props.width) {
      return index === 0 ? currentTag : `${accumulator}, ${currentTag}`;
    }
      return accumulator;
  }, firstTagName);
      return `${minimizeString(result, props.width + 4)} (+${_process.tags.length - result.split(", ").length})`
    }
    return firstTagName
  }

  useEffect(() => {
    if (editId === _process.id) {
      setOpen(false);
      setNewPriority(priority);
    }
  }, [editId]);

  useEffect(() => {
    setEditId(0);
  }, [filterProcess.showAll]);

  return (
    <>
      <CustomTableRow
            backgroundColor={disableBgColor}
            onClick={() => editId !== _process.id && openAndResetValue()}
            disabled={hiddenProcess}
            key={`${_process.id}-${hiddenProcess}`}
      >
        <CustomTableCell className={(!isSRQueuesDisabled && _process?.queueId) ? classes.cardRootSR : classes.cardRoot} {...cellProps}>
          <Box component="span" display="grid" gridTemplateColumns={`1FR 50px ${(props.width + 9) * 7}px`} gridColumnGap={5} alignItems="center" justifyContent="space-between">
            <ProcessIconName
                  imgSrc={_process?.icon}
                  avatarName={getInitiales(title)}
                  processName={title}
                  disabled={hiddenProcess}
              />
            <Tooltip title={_process?.orchestratorType} placement="top">
              <IconButton>
                {_process?.orchestratorType === "blueprism" ? <BluePrismLogo /> : <UiPathLogo />}
              </IconButton>
            </Tooltip>
            {_process?.tags?.length > 0 && (
              <CustomTooltip
                      title={(
                        <Box width="fit-content">
                          <Typography color="primary" className={classes.tagTooltipTitle}>{t("Tags Assigned")}</Typography>
                          {_process?.tags?.map(({ id, name }) => (
                            <Chip
                                    key={id}
                                    label={name}
                                    className={classes.tagChip}
                                    classes={{
                                      label: classes.tagChipLabel
                                    }}
                                />
                            ))}
                        </Box>
                      )}
                      placement="top-start"
                      arrow
                  >
                <ButtonBase component={Box} disableRipple>
                  <Chip
                          label={getTagLabel()}
                          className={clsx(classes.tagChip, classes.tagChipWidth)}
                          classes={{
                            label: classes.tagChipLabel
                }}
              />
                </ButtonBase>
              </CustomTooltip>
            )}
          </Box>
        </CustomTableCell>
        <CustomTableCell
              text={formtNumberByLanguage(
                  _process?.processDetail?.executionCount,
              )}
              {...cellProps}
          />
        <CustomTableCell
              text={formtNumberByLanguage(_process?.processDetail?.completedCount,)}
              {...cellProps}
          />
        {/* <CustomTableCell
              text={formtNumberByLanguage(_process?.processDetail?.exceptionCount,)}
              {...cellProps}
          /> */}
        <CustomTableCell
              text={formtNumberByLanguage(_process?.processDetail?.pendingCount,)}
              {...cellProps}
          />
        <CustomTableCell
              text={roi}
              {...cellProps}
          />
        <CustomTableCell
              text={breakEven}
              {...cellProps}
          />
        <CustomTableCell {...cellProps}>
          <StatusButton
                isProcess
                status={_process.processStatus}
                label={statusData[_process.processStatus]?.label || "Ready"}
                disabled={hiddenProcess}
            />
        </CustomTableCell>
        <CustomTableCell width={130} align="center" {...cellProps}>
          {editId !== _process.id ? (
            <PriorityChip
                    hidden={hiddenProcess}
                    priority={priority}
                    onClick={(event) => {
                      event.stopPropagation();
                      onPriorityClick();
                    }}
                />
            ) : (
              <Box component="span" display="flex" alignItems="flex-end">
                <CustomSelectField
                      options={availablePriorities}
                      value={newPriority}
                      onChange={(e) => handleChange(e)}
                      formControlClassName={classes.prioritySelect}
                      variant="standard"
                      fullWidth={false}
                      isCustom
                  >
                  {availablePriorities.map((priority) => (
                    <MenuItem value={priority}>
                      <em>{priority}</em>
                    </MenuItem>
                    ))}
                </CustomSelectField>
                <IconButton
                      component="span"
                      onClick={() => updatePriority()}
                      className={classes.okBtn}
                      size="small"
                  >
                  <Done fontSize="small" />
                </IconButton>
                <IconButton
                      color="danger"
                      component="span"
                      size="small"
                      onClick={() => {
                        setEditId(0);
                      }}
                      className={classes.clearPriority}
                  >
                  <Clear fontSize="small" />
                </IconButton>
              </Box>
            )}
        </CustomTableCell>
      </CustomTableRow>
      {!disabled && open && processState?.id && (
        <CustomizedDialogs
                open={open}
                handleClose={closeDialog}
                handleClickOpen={() => openAndResetValue()}
                content={content}
                title={title}
                _process={processState}
                setProcess={setProcessState}
                value={value}
                setValue={setValue}
                callAfterClose={setEditId}
                changePriority={changePriority}
                statusData={statusData}
                isLoading={isLoading}
            />
        )}
      {openSlide && (
        <ConfirmMessage
                message={t("priority.error")}
                openStart={openSlide}
                onCancel={handleCloseSlide}
                onConfirm={handleCloseSlide}
                buttonConfirm={t("Accept")}
                buttonCancel={t("Cancel")}
            />
        )}
    </>
  );
}

export default TableCard;
