import React from "react";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Typography from "@material-ui/core/Typography";
import Menu from "@material-ui/core/Menu";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import supportedLanguages from "./supportedLanguages";
import useStyles from "./style.js";

const changeLanguage = (language, setAnchorLangEl) => {
    setAnchorLangEl(null);
    i18n.changeLanguage(language);
    localStorage.setItem("lgn", language);
};

const LanguageHeader = () => {
    const [anchorLangEl, setAnchorLangEl] = React.useState(null);
    const { t } = useTranslation()
    const classes = useStyles();
    return (
      <>
        <Tooltip title={t("language")}>
          <IconButton
               onClick={(e) => setAnchorLangEl(e.currentTarget)}
               className={classes.lnIcon}
           >
            <Typography
                   className={`flag-icon flag-icon-${
                       supportedLanguages.find(({ key }) => key === i18n.language)?.flag
                   } flag-icon-squared`}
               />
          </IconButton>
        </Tooltip>
        <Menu
            id="langage-menu"
            anchorEl={anchorLangEl}
            keepMounted
            open={Boolean(anchorLangEl)}
            onClose={() => setAnchorLangEl(null)}
        >
          {supportedLanguages.map(({ key, text, flag }) => (
            <MenuItem
                onClick={() => changeLanguage(key, setAnchorLangEl)}
                selected={key === i18n.language}
                key={key}
            >
              <ListItemIcon>
                <Typography
                  className={`flag-icon flag-icon-${flag} flag-icon-squared`}
                />
              </ListItemIcon>
              {text}
            </MenuItem>
          ))}
        </Menu>
      </>
    )
}
export default LanguageHeader;
