import { makeStyles } from "@material-ui/core/styles";
import { fade } from "@material-ui/core/styles/colorManipulator";

export default makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: fade(theme.palette.background.default, 0.8),
    overflowX: "hidden",
    overflowY: "hidden",
    padding: "20px",
  },
  ressourcegrid: {
    marginBottom: "10px",
    justifyContent: "center",
    margin: "auto",
  },
  grid: {
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "5px",
    margin: "auto",
  },
  typography: {
    color: "gray",
  },
  marginauto: {
    marginTop: "17px",
  },
  executionTime: {
    display: "flex",
    alignItems: "center",
  },
  executionTimeT: {
    display: "inline",
    marginRight: "20px",
  },
  dialogRoot: {
    margin: 0,
    padding: theme.spacing(2),
  },
  DialogContentRoot: {
    paddingTop: theme.spacing(0),
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  cardHeader: {
    textAlign: "center",
  },
  span: {
    backgroundColor: theme.palette.grey[200],

    width: "30%",
  },
  content: {
    justifyContent: "center",
    paddingLeft: theme.spacing(3),
    paddingTop: theme.spacing(4),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  title: {
    marginLeft: theme.spacing(2),
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  tableCellHistorical: {
    textAlign: "center",
  },
  tableCellAction: {
    minWidth: 300,
    fontSize: 15,
    textAlign: "left",
    paddingLeft: 0,
  },
  resources: {
    maxHeight: "150px",
    overflow: "auto",
    width: "300px",
  },

  deleteButton: {
    marginLeft: theme.spacing(1),
  },
  tabSize: {
    minWidth: theme.spacing(100),
    maxWidth: "95%",
  },
  successStar: {
    color: theme.custom.color.color1,
  },
  errorStart: {
    color: theme.custom.color.color4,
  },
  startMessage: {
    display: "flex",
    alignItems: "center",
    color: theme.palette.grey[800],
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  editTexField: {
    width: "100%",
  },
  editTexAreaField: {
    width: "100%",
  },

  button: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  cancelButton: {
    paddingBottom: theme.spacing(0),
    paddingTop: theme.spacing(0),
    backgroundColor: theme.custom.color.color4,
    "&:hover": {
      backgroundColor: theme.custom.color.color4,
    },
    color: "white",
  },
  saveButton: {
    paddingBottom: theme.spacing(0),
    paddingTop: theme.spacing(0),
    marginRight: theme.spacing(0.4),
    backgroundColor: theme.custom.color.color1,
    "&:hover": {
      backgroundColor: theme.custom.color.color1,
    },
    color: "white",
  },
  pending: {
    color: theme.custom.color.color4,
    borderColor: theme.custom.color.color4,
  },
  running: {
    color: theme.custom.color.color2,
    borderColor: theme.custom.color.color2,
  },
  done: {
    color: theme.custom.color.color1,
    borderColor: theme.custom.color.color1,
  },
  status: {
    background: "transparent",
    border: "2px solid ",
    borderRadius: "1rem",
    padding: 2,
    fontSize: "12px",
  },

  padding: {
    padding: "2px",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  toDelete: {
    background: "transparent",
    color: "#3c44b1",
    border: "2px solid #3c44b1",
    borderRadius: "1rem",
    padding: 0,
  },
  editIconBtn: {
    marginBottom: theme.spacing(3),
  },
  icon: {

    width: "15px",
    height: "15px",
    borderRadius: "50%",
  },
  beFormContainer: {
    marginBottom: theme.spacing(4),
  },
  dateContainer: {
    marginLeft: theme.spacing(2),
    width: 300,
  },
  validation_error: {
    color: "red",
    margin: "0",
    marginTop: theme.spacing(1),
    position: "absolute",
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    width: "25%",
  },

  typograpgy: {
    marginTop: theme.spacing(2),
  },
  cardActions: {
    justifyContent: "right",
    marginTop: theme.spacing(2),
    padding: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
  },
  selectEmpty: {
    width: theme.spacing(8),
  },
  buttonRight: {
    textAlign: "right",
  },
  deleteIcon: {
    color: theme.custom.color.color4,
  },
  hourly: {
    position: "relative",
    bottom: theme.spacing(3),
  },
  daily: {
    position: "relative",
    top: theme.spacing(2),
  },
  weekly: {
    position: "relative",
    top: theme.spacing(8),
  },
  monthly: {
    position: "relative",
    top: theme.spacing(14),
  },
  yearly: {
    position: "relative",
    top: theme.spacing(20),
  },
  tableCell: {
    paddingLeft: theme.spacing(3),
  },
  autoComplete: {
    width: "80%",
    marginLeft: 10,
    marginBottom: 10,
  },
  pagination: {
    fontSize: theme.spacing(0.875),
  },
  input: {
    fontSize: "1rem",
    fontFamily: "Roboto",
    fontWeight: "400",
  },
}));
