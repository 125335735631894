import React from "react";
import Box from "@material-ui/core/Box";
import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import LinearProgress from "@material-ui/core/LinearProgress";
import Typography from "@material-ui/core/Typography";
import DescriptionIcon from "@material-ui/icons/Description";
import GetAppIcon from "@material-ui/icons/GetApp";
import DeleteIcon from "@material-ui/icons/Delete";
import { useTranslation } from "react-i18next";
import RefreshIcon from "@material-ui/icons/Refresh";
import InboxIcon from "@material-ui/icons/Inbox";
import ButtonBase from "@material-ui/core/ButtonBase";
import Tooltip from "@material-ui/core/Tooltip";
import AssignmentReturnedIcon from "@material-ui/icons/AssignmentReturned";
import DeleteSweepOutlinedIcon from "@material-ui/icons/DeleteSweepOutlined";
import { useDispatch } from "react-redux";
import { FETCH_EXPORTED_FILES } from "redux/constants/index.js";
import {
  deleteAllExportedFiles,
  deleteExportedFileByName,
  exportAllFiles,
  exportFile,
  fetchExportedFiles,
} from "redux/actions/services/index.js";
import {
  COLLECTING_STATUS_COLOR, DEFAULT_STATUS_COLOR, EXPORTING_STATUS_COLOR, FINISHED_STATUS_COLOR
} from "util/constants/colors.js";
import { useQuery } from "@redux-requests/react";
import { EXPORT_STATUS } from "util/constants/Status.js";
import NoData from "components/NoData";
import CustomButton from "../../../components/CustomButton";
import CircularLoader from "../../../components/Loaders/CircularLoader";
import ConfirmMessage from "../../../components/ConfirmMessage";
import CustomDialog from "../../../components/CustomDialog";
import useStyles from "./style.js";

function ExportDialog({ exportOpen, setExportOpen }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [exportedFiles, setExportedFiles] = React.useState([]);
  const exportedData = useQuery({ type: FETCH_EXPORTED_FILES });
  const [exportedLoading, setExportedLoading] = React.useState(false);
  const [openDeleteAllConfirmation, setOpenDeleteAllConfirmation] = React.useState(false);
  const [openDeleteSelectedConfirmation, setOpenDeleteSelectedConfirmation] = React.useState(false);
  const [filename, setFilename] = React.useState(null);
  const handleConfirmDeleteAll = () => {
    setExportedLoading(true);
    dispatch(deleteAllExportedFiles(refreshExportedFiles));
    handleCloseSlide();
  }
  const handleConfirmDeleteSelectedFile = () => {
    setExportedLoading(true);
    dispatch(deleteExportedFileByName(filename, refreshExportedFiles));
    handleCloseSlide();
  }
  const handleCloseSlide = () => {
    setOpenDeleteAllConfirmation(false);
    setOpenDeleteSelectedConfirmation(false);
  }
  React.useEffect(() => {
    setExportedFiles(exportedData.data);
  }, [exportedData]);

  const formatFilename = (filename) => {
    // eslint-disable-next-line no-unsafe-optional-chaining
    const prefix = filename.substring(0, filename?.indexOf("-") + 1);
    const uuid = filename.substring(
      filename.indexOf("-") + 1,
      filename.indexOf(".xls")
    );
    return `${prefix}${uuid.substring(0, 4)}...${uuid.substring(
      // eslint-disable-next-line no-unsafe-optional-chaining
      uuid?.length - 5,
      uuid?.length
    )}.xls`;
  };
  React.useEffect(() => {
    const refresh = setInterval(() => refreshExportedFiles(false), 15000);
    () => clearInterval(refresh);
  }, []);

  const exportFileByName = (filename) => {
    dispatch(exportFile(filename));
  };
  const exportAll = () => {
    dispatch(exportAllFiles());
  };
  const refreshExportedFiles = (withLoading = true) => {
    if (withLoading && !exportedLoading) {
      setExportedLoading(true);
    }
    dispatch(fetchExportedFiles(onFetchExportedDataSuccess));
  };
  const onDeleteExportedFile = (fileName) => {
    setFilename(fileName);
    setOpenDeleteSelectedConfirmation(true);
  };
  const deleteAllFiles = () => {
    setOpenDeleteAllConfirmation(true);
  }
  const onFetchExportedDataSuccess = () => {
    setExportedLoading(false);
  }
  const exportNotifications = (fileStatus) => {
    switch (fileStatus) {
        case EXPORT_STATUS.COLLECTING:
            return COLLECTING_STATUS_COLOR;
        case EXPORT_STATUS.EXPORTING:
            return EXPORTING_STATUS_COLOR;
        case EXPORT_STATUS.FINISHED:
            return FINISHED_STATUS_COLOR;
        default:
            return DEFAULT_STATUS_COLOR;
    }
  }
  const renderCase = () => {
    if (exportedFiles?.length > 0 && exportedLoading) {
      return (
        <Box marginTop="15px">
          <CircularLoader />
        </Box>
      )
    }
    return (
      <NoData message={t("exporting.no.data")} withVariant={false}>
        <InboxIcon className={classes.nodataIcon} />
      </NoData>
    )
  }
  return (
    <CustomDialog
        contentText={t("exporting.title")}
        open={exportOpen}
        onClose={() => setExportOpen(false)}
        actionElements={[{
          label: "Close",
          onClick: () => setExportOpen(false),
          color: "primary"
        }]}
        maxWidth="sm"
        fullWidth
      >
      <Grid container justify="flex-end" spacing={1}>
        <Grid item>
          <CustomButton
                  view="primary"
                  disabled={!exportedData.data?.length > 0}
                  endIcon={<AssignmentReturnedIcon fontSize="small" />}
                  size="small"
                  classes={{ sizeSmall: classes.refreshBtn }}
                  onClick={exportAll}
                >
            {t("exporting.btn.download.all")}
          </CustomButton>
        </Grid>
        <Grid item>
          <CustomButton
                view="primary"
                disabled={!exportedData.data?.length > 0}
                  endIcon={<DeleteSweepOutlinedIcon fontSize="small" />}
                  size="small"
                  classes={{ sizeSmall: classes.refreshBtn }}
                  onClick={deleteAllFiles}
              >
            {t("exporting.btn.delete.all")}
          </CustomButton>
        </Grid>
        <Grid item>
          <CustomButton
                  view="primary"
                  endIcon={<RefreshIcon fontSize="small" />}
                  size="small"
                  classes={{ sizeSmall: classes.refreshBtn }}
                  onClick={refreshExportedFiles}
                >
            {t("dashboar.refresh")}
          </CustomButton>
        </Grid>
      </Grid>
      {exportedFiles?.length > 0 && !exportedLoading ? (
              exportedFiles.sort((fileA, fileB) => new Date(fileB.createdAt) - new Date(fileA.createdAt)).map((expFile) => (
                <Grid container>
                  <Grid item xs={1}>
                    <DescriptionIcon className={classes.xlsFile} />
                  </Grid>
                  <Grid
                    container
                    item
                    xs={11}
                    alignItems="center"
                    justify="space-between"
                  >
                    <Box
                      alignItems="flex-start"
                      justifyContent="space-evenly"
                      display="flex"
                      flexDirection="column"
                      marginLeft="5px"
                    >
                      <Tooltip title={expFile.fileName} placement="bottom">
                        <ButtonBase>
                          <Typography>
                            {formatFilename(expFile.fileName)}
                          </Typography>
                        </ButtonBase>
                      </Tooltip>
                      <Box width={240}>
                        {expFile?.exportState !== EXPORT_STATUS.FINISHED && (
                          <LinearProgress color="primary" />
                        )}
                      </Box>
                    </Box>
                    <Box>
                      <Chip
                        label={t(expFile.exportState)}
                        size="small"
                        style={{
                          backgroundColor: exportNotifications(
                            expFile.exportState
                          ),
                          color: "white",
                        }}
                      />
                      <IconButton
                        disabled={
                          expFile.exportState !== EXPORT_STATUS.FINISHED
                        }
                        color="primary"
                        onClick={() => exportFileByName(expFile.fileName)}
                      >
                        <GetAppIcon />
                      </IconButton>
                      <IconButton
                        color="primary"
                        onClick={() => onDeleteExportedFile(expFile.fileName)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  </Grid>
                </Grid>
              ))
            ) : (
              renderCase()
            )}
      <ConfirmMessage
          message={t("export.confirm.msg.delete.all")}
          openStart={openDeleteAllConfirmation}
          onCancel={handleCloseSlide}
          onConfirm={handleConfirmDeleteAll}
          buttonConfirm={t("Yes")}
          buttonCancel={t("No")}
      />
      <ConfirmMessage
          message={t("export.confirm.msg.delete.one")}
          openStart={openDeleteSelectedConfirmation}
          onCancel={handleCloseSlide}
          onConfirm={handleConfirmDeleteSelectedFile}
          buttonConfirm={t("Yes")}
          buttonCancel={t("No")}
      />
    </CustomDialog>
  );
}
export default ExportDialog;
