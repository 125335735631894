import React, { useState } from "react";
import {
  AppBar, Tabs, Tab, Grid,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import SubPageContainer from "../../components/SubPageContainer";
import SubPagePanel from "../../components/SubPagePanel";
import ChangePasswordRequest from "./ResetPassword/ChangePasswordRequest";
import useStyles from "./style";
import General from "./General";

function a11yProps(index) {
  return {
    id: `user-profile-tab-${index}`,
    "aria-controls": `user-profile-tabpanel-${index}`,
  };
}

const Profile = () => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();

  const routes = ["/profile/general", "/profile/security"];

  const [value, setValue] = useState(routes.indexOf(history.location.pathname));

  const handleChange = (event, newValue) => {
    history.push(routes[newValue]);
    setValue(newValue);
  };

  return (
    <SubPageContainer>
      <div className={classes.root}>
        <AppBar elevation={0} position="static">
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="Assets"
            variant="fullWidth"
            className={classes.tabs}
          >
            <Tab
              label={t("profile.user.general.title")}
              disabled={false}
              value={0}
              {...a11yProps(0)}
              className={classes.tab}
            />
            <Tab
              label={t("profile.user.general.security")}
              disabled={false}
              value={1}
              {...a11yProps(1)}
              className={classes.tab}
            />
          </Tabs>
        </AppBar>
        <Grid className={classes.firstGrid}>
          <SubPagePanel value={value} index={0}>
            <General classes={classes} />
          </SubPagePanel>
          <SubPagePanel value={value} index={1}>
            <ChangePasswordRequest classes={classes} />
          </SubPagePanel>
        </Grid>
      </div>
    </SubPageContainer>
  );
};

export default Profile;
