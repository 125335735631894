import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  dropabbleTitle: {
    padding: theme.spacing(1),
    paddingLeft: 2
  },
  droppableContainer: {
    background: theme.custom.color.containerBackground,
    borderRadius: "4px",
    width: "100%",
    height: "300px",
    overflow: "auto",
    border: `8px ${theme.custom.color.containerBackground} solid`,
    paddingRight: "6px",
  },
  card: {
    width: "100%",
    padding: 2
  },
  itemsContainer: {
    backgroundColor: theme.custom.color.containerBackground
  },
  listItem: {
    padding: "0px",
  },
  droppableList: {
    backgroundColor: theme.palette.background.default,
    borderColor: theme.palette.background.default,
    boxShadow:
        "0px 0px 2px 0px rgb(172,186,255)",
  },
  processContainer: {
    width: "32%",
  },
  droppableTitle: {
    fontSize: 13,
    color: theme.custom.palette.status.pending,
    paddingBottom: 0
  },
  processSeparator: {
    width: "1%",
  },
}));
