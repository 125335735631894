import React, { useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  Card, Grid, Paper, Typography,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import useStyles from "./style.js";
import { clone, isEmpty } from "lodash";
import SearchField from "../../../components/EmptyDropSection/SearchField";
import EmptyDropSection from "../../../components/EmptyDropSection";

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = clone(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

/**
 * Moves an item from one list to another list.
 */
const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: `${grid}px ${grid * 2}px`,
  margin: `0 0 ${grid}px 0`,
  boxShadow:
      "0px 1px 2px 0px rgb(172,186,255)",
  fontWeight: 400,
  fontSize: 14,
  borderRadius: "2px",
  // change background colour if dragging
  background: "#FFFFFF",

  // styles we need to apply on draggables
  ...draggableStyle,
});

const DivisionSelector = ({
  availableDivisions,
  selectedDivisions,
  isDisabled,
  setAvailableDivisions,
  setSelectedDivisions,
  onAssignDivision,
  onUnAssignDivision,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [availableSearchTerm, setAvailableSearchTerm] = useState("")
  const [assignedSearchTerm, setAssignedSearchTerm] = useState("")
  const getList = (id) => (id === "droppable" ? availableDivisions : selectedDivisions);

  const validateDragDrop = (
    source,
    destination,
    droppableSource,
    droppableDestination,
  ) => {
    const sourceClone = Array.from(source);
    const [removed] = sourceClone.splice(droppableSource.index, 1);
    if (droppableDestination.droppableId === "droppable") {
      if (onUnAssignDivision) return onUnAssignDivision(removed);
    } else if (onAssignDivision) return onAssignDivision(removed);
    return true;
  };

  const onDragEnd = async (result) => {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      const items = reorder(
        getList(source.droppableId),
        source.index,
        destination.index,
      );

      // let state = { items };
      if (source.droppableId === "droppable2") {
        setSelectedDivisions(items);
      } else {
        setAvailableDivisions(items);
      }
    } else {
      const result = move(
        getList(source.droppableId),
        getList(destination.droppableId),
        source,
        destination,
      );
      setAvailableDivisions(result.droppable);
      setSelectedDivisions(result.droppable2);

      const res = await validateDragDrop(
        getList(source.droppableId),
        getList(destination.droppableId),
        source,
        destination,
      );
      if (!res) {
        setSelectedDivisions(getList(source.droppableId));
        setAvailableDivisions(getList(destination.droppableId));
      }
    }
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Grid item container xs={12}>
        <Grid
          item
          container
          className={clsx(classes.dropabbleTitle, classes.divisionContainer)}
        >
          <Typography className={classes.droppableTitle}>{t("divisionsSelector.availableDivision")}</Typography>
        </Grid>
        <Grid item className={classes.divisionSeparator} />
        <Grid
          item
          container
          className={clsx(classes.dropabbleTitle, classes.divisionContainer)}
        >
          <Typography className={classes.droppableTitle}>{t("divisionsSelector.assignedDivision")}</Typography>
        </Grid>
        <Grid item container xs={12}>
          <SearchField
              searchTerm={availableSearchTerm}
              setSearchTerm={setAvailableSearchTerm}
              placeholder={t("search.available.divisions")}
              disabled={isEmpty(availableDivisions)}
              justify="flex-start"
          />
          <SearchField
              searchTerm={assignedSearchTerm}
              setSearchTerm={setAssignedSearchTerm}
              placeholder={t("search.assigned.divisions")}
              disabled={isEmpty(selectedDivisions)}
              justify="flex-end"
          />
        </Grid>
      </Grid>
      <Grid item container xs={12}>
        <Grid item className={classes.divisionContainer}>
          <Card className={classes.card}>
            <Droppable droppableId="droppable" className={classes.droppableContainer}>
              {(provided) => (
                <div
                  ref={provided.innerRef}
                  className={classes.droppableContainer}
                >
                  {availableDivisions?.filter((item) => (availableSearchTerm.trim() !== "" ? item?.divisionName?.toLowerCase()?.includes(availableSearchTerm.trim()) : true))?.map((item, index) => (
                    <Draggable
                      key={`available-division-draggable-${item.id}`}
                      draggableId={`available-division-draggable-${item.id}`}
                      index={index}
                      isDragDisabled={isDisabled}
                    >
                      {(provided, snapshot) => (
                        <Paper
                          className={classes.contentPaper}
                        >
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style,
                            )}
                          >
                            {item.divisionName}
                          </div>
                        </Paper>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </Card>
        </Grid>
        <Grid item className={classes.divisionSeparator} />
        <Grid item container justify="flex-end" className={classes.divisionContainer}>
          <Card className={classes.card}>
            <Droppable droppableId="droppable2" className={classes.droppableContainer}>
              {(provided) => (
                <div
                  ref={provided.innerRef}
                  className={classes.droppableContainer}
                >
                  {isEmpty(selectedDivisions) ? (
                    <EmptyDropSection />
                      )
                      : (
                    selectedDivisions?.filter((item) => (assignedSearchTerm.trim() !== "" ? item?.divisionName?.toLowerCase()?.includes(assignedSearchTerm.trim()) : true))?.map((item, index) => (
                      <Draggable
                      key={`selected-division-draggable-${item.id}`}
                      draggableId={`selected-division-draggable-${item.id}`}
                      index={index}
                      isDragDisabled={isDisabled}
                    >
                        {(provided, snapshot) => (
                          <Paper className={classes.contentPaper}>
                            <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style,
                            )}
                          >
                              {item.divisionName}
                            </div>
                          </Paper>
                      )}
                      </Draggable>
                  )))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </Card>
        </Grid>
      </Grid>
    </DragDropContext>
  );
};

export default DivisionSelector;
