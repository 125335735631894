import React, { useEffect, useState } from "react";
import get from "lodash/get";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Grid from "@material-ui/core/Grid";
import Tab from "@material-ui/core/Tab";
import { useTranslation } from "react-i18next";
import useResizeObserver from "util/tabsWidthRefresh";
import useStyles from "./style";

import SubPagePanel from "../../components/SubPagePanel";
import SubPageContainer from "../../components/SubPageContainer";
import {
  isSubModulePermitted,
} from "../../components/HasPermission";
import DefaultAlerts from "./DefaultAlerts";
import CustomtAlerts from "./CustomAlerts";

export const reasons = {
  CREATE: "create-option",
  REMOVE: "remove-option",
  CLEAR: "clear",
};

export default function AlertsManagement() {
  const classes = useStyles();
  const { tabsRef } = useResizeObserver();
  const routes = [
    "/alertsManagement/defaultAlerts",
    "/alertsManagement/customAlerts",
  ];

  const history = useHistory();

  const [value, setValue] = useState(routes.indexOf(history.location.pathname));

  const handleChange = (event, newValue) => {
    history.push(routes[newValue]);
    setValue(newValue);
  };

  const { t } = useTranslation();

  const currentUser = useSelector(({ requests }) => get(requests, "queries.FETCH_CURRENT_USER.data"));

  useEffect(() => {
    if (history.location.state && history.location.state.dialogMsg) {
      history.replace({ ...history.location });
    }
  }, [history]);
  return (
    <SubPageContainer>
      <div className={classes.root}>
        <AppBar elevation={0} position="static">
          <Tabs
              ref={tabsRef}
              value={value}
              onChange={handleChange}
              aria-label="Assets"
              variant="fullWidth"
              className={classes.tabs}
            >
            {isSubModulePermitted(currentUser, "Default Alerts") && (
            <Tab
                  className={classes.tab}
                  label={t("alerts.default.tab.title")}
                  disabled={false}
                  value={0}
                />
              )}
            {isSubModulePermitted(currentUser, "Custom Alerts") && (
            <Tab
                  className={classes.tab}
                  label={t("alerts.custom.tab.title")}
                  disabled={false}
                  value={1}
                />
              )}
          </Tabs>
        </AppBar>
        <Grid className={classes.firstGrid}>
          {isSubModulePermitted(currentUser, "Default Alerts") && (
          <SubPagePanel value={value} index={0} classes={classes.noPadding}>
            {/* <HasPermission name="View default alerts"> */}
            <DefaultAlerts />
            {/* </HasPermission> */}
          </SubPagePanel>
            )}
          {isSubModulePermitted(currentUser, "Custom Alerts") && (
          <SubPagePanel value={value} index={1} classes={classes.noPadding}>
            {/* <HasPermission name="View custom alerts"> */}
            <CustomtAlerts />
            {/* </HasPermission> */}
          </SubPagePanel>
            )}
        </Grid>
      </div>
    </SubPageContainer>
  );
}
