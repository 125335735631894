import React from "react"
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";

export const StyledTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.background.default,
        filter: "drop-shadow(-1px 2px 5px #DEE4FF)",
        borderRadius: 0,
        padding: 10
    },
    arrow: {
        color: theme.palette.background.default,
    }
}))(Tooltip);

export default function CustomTooltip({ title, children, ...rest }) {
    return (
      <StyledTooltip
            title={title}
            {...rest}
        >
        {children}
      </StyledTooltip>
    )
}
