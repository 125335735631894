import React, { useEffect } from "react";
import { useDispatch, useSelector, connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { format } from "date-fns-tz";
import Grid from "@material-ui/core/Grid";
import { subYears } from "date-fns";
import { useHistory, useLocation } from "react-router-dom";
import {
  fetchProcessExecutionExceptions,
  fetchProcessesNameException,
  fetchProcessExecutionExceptionsReason,
  fetchProcessExecutionExceptionsType,
  deleteProcessExecutionExceptions,
  showProcessExecutionExceptions,
  deleteProcessExecutionException,
  exportProcessExecutionExceptionXLSX,
  fetchProcessExceptionsAnalyticsKpi,
  fetchExportedFiles,
} from "../../../redux/actions/services";
import useStyles from "../style";

import ConfirmMessage from "../../../components/ConfirmMessage";
import Filter from "../Filter";
import CircularLoader from "../../../components/Loaders/CircularLoader";
import PageHeader from "../../../components/PageHeader";
import Details from "../../../components/DataDetail";
import ExceptionsContent from "../ExceptionsContent";
import Analytics from "../Analytics";
import { useQuery } from "../../../components/Filter";
import { updateFilter } from "../../../redux/slices/exceptionFilter";
import { exceptionCurrentModule } from "../../../util";
import { toast } from "react-toastify";
import CustomPagination from "pages/Services/components/CustomPagination";

const sortColumns = [
  {
    id: "executionStartTime",
    label: "Creation date (Descending)",
    order: "desc",
  },
  {
    id: "executionStartTime",
    label: "Creation date (Ascending)",
    order: "asc",
  },
  {
    id: "executionEndTime",
    label: "End date (Descending)",
    order: "desc",
  },
  {
    id: "executionEndTime",
    label: "End date (Ascending)",
    order: "asc",
  },
  {
    id: "executionDuration",
    label: "Execution Time (Descending)",
    order: "desc",
  },
  {
    id: "executionDuration",
    label: "Execution Time (Ascending)",
    order: "asc",
  },
];

const dateFormat = "yyyy/MM/dd HH:mm";
function ProcessesExceptions(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const query = useQuery();

  const processExecIdsParam = Number(
    history?.location?.state?.processExecutionId
      || query.get("processExecution"),
  );
  const processExecIds = processExecIdsParam ? [processExecIdsParam] : [];
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [data, setData] = React.useState([]);
  const [count, setCount] = React.useState(0);
  const [processName, setProcessName] = React.useState([]);
  const [processes, setProcesses] = React.useState([]);
  const [exceptions, setExceptions] = React.useState([]);
  const [exceptionsType, setExceptionsType] = React.useState([]);
  const [openMsgDetail, setOpenMsgDetail] = React.useState(false);
  const [fleets, setFleets] = React.useState([]);
  const [showHidden, setShowHidden] = React.useState(false);
  const [deleteButtonContent, setDeleteButtonContent] = React.useState("");
  const [selectedException, setSelectedException] = React.useState(null);
  const [process, setProcess] = React.useState([]);

  const dispatch = useDispatch();
  const [selectedFleetIds, setSelectedFleetIds] = React.useState([]);
  const [selectedProcessIds, setSelectedProcessIds] = React.useState([]);
  const [loadedDatetime, setLoadedDatetime] = React.useState(null);
  const [completedDatetime, setCompletedDatetime] = React.useState(null);
  const [selectedExceptionsType, setSelectedExceptionsType] = React.useState(
    [],
  );
  const kpiData = useSelector(
    ({ requests }) => requests.queries.FETCH_PROCESS_EXCEPTION_ANALYTICS_KPI?.data,
  );
  const exceptionFilters = useSelector(
    ({ filterListException }) => filterListException[exceptionCurrentModule.PROCESSES],
  );
  const filterExceptionList = useSelector(
    ({ filterListException }) => filterListException,
  );
  const containerRef = React.useRef();
  const fetchProcessExecutionExceptionsType = () => {
    props
      .fetchProcessExecutionExceptionsType(
        selectedProcessIds,
        loadedDatetime,
        completedDatetime,
        selectedFleetIds,
      )
      .then((res) => {
        setExceptionsType(res.data);
      });
  };
  const fetchProcessesNameException = () => {
    props.fetchProcessesNameException(selectedFleetIds).then((res) => {
      setProcesses(res.data);
    });
  };
  const fetchProcessExecutionExceptionsReason = () => {
    props
      .fetchProcessExecutionExceptionsReason(
        selectedExceptionsType,
        selectedProcessIds,
        loadedDatetime,
        completedDatetime,
        selectedFleetIds,
      )
      .then((res) => {
        setExceptions(res.data);
      });
  };
  React.useEffect(() => {
    handleChangeDates(null, null, "ALL_TIME");
    fetchProcessesNameException();
    fetchProcessExecutionExceptionsType();
    fetchProcessExecutionExceptionsReason();
  }, [selectedFleetIds.join()]);
  React.useEffect(() => {
    fetchProcessExecutionExceptionsType();
    fetchProcessExecutionExceptionsReason();
  }, [selectedProcessIds]);
  React.useEffect(() => {
    fetchProcessExecutionExceptionsReason();
  }, [selectedExceptionsType.join()]);

  const fetchData = () => {
    const filterExceptionTypes = exceptionFilters.exceptionType?.map(
      (e) => e.desc,
    );
    const filterExceptionReasons = exceptionFilters.exception?.map(
      (e) => e.exceptionReason,
    );
    const peId = processExecIds || exceptionFilters.processExecutions;
    const filterFleetIds = exceptionFilters.fleet.map((c) => c.id);
    getExceptions(
      exceptionFilters.pageNo,
      exceptionFilters.pageSize,
      exceptionFilters.order.id,
      exceptionFilters.order.order,
      exceptionFilters.process,
      exceptionFilters.divisions,
      filterExceptionReasons,
      filterExceptionTypes,
      filterFleetIds,
      exceptionFilters.exceptionsFromDate,
      exceptionFilters.exceptionsToDate,
      showHidden,
      exceptionFilters.searchText,
      peId,
      exceptionFilters.tags
    );
  };

  useEffect(() => {
    fetchData();
  }, [exceptionFilters]);

  const handleChangeShowHidden = (event) => {
    setShowHidden(event.target.checked);
    dispatch(
      updateFilter({
        ...filterExceptionList,
        [exceptionCurrentModule.PROCESSES]: {
          pageNo: 0,
          showHidden: event.target.checked,
        },
      }),
    );
  };
  useEffect(() => {
    setPage(exceptionFilters.pageNo);
    setRowsPerPage(exceptionFilters.pageSize);
  }, [exceptionFilters.pageNo, exceptionFilters.pageSize]);

  const handleChangeException = (values) => {
    if (values) {
      dispatch(
        updateFilter({
          ...filterExceptionList,
          [exceptionCurrentModule.PROCESSES]: {
            pageNo: 0,
            exception: values,
          },
        }),
      );
    }
  };

  const handleChangeExceptionType = (values) => {
    const exceptionTypesDesc = values?.map((e) => e.desc);
    if (values) {
      setSelectedExceptionsType(exceptionTypesDesc);
      dispatch(
        updateFilter({
          ...filterExceptionList,
          [exceptionCurrentModule.PROCESSES]: {
            exceptionType: values,
          },
        }),
      );
    }
  };

  const handleChangeProcess = (values) => {
    if (values) {
      setProcessName(values);
      const processIds = values.map((p) => p.id);
      setSelectedProcessIds(processIds);
      dispatch(
        updateFilter({
          ...filterExceptionList,
          [exceptionCurrentModule.PROCESSES]: {
            process: values,
          },
        }),
      );
    }
    if (processExecIds?.length && processExecIds.length !== 0) {
      history.replace({
        pathname: location.pathname,
      });
    }
  };

  const handleChangeFleet = (values) => {
    setFleets(values);
    props.setIsLoading(false);
    const fleetIds = values.map((v) => v.id);
    setSelectedFleetIds(fleetIds);
    dispatch(
      updateFilter({
        ...filterExceptionList,
        [exceptionCurrentModule.PROCESSES]: {
          pageNo: 0,
          fleet: values,
        },
      }),
    );
  };

  const handleChangeDivision = (values) => {
    props.setIsLoading(false);
    dispatch(
        updateFilter({
          ...filterExceptionList,
          [exceptionCurrentModule.PROCESSES]: {
            pageNo: 0,
            divisions: values,
          },
        }),
    );
  };

  const handleRequestSort = (property) => {
    dispatch(
      updateFilter({
        ...filterExceptionList,
        [exceptionCurrentModule.PROCESSES]: {
          pageNo: 0,
          order: property,
        },
      }),
    );
  };

  const handleSelectAllClick = (event) => {
    const newSelecteds = data.map((n) => n.id);
    if (event.target.checked) {
      props.setSelected((prvSelected) => [...prvSelected, ...newSelecteds]);
      const exceptionsToShow = data
        .filter((item) => item.isDeleted)
        .map((n) => n.id);
      props.setExceptionsToShow((prvExceptionsToShow) => Array.from(new Set([...prvExceptionsToShow, ...exceptionsToShow])));
      const exceptionsToHide = data
        .filter((item) => !item.isDeleted)
        .map((n) => n.id);
      props.setExceptionsToHide((prvExceptionsToHide) => Array.from(new Set([...prvExceptionsToHide, ...exceptionsToHide])));
      return;
    }
    props.deleteDuplicationSelection(newSelecteds);
  };

  const handleChangePage = (event, newPage) => {
    dispatch(
      updateFilter({
        ...filterExceptionList,
        [exceptionCurrentModule.PROCESSES]: {
          pageNo: newPage,
        },
      }),
    );
  };

  const handleNext = (page) => {
    dispatch(
      updateFilter({
        ...filterExceptionList,
        [exceptionCurrentModule.PROCESSES]: {
          pageNo: page + 1,
        },
      }),
    );
    }
    const handlePrevious = (page) => {
      dispatch(
        updateFilter({
          ...filterExceptionList,
          [exceptionCurrentModule.PROCESSES]: {
            pageNo: page - 1,
          },
        }),
      );
    }

  const handleChangeRowsPerPage = (event) => {
    dispatch(
      updateFilter({
        ...filterExceptionList,
        [exceptionCurrentModule.PROCESSES]: {
          pageNo: 0,
          pageSize: event.target.value,
        },
      }),
    );
  };

  const handleChangeDates = (from, to, value) => {
    if (from && to) {
      const fromString = `${from}`;
      const toString = `${to}`;
      from = fromString.includes("/") ? from : format(from, dateFormat);
      to = toString.includes("/") ? to : format(to, dateFormat);
    }
    setLoadedDatetime(from);
    setCompletedDatetime(to);
    dispatch(
      updateFilter({
        ...filterExceptionList,
        [exceptionCurrentModule.PROCESSES]: {
          pageNo: 0,
          exceptionsSelectedDurationValue: value,
          exceptionsFromDate: from,
          exceptionsToDate: to,
        },
      }),
    );
  };
  const getExceptions = (
    page,
    size,
    sortField,
    sortOrder,
    processes,
    divisions,
    exceptions,
    exceptionsType,
    fleetIds,
    executionStartTime,
    executionEndTime,
    showHidden,
    searchContent,
    pied,
    tags,
  ) => {
    props.setIsLoading(false);
    const processID = processes ? processes.map((e) => e.id) : [];
    props.setIsLoading(true);
    props
      .fetchProcessExecutionExceptions(
        page,
        size,
        sortField,
        sortOrder,
        processID,
        divisions,
        exceptions,
        exceptionsType,
        fleetIds,
        executionStartTime,
        executionEndTime,
        showHidden,
        searchContent,
        tags
      )
      .then((result) => {
        setData(result?.data?.list);
        setCount(result?.data?.resultsCount);
        props.setIsLoading(false);
      });
  };

  useEffect(() => {
    const fleetIds = exceptionFilters.fleet.map((c) => c.id);
    const typesIds = exceptionFilters.exceptionType.map((t) => t.desc);
    const processIds = exceptionFilters.process.map((p) => p.id);
    const from = exceptionFilters.exceptionsFromDate;
    const to = exceptionFilters.exceptionsToDate;

    props.fetchProcessesNameException(fleetIds).then((result) => {
      setProcesses(result.data);
    });
    props
      .fetchProcessExecutionExceptionsReason(
        typesIds,
        processIds,
        from,
        to,
        fleetIds,
      )
      .then((resultE) => {
        setExceptions(resultE.data);
      });
    props
      .fetchProcessExecutionExceptionsType(processIds, from, to, fleetIds)
      .then((resultT) => {
        setExceptionsType(resultT.data);
      });
  }, []);

  useEffect(() => {
    dispatch(
      updateFilter({
        ...filterExceptionList,
        [exceptionCurrentModule.PROCESSES]: {
          pageNo: 0,
          order: exceptionFilters.order,
        },
      }),
    );
  }, [exceptionCurrentModule.PROCESSES]);

  const deleteException = (e, row) => {
    setSelectedException(row);
    props.setOpenMsgConfirm(true);
    props.setMessageConfirm(
      row?.isDeleted
        ? t("Are you sure you want to show this exception ?")
        : t("Are you sure you want to delete this exception ?"),
    );
    setDeleteButtonContent(
      row?.isDeleted ? t("tooltip.action.show") : t("tooltip.action.hide"),
    );
  };
  const deleteExceptions = () => {
    props.setOpenMsgConfirm(true);
    props.setMessageConfirm(
      `${t("Are you sure you want to delete")
      } ${
        props.exceptionsToHide.length
      } ${
        t("exceptions")
      } ?`,
    );
    setDeleteButtonContent(t("tooltip.action.hide"));
  };

  const showExceptions = () => {
    props.setOpenMsgConfirm(true);
    props.setMessageConfirm(
      t("Are you sure you want to show X exceptions?", {
        count: props.exceptionsToShow.length,
      }),
    );
    setDeleteButtonContent(t("tooltip.action.show"));
  };

  const cancelConfirm = () => {
    props.setOpenMsgConfirm(false);
    setSelectedException(null);
  };
  const confirmDelete = () => {
    const filterExceptionTypes = exceptionFilters.exceptionType?.map(
      (e) => e.desc,
    );
    const filterExceptionReasons = exceptionFilters.exception?.map(
      (e) => e.exceptionReason,
    );
    const peId = processExecIds || exceptionFilters.processExecutions;
    const filterFleetIds = exceptionFilters.fleet.map((c) => c.id);

    props.setIsLoadingConfirm(true);
    if (selectedException?.id) {
      props
        .deleteProcessExecutionException(selectedException.id)
        .then(() => {
          toast.success(selectedException?.isDeleted ? t("Exception Restored Successfully") : t("Exception Deleted Successfully"))
          props.setOpenMsgConfirm(false);
          props.setIsLoadingConfirm(false);
          props.setSelected([]);
          props.setExceptionsToShow([]);
          props.setExceptionsToHide([]);
          setSelectedException(null);
          getExceptions(
            exceptionFilters.pageNo,
            exceptionFilters.pageSize,
            exceptionFilters.order.id,
            exceptionFilters.order.order,
            exceptionFilters.process,
            exceptionFilters.divisions,
            filterExceptionReasons,
            filterExceptionTypes,
            filterFleetIds,
            exceptionFilters.exceptionsFromDate,
            exceptionFilters.exceptionsToDate,
            showHidden,
            exceptionFilters.searchText,
            peId,
            exceptionFilters.tags
          );
        });
    } else if (deleteButtonContent === t("tooltip.action.hide")) {
      props
        .deleteProcessExecutionExceptions(props.selected)
        .then(() => {
          toast.success(`${props.exceptionsToHide.length} ${t("exceptions successfully deleted")}`)
          props.setIsLoadingConfirm(false);
          props.setOpenMsgConfirm(false);
          props.setSelected([]);
          props.setExceptionsToShow([]);
          props.setExceptionsToHide([]);
          setSelectedException(null);
          getExceptions(
            exceptionFilters.pageNo,
            exceptionFilters.pageSize,
            exceptionFilters.order.id,
            exceptionFilters.order.order,
            exceptionFilters.process,
            exceptionFilters.divisions,
            filterExceptionReasons,
            filterExceptionTypes,
            exceptionFilters.fleet,
            exceptionFilters.exceptionsFromDate,
            exceptionFilters.exceptionsToDate,
            showHidden,
            exceptionFilters.searchText,
            peId,
            exceptionFilters.tags
          );
        });
    } else if (deleteButtonContent === t("tooltip.action.show")) {
      props
        .showProcessExecutionExceptions(props.selected)
        .then(() => {
          toast.success(t("X exceptions successfully restored", { count: props.exceptionsToShow.length, }))
          props.setIsLoadingConfirm(false);
          props.setOpenMsgConfirm(false);
          props.setSelected([]);
          props.setExceptionsToShow([]);
          props.setExceptionsToHide([]);
          setSelectedException(null);
          getExceptions(
            exceptionFilters.pageNo,
            exceptionFilters.pageSize,
            exceptionFilters.order.id,
            exceptionFilters.order.order,
            exceptionFilters.process,
            exceptionFilters.divisions,
            filterExceptionReasons,
            filterExceptionTypes,
            exceptionFilters.fleet,
            exceptionFilters.exceptionsFromDate,
            exceptionFilters.exceptionsToDate,
            showHidden,
            exceptionFilters.searchText,
            peId,
            exceptionFilters.tags
          );
        });
    }
  };

  const closeDialog = () => {
    setOpenMsgDetail(false);
  };

  const exportItemExceptionXLSX = (ids, exceptionTypeName, exceptionName) => {
    const exceptionNames = exceptionName.map((e) => e.exceptionReason);
    const exceptionTypes = exceptionTypeName.map((e) => e.desc);
    dispatch(
      exportProcessExecutionExceptionXLSX(
        ids,
        exceptionTypes,
        exceptionNames,
        exceptionFilters.fleet,
        exceptionFilters.exceptionsFromDate,
        exceptionFilters.exceptionsToDate,
        props.selected,
        showHidden,
        exceptionFilters.searchText,
        exceptionFilters.order.id,
        exceptionFilters.order.order,
        exceptionFilters.tags
      ),
    ).then(() => {
      props.setExportLoading(false);
      dispatch(fetchExportedFiles());
      toast.success(t("export.successful.notification"));
    });
  };

  const deletePermission = "Delete process exceptions";

  const [analyticsLoading, setAnalyticsLoading] = React.useState(false);

  useEffect(() => {
    if (props.showAnalyticsContent) {
      setAnalyticsLoading(true);
      const now = new Date();
      const to = format(now, dateFormat);
      const from = format(subYears(now, 1), dateFormat);
      dispatch(
        fetchProcessExceptionsAnalyticsKpi(
          filterExceptionList[exceptionCurrentModule.PROCESSES].process?.map((e) => e.id),
          [],
          [],
          fleets,
          from,
          to,
          showHidden,
          () => setAnalyticsLoading(false)
        ),
      );
    }
  }, [props.showAnalyticsContent, data, filterExceptionList]);

  const handleChangeSearchText = (value) => {
    dispatch(
      updateFilter({
        ...filterExceptionList,
        [exceptionCurrentModule.PROCESSES]: {
          pageNo: 0,
          searchText: value,
        },
      }),
    );
  };

  const handleChangeTag = (values) =>
  {
    dispatch(
        updateFilter({
          ...filterExceptionList,
          [exceptionCurrentModule.PROCESSES]: {
            pageNo: 0,
            tags: values
          },
        }),
    );
  }
  return (
    <Grid
      container
      spacing={4}
      alignItems="stretch"
      className={classes.rootGlobal}
      ref={containerRef}
    >
      <PageHeader title="exceptions.processes.tab.title" />
      <Grid container xs={12} direction="row" justify="space-between">
        <Grid container xs={12}>
          <Filter
            indeterminateCheck={
              data?.length > 0
              && data.some((d) => props.selected.includes(d.id))
            }
            checkedAll={
              data?.length > 0
              && data.every((d) => props.selected.includes(d.id))
            }
            selected={props.selected}
            classes={classes}
            processes={processes}
            exceptionsType={exceptionsType}
            exceptions={exceptions}
            handleChangeException={handleChangeException}
            handleChangeExceptionType={handleChangeExceptionType}
            handleChangeProcess={handleChangeProcess}
            handleChangeDates={handleChangeDates}
            handleRequestSort={handleRequestSort}
            handleChangeDivision={handleChangeDivision}
            handleChangeFleet={handleChangeFleet}
            handleChangeTag={handleChangeTag}
            onDelete={deleteExceptions}
            onShow={showExceptions}
            numSelected={props.selected?.length}
            onSelectAllClick={handleSelectAllClick}
            rowCount={data?.length}
            exceptionTypeName={[]}
            exceptionName={[]}
            processName={processName}
            exportExceptionXLSX={exportItemExceptionXLSX}
            deletePermission={deletePermission}
            exportLoading={props.exportLoading}
            setExportLoading={props.setExportLoading}
            showAnalyticsContent={props.showAnalyticsContent}
            setShowAnalyticsContent={props.setShowAnalyticsContent}
            analyticsLoading={analyticsLoading}
            setAnalyticsLoading={setAnalyticsLoading}
            executionStartTime={null}
            executionEndTime={null}
            sortColumns={sortColumns}
            handleChangeShowHidden={handleChangeShowHidden}
            showHidden={showHidden}
            handleChangeSearchText={handleChangeSearchText}
            setProcess={setProcess}
            process={process}
            exceptionsToShow={props.exceptionsToShow}
            exceptionsToHide={props.exceptionsToHide}
            durations={props.durations}
            currModule={exceptionCurrentModule.PROCESSES}
          />
        </Grid>
      </Grid>

      {props.showAnalyticsContent
        // eslint-disable-next-line no-nested-ternary
        && (!analyticsLoading && data && data.length ? (
          <Grid item container xs={12}>
            <Analytics
              kpiData={kpiData}
              processesId={processName}
              exceptions={[]}
              exceptionsType={[]}
              fleetIds={fleets}
              executionStartTime={null}
              executionEndTime={null}
              setAnalyticsLoading={setAnalyticsLoading}
              setShowAnalyticsContent={props.setShowAnalyticsContent}
              containerRef={containerRef}
            />
          </Grid>
        ) : (!props.isLoading && analyticsLoading) ? (
          <Grid item xs m={4}>
            <CircularLoader />
          </Grid>
        ) : (
          null
        ))}
      {!props.isLoading && data ? (
        <ExceptionsContent
          data={data}
          t={t}
          classes={classes}
          getDate={props.getDate}
          getDateDifference={props.getDateDifference}
          deleteException={deleteException}
          isSelected={props.isSelected}
          selected={props.selected}
          setSelected={props.setSelected}
          handleClick={props.handleClick}
          deletePermission={deletePermission}
          endDateLabel={t("End date")}
        />
      ) : (
        <CircularLoader />
      )}
      <Grid container xs={12} direction="row" justify="flex-end">
        { data?.length > 0 && <CustomPagination
          rowsPerPageOptions={[5, 10, 25]}
          count={count ?? 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          onNext={handleNext}
          onPrevious={handlePrevious}
        />}
      </Grid>
      {openMsgDetail && (
        <Details
          handleClose={closeDialog}
          logDetail={props.logDetail}
          data={props.detail}
          validate={props.validate}
          openStart={openMsgDetail}
          onCancel={cancelConfirm}
          onConfirm={confirmDelete}
        />
      )}
      {props.openMsgConfirm && (
        <ConfirmMessage
          message={props.messageConfirm}
          openStart={props.openMsgConfirm}
          onCancel={cancelConfirm}
          onConfirm={confirmDelete}
          buttonConfirm={deleteButtonContent}
          buttonCancel={t("Cancel")}
          isDelete="true"
          isLoading={props.isLoadingConfirm}
        />
      )}
    </Grid>
  );
}

const mapDispatchToProps = {
  fetchProcessExecutionExceptions,
  fetchProcessesNameException,
  fetchProcessExecutionExceptionsReason,
  fetchProcessExecutionExceptionsType,
  deleteProcessExecutionExceptions,
  showProcessExecutionExceptions,
  deleteProcessExecutionException,
};
export default connect(null, mapDispatchToProps)(ProcessesExceptions);
