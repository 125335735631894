import React, { useEffect } from "react";
import { format } from "date-fns";
import Grid from "@material-ui/core/Grid";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DateTimePicker } from "@material-ui/pickers";
import { useTranslation } from "react-i18next";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CustomSwitch from "../CustomSwitch";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import MuiDialogActions from "@material-ui/core/DialogActions";
import SaveIcon from "@material-ui/icons/Save";
import RefreshIcon from "@material-ui/icons/Refresh";
import { useForm } from "react-hook-form";
import FormControl from "@material-ui/core/FormControl";
import { CalendarToday } from "@material-ui/icons";
import { connect, useDispatch } from "react-redux";
import AddIcon from "@material-ui/icons/Add";
import Divider from "@material-ui/core/Divider";
import {
  saveSchedule,
  saveScheduleInSRQueues,
  updateSchedule,
  updateScheduleInSRQueues,
  getQueuRobots,
    isProcessAssignedToQueue
}
  from "../../../../redux/actions/services";
import ConfirmMessage from "../../../../components/ConfirmMessage";
import { formatDatePickerByLanguage, getLocale } from "../../../../util";
import RobotDialog from "../Robot";
import SchedulePeriod from "../SchedulePeriod/SchedulePeriod";
import ScheduleInfo from "../ScheduleInfo";
import { toast } from "react-toastify";
import CustomButton from "../../../../components/CustomButton";
import useStyles from "./style";

function TabPanel(props) {
  const {
    children, value, index, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3} style={{ alignItems: "left", justifyContent: "left" }}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function getDateForm(dateIn) {
  const dt = new Date(dateIn);
  const pattern = "yyyy-MM-dd'T'HH:mm";
  return format(dt, pattern);
}
function ScheduleForm(props) {
  const { process, showFromParent } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const [tabValue, setTabValue] = React.useState(0);
  const [dataToSave, setDataToSave] = React.useState(null);
  const [openMsgConfirm, setOpenMsgConfirm] = React.useState(false);
  const [openMsgDiscard, setOpenMsgDiscard] = React.useState(false);
  const [scheduleEdit, setScheduleEdit] = React.useState(null);
  const [showForm, setShowForm] = React.useState(
    showFromParent || false,
  );
  const [endDateCheck, setEndDateCheck] = React.useState(false);
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [openRobotDialog, setOpenRobotDialog] = React.useState(false);
  const [queueRobots, setQueueRobots] = React.useState(null);
  const [selectedRobotName, setSelectedRobotName] = React.useState(null);
  const [isAssignedToQueue, setIsAssignedToQueue] = React.useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
    clearErrors,
    setValue,
  } = useForm({
    mode: "onChange",
  });

  const postScheduleAction = (result, successMsg, failureMsg) => {
    if ([200, 201].includes(result.status)) toast.success(t(successMsg))
    else if (result?.error?.response?.status === 409) toast.error(t(result.error.response.data?.detail))
    else toast.error(t(failureMsg))
    setOpenMsgConfirm(false);
    editShowForm();
    setIsLoading(false);
  }
  const confirmSave = () => {
    setIsLoading(true);
    if (scheduleEdit != null) {
      isAssignedToQueue
        ? props.updateScheduleInSRQueues(dataToSave).then((result) => {
          postScheduleAction(
            result,
            "Schedule modified successfully",
            "Failed to update the schedule! Please try again later"
          )
        })
        : props.updateSchedule(dataToSave).then((result) => {
          postScheduleAction(
            result,
            "Schedule modified successfully",
            "Failed to update the schedule! Please try again later"
          )
        })
    } else {
      isAssignedToQueue
      ? props.saveScheduleInSRQueues(dataToSave).then((result) => {
          postScheduleAction(
            result,
            "The schedule was created successfully",
            "Failed to create the schedule! Please try again later"
          )
        })
        : props.saveSchedule(dataToSave).then((result) => {
          postScheduleAction(
            result,
            "The schedule was created successfully",
            "Failed to create the schedule! Please try again later"
          )
        })
    }
    setSelectedRobotName(null);
  };
  const confirmDiscard = () => {
    setOpenMsgDiscard(false);
    toast.success(t("Changes disregarded"))
    editShowForm();
  };

  const cancelConfirm = () => {
    setOpenMsgConfirm(false);
    setSelectedRobotName(null);
  };
  const cancelDiscard = () => {
    setOpenMsgDiscard(false);
  };
  const onSubmit = (data) => {
    if (data && data.name && startDate) {
      props.isProcessAssignedToQueue(process?.id)
          .then((result) => {
            setIsAssignedToQueue(result.data);
          })
      const dataToSend = data;
      dataToSend.name = data.name.trim();
      dataToSend.scheduleOrgId = scheduleEdit != null ? scheduleEdit.scheduleOrgId : null;
      dataToSend.startDate = getDateForm(startDate);
      dataToSend.endDate = endDate ? getDateForm(endDate) : null;
      dataToSend.period = data.period;
      dataToSend.unitType = tabValue + 1;
      dataToSend.processOriginalId = process.originalId;
      dataToSend.processId = process?.id;
      dataToSend.description = "";
      dataToSend.priority = 1;
      dataToSend.processInputs = "";
      dataToSend.orchestratorId = process.orchestrator.id;
      dataToSend.resource = selectedRobotName;
      dataToSend.isDisabled = false;
      setDataToSave(dataToSend);
      prepareRobotDialog();
      setOpenRobotDialog(true);
    }
  };

  const prepareRobotDialog = () => {
    if (isAssignedToQueue)
    {
      dispatch(getQueuRobots(process.queueId)).then((response) => {
        setQueueRobots(response.data);
      })
    }
  }
  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleChange = (event) => {
    setEndDateCheck(event.target.checked);
    if (!event.target.checked) {
      reset({
        name: getValues("name"),
        startDate: getValues("startDate"),
        endDate: "",
        period: getValues("period"),
      });
      setEndDate(null);
    }
  };

  const editShowForm = () => {
    setShowForm(!showForm);
    if (!showForm) {
      reset({
        name: "", startDate: "", endDate: "", period: 1,
      });
      if (scheduleEdit === null) {
        setStartDate(null);
        setEndDate(null);
      }
      setEndDateCheck(false);
    }
  };
  const showMsgDiscard = () => {
    setOpenMsgDiscard(true);
  };
  const onEdit = (row) => {
    setScheduleEdit(row);
    setTabValue(row.unitType - 1);
    setEndDateCheck(!!row.endDate);
    setShowForm(!showForm);
    reset({
      name: row.scheduleName,
      startDate: new Date(row.endDate),
      endDate: new Date(row.endDate),
      period: row.period,
    });
    setStartDate(new Date(row.startDate).toISOString());
    setEndDate(row.endDate ? new Date(row.endDate) : null);
  };
  const changeStarDate = (value) => {
    setStartDate(value);
    setValue("startDate", value);
    clearErrors("startDate");
  };

  const changeEndDate = (value) => {
    setEndDate(value);
    setValue("endDate", value);
  };

  const cancelRobotConfirm = () => {
    setOpenRobotDialog(false);
  };

  const confirmRobotSave = () => {
    setOpenRobotDialog(false);
    setDataToSave({ ...dataToSave, resource: selectedRobotName });
    setOpenMsgConfirm(true);
  };

  useEffect(() => {
    prepareRobotDialog()
  }, [isAssignedToQueue]);
  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={classes.root}
      noValidate
      autoComplete="off"
      style={{ height: "100%" }}
    >
      {showForm && (
        <Grid>
          <Grid container className={classes.scheduleContainer}>
            <Grid item xs={3}>
              <Box mt={2.5}>
                <Typography variant="subtitle1">{t("Name")}</Typography>
              </Box>
            </Grid>
            <Grid item xs={9}>
              <TextField
                fullWidth
                type="text"
                id="name"
                {...register("name", {
                  required: true,
                  validate: (value) => !!value.trim(),
                })}
              />

              {errors.name && (
                <Typography color="error">
                  {t("Your input is required")}
                </Typography>
              )}
            </Grid>
            <Grid item xs={3}>
              <Box mt={4}>
                <Typography variant="subtitle1">{t("Start Date")}</Typography>
              </Box>
            </Grid>
            <Grid item xs={9} mb={1.5}>
              <MuiPickersUtilsProvider
                utils={DateFnsUtils}
                locale={getLocale()}
              >
                <FormControl margin="normal" fullWidth>
                  <DateTimePicker
                    InputProps={{ endAdornment: <CalendarToday /> }}
                    renderInput={(params) => (
                      <TextField className={classes.input} {...params} />
                    )}
                    {...register("startDate", { required: true })}
                    value={startDate}
                    onChange={changeStarDate}
                    disablePast={!scheduleEdit}
                    showTodayButton
                    fullWidth
                    minDate={startDate || new Date()}
                    format={formatDatePickerByLanguage()}
                    cancelLabel={t("user.button.cancel")}
                    todayLabel={t("Today")}
                    minutesStep={30}
                    ampm={false}
                  />
                </FormControl>
              </MuiPickersUtilsProvider>
              {errors.startDate && (
                <Typography color="error">
                  {t("Your input is required")}
                </Typography>
              )}
            </Grid>
            <Grid item xs={3}>
              <Box mt={2.5}>
                <Typography variant="subtitle1">
                  {t("End date")}
                  <FormControlLabel
                    style={{ marginLeft: "8px" }}
                    control={(
                      <CustomSwitch
                        checked={endDateCheck}
                        handleChange={handleChange}
                        name="endDateCheck"
                      />
                    )}
                  />
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={9}>
              <MuiPickersUtilsProvider
                utils={DateFnsUtils}
                locale={getLocale()}
              >
                <FormControl margin="normal" fullWidth>
                  <DateTimePicker
                    InputProps={{ endAdornment: <CalendarToday /> }}
                    renderInput={(params) => (
                      <TextField className={classes.input} {...params} />
                    )}
                    {...register("endDate", {
                      disabled: !endDateCheck,
                      required: endDateCheck,
                      validate: (data) => {
                        const date = new Date(data);
                        return endDateCheck && startDate
                          ? date.getTime() >= new Date(startDate).getTime()
                          : true;
                      },
                    })}
                    value={endDate}
                    onChange={changeEndDate}
                    disablePast={!scheduleEdit}
                    showTodayButton
                    minDate={startDate || new Date()}
                    format={formatDatePickerByLanguage()}
                    fullWidth
                    cancelLabel={t("user.button.cancel")}
                    todayLabel={t("Today")}
                    ampm={false}
                  />
                </FormControl>
              </MuiPickersUtilsProvider>
              {errors.endDate && errors.endDate.type === "required" && (
                <Typography color="error">
                  {t("Your input is required")}
                  {" "}
                </Typography>
              )}
              {errors.endDate && errors.endDate.type === "validate" && (
                <Typography color="error">
                  {t("End Date should not be before Start Date")}
                </Typography>
              )}
            </Grid>

            <Grid item xs={12}>
              <SchedulePeriod
                classes={classes}
                value={tabValue}
                handleChangeTab={handleChangeTab}
                register={register}
                errors={errors}
                startDate={startDate}
              />
            </Grid>
          </Grid>
          <Divider />
          <MuiDialogActions className={classes.cardActions}>
            <Box
              ml="1rem"
              component={Button}
              variant="contained"
              size="medium"
              onClick={showMsgDiscard}
              className={classes.resetButton}
              startIcon={<RefreshIcon />}
            >
              {t("Discard")}
            </Box>
            <CustomButton
              view="primary"
              size="medium"
              type="submit"
              startIcon={<SaveIcon />}
            >
              {scheduleEdit ? t("Edit schedule") : t("Create schedule")}
            </CustomButton>
          </MuiDialogActions>
        </Grid>
      )}
      {openMsgConfirm && (
        <ConfirmMessage
          message={
            scheduleEdit
              ? t("Are you sure you want to save the changes?")
              : t("Are you sure you want to create this schedule ?")
          }
          openStart={openMsgConfirm}
          onCancel={cancelConfirm}
          onConfirm={confirmSave}
          buttonConfirm={
            scheduleEdit ? t("Save changes") : t("Create schedule")
          }
          buttonCancel={t("Cancel")}
          isLoading={isLoading}
        />
      )}
      {openRobotDialog && (
        <RobotDialog
          message={t("Select a robot")}
          openStart={openRobotDialog}
          onCancel={cancelRobotConfirm}
          onConfirm={confirmRobotSave}
          buttonConfirm={t("Validate")}
          buttonCancel={t("Cancel")}
          _robots={isAssignedToQueue ? queueRobots : process?.robots}
          setSelectedRobotName={setSelectedRobotName}
          selectedRobotName={selectedRobotName}
          isRobotRequired={!isAssignedToQueue}
        />
      )}
      {openMsgDiscard && (
        <ConfirmMessage
          message={t("Are you sure you want to disregard the changes ?")}
          openStart={openMsgDiscard}
          onCancel={cancelDiscard}
          onConfirm={confirmDiscard}
          buttonConfirm={t("Disregard changes")}
          buttonCancel={t("Cancel")}
        />
      )}
      {!showForm && (
        <Grid container style={{ height: "100%" }}>
          <Grid item xs={12} style={{ height: "100%" }}>
            <ScheduleInfo
              onClickEdit={onEdit}
              process={process}
              classes={classes}
              isAssignedToQueue={isAssignedToQueue}
              button={(
                <CustomButton
                  view="primary"
                  size="medium"
                  onClick={editShowForm}
                  startIcon={<AddIcon />}
                >
                  {t("add.schedule")}
                </CustomButton>
              )}
            />
          </Grid>
        </Grid>
      )}
    </form>
  );
}
const mapDispatchToProps = {
  saveSchedule,
  saveScheduleInSRQueues,
  updateSchedule,
  updateScheduleInSRQueues,
  isProcessAssignedToQueue
};
export default connect(null, mapDispatchToProps)(ScheduleForm);
