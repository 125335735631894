import React, { useState, useEffect } from "react";
import moment from "moment";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";
import FullscreenExitIcon from "@material-ui/icons/FullscreenExit";
import Badge from "@material-ui/core/Badge";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Paper from "@material-ui/core/Paper";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { Add, Settings } from "@material-ui/icons";
import { useTranslation, withTranslation } from "react-i18next";
import "./style.css";
import {
  fetchProcessesExecutionsPlanner,
  fetchProcesses,
  fetchOrchestratorsNamesAndIds,
  fetchPlannerProcessesList,
  updateProcessRiskTime,
  fetchTagsForFilters,
  fetchRobotsByProcesses,
} from "../../redux/actions/services";
import { useDispatch, useSelector } from "react-redux";
import CalendarScheduleForm from "./Components/ScheduleForm";
import RiskManagement from "./Components/RiskManagement";
import CustomToolbar from "./Components/CustomToolBar";
import TreeDaysView from "./Components/TreeDaysView";
import { getRandomColor } from "../../util";
import useStyles, { DayStyledCard, StyledCard } from "./style";
import {
  getDayGridRowStart,
  getSpanNumber,
  getWeekGridRowStart,
} from "./utils";
import PageHeader from "../../components/PageHeader";

import {
  setOrchestrator,
  setFullScreen,
  setRobot,
} from "../../redux/slices/plannerSlice";
import PlannerWarnings from "./Components/PlannerWarnings";
import { HasPermission } from "../../components/HasPermission";
import { groupBy, orderBy } from "lodash";
import clsx from "clsx";
import ClearFilter from "../../components/ClearFilter";
import NoDataMenu from "../../components/NoData/NoDataMenu";
import CustomSelectField from "../../components/FormFields/CustomSelectField";
import CustomAutoComplete from "../../components/FormFields/CustomAutoComplete";
import CustomButton from "../../components/CustomButton";
import { isSchedulableDisabled } from "../../util/helpers/filters";

const CalendarComponent = ({ i18n }) => {
  const classes = useStyles();
  moment.updateLocale(i18n.language, {
    week: {
      dow: 1,
    },
  });
  const localizer = momentLocalizer(moment);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [plannerData, setPlannerData] = useState();
  const [isModalOpen, setModalOpen] = React.useState(false);
  const plannerState = useSelector(({ planner }) => planner);
  const [currentDate, setCurrentDate] = React.useState(new Date());
  const [processesData, setProcessesData] = React.useState(null);
  const [robotData, setRobotData] = React.useState(null);
  const [orchestrator, setOrchastrator] = React.useState(null);
  const [selectedProcesses, setSelectedProcesses] = React.useState([]);
  const [selectedOrchestrator, setSelectedOrchestrator] = React.useState("");
  const [selectedRobot, setSelectedRobot] = React.useState(null);
  const [openSettings, setSettings] = React.useState(false);
  const [openWarnings, setWarnings] = React.useState(false);
  const [warningsData, setWarningsData] = React.useState([]);
  const [warningsDataForSelectedDate, setWarningsDataForSelectedDate] = React.useState([]);
  const [warningsCount, setWarningsCount] = React.useState([]);
  const [selectedDay, setSelectedDay] = React.useState();
  const [tags, setTags] = useState([]);
  const [selectedTags, setSelectedTags] = React.useState([]);
  const isInFullScreenValue = () => (
    (document.fullscreenElement && document.fullscreenElement !== null)
      || (document.webkitFullscreenElement
        && document.webkitFullscreenElement !== null)
      || (document.mozFullScreenElement
        && document.mozFullScreenElement !== null)
      || (document.msFullscreenElement && document.msFullscreenElement !== null)
  );

  const toggleFullScreen = () => {
    const isInFullScreen = isInFullScreenValue();
    const docElm = document.documentElement;
    if (!isInFullScreen) {
      if (docElm.requestFullscreen) {
        docElm.requestFullscreen();
      } else if (docElm.mozRequestFullScreen) {
        docElm.mozRequestFullScreen();
      } else if (docElm.webkitRequestFullScreen) {
        docElm.webkitRequestFullScreen();
      } else if (docElm.msRequestFullscreen) {
        docElm.msRequestFullscreen();
      }
    } else if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }
    dispatch(setFullScreen(!plannerState?.isFullScreen));
  };

  const exitHandler = () => {
    if (
      !document.fullscreenElement
      && !document.webkitIsFullScreen
      && !document.mozFullScreen
      && !document.msFullscreenElement
    ) {
      dispatch(setFullScreen(false));
    }
  };

  // adding listenners prefixes for cross-browser codes

  document.addEventListener("fullscreenchange", exitHandler);
  document.addEventListener("webkitfullscreenchange", exitHandler);
  document.addEventListener("mozfullscreenchange", exitHandler);
  document.addEventListener("MSFullscreenChange", exitHandler);

  const processes = useSelector(
    ({ requests }) => requests.queries.FETCH_PLANNER_PROCESSES_LIST?.data,
  );

  const orchestrators = useSelector(
    ({ requests }) => requests.queries.FETCH_ORCHESTRATORS_NAMES_AND_IDS?.data,
  );

  const robots = useSelector(
    ({ requests }) => requests.queries.FETCH_ROBOTS_BY_PROCESSES?.data,
  );
  const plannerSelectedRobot = useSelector(
    ({ planner }) => planner.selectedRobot,
  );

  useEffect(() => {
    dispatch(fetchPlannerProcessesList()).then((res) => {
      dispatch(fetchRobotsByProcesses(res.data.map(({ id }) => id)))
  })
    if (!orchestrators) dispatch(fetchOrchestratorsNamesAndIds());
  }, []);

  useEffect(() => {
    if (orchestrators) {
      setOrchastrator(
        orchestrators?.map((e) => ({
          id: e?.id,
          title: e?.name,
          sessionNumber: e?.sessionNumber,
          orchestratorType: e?.orchestratorType
        })),
      );
    }
  }, [orchestrators]);

  useEffect(() => {
    if (robots?.length) {
      setRobotData(
        robots?.map((e) => ({
          id: e.id,
          title: e.robotDisplayName,
          orchestratorId: e.orchestrator?.id,
        })),
      );
    }
  }, [robots]);

  useEffect(() => {
    if (robotData?.length === 1) {
      dispatch(setRobot(...robotData));
    }
  }, [robotData]);
  useEffect(() => {
      setSelectedRobot(plannerSelectedRobot);
  }, [plannerSelectedRobot]);

  useEffect(() => {
    if (processes) {
      setProcessesData(
        processes
          .filter((p) => p.isSchedulable)
          .map((e, i) => ({
            processId: e.id,
            processOriginalId: e.originalId,
            title: e.processDisplayName,
            orchestratorId: e.orchestratorId,
            hexColor: getRandomColor(i),
            robotId: e.robots.map((e) => e.id),
            riskTime: e.riskTime,
          })),
      );
    }
  }, [processes]);

  useEffect(() => {
    if (orchestrator && plannerState?.selectedOrchestrator) {
      handleChangeOrchestrator(
        Object.values(orchestrator)?.find(
          ({ id }) => id === plannerState?.selectedOrchestrator.id,
        ),
      );
    } else if (orchestrator) {
      handleChangeOrchestrator(orchestrator[0]);
    }
  }, [orchestrator]);

  const handleChangeOrchestrator = (value) => {
    reloadPlannerData();
    dispatch(setOrchestrator(value));
    if (value) setSelectedOrchestrator(value);
    setSelectedRobot(null);
    setSelectedProcesses([]);
  };

  const handleChangeProcess = (newProcess) => {
    if (newProcess) setSelectedProcesses(newProcess);
  };

  const handleChangeRobot = (newRobot) => {
    if (newRobot) {
      dispatch(setRobot(newRobot));
      setSelectedRobot(newRobot);
      setSelectedProcesses([]);
    }
  };

  const handleClose = () => {
    setModalOpen(false);
  };
  const handleOpenSettings = () => {
    setSettings(true);
  };

  const handleCloseSettings = () => {
    setSettings(false);
  };

  const handleOpenWarnings = (event, date) => {
    setSelectedDay(date);
    event.stopPropagation();
    setWarnings(true);
  };

  const warningsLogic = () => {
    const warningsCount = [];
    const warningsOfWeekDays = [];
    if (plannerData?.length > 0) {
      // eslint-disable-next-line no-plusplus
      for (let j = 0; j < 7; j++) {
        const warningsOffAllTheWeek = [];
        const firstDayOfWeek = moment(currentDate).startOf("week").toDate();
        const iDayOfWeek = moment(firstDayOfWeek).add(j, "days");
        const plannerDataForChosenDate = getPlannerDataForChosenDate(
          plannerData,
          iDayOfWeek,
        );
        if (plannerDataForChosenDate && plannerDataForChosenDate.length > 0) {
          let numberOfWarningForChosenDate = 0;
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < 48; i++) {
            let counter = 0;
            const startTimeInterval = moment(iDayOfWeek).add(i * 0.5, "hours");
            const endTimeInterval = moment(startTimeInterval).add(0.5, "hours");
            const warningObj = {
              startDateRange: startTimeInterval.toDate(),
              endDateRange: endTimeInterval.toDate(),
              planners: [],
              reason: [],
              processes: [],
            };
            const processes = [];
            plannerDataForChosenDate.forEach((planner) => {
              if (
                (planner.start <= startTimeInterval && planner.end >= endTimeInterval)
                || (planner.start < startTimeInterval && planner.end > startTimeInterval && planner.end <= endTimeInterval)
                || (planner.start >= startTimeInterval && planner.start < endTimeInterval)
              ) {
                counter += 1;
                warningObj.planners.push(planner);
                processes.push({
                  robotName: planner.robotName,
                  robotDisplayName: planner.robotDisplayName,
                  processName: planner.processName,
                  // to prioritize the executions of scheduled processes, we assume that a process without restrictions has the highest priority of 100
                  processPriority: planner.priority || 100,
                  processStartDate: planner.start,
                });
              }
            });
            let addWarning = false;
            if (counter > selectedOrchestrator.sessionNumber) {
              addWarning = true;
              warningObj.reason.push(
                t(
                  "allowedSessionsExceededForAnOrchestrator",
                  { parallelExecutions: counter, OrhcestratorName: selectedOrchestrator.title, allowedSessions: selectedOrchestrator.sessionNumber },
                ),
              );
            }
            const groupedExecutionsByRobot = groupBy(processes, "robotDisplayName");
            let robotConcurentExecutionsMessage = "";
            // eslint-disable-next-line guard-for-in, no-restricted-syntax
            for (const key in groupedExecutionsByRobot) {
              if (groupedExecutionsByRobot[key].length > 1) {
                addWarning = true;
                robotConcurentExecutionsMessage = t(
                  "concurrentExecutionInTheSameRobotMessage",
                  {
                    robotName: key,
                    plannerStart: moment(startTimeInterval).format("HH:mm A"),
                    plannerEnd: moment(endTimeInterval).format("HH:mm A"),
                    executionDate: moment(startTimeInterval).format("HH:mm A"),
                  },
                );
                const orderedExecutionsByPriority = orderBy(
                  groupedExecutionsByRobot[key],
                  "processPriority",
                  "asc",
                );
                robotConcurentExecutionsMessage += orderedExecutionsByPriority.map(({ processName }) => processName).join(", ");
                warningObj.reason.push(robotConcurentExecutionsMessage);
              }
              robotConcurentExecutionsMessage = "";
            }
            if (addWarning) {
              numberOfWarningForChosenDate += 1;
              warningObj.processes.push(processes);
              warningsOffAllTheWeek.push(warningObj);
            }
          }
          warningsOfWeekDays.push(warningsOffAllTheWeek);
          warningsCount.push({ iDayOfWeek, numberOfWarningForChosenDate });
        } else {
          warningsOfWeekDays.push(null);
        }
      }
    }
    setWarningsData(warningsOfWeekDays);
    setWarningsCount(warningsCount);
  };

  useEffect(() => {
    warningsLogic();
  }, [plannerData, t]);

  useEffect(() => {
    if (warningsData?.length > 0 && selectedDay) {
      setWarningsDataForSelectedDate(
        warningsData[
          moment(selectedDay).day() - 1 !== -1
            ? moment(selectedDay).day() - 1
            : 6
        ],
      );
    }
  }, [selectedDay]);

  const getPlannerDataForChosenDate = (plannerData, selectedDay) => plannerData.filter(
    (planner) => planner.start >= selectedDay
      && planner.end <= moment(selectedDay).add(1, "days"),
  );

  const handleCloseWarnings = () => {
    setWarnings(false);
  };
  const handleOpen = () => {
    setModalOpen(true);
  };

  const processPlannerData = useSelector(
    ({ requests }) => requests.queries.FETCH_PROCESS_PLANNER?.data,
  );
  const reloadPlannerData = (date) => {
    let processId = [];
    if (selectedProcesses) processId = selectedProcesses.map((e) => e?.processId);
    dispatch(
        fetchProcessesExecutionsPlanner(
            moment(date || currentDate).format("yyyy-MM-DD"),
            selectedOrchestrator?.id,
            processId,
            selectedRobot?.id,
            selectedTags
        ),
    )
  };

  useEffect(() => {
    if (selectedOrchestrator?.id) reloadPlannerData();
  }, [selectedOrchestrator]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (selectedProcesses) reloadPlannerData();
  }, [selectedProcesses]);

  useEffect(() => {
    if (selectedRobot) reloadPlannerData();
  }, [selectedRobot]);

  useEffect(() => {
    if (selectedTags) reloadPlannerData();
  }, [selectedTags]);

  const refreshData = React.useCallback(
    (date) => {
      if (Math.abs(moment(date).startOf("week").diff(moment(currentDate).startOf("week").toDate(), "days")) >= 7
        || Math.abs(moment(date).startOf("week").diff(moment(currentDate).endOf("week").toDate(), "days")) > 0) {
        reloadPlannerData(date);
        setCurrentDate(date);
      }
    },
    [selectedOrchestrator, selectedRobot, selectedProcesses, currentDate],
  ); // eslint-disable-line react-hooks/exhaustive-deps

  const Event = ({ event, children }) => {
    const { title } = children.props;
    const [isModalOpen, setModalOpen] = React.useState(false);
    const [value, setValue] = React.useState(0);
    const ref = React.useRef(null);
    const [isVertical, setIsVertical] = React.useState(true);
    useEffect(() => {
      if (ref.current) {
        if (ref.current.offsetWidth >= 70) {
          setIsVertical(false);
        }
      }
    }, [ref.current]);
    const [data, setData] = React.useState(null);

    const handleOpen = (event) => {
      setModalOpen(true);
      setData(event);
    };

    const handleClose = () => {
      setModalOpen(false);
    };

    return (
      <>
        <Tooltip
          title={title}
        >
          <StyledCard
            ref={ref}
            gridrowstart={getWeekGridRowStart(event.start)}
            span={getSpanNumber(event.start, event.end)}
            backgroundcolor={event.hexColor}
            onClick={() => handleOpen(event)}
          >
            <Typography
              className={isVertical ? classes.vertical : classes.horizontal}
            >
              {event.title}
            </Typography>
          </StyledCard>
        </Tooltip>
        {isModalOpen && (
          <CalendarScheduleForm
            data={data}
            editMode
            openStart={isModalOpen}
            handleClose={handleClose}
            value={value}
            t={t}
            setModalOpen={setModalOpen}
            setValue={setValue}
            reloadPlannerData={reloadPlannerData}
          />
        )}
      </>
    );
  };

  const EventDay = ({ event, children }) => {
    const { title } = children.props;
    const [value, setValue] = React.useState(0);
    const [isModalOpenevent, setModalOpenEvent] = React.useState(false);
    const [data, setData] = React.useState(null);

    const handleOpen = (event) => {
      setModalOpenEvent(true);
      setData(event);
    };
    const handleClose = () => {
      setModalOpenEvent(false);
    };

    return (
      <>
        <DayStyledCard
          title={title}
          onClick={() => handleOpen(event)}
          gridRowStart={getDayGridRowStart(
            moment(currentDate).format("dddd, MMMM D yyyy"),
            event.start,
          )}
          span={getSpanNumber(event.start, event.end)}
          backgroundColor={event.hexColor}
        >
          {event.processName}
        </DayStyledCard>
        {isModalOpenevent && (
          <CalendarScheduleForm
            data={data}
            editMode
            openStart={isModalOpenevent}
            handleClose={handleClose}
            value={value}
            t={t}
            setModalOpen={setModalOpen}
            setValue={setValue}
            reloadPlannerData={reloadPlannerData}
          />
        )}
      </>
    );
  };

  useEffect(() => {
    if (processPlannerData) {
      const data = [];
      processPlannerData.forEach((e) => {
        // Split times if start date and end date not in the same day
        if (moment(e.start).toDate().getDate() !== moment(e.end).toDate().getDate()) {
          const newPlanner = { ...e };
          newPlanner.end = moment(e.start).endOf("day").toDate();
          data.push({
            ...newPlanner,
            start: new Date(newPlanner.start),
            end: new Date(newPlanner.end),
            originalStart: newPlanner.originalStartDate
              ? new Date(newPlanner.originalStartDate)
              : null,
            originalEnd: newPlanner.originalEndDate ? new Date(newPlanner.originalEndDate) : null,
          });
          e.start = moment(e.start).startOf("day").toDate();
        }
        data.push({
          ...e,
          start: new Date(e.start),
          end: new Date(e.end),
          originalStart: e.originalStartDate
            ? new Date(e.originalStartDate)
            : null,
          originalEnd: e.originalEndDate ? new Date(e.originalEndDate) : null,
        });
      });

      const displayedData = processesData
        ? data?.map((e) => ({
          ...e,
          hexColor: Object.values(processesData)
            .filter((f) => f.title === e.processName)
            .map((color) => color.hexColor),
        }))
        : [];
      setPlannerData(displayedData);
    }
  }, [processPlannerData, processesData]);

  // eslint-disable-next-line no-nested-ternary
  const getFilteredProcesses = (processesData) => (processesData
    ? selectedOrchestrator
      ? processesData.filter((e) => (selectedRobot
        ? e.orchestratorId === selectedOrchestrator?.id
            && e.robotId.includes(selectedRobot.id)
        : e.orchestratorId === selectedOrchestrator?.id))
      : processesData
    : []);

  // eslint-disable-next-line no-nested-ternary
  const getFilteredRobots = () => (robotData
    ? selectedOrchestrator
      ? robotData.filter((e) => e.orchestratorId === selectedOrchestrator?.id)
      : robotData
    : []);

  const handleClear = () => {
    setSelectedRobot(null);
    setSelectedProcesses([]);
    setSelectedTags([]);
  };

  const customHeader = ({ date, culture, localizer }) => (
    <span className={classes.schedulerTitles}>
      {`${t(localizer.format(date, "dddd", culture))} 
        ${localizer.format(date, "DD")} 
      `}
      {
        warningsCount && warningsCount.length > 0 && warningsCount.map((warning) => (
          warning.iDayOfWeek.isSame(date)
            && warning.numberOfWarningForChosenDate > 0
            && (
            <IconButton onClick={(event) => handleOpenWarnings(event, date)}>
              <Badge
                badgeContent={
                  warning.numberOfWarningForChosenDate
                }
                classes={{ badge: classes.alertWarning }}
              >
                <ReportProblemOutlinedIcon fontSize="small" />
              </Badge>
            </IconButton>
            )
        ))
      }
    </span>
  );
  useEffect(() => {
    dispatch(fetchTagsForFilters()).then((res) => {
      setTags(res.data);
    });
  }, [])
  const handleChangeTagsFilter = (event) => {
      setSelectedTags(event?.map(({ id }) => id))
      reloadPlannerData()
  };
  return (
    <>
      <Grid container item xs={12} spacing={1} className={classes.mainContainer}>
        <PageHeader title="Planner" />
        <Grid container item xs={2} className={clsx(classes.filtersContainers)}>
          <CustomSelectField
              options={orchestrator || []}
              optionLabel="title"
              value={selectedOrchestrator}
              onChange={(event) => handleChangeOrchestrator(event.target.value)}
              label={t("orchestrator")}
              formControlClassName={classes.filtersFormControl}
              additionalMenuNode={(!orchestrator || orchestrator.length === 0) && (
              <Box paddingX={1}><NoDataMenu message={t("no.orchestrator.message")} /></Box>
              )}
              isCustom
          />
        </Grid>
        <Grid container item xs={2} className={classes.filtersContainers}>
          <FormControl fullWidth className={classes.filtersFormControl}>
            <CustomAutoComplete
              options={getFilteredRobots(robotData) ?? []}
              optionLabel="title"
              value={selectedRobot}
              noOptionsNode={<NoDataMenu message={t("no.robot.message")} />}
              onChange={handleChangeRobot}
              label={t("robot")}
              translateLabel={false}
          />
          </FormControl>
        </Grid>
        <Grid container item xs={2} className={classes.filtersContainers}>
          <FormControl fullWidth className={classes.filtersFormControl}>
            <CustomAutoComplete
                multiple
                options={tags || []}
                optionLabel="name"
                value={tags?.filter(({ id }) => selectedTags.includes(id))}
                noOptionsNode={<NoDataMenu message={t("no.tags.message")} />}
                onChange={(event) => handleChangeTagsFilter(event)}
                label={t("Tags")}
            />
          </FormControl>
        </Grid>
        <Grid container item xs={2} className={classes.filtersContainers}>
          <FormControl fullWidth className={classes.filtersFormControl}>
            <CustomAutoComplete
                multiple
                options={getFilteredProcesses(processesData) ?? []}
                optionLabel="title"
                optionUuid="processId"
                value={selectedProcesses}
                noOptionsNode={<NoDataMenu message={t("no.process.planned")} />}
                onChange={handleChangeProcess}
                label={t("process")}
                customRenderOption={(option) => (
                  <>
                    <ListItemIcon className={classes.processListItem}>
                      <span
                          style={{
                            backgroundColor: option.hexColor,
                          }}
                          className={classes.processChip}
                      />
                    </ListItemIcon>
                    <ListItemText primary={option.title} />
                  </>
                )}
            />
          </FormControl>
        </Grid>
        <Grid container item xs={4} justify="space-between">
          <Grid
              container
              item
              xs={2}
              alignItems="flex-end"
              className={classes.clearFilterContainer}
          >
            <ClearFilter
                clearFilter={handleClear}
                size="24px"
                label={t("reporting.clear")}
            />
          </Grid>
          <Grid
              container
              item
              xs={10}
              alignItems="flex-end"
              justify="flex-end"
          >
            <HasPermission name="Risk management">
              <CustomButton
                  color="secondary"
                  className={classes.btnBasicMargin}
                  title={t("Risk management")}
                  onClick={handleOpenSettings}
              >
                <Settings />
              </CustomButton>
            </HasPermission>
            {!isSchedulableDisabled(selectedOrchestrator?.orchestratorType) && (
              <CustomButton
                view="primary"
                className={classes.buttonAddScheduler}
                startIcon={<Add />}
                onClick={handleOpen}
              >
                {t("create.scheduler")}
              </CustomButton>
            )}
            <CustomButton
                color="secondary"
                className={classes.btnBasicMargin}
                title={
                  plannerState?.isFullScreen
                      ? t("planner.fullScreenExit")
                      : t("planner.fullScreen")
                }
                onClick={toggleFullScreen}
            >
              {plannerState?.isFullScreen ? (
                <FullscreenExitIcon />
              ) : (
                <FullscreenIcon />
              )}
            </CustomButton>
          </Grid>
        </Grid>
      </Grid>
      <Paper style={{ padding: "20px" }}>
        <Calendar
          className={
            plannerState?.isFullScreen
              ? classes.calendarFullScreen
              : classes.calendar
          }
          localizer={localizer}
          components={{
            toolbar: (props) => <CustomToolbar {...props} handleOpenWarnings={handleOpenWarnings} warningsCount={warningsCount} />,
            header: (props) => customHeader(props),
            day: {
              eventWrapper: EventDay,
            },
            work_week: {
              eventWrapper: Event,
            },
            week: {
              eventWrapper: Event,
            },
          }}
          events={plannerData}
          defaultView="week"
          defaultDate={new Date()}
          startAccessor="start"
          endAccessor="end"
          selectable
          views={{
            day: true,
            week: true,
            work_week: TreeDaysView,
          }}
          step={15}
          onNavigate={(date) => refreshData(date)}
        />
      </Paper>

      <CalendarScheduleForm
        openStart={isModalOpen}
        handleClose={handleClose}
        editMode={false}
        t={t}
        reloadPlannerData={reloadPlannerData}
      />
      <RiskManagement
        t={t}
        processes={processesData}
        openStart={openSettings}
        handleClose={handleCloseSettings}
        dispatch={dispatch}
        updateProcessRiskTime={updateProcessRiskTime}
        reloadPlannerData={reloadPlannerData}
        fetchProcesses={fetchProcesses}
      />
      {selectedDay && (
        <PlannerWarnings
          openStart={openWarnings}
          handleClose={handleCloseWarnings}
          t={t}
          warnings={warningsDataForSelectedDate}
          selectedDay={selectedDay}
          orchestrator={selectedOrchestrator}
        />
      )}
    </>
  );
};

export default withTranslation()(CalendarComponent);
