import React from "react";

import ProcessAssessments from "./ProcessAssessment";

import useStyles from "./style";

export default function ProcessAssessment() {
  const classes = useStyles();

  return (
    <div className={`${classes.root} `}>
      <ProcessAssessments />
    </div>
  );
}
