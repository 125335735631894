import { useHistory } from "react-router-dom";
import { React, useState } from "react";
import { useTranslation } from "react-i18next";
import AppBar from "@material-ui/core/AppBar";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Grid from "@material-ui/core/Grid";
import { useQuery } from "@redux-requests/react";
import { FETCH_CURRENT_USER } from "redux/constants";
import { isSubModulePermitted } from "components/HasPermission";
import ExceptionsPage from "pages/ExceptionsPage";
import SubPagePanel from "components/SubPagePanel";
import WorkItemsPage from "./WorkItemsPage/CompletedItems";
import PendingItems from "./WorkItemsPage/PendingItems";
import SubPageContainer from "components/SubPageContainer";
import useResizeObserver from "util/tabsWidthRefresh";
import useStyles from "./style";

function a11yProps(index) {
  return {
    id: `administrator-tab-${index}`,
    "aria-controls": `administrator-tabpanel-${index}`,
  };
}

export default function ProcessItemPage(props) {
  const history = useHistory();
  const routes = ["/items/itemsExceptions", "/items/completedItems", "/items/pendingItems"];
  const { t } = useTranslation();
  const classes = useStyles();
  const { tabsRef } = useResizeObserver();

  const [value, setValue] = useState((!props.currentUserItems) && routes.indexOf(history.location.pathname) !== -1 ? routes.indexOf(history.location.pathname) : 0);
  const { data: currentUser } = useQuery({ type: FETCH_CURRENT_USER });

  const handleChange = (event, newValue) => {
      history.push(routes[newValue]);
      setValue(newValue);
  };

  return (
    <SubPageContainer>
      <div className={classes.rootTabs}>
        <AppBar elevation={0} position="static">
          <Tabs
            ref={tabsRef}
            value={value}
            onChange={handleChange}
            aria-label="Assets"
            variant="fullWidth"
            className={classes.tabs}
          >
            {isSubModulePermitted(currentUser, "Process Item") && (
              <Tab
              className={classes.tab}
                label={t("exceptions.item.tab.title")}
                disabled={false}
                value={0}
                {...a11yProps(0)}
              />
            )}
            {isSubModulePermitted(currentUser, "Process Item") && (
              <Tab
              className={classes.tab}
                label={t("Process CompletedItems")}
                disabled={false}
                value={1}
                {...a11yProps(1)}
              />
            )}
            {isSubModulePermitted(currentUser, "Process Item") && (
              <Tab
              className={classes.tab}
                label={t("dashboard.graph.pendingItems")}
                disabled={false}
                value={2}
                {...a11yProps(2)}
              />
            )}
          </Tabs>
        </AppBar>
        <Grid className={classes.firstGrid}>
          {isSubModulePermitted(currentUser, "Process Item") && (
            <SubPagePanel value={value} index={0}>
              <ExceptionsPage page="Process Item" currentUserItems={props.currentUserItems} />
            </SubPagePanel>
            )}
          {isSubModulePermitted(currentUser, "Process Item") && (
            <SubPagePanel value={value} index={1}>
              <WorkItemsPage />
            </SubPagePanel>
          )}
          {isSubModulePermitted(currentUser, "Process Item") && (
            <SubPagePanel value={value} index={2}>
              <PendingItems />
            </SubPagePanel>
          )}
        </Grid>
      </div>
    </SubPageContainer>
  );
}
