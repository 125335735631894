import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import TableBody from "@material-ui/core/TableBody";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { updateFilter } from "../../redux/slices/auditFilterSlice";
import PageHeader from "../../components/PageHeader";
import AuditFilter from "./AuditFilter";
import CustomTableContainer from "../../components/TableComponents/CustomTableContainer";
import CustomTable from "../../components/TableComponents/CustomTable";
import CustomTableHeader from "../../components/TableComponents/CustomTableHeader";
import CircularLoader from "../../components/Loaders/CircularLoader";
import CustomPagination from "../Services/components/CustomPagination";
import Audit from "./Audit";
import useStyles from "./style";

export default function Audits() {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = React.useState(false);
  const auditFilters = useSelector(({ auditFilters }) => auditFilters);
  const data = [
    {
      id: 1,
      module: "My process",
      action: "Process started",
      object: "Country Times",
      user: "Oliver McCloskey",
      timeStamp: "12/10/2023 15:25",
    },
    {
      id: 2,
      module: "Planner",
      action: "Schedule added",
      object: "Country Times",
      user: "Oliver McCloskey",
      timeStamp: "12/10/2023 15:25",
    },
    {
      id: 3,
      module: "Item exception",
      action: "Item exception Hidden",
      object: "Country Times",
      user: "Oliver McCloskey",
      timeStamp: "12/10/2023 15:25",
    },
    {
      id: 4,
      module: "My process",
      action: "SLAs updated",
      object: "Country Times",
      user: "Oliver McCloskey",
      timeStamp: "12/10/2023 15:25",
    },
    {
      id: 5,
      module: "Planner",
      action: "Item exception unHidden",
      object: "Country Times",
      user: "Oliver McCloskey",
      timeStamp: "12/10/2023 15:25",
    },
    {
      id: 6,
      module: "My process",
      action: "Process started",
      object: "Country Times",
      user: "Oliver McCloskey",
      timeStamp: "12/10/2023 15:25",
    },
    {
      id: 7,
      module: "Item exception",
      action: "Schedule added",
      object: "Country Times",
      user: "Oliver McCloskey",
      timeStamp: "12/10/2023 15:25",
    },
    {
      id: 8,
      module: "Planner",
      action: "SLAs updated",
      object: "Country Times",
      user: "Oliver McCloskey",
      timeStamp: "12/10/2023 15:25",
    },
    {
      id: 9,
      module: "Item exception",
      action: "Item exception Hidden",
      object: "Country Times",
      user: "Oliver McCloskey",
      timeStamp: "12/10/2023 15:25",
    },
    {
      id: 10,
      module: "My process",
      action: "Process started",
      object: "Country Times",
      user: "Oliver McCloskey",
      timeStamp: "12/10/2023 15:25",
    },
  ];
  const { resultsCount } = { resultsCount: 1234 } || { data } || 0;
  const headerCellProps = {
    paddingBottom: 0,
  };
  const labels = [
    { label: t("audit.module"), ...headerCellProps },
    { label: t("audit.action"), ...headerCellProps },
    { label: t("audit.object"), ...headerCellProps },
    { label: t("audit.user"), ...headerCellProps },
    { label: t("audit.times.stamp"), ...headerCellProps },
  ];
  const handlePageChange = (e, pageNo) => {
    dispatch(
      updateFilter({
        ...auditFilters,
        pageNo,
      })
    );
  };

  const handleRowPerPageChange = (e) => {
    dispatch(
      updateFilter({
        ...auditFilters,
        pageNo: 0,
        pageSize: e.target.value,
      })
    );
  };

  const handleNextPerPage = (pageNo) => {
    dispatch(
      updateFilter({
        ...auditFilters,
        pageNo: pageNo + 1,
      })
    );
  };

  const handlePreviousPage = (pageNo) => {
    dispatch(
      updateFilter({
        ...auditFilters,
        pageNo: pageNo - 1,
      })
    );
  };

  // FIXME: to remove
  useEffect(() => {
    setIsLoading(false)
  }, []);

  return (
    <Grid container spacing={4} className={classes.rootGlobal}>
      <PageHeader title="Audit" />
      <Grid container className={classes.filterRoot} justify="space-between">
        <AuditFilter />
      </Grid>
      <Grid container>
        {!isLoading ? (
          <CustomTableContainer>
            <CustomTable>
              <CustomTableHeader labels={labels} />
              <TableBody>
                {data?.length
                  && data
                    ?.slice(0, auditFilters.pageSize)
                    ?.map((row, i) => (
                      <Audit row={row} key={`audit-row-${i}`} />
                    ))}
              </TableBody>
            </CustomTable>
          </CustomTableContainer>
        ) : (
          <CircularLoader />
        )}
      </Grid>
      <Grid container xs={12} direction="row" justify="flex-end">
        {!isLoading && data && data.length > 0 && (
          <CustomPagination
            count={resultsCount}
            rowsPerPage={auditFilters.pageSize}
            page={auditFilters.pageNo}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowPerPageChange}
            onNext={handleNextPerPage}
            onPrevious={handlePreviousPage}
          />
        )}
      </Grid>
    </Grid>
  );
}
