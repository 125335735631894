import i18n from "../../i18n";

export const formtNumberByLanguage = (numberValue) => {
    if (numberValue === null) return null;
    if (!Number(numberValue)) return numberValue;

    const { language } = i18n;
    let formattingValue = new Intl.NumberFormat("fr-FR").format(numberValue);
    if (language === "en") formattingValue = new Intl.NumberFormat("en-EN").format(numberValue);
    return formattingValue;
};

export const getDefaultFormat = (value) => {
    const { language } = i18n;
    if (typeof value === "string") {
        let newValue = String(value).replaceAll(",", language === "en" ? "" : ".");
        newValue = newValue.split("").filter((i) => i === "0" || !!Number(i) || i === ".").join("");
        return language === "en" ? newValue : Number(newValue);
    }
    return Number(value);
};

export const formatInputOnBlur = (field, form) => {
    const value = getDefaultFormat(form.getValues(field) || "");
    form.setValue(field, formtNumberByLanguage(value));
};

export const formatInputOnFocus = (field, form) => {
    const value = getDefaultFormat(form.getValues(field) || "");
    form.setValue(field, value);
};
