import React, { useEffect } from "react";
import Box from "@material-ui/core/Box";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  resetSrQueues,
  fetchAssignedProcessesByQueue
} from "../../../redux/actions/services/index";
import { triggerQueueAction, updateQueueAction } from "../../../redux/slices/executionManagerSlice";
import {
  ASK_DELETE_QUEUE,
  CONFIRM_DELETE_QUEUE,
  ASK_ENABLE_QUEUE,
  QUEUE_ASSIGN_PROCESS_ACTION,
  ASK_DISABLE_QUEUE,
  QUEUE_UNASSIGN_PROCESS_ACTION,
  CONFIRM_ENABLE_QUEUE,
  CONFIRM_DISABLE_QUEUE,
  ASK_DELETE_ONE_QUEUE,
  ASK_DELETE_QUEUE_FROM_ONE,
} from "util/constants";
import PageHeader from "../../../components/PageHeader";
import ExecutionManager from "../../ExecutionManager";
import ConfirmMessage from "../../../components/ConfirmMessage";
import QueueTable from "./QueueTable";
import { ProcessAssignDialog } from "./ProcessAssignDialog/index"
import useStyles from "../../../components/TableComponents/style";

export default function QueueManagement() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation();
  const [openMsgConfirm, setOpenMsgConfirm] = React.useState(false);
  const [dialogMsg, setDialogMsg] = React.useState("");
  const [queueId, setQueueId] = React.useState(null);
  const [openProcessAssignDialog, setOpenProcessAssignDialog] = React.useState(false);
  const [assignedProcesses, setAssignedProcesses] = React.useState([]);
  const [dialogMsg2, setDialogMsg2] = React.useState("");
  const [hasSchedules, setHasSchedules] = React.useState(false);
  const [hasSchedulesWithoutRobots, setHasSchedulesWithoutRobots] = React.useState(false);
  const [confirmAction, setConfirmAction] = React.useState(false);

  const { queueAction, entityId } = useSelector(({ executionManager }) => executionManager);

  const askingForQueueAction = (actionKey) => [ASK_DELETE_QUEUE, ASK_DELETE_ONE_QUEUE, ASK_ENABLE_QUEUE, ASK_DISABLE_QUEUE, ASK_DELETE_QUEUE_FROM_ONE].includes(
      actionKey
    );

  // eslint-disable-next-line consistent-return
  const actionMessageKey = (actionKey) => {
    if (actionKey === ASK_ENABLE_QUEUE) {
      return QUEUE_ASSIGN_PROCESS_ACTION;
    } if (
        actionKey === ASK_DELETE_QUEUE
        || actionKey === ASK_DISABLE_QUEUE
        || actionKey === ASK_DELETE_ONE_QUEUE
        || actionKey === ASK_DELETE_QUEUE_FROM_ONE
    ) {
      return QUEUE_UNASSIGN_PROCESS_ACTION;
    }
  };
  // eslint-disable-next-line consistent-return
  const getFeedBackAction = (askingAction) => {
    switch (askingAction) {
      case ASK_DELETE_QUEUE:
        return CONFIRM_DELETE_QUEUE;
      case ASK_DELETE_QUEUE_FROM_ONE:
        return CONFIRM_DELETE_QUEUE;
      case ASK_ENABLE_QUEUE:
        return CONFIRM_ENABLE_QUEUE;
      case ASK_DISABLE_QUEUE:
        return CONFIRM_DISABLE_QUEUE;
      default:
        break;
    }
  };

  useEffect(() => {
    if (
      queueAction && askingForQueueAction(queueAction)
      && entityId
    ) {
      dispatch(fetchAssignedProcessesByQueue(entityId, onSuccessGetAssignedByQueue));
    }
  }, [queueAction]);

  const onSuccessGetAssignedByQueue = (assignedProcessesRes) => {
    const assignedProcesses = assignedProcessesRes?.data ? assignedProcessesRes?.data : [];
    const isQueueHasProcesses = assignedProcesses?.length > 0;
    const isQueueHasSchedule = [];
    // eslint-disable-next-line no-unsafe-optional-chaining
    isQueueHasSchedule.push(...assignedProcesses?.filter(((proc) => (proc.countSchedulesWithRBT + proc.countSchedulesWithoutRBT) > 0)));

    setHasSchedules(isQueueHasSchedule.length > 0);
    const nbSchedules = assignedProcesses.reduce(
      (accumulator, currentValue) => accumulator + currentValue.countTotalSchedules,
      0
    );
    setAssignedProcesses(assignedProcesses)
    if (!isQueueHasProcesses) {
      if (queueAction === ASK_DELETE_QUEUE_FROM_ONE) {
        dispatch(updateQueueAction({ queueAction: getFeedBackAction(queueAction) }));
      } else {
        // Si le résultat est vide, donc le queue n'a pas de process
        /** - Donc confirmer la suppression ou desactivation sans aucun feedback ok ! */
        dispatch(
          triggerQueueAction({
            queueAction: getFeedBackAction(queueAction),
            id: entityId,
          })
        );
      }
    } else if (isQueueHasSchedule.length >= 0) {
      // Le queue dont il a des process assigné, mais ces dérnières n'ont pas de schedules
      /** Donc - Préparer un message pour (Confirmer Ou Annuler la délagation dans le cas SUPPRIMER | REACTIVER | DESACTIVER) */
      setHasSchedulesWithoutRobots(assignedProcesses.filter((proc) => proc.countSchedulesWithoutRBT > 0).length > 0);
      setOpenProcessAssignDialog(true);
      setDialogMsg(t(`queue.management.${actionMessageKey(queueAction)}.process.part1`, { this_process: t("thisProcess_plural") }));
      setDialogMsg2(nbSchedules === 0 ? null
        : t(`queue.management.${actionMessageKey(queueAction)}.process.part2`, {
          count: nbSchedules,
          this_process: t("thisProcess_plural"),
          at_its: t("atIts_plural"),
          nbSchedules: t("some")
        }));
    }
  }

  const resetEntity = (id) => {
    setQueueId(id);
    setOpenMsgConfirm(true);
  }

  const confirmReset = () => {
    dispatch(resetSrQueues(queueId)).then((res) => {
      if (res?.status === 200) {
        setDialogMsg(t("queue reseted successfully"));
        toast.success(t("queue reseted successfully"))
      } else {
        setDialogMsg(t("error while reseting queue"));
        toast.error(t("error while reseting queue"))
      }
      setOpenMsgConfirm(false);
    });
  };

  const cancelConfirm = () => {
    setOpenMsgConfirm(false);
  };

  return (
    <>
      <Box className={classes.root}>
        <Box className={classes.paper}>
          <PageHeader title="queue.management.title" />
          <QueueTable
            resetEntity={resetEntity}
          />
        </Box>
      </Box>

      <ExecutionManager />

      <ProcessAssignDialog
        assignedProcesses={assignedProcesses}
        classes={classes}
        dialogMsg={dialogMsg}
        dialogMsg2={dialogMsg2}
        hasSchedules={hasSchedules}
        setAssignedProcesses={setAssignedProcesses}
        openProcessAssignDialog={openProcessAssignDialog}
        setOpenProcessAssignDialog={setOpenProcessAssignDialog}
        confirmAction={confirmAction}
        setConfirmAction={setConfirmAction}
        hasSchedulesWithoutRobots={hasSchedulesWithoutRobots}
      />
      <ConfirmMessage
        message={t("queue.management.reset.confirm-msg")}
        openStart={openMsgConfirm}
        onCancel={cancelConfirm}
        onConfirm={confirmReset}
        buttonConfirm={t("Reset")}
        buttonCancel={t("Cancel")}
        isDelete="true"
      />
    </>
  );
}
