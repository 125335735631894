import React from "react";
import { useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import ScheduleIcon from "@material-ui/icons/Schedule";
import VisibilityIcon from "@material-ui/icons/Visibility";
import ConfirmMessage from "../../../../../../components/ConfirmMessage";
import EnhancedTableHead from "../../../../../../components/DataTable/EnhancedTableHead";
import { formatDateByLanguage } from "../../../../../../util";
import CustomButton from "../../../../../../components/CustomButton";

export default function ScheduleInfo({
  classes,
  submittedScheduler,
  handleClickOpen,
  handleDeleteScheduler,
  disableFields,
  isLoading,
}) {
  const [openMsgConfirm, setOpenMsgConfirm] = React.useState(false);

  const { t } = useTranslation();

  const getPeriod = (period) => {
    const periodPivot = parseInt(period);
    switch (periodPivot) {
      case 1:
        return "Hourly";
      case 2:
        return "Daily";
      case 3:
        return "Weekly";
      case 4:
        return "Monthly";
      default:
        return "";
    }
  };

  const headCells = [
    {
      id: "period",
      label: t("Frequency"),
    },
    {
      id: "recurrence",
      label: t("Recurrence"),
    },
    {
      id: "startDate",
      label: t("Start date"),
    },

    {
      id: "endDate",
      label: t("End date"),
    },
    {
      id: "actions",
      label: t("Actions"),
    },
  ];

  const update = () => {
    handleClickOpen(false);
  };

  const show = () => {
    handleClickOpen(true);
  };

  const deleteSchedule = () => {
    setOpenMsgConfirm(true);
  };

  const cancelConfirm = () => {
    setOpenMsgConfirm(false);
  };
  const confirmDelete = () => {
    handleDeleteScheduler();
  };

  return (
    <Grid container item spacing={2}>
      <Grid item>
        <span className={classes.trigger}>
          <ScheduleIcon />
          <span style={{ marginLeft: "20px" }}>{t("Schedule")}</span>
        </span>
      </Grid>
      <TableContainer>
        <Table aria-label="Actions Table" classes={{ root: classes.table }}>
          <EnhancedTableHead
            classes={classes}
            headCells={headCells}
            align="center"
          />
          <TableBody>
            <TableRow hover role="checkbox" tabIndex={-1}>
              <TableCell
                align="center"
                className={
                  disableFields ? classes.tableCellGrey : classes.tableCell
                }
              >
                {t(getPeriod(submittedScheduler?.unitType))}
              </TableCell>
              <TableCell
                align="center"
                className={
                  disableFields ? classes.tableCellGrey : classes.tableCell
                }
              >
                {submittedScheduler?.frequency}
              </TableCell>
              <TableCell
                align="center"
                className={
                  disableFields ? classes.tableCellGrey : classes.tableCell
                }
              >
                {submittedScheduler?.startDate
                  ? formatDateByLanguage(submittedScheduler?.startDate, false)
                  : "---"}
              </TableCell>
              <TableCell
                align="center"
                className={
                  disableFields ? classes.tableCellGrey : classes.tableCell
                }
              >
                {submittedScheduler?.endDate
                  ? formatDateByLanguage(submittedScheduler?.endDate)
                  : t("FOREVER")}
              </TableCell>
              <TableCell
                align="center"
                className={classes.tableCell}
                style={{ paddingLeft: 0 }}
              >
                <Tooltip title={t("tooltip.action.view")}>
                  <CustomButton onClick={() => show()}>
                    <VisibilityIcon />
                  </CustomButton>
                </Tooltip>
                <Tooltip title={t("tooltip.action.edit")}>
                  <CustomButton onClick={() => update()} disabled={disableFields}>
                    <EditIcon />
                  </CustomButton>
                </Tooltip>

                <Tooltip title={t("tooltip.action.delete")}>
                  <CustomButton
                    onClick={() => deleteSchedule()}
                    disabled={disableFields}
                    primary
                  >
                    <DeleteIcon
                      className={disableFields ? "" : classes.deleteIcon}
                    />
                  </CustomButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      {openMsgConfirm && (
        <ConfirmMessage
          message={t("Are you sure you want to delete this frequency ?")}
          openStart={openMsgConfirm}
          onCancel={cancelConfirm}
          onConfirm={confirmDelete}
          isDelete="true"
          buttonConfirm={t("Delete")}
          buttonCancel="Cancel"
          isLoading={isLoading}
        />
      )}
    </Grid>
  );
}
