import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Paper from "@material-ui/core/Paper";
import {
  fetchScheduleByProcess,
  deleteSchedule,
  isProcessAssignedToQueue,
  enableOrDisableSRQScheduler,
  enableOrDisableScheduler
} from "../../../../redux/actions/services";
// import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";

import { connect } from "react-redux";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import EnhancedTableHead from "../../../../components/DataTable/EnhancedTableHead";
import ConfirmMessage from "../../../../components/ConfirmMessage";
import { EnhancedTableToolbar } from "../../../../components/DataTable/EnhancedTableToolbar";
import CircularLoader from "../../../../components/Loaders/CircularLoader";
import DataNotFound from "../../../../components/DataNotFound";
import { formatDateByLanguage } from "../../../../util";
import { toast } from "react-toastify";
import CustomSwitch from "../CustomSwitch";
import CustomPagination from "../CustomPagination";
import CustomAutoComplete from "../../../../components/FormFields/CustomAutoComplete";
import NoDataMenu from "../../../../components/NoData/NoDataMenu";

const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

const getComparator = (order, orderBy) => (order === "desc"
  ? (a, b) => descendingComparator(a, b, orderBy)
  : (a, b) => -descendingComparator(a, b, orderBy));

const stableSort = (array, comparator) => {
  if (array && array.length > 0) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }
  return null;
};

const ScheduleInfo = (props) => {
  const { classes, process } = props;
  const [loaded, setLoaded] = React.useState(false);
  const [selected, setSelected] = React.useState([]);
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("scheduleName");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [data, setData] = React.useState([]);
  const [count, setCount] = React.useState(0);
  const [openMsgConfirm, setOpenMsgConfirm] = React.useState(false);
  const [row, setRow] = React.useState(null);
  const [showFilter, setShowFilter] = React.useState(false);
  const [periods, setPeriods] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [isAssignedToQueue, setIsAssignedToQueue] = React.useState(false);
  const [openConfirmationPopup, setOpenConfirmationPopup] = React.useState(false);
  const [disableInfo, setDisableInfo] = React.useState({ disabled: false, scheduleIndex: -1 });

  const { t } = useTranslation();
  const periodList = [
    { title: t("Hourly"), id: "1" },
    { title: t("Daily"), id: "2" },
    { title: t("Weekly"), id: "3" },
    { title: t("Monthly"), id: "4" },
    { title: t("Yearly"), id: "5" },
  ];
  const getPeriod = (period) => {
    const periodPivot = parseInt(period);
    switch (periodPivot) {
      case 1:
        return "Hourly";
      case 2:
        return "Daily";
      case 3:
        return "Weekly";
      case 4:
        return "Monthly";
      case 5:
        return "Yearly";

      default:
        return "";
    }
  };

  const getScheduleList = (pageE, size, sortField, sortOrder, periods) => {
    setIsLoaded(false);
    props
      .fetchScheduleByProcess(
        process.id,
        pageE,
        size,
        sortField,
        sortOrder,
        periods,
      )
      .then((result) => {
        setData(result?.data?.list);
        setCount(result?.data?.resultsCount);
      })
      .then(() => setIsLoaded(true));
  };

  /* Stocker les infos de schedule qu'on veut activer/désactiver
    Puis on affiche le POP-UP pour confirmer l'action */
  const handleChangeSwitch = () => {
    setOpenConfirmationPopup(true);
    // props.partialUpdateScheduleInSRQueues()
  };

  // L'utilisateur a confirmé le disabling/enabling de schedule
  const confirmChangeSwitch = () => {
    const { scheduleIndex, disabled } = disableInfo;
    setIsLoading(true);
    if (isAssignedToQueue) {
      props
      .enableOrDisableSRQScheduler(
        data.at(scheduleIndex)?.id,
        disabled,
        onDisableEnableSuccess,
        onDisableEnableError
      )
    } else {
      props
      .enableOrDisableScheduler(
        data.at(scheduleIndex)?.id,
        disabled,
        onDisableEnableSuccess,
        onDisableEnableError
      )
    }
    }

  const onDisableEnableSuccess = (res) => {
    const { scheduleIndex } = disableInfo;
    const newData = data.map((item, index) => {
      if (scheduleIndex === index) {
        return { ...item, isDisabled: res?.data.isDisabled };
      }
        return item;
    });
    setData(newData);
    setDisableInfo({ disabled: false, scheduleIndex: -1 });
    toast.success(t("Schedule modified successfully"))
    setOpenConfirmationPopup(false);
    setIsLoading(false);
  };

  const onDisableEnableError = () => {
    toast.error(t("Failed to update the schedule! Please try again later"))
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getScheduleList(newPage, rowsPerPage, orderBy, order, periods);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
    getScheduleList(0, event.target.value, orderBy, order, periods);
  };
  const headCells = [
    {
      id: "scheduleName",
      numeric: false,
      disablePadding: false,
      label: t("Schedule Name"),
      sortable: false,
    },
    {
      id: "robot",
      numeric: false,
      disablePadding: false,
      label: t("robot"),
      sortable: false,
    },
    {
      id: "period",
      numeric: false,
      disablePadding: false,
      label: t("Frequency"),
      sortable: false,
    },
    {
      id: "recurrence",
      numeric: false,
      disablePadding: false,
      label: t("Recurrence"),
      sortable: false,
    },
    {
      id: "startDate",
      numeric: false,
      disablePadding: false,
      label: t("Start date"),
      sortable: true,
    },
    {
      id: "endDate",
      numeric: false,
      disablePadding: false,
      label: t("End date"),
      sortable: true,
    },
    {
      id: "actions",
      numeric: false,
      disablePadding: false,
      label: t("Actions"),
      sortable: false,
    },
  ];

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    getScheduleList(
      page,
      rowsPerPage,
      property,
      isAsc ? "desc" : "asc",
      periods,
    );
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  useEffect(() => {
    setIsLoaded(false);
    props.isProcessAssignedToQueue(process?.id)
    .then((result) => {
      setIsAssignedToQueue(result.data);
    })
  }, [])

  useEffect(() => {
    if (!loaded) {
      setLoaded(true);
      getScheduleList(page, rowsPerPage, orderBy, order, periods);
    }
  }, [
    setLoaded,
    loaded,
    props,
    process.id,
    getScheduleList,
    order,
    orderBy,
    page,
    periods,
    rowsPerPage,
  ]);

  const deleteSchedule = (event, row) => {
    setRow(row);
    setOpenMsgConfirm(true);
  };

  const update = (event, row) => {
    props.onClickEdit(row);
  };

  const cancelConfirm = () => {
    setOpenMsgConfirm(false);
  };
  const onCancel = () => {
    setDisableInfo({ disabled: false, scheduleIndex: -1 });
    setOpenConfirmationPopup(false);
  };
  const confirmDelete = () => {
    setIsLoading(true);
    props.deleteSchedule(row.id).then(() => {
      toast.success(t("Schedule Deleted Successfully"))
      setOpenMsgConfirm(false);
      getScheduleList(page, rowsPerPage, orderBy, order, periods);
      setIsLoading(false);
    });
  };

  const editShowFilter = () => {
    setShowFilter(!showFilter);
  };
  const handleChangePeriod = (value) => {
    setPeriods(value);
    setPage(0);
    getScheduleList(0, rowsPerPage, orderBy, order, value.map(({ id }) => id));
  };

  const handleNext = (page) => {
    setPage(page + 1);
    getScheduleList(page + 1, rowsPerPage, orderBy, order, periods);
  }
  const handlePrevious = (page) => {
    setPage(page - 1);
    getScheduleList(page - 1, rowsPerPage, orderBy, order, periods);
  }
  return (
    <div className={classes.root}>
      <Paper elevation="0" className={classes.paper}>
        <EnhancedTableToolbar
          filtred
          onShow={editShowFilter}
          numSelected={0}
          button={props.button}
          filterInput={
            <CustomAutoComplete
                multiple
                options={periodList || []}
                optionLabel="title"
                value={periods}
                noOptionsNode={<NoDataMenu message={t("no.period.message")} />}
                onChange={handleChangePeriod}
                label={t("filter by")}
            />
          }
        />
        <TableContainer className={classes.scheduleTable}>
          {isLoaded
            ? (
              <Table stickyHeader aria-label="Actions Table" classes={{ root: classes.table }}>
                {count > 0 && data && <EnhancedTableHead
                  classes={classes}
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={count}
                  headCells={headCells}
                />}
                <TableBody>
                  {count > 0 && data ? (
                    stableSort(data, getComparator(order, orderBy)).map(
                      (row, index) => (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.id}
                          className={classes.tableRow}
                        >
                          <TableCell
                            component="th"
                            scope="row"
                            align="left"
                            className={classes.tableCell}
                          >
                            {row?.scheduleName}
                          </TableCell>
                          <TableCell align="left" className={classes.tableCell}>
                            {row?.robot?.robotDisplayName}
                          </TableCell>
                          <TableCell align="left" className={classes.tableCell}>
                            {t(getPeriod(row?.unitType))}
                          </TableCell>
                          <TableCell align="left" className={classes.tableCell}>
                            {row?.period}
                          </TableCell>
                          <TableCell align="left" className={classes.tableCell}>
                            {row?.startDate
                              ? formatDateByLanguage(row?.startDate)
                              : "---"}
                          </TableCell>
                          <TableCell align="left" className={classes.tableCell}>
                            {row?.endDate
                              ? formatDateByLanguage(row?.endDate)
                              : t("FOREVER")}
                          </TableCell>
                          <TableCell
                            align="left"
                            className={classes.tableCell}
                          >
                            <Tooltip
                              placement="top"
                              title={t(
                                `tooltip.action.${
                                  row?.isDisabled ? "enable" : "disable"
                                }`
                              )}
                            >
                              <CustomSwitch
                                  name="isDisabledCheck"
                                  checked={!row?.isDisabled}
                                  handleChange={() => {
                                  setDisableInfo({
                                    disabled: !row?.isDisabled,
                                    scheduleIndex: index,
                                  });
                                  handleChangeSwitch();
                                  }}
                              />
                            </Tooltip>
                            <Tooltip title={t("Edit schedule")}>
                              <IconButton className={{ label: classes.editIcon }} onClick={(event) => update(event, row)}>
                                <EditIcon />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title={t("Delete Schedule")}>
                              <IconButton
                                onClick={(event) => deleteSchedule(event, row)}
                              >
                                <DeleteIcon className={classes.deleteIcon} />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      ),
                    )
                  ) : (
                    <div>
                      <DataNotFound
                          padding={50}
                          message={t("no.scheduler.message")}
                        />
                    </div>
                  )}
                </TableBody>
              </Table>
            )
            : <CircularLoader />}
        </TableContainer>
        {count > 0 && data && <CustomPagination
          page={page}
          rowsPerPage={rowsPerPage}
          count={count}
          onChangePage={handleChangePage}
          onNext={handleNext}
          onPrevious={handlePrevious}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />}
      </Paper>
      {openMsgConfirm && (
        <ConfirmMessage
          message={t("Are you sure you want to delete this schedule ?")}
          openStart={openMsgConfirm}
          onCancel={cancelConfirm}
          onConfirm={confirmDelete}
          isDelete="true"
          buttonConfirm={t("Delete")}
          buttonCancel="Cancel"
          isLoading={isLoading}
        />
      )}
      <ConfirmMessage
        message={disableInfo.disabled ? t("Are you sure you want to disable this schedule ?") : t("Are you sure you want to enable this schedule ?")}
        openStart={openConfirmationPopup}
        onCancel={onCancel}
        onConfirm={confirmChangeSwitch}
        buttonConfirm={disableInfo.disabled ? t("button.deactivate.confirm") : t("button.activate.confirm")}
        buttonCancel={t("Cancel")}
        isLoading={isLoading}
      />

    </div>
  );
};
const mapDispatchToProps = {
  fetchScheduleByProcess,
  deleteSchedule,
  isProcessAssignedToQueue,
  enableOrDisableSRQScheduler,
  enableOrDisableScheduler
};
export default connect(null, mapDispatchToProps)(ScheduleInfo);
