import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  chip: {
    maxWidth: "calc(95% - 65px)",
    height: 25,
    backgroundColor: theme.custom.color.containersBorder,
    fontSize: 12,
    borderRadius: 4,
    border: `1px ${theme.custom.color.chartColor1} solid`,
    marginRight: 2
  },
  chipIcon: {
    width: 15
  },
  chipText: {
    fontSize: 12,
    maxWidth: "100%",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  itemText: {
    maxWidth: "100%",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    fontWeight: 600,
    color: theme.palette.primary.main
  },
  chipBadge: {
    position: "relative",
    transform: "revert",
    fontSize: 9,
    padding: 0,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.background.default
  },
  chipBadgeClose: {
    backgroundColor: theme.custom.color.chartColor3,
    cursor: "pointer"
  },
  chipTag: {
    position: "absolute",
    right: 21,
    visibility: "visible",
    display: "block"
  },
  closeIcon: {
    fontSize: 12,
    cursor: "pointer"
  },
  selectedInput: {
    "& .MuiAutocomplete-input": {
      width: 10
    }
  },
  selectedMonoInput: {
    fontSize: 14,
    maxWidth: "90%",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden"
  },
  customRenderOptionText: {
    maxWidth: "85%"
  },
  optionItem: {
    paddingRight: 4
  }
}));
