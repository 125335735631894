// eslint-disable-next-line import/no-anonymous-default-export
export default {
  "dashboar.refresh": "Rafraîchir",
  "dashboard.refresh.desc": "Chaque {{time}} {{timeUnit}}",
  "All rights reserved": "Tous droits réservés.",
  Dashboard: "Tableau de bord",
  Planner: "Planificateur de ressources",
  "Resource Planner": "Planificateur de ressources",
  "Gestion d'entreprise": "Gestion commerciale",
  Orders: "Commandes",
  Invoices: "Factures",
  Regulations: "Règlements",
  Suppliers: "Fournisseurs",
  Reports: "Rapports",
  Search: "Rechercher",
  "Add your Invoice": "Déposez votre Facture ",
  browse: "parcourir",
  "Show all": "Afficher tout",
  "restriction.form.to.error": "Date doit être supérieur à ",
  "restriction.form.allDays": "Tous les jours",
  Mon: "Lun",
  Tue: "Mar",
  Wed: "Mer",
  Thu: "Jeu",
  Fri: "Ven",
  Sat: "Sam",
  Sun: "Dim",
  "priority.error": "La valeur existe déjà, veuillez choisir une autre valeur.",
  "priority.error.unhide":
    "La valeur de priorité de ce processus existe déjà. Votre processus sera affiché sans aucune priorité définie.",
  "process.error.unhide.disabledOrchestrator": "L'orchestrateur de ce processus est désactivé, activez-le pour afficher à nouveau tous ses processus.",
  "restriction.form.estimationExecutaion.required.error":
    "Veuillez saisir un temps d'exécution",
  "restriction.form.numberInteger.error":
    "Veuillez saisir un numéro entier valide",
  "restriction.form.minValue.error":
    "La valeur doit être supérieure ou égale à 0.",
  "restriction.form.maxValue.error":
    "Le nombre des chiffres doit être inférieur ou égal à 9.",
  "restriction.form.frequency": "Fréquence",
  "restriction.form.occurence": "Occurrence",
  "restriction.form.priority.required.error": "Veuillez saisir un nombre",
  "restriction.form.priority.minValue.error":
    "Veuillez saisir une valeur supérieur ou égale à 1",
  "restriction.form.priority.maxValue.error":
    "Veuillez saisir une valeur inférieur ou égale à 100",
  Supplier: "Fournisseur",
  "Invoice No.": "N° Facture",
  "Release date": "Date de lancement",
  "Due date": "Date d'échéance",
  "Price WT": "Montant HT",
  "Taxe Rate": "TVA",
  Price: "Montant total",
  "Purchase order": "Bon de commande",
  "Last actions": "Dernières actions",
  Today: "Aujourd'hui",
  Yesterday: "Hier",
  "Last week": "Semaine dernière",
  "Update using historical data": "Mise à jour à l'aide de données historiques",
  "no execution in this period": "Aucune exécution de processus trouvée.",
  "Estimated Execution":
    "Sur la base des critères de sélection, le temps d'exécution estimé pour ce processus est ",
  "Use Historical data": "Utiliser les données historiques?",
  "Choose the period":
    "Choisissez la période à prendre en compte lors du calcul du temps d'exécution estimé pour ce processus :",
  Status: "Statut",
  "Execution.time": "Date d'exécution",
  "planned.executions": "Exécutions planifiées",
  robot: "Robot",
  "planner.fullScreenExit": "Sortir en mode plein écran",
  "planner.fullScreen": "Ouvrir le planner en plein écran",
  "Risk management": "Marge de risque",
  "planner.risktime": "Temps du risque",
  "Latest Exceptions": "Dernières exceptions",
  Date: "Date",
  "My tasks": "Mes tâches",
  "Invoices to verify": "Factures à vérifier",
  "Invoices to be checked": "Factures à contrôler",
  "Invoices to Payers": "Factures aux payeurs",
  "Paid invoices": "Factures payées",
  "Unpaid invoices": "Factures impayées",
  "Total amount of invoices": "Montant total des factures",
  "Total VAT amount": "Montant total de TVA",
  "Statistical overview of purchases": "Aperçu statistique des achats",
  "Statistical overview of product sales":
    "Aperçu statistique des ventes de produits",
  "Total Purchases": "Total des achats",
  "Total of expenses": "Total des dépenses",
  Late: "En retard",
  Administrator: "Administrateur",
  "administration.status": "Statut",
  "administration.company": "Entreprise",
  "administration.userId": "Identification",
  "administration.name": "Nom",
  "administration.creationDate": "Date de création",
  "administration.role": "Rôle",
  "administration.email": "E-mail",
  "administration.phone": "Téléphone",
  "administration.departement": "Département",
  "administration.subscription": "Abonnement",
  "administration.activate.deactivate.user.message":
    "Voulez-vous vraiment activer/désactiver cet utilisateur ?",
  "administration.orchestrator.robot.list.not.available":
    "Il n'y a pas de robots disponibles",
  "administration.orchestrator.process.list.not.available":
    "Il n'y a pas des processes disponibles",
  "user.add.civility": "Civilité",
  "user.add.lastName": "Nom",
  "user.add.firstName": "Prénom",
  "user.add.creationDate": "Date de création",
  "user.add.entreprise": "Entreprise",
  "user.add.subscription": "Engagement",
  "user.add.email": "Email",
  "user.add.pass1": "Mot de passe",
  "user.add.pass2": "Confirmer le mot de passe",
  "user.add.comm.lang": "Langage de communication",
  "user.add.comm.lang.helperText": "Toutes les communications et e-mails envoyés par SmartRoby seront dans la langue sélectionnée",
  "user.add.process": "Processus",
  "user.add.availableProcess": "Processus disponibles",
  "user.add.affectedProcess": "Processus affecté",
  "user.add.button": "AJOUTER UTILISATEUR",
  "user.add.mfa": "OTP requis",
  "user.add.division.admin": "Administrateur de division",
  "user.add.fleet.admin": "Administrateur de groupe",
  "user.add.division": "Division",
  "user.status.active": "Actif",
  "user.status.disable": "Inactif",
  "user.modify": "Modifier",
  "process.modify": "Modifier",
  "process.hidden": "Masqué",
  "user.add": "Ajouter",
  "user.title.add": "Ajouter un utilisateur",
  "user.title.details": "Détails de l'utilisateur",
  "user.button.save": "Ajouter utilisateur",
  "user.button.update": "Mettre à jour",
  "user.button.cancel": "Annuler",
  "user.save.confirmMsg": "Êtes-vous sûr de vouloir ajouter cet utilisateur ?",
  "user.update.confirmMsg":
    "Êtes-vous sûr de vouloir mettre à jour les détails de cet utilisateur ? ",
  "user.delete.confirmMsg":
    "Êtes-vous sûr de vouloir supprimer cet utilisateur?",
  "user.delete.discard": "Êtes-vous sûr de vouloir ignorer ces modifications?",
  "orchestrator.button.save": "Ajouter",
  "orchestrator.save.confirmMsg":
    "Êtes-vous sûr de vouloir ajouter cet orchestrateur ?",
  "orchestrator.update.confirmMsg":
    "Êtes-vous sûr de vouloir mettre à jour les détails de cet orchestrateur ?",
  "orchestrator.management.add.button": "Connecter un orchestrateur",
  "orchestrator.management.delete.confirmMsg":
    "Êtes-vous sûr de vouloir supprimer l'orchestrateur ? Toutes les données seront définitivement perdues.",
  "invalid.value": "Valeur invalide",
  Required: "Requis",
  "exception.type": "Type d'exception",
  "exception.time": "Date d'exception",
  Mr: "M",
  Mrs: "Madame",
  Ms: "Mlle",
  "tooltip.action.delete": "Supprimer",
  "Visualise Scheduler": "Visualiser le programme ",
  "tooltip.action.edit": "Modifier",
  "tooltip.action.view": "Voir",
  "tooltip.action.add": "Ajouter",
  firstName: "Prénom",
  lastName: "Nom",
  companyName: "Entreprise",
  "720 hours/year": "720 heure/an",
  "1800 hours/year": "1800 heure/an",
  "60 days/year": "60 jour/an",
  "120 days/year": "120 jour/an",
  "3 months/year": "3 mois/an",
  "5 months/year": "5 mois/an",
  "user.roles.admin": "Administrateur",
  "user.roles.user": "Utilisateur",
  "New suppliers": "Nouveaux fournisseurs",
  "sorted descending": "Trié par ordre décroissant",
  "sorted ascending": "Trié par ordre croissant",
  Invoicing: "Facturation",
  Alerts: "Notifications",
  "User Management": "Gestion des utilisateurs",
  "Audit Logs": "Journaux d'audits",
  Documentation: "Documentation",
  Services: "Services",
  "National identity card": "Carte nationale d'identité",
  "Driving license": "Permis de conduire",
  Passport: "Passeport",
  "Green card": "Attestation d’assurance",
  "Car registration papers": "Carte grise",
  Facture: "Facture",
  Invoice: "Facture",
  RIB: "RIB",
  "Order form": "Bon de commande",
  payslip: "Fiche de paie",
  "Payroll statemenet": "Fiche de paie",
  "Notice of assessment": "Avis de cotisation",
  "Tax notice": "Avis d'imposition",
  "My Services": "Mes services",
  Tutorials: "Tutoriels",
  "Statistical overview of success and exception rates":
    "Aperçu statistique des taux de réussite et d'exception",
  "Executed Processes": "Processus Exécutés",
  "Insurance Validation and Entry": "Saisie et validation d'assurance",
  "Resource Leveling": "Nivellement des ressources",
  "Least Executed Processes": "Processus les moins exécutés",
  "Most Executed Processes": "Processus les plus exécutés",
  "Number of executions": "Nombre d'exécutions",
  "My Active Orchestrators": "Mes Orchestrateurs Actifs",
  "Last Transcriptions": "Dernières transcriptions",
  "Total API Calls": "Total des appels d'API",
  "Remaining Balance": "Solde Restant",
  "Success rate": "Taux de réussite",
  "Items success rate": "Taux de réussite des items",
  "Exception rate": "Taux d'exceptions",
  "Use of APIs": "Consommation des Services",
  "My Active Services": "Mes Services Actifs",
  "Process overview": "Temps d'exécution par processus",
  "of the total": "du total",
  "Robot overview": "Temps d'exécution par robot",
  "Execution time / Time saved": "Temps d'exécution / Temps économisé",
  "Latest Transcriptions": "Dernières Transcriptions",
  "Break-even": "Point d'amortissement",
  "Return on Investment": "Taux d'amortissement",

  // smart robots -> My Processes
  "Invoice Management": "Gestion des factures",
  "Client Onboarding": "Intégration du client",
  "Bank Reconciliation": "Rapprochement bancaire",
  "Expense Report Management": "Gestion des notes de frais",
  "Salary Statement Monitoring": "Suivi des relevés de salaire",
  "Virtual Assistant for agents": "Assistant virtuel pour les agents",
  "Trigger Details": "Details du déclencheur",
  "Start Process": "Démarrer",
  "Stop Process": "Arrêter le processus",
  Schedule: "Programmer",
  Prioritize: "prioriser",
  Sheet: "Feuille",
  Parameters: "paramètres",
  "no entity currently": "Aucune entité n'existe.",
  Validate: "Valider",
  validate: "Valider",
  "The document was updated successfully":
    "Le document a été mis à jour avec succès",
  Upload: "Télécharger",
  upload: "Télécharger",

  // smart ocr cards

  "Sage Interface": "Interface Sage",
  "SAP Interface": "Interface SAP",
  "Central Banking Data Integration":
    "Intégration des données de la banque centrale",
  "Salesforce Interaction": "Interaction Salesforce",

  "Drop process": "Poser ici pour démarrer le processus",
  "under construction":
    "Cette section est en construction, notre équipe y travaille. Restez connecté...",
  // smart ocr services
  "Analyze a new file": "Analyser un nouveau fichier",
  Receipt: "Reçu",
  "EDF proof": "Justificatif EDF",
  "Click here or drop a file to download":
    "Cliquez ici ou déposez un fichier à télécharger",
  "Loaded File": "Fichier chargé",
  "File preview": "Aperçu du fichier",
  Informations: "Informations",
  "Document name": "Nom du document",
  "File content type": "Type de contenu de fichier",
  userId: "Identification",
  "First name": "Prénom",
  "Last name": "Nom",
  CIN: "CNI",
  "Date of Birth": "Date de naissance",
  "Place of birth": "Lieu de naissance",
  "Expiration date": "Date d'expiration",
  Address: "Adresse",
  Gender: "Sexe",
  CivilStatusNo: "Situation",
  Overview: "Aperçu",
  "restriction.form.resources": "Robots",
  "restriction.form.priority": "Priorité",
  "restriction.form.priority.label": "Priorité de votre processus",
  "restriction.form.executionTimeLine": "Calendrier d’exécution",
  "restriction.form.executionDay": "Jour d'éxecution",
  "restriction.form.executionTime": "Temps d'éxecution",
  "restriction.form.from": "De",
  "restriction.form.to": "A",
  "restriction.form.estimatedExecutionDuration": "Durée d'exécution estimée",
  "restriction.form.allowedSession":
    "Nombre d'exécutions parallèles autorisées",
  "restriction.form.estimatedTime": "Temps estimé",
  "restriction.userGuide.resources":
    "Indique sur quelles machines (Robots) ce processus peut être exécuté. Pour ajouter des robots éligibles, veuillez contacter votre équipe RPA",
  "restriction.userGuide.priority":
    "Lorsque tous les SLA sont identiques, le processus avec la priorité la plus élevée (le numéro le plus bas) sera exécuté en premier.",
  "sla.update.success": "Informations SLA mises à jour avec succès",
  "restriction.userGuide.executionTimeLine":
    "Choisissez les jours et les heures où ce processus est autorisé à s'exécuter",
  "restriction.userGuide.estimatedExecutionTime":
    "Le temps moyen nécessaire pour terminer ce processus",
  "restriction.userGuide.allowedSession":
    "Le nombre d'exécutions parallèles autorisées pour ce processus, si suffisamment de licences et de machines sont disponibles pour les exécuter",
  "restriction.userguide.frequencyOccurence":
    "La fréquence d'exécution minimale que le processus doit exécuter",
"sla.update.error": "Échec de la mise à jour des SLAs.",
  Historical: "Historique",
  "General Information": "Informations Générales",
  "Schedule Information": "Informations sur le programme",
  Description: "Description",
  processOriginalName: "Nom original du processus",
  Priority: "Priorité",
  "Start date": "Date de début",
  "End date": "Date de fin",
  "End Date": "Date de fin",
  Cron: "Cron",
  "Unit cost": "Coût à l'unité",
  "Number of units": "Nombre d'unités",
  "Process cost": "Coût du processus",
  "add.schedule": "Créer un horaire",
  "add.process": "Ajouter un processus",
  Reset: "Réinitialiser",
  Day: "Jour",
  Week: "Semaine",
  "3 DAYS": "3 jours",
  Delete: "Supprimer",
  Update: "Mettre à jour",
  Save: "Sauvegarder",
  Cancel: "Annuler",
  Confirm: "Confirmer",
  "Are you sure delete this row ?":
    "Êtes-vous sûr de vouloir supprimer cette ligne ?",
  "This process was started successfully":
    "Ce processus a été démarré avec succès",
  "The process has been added to the SR Queues.": "L'exécution demandée a été ajoutée à la file SR.",
  "An execution trigerred manually on the same robot has already been requested for this process": "Une exécution déclenchée manuellement sur le même robot a déjà été crée pour ce processus",
  "The process start failed! Please try again later":
    "Le démarrage du processus a échoué ! Veuillez réessayer plus tard",
  ERRORS_EMPTY_FIELD: "Champ requis",
  ERRORS_LESS_THAN_MIN_FIELD: "Merci d’entrer une valeur supérieure à {{ limit }}",
  ERRORS_INVALID_DATA: "Merci d’entrer des données valides.",
  ERRORS_NO_DATA: "Aucune donnée trouvée!",
  WEIGHTS_SUM_MUST_BE_100: "La somme des poids doit être de 100%",
  "Start date must be less than the end date":
    "La date de début doit être antérieure à la date de fin",
  "End date must be greater than the start date":
    "La date  de fin doit être postérieure à la date de début",
  "Start time": "Heure de début",
  "End time": "Heure de fin",
  "How much time does it take an employee to execute this process?":
    "Combien de temps faut-il à un employé pour exécuter ce processus ?",
  "How much time does it take an employee to execute an execution in this process? (in minutes)":
    "Combien de temps (en minutes) faut-il à un employé pour compléter une execution de ce processus ?",
  "How much does the hour cost for an employee executing this task?":
    "Combien coûte l'heure pour un employé exécutant cette tâche ?",
  "How much does this process cost per month ?":
    "Combien coûte ce processus par mois ?",
  Recurrence: "Récurrence",
  Previous: "Précédent",
  // authentication page
  "Enter your mail": "Renseignez votre e-mail",
  "Confirm your mail": "Confirmez votre e-mail",
  "Enter your password": "Entrez votre mot de passe",
  Login: "Se connecter",
  "Sign up to SmartRoby": "Inscription à SmartRoby",
  Register: "S'inscrire",
  "You are already signed up": "Vous êtes déjà inscrit",
  "Go to SmartRoby": "Aller à SmartRoby",
  "Registration was successfull, you will be redirected":
    "Inscription réussie, vous allez être redirigé",
  "Registration failed": "Inscription échouée",
  Close: "Fermer",
  "Aws registration token missing": "Jeton d'enregistrement Aws manquant",
  "Email not matching": "L'email ne correspond pas",
  "Forgot your password ?": "Mot de passe oublié ?",
  "Login or password incorrect !": "Email ou mot de passe incorrect !",
  "Copyright © Smart-Roby  All rights reserved.":
    "Copyright © Smart-Roby  Tous droits réservés.",
  English: "Anglais",
  French: "Français",
  Spanish: "Espagnol",
  Polish: "Polonais",
  "Process executions": "Exécutions des processus",
  Ready: "Prêt",
  "Executed by": "Exécuté par",
  "you want to modify this data ?":
    "Êtes-vous sûr de vouloir modifier ces données ?",
  Yes: "Oui",
  No: "Non",
  "Data saved with success": "Données sauvegardées avec succès",
  "Data not saved": "Données non enregistrées ",
  "Hour(s)": "Heure(s)",
  "Day(s)": "Jour(s)",
  "Week(s)": "Semaine(s)",
  "Month(s)": "Mois",
  "Year(s)": "Année(s)",
  Year: "Année",

  Every: "Chaque",
  "Choose period": "Choisir une période",
  "Your input is required": "Votre intervention est requise",
  "The process": "Le processus",
  "is running, try again when it's completed.":
    "est en cours d'exécution, réessayez une fois l'opération terminée.",
  "Are you sure you want to execute this process ?":
    "Êtes-vous sûr de vouloir exécuter ce processus ?",
  Domain: "Domaine",
  "Process name": "Nom du processus",
  "Process reference": "Référence du processus",
  "Number used of systems and applications":
    "Nombre de systèmes et d'applications utilisés",
  "SAP environments (if applicable)": "Environnements SAP (si appliqué)",
  Frequency: "Fréquence",
  Volume: "Volume",
  "Rule-based": "Basé sur des règles",
  Standardization: "Standardisation",
  "Structured data": "Données structurées",
  "IT environment": "Environnement IT",
  "Test enabled": "Testable",
  "Documentation Readiness": "Préparation de la documentation",
  "Authentication mode": "Mode d'authentification",
  "Process UoM": "Unité de mesure du processus",
  "Time spent for one execution": "Temps passé pour une exécution",
  "Frequency of occurence": "Fréquence d'occurrence",
  "Time Spent": "Temps Passé",
  "Time granularity": "Unité de temps d’exécution",
  "Occurence time granularity": "Unité de temps d'occurrence",
  Occurrence: "Occurrence",
  "Time period": "Période",
  "Occurrence driver": "Conducteur d'occurrence",
  "Total time spent in hours per year": "Temps (en heures) redonné à l'entreprise par an",
  "Re-usability": "Réutilisabilité",
  "Expected qualitative benefits": "Bénéfices qualitatifs attendus",
  "Process Stability": "Stabilité du processus",
  "Number of steps": "Nombre d'étapes",
  "Number of windows": "Nombre de fenêtres",
  "Business Rules": "Règles métier",
  "Business Applications": "Applications métier",
  "Complex functions - Unstructured data / OCR":
    "Fonctions complexes - Données non structurées / OCR",
  "Complex functions - DataBase": "Fonctions complexes - DataBase",
  "Complex functions - Powershell": "Fonctions complexes - Powershell",
  "Complex functions - Active Directory":
    "Fonctions complexes - Active Directory",
  "Complex functions - VB Script": "Fonctions complexes - VB Script",
  "Complex functions - Web Services": "Fonctions complexes - Web Services",
  "Type of applications - Web application":
    "Type d'applications - Application web",
  "Type of applications - Windows application":
    "Type d'applications - Application Windows",
  "Type of applications - Mainframe application":
    "Type d'applications -Application Mainframe",
  "Type of applications - Citrix application":
    "Type d'applications - application Citrix ",
  "Automation without RPA": "Automatisation sans RPA",

  Accounting: "Comptabilité",
  Controlling: "Contrôle de gestion",
  Other: "Autre",
  Others: "Autres",
  "assessment.Yes - Based on objective criteria":
    "Oui - Basé sur des critères objectifs",
  "assessment.No - Judgment based": "Non - Basé sur le jugement",
  "assessment.Completely optimized process, managed at Group level":
    "Processus complètement optimisé, géré au niveau du groupe",
  "assessment.Largely standardized process, mostly managed at group level":
    "Processus largement standardisé, géré pour l'essentiel au niveau du groupe",
  "assessment.Good level of standardisation, managed regionaly":
    "Bon niveau de normalisation, géré au niveau régional",
  "assessment.Low degree of standardization and split responsibilities":
    "Faible degré de normalisation et partage des responsabilités",
  "assessment.Disaggregated process and split responsibilities":
    "Processus désagrégé et partage des responsabilités",
  "assessment.Yes - Completely structured (e.g. in a form/table/spreadsheet)":
    "Oui - Entièrement structuré (par exemple sous forme de formulaire/tableau/feuille de calcul)",
  "assessment.No - Completely un-structured e.g. non standardized email":
    "Non - Complètement non structuré, par exemple courrier électronique non standardisé",
  "assessment.Few simple applications (Excel, Outlook, …)":
    "Peu d'applications simples (Excel, Outlook, ...)",
  "assessment.Few simple applications with a unique SAP instance or another / custom application":
    "Peu d'applications simples avec une instance SAP unique ou une autre / application personnalisée",
  "assessment.Few complex / custom applications or ERP instances":
    "Peu d'applications complexes / personnalisées ou d'instances ERP",
  "assessment.Several complex / custom applications, and a cloud application":
    "Plusieurs applications complexes / personnalisées, et une application en Cloud",
  "assessment.Large number of applications, (i.e. several ERP instances)":
    "Nombre important d'applications, (c'est-à-dire plusieurs instances ERP)",
  "assessment.Yes - Data-sets fully available to conduct multiple tests":
    "Oui - Séries de données entièrement disponibles pour effectuer des tests multiples",
  "assessment.No - No Data-sets available to conduct multiple tests":
    "Non - Pas de séries de données  disponibles pour effectuer des tests multiples",
  "assessment.A detailed documentation is available":
    "Une documentation détaillée est disponible",
  "assessment.A high level documentation is available":
    "Une documentation de haut niveau est disponible",
  "assessment.No documentation": "Pas de documentation",
  "assessment.Standard authentication mode (ID and password)":
    "Mode d'authentification standard (ID et mot de passe)",
  "assessment.SSO only authentication mode":
    "Mode d'authentification SSO uniquement",
  "assessment.Additional authentication required (e.g. USB key)":
    "Authentification supplémentaire requise (par exemple, clé USB)",
  "assessment.Basic documentation is available":
    "Une documentation basique est disponible",
  "assessment.Few simple applications with a unique ERP instance custom application":
    "Peu d'applications simples avec une application personnalisée d'instance d'ERP unique",
  Seconds: "Secondes",
  Minutes: "Minutes",
  Minute: "Minute",
  Hours: "Heures",
  Days: "Jours",
  "unit.manday": "j/h",
  "assessment.Fully Core process compliant":
    "Entièrement conforme au processus de base",
  "assessment.Partially Core process compliant":
    "Partiellement conforme au processus de base",
  "assessment.Local (zone or country specific)":
    "Local (zone ou pays spécifique)",
  "assessment.Major": "Majeur",
  "assessment.High": "Haut",
  "assessment.Medium": "Moyen",
  "assessment.Low": "Faible",
  "assessment.Minor": "Mineur",
  "assessment.No change is planned": "Aucun changement n'est prévu",
  "assessment.No change is planned in the next 2 years":
    "Aucun changement n'est prévu dans les 2 prochaines années",
  "assessment.Process/system may change in next 2 years":
    "Le processus/système pourrait changer dans les 2 prochaines années",
  "assessment.Process/system update planned in next 2 years":
    "Mise à jour du processus/système prévue dans les deux prochaines années",
  "assessment.Frequent changes occur in the process":
    "Des changements fréquents interviennent au cours du processus",
  "assessment.1-16": "1-16",
  "assessment.17-45": "17-45",
  "assessment.46-94": "46-94",
  "assessment.More than 94": "Plus de 94",
  "assessment.1-4": "1-4",
  "assessment.5-10": "5-10",
  "assessment.11-20": "11-20",
  "assessment.More than 20": "Plus de 20",
  "assessment.Between 2 and 3": "Entre 2 et 3",
  "assessment.Between 4 and 10": "Entre 4 et 10",
  "assessment.More than 10": "Plus de 10",
  "assessment.Between 4 and 5": "Entre 4 et 5",
  "assessment.More than 5": "Plus de 5",
  "assessment.1": "1",
  true: "vrai",
  false: "faux",

  "Time saved": "Temps économisé",
  "Total time saved": "Temps total gagné",
  "Process ": "Processus",
  Executing: "En cours d'exécution",
  Planned: "Planifié",
  Paused: "En pause",
  "Rows Per page": "Lignes par page",
  "pagination.of": "sur",
  Completed: "Terminé",
  "How long will it take an employee to complete this process?":
    "Combien de temps faut-il à un employé pour exécuter ce processus?",

  "How much does it cost per hour for an employee to perform this task?":
    "Combien coûte l'heure pour un employé pour exécuter cette tâche ?",

  unknown: "inconnu",
  Unknown: "Inconnu",
  "This Week": "Cette semaine",
  "This Month": "Ce mois-ci",
  "This Year": "Cette année",
  "All time": "Toute la période",
  "FTE Time to Process": "Temps de traitement ETP",
  "FTE Cost": "Coût ETP",
  Discard: "Abandonner",
  "Exception Reason": "Raison de l'exception",
  "Exception Workflow Status": "Statut",
  "Exception Assignee": "Affecter à",
  "Show more filters": "Afficher plus de filtres",
  "Hide filters": "Masquer les filtres",
  READY: "Prêt",
  TAKEN: "Assigné",
  // process asseessment
  "kpi.parameter.ConsiderBusinessExceptionsAsCompletedItems": "Considérez les exceptions métier comme des éléments terminés dans les KPI de surveillance",
  "kpi.parameter.ConsiderBusinessExceptionsAsCompletedItems.tooltip": "Si cette option est activée, toutes les exceptions d'éléments de type Business Exception dans les KPI de suivi seront considérées comme des éléments terminés.",
  createdBy: "Crée par",
  updatedBy: "Dernière modification par",
  "Last Update ": "Dernière actualisation",
  Impact: "Impact",
  "header.kpi.exceptions": "Exceptions à traiter",
  "Schedule Name": "Nom du programme",
  Period: "Période",
  Exceptions: "Exceptions",
  Actions: "Actions",
  Reason: "Raison",
  "Exception Time": "Temps d'exception",
  Process: "Processus",
  "user deleted successfully": "Utilisateur supprimé avec succès.",
  "fleet deleted successfully": "Groupe supprimé avec succès.",
  "orchestrator deleted successfully": "Orchestrateur supprimé avec succès.",
  "queue deleted successfully": "File SR supprimée avec succès",
  "robot.hidden.success": "Robot {{status}} avec succès",
  hidden: "caché",
  unhidden: "non caché",
  "user has exception item":
    "Ce compte d'utilisateur est associé à des exceptions.",
  "Unexpected Error": "Erreur Inattendue.",
  "How much time does it take an employee to execute an Item in this process? (in minutes)":
    "Combien de temps (en minutes) faut-il à un employé pour exécuter un élément de ce processus ?",
  "How much does this Process cost to maintain per Year?":
    "Combien coûte ce processus en maintenance par an?",
  "Add tag": "Ajouter un tag",
  "Edit tag": "Modifier un tag",
  "Delete tag": "Supprimer un tag",
  "Tags Module": "Tags",
  Type: "Type",
  "Delete Item": "Supprimer l'élément",
  "Are you sure you want to delete this cost ?": "Êtes-vous sûr de vouloir supprimer ce coût ?",
  "This cost is shared with multiple processes, if you would like to remove the cost allocated to this process individually, please edit the cost and remove the processes from the Sharing paramteres. If you are sure you want to delete this cost from all processes, proceed with the delete.":
  "Ce coût est partagé avec plusieurs processus. Si vous souhaitez supprimer le coût attribué à ce processus individuellement, veuillez modifier le coût et supprimer les processus des paramètres de partage. Si vous êtes sûr de vouloir supprimer ce coût de tous les processus, procédez à la suppression",
  "Cost Configuration": "Configuration des coûts",
  "Cost Name": "Nom du coût",
  "Cost Category": "Catégorie de coût",
  Category: "Catégorie",
  "Reccuring ": "Réccurente",
  "Cost Type": "Type de coût",
  "Weight ": "Poids",
  "Add cost": "Ajouter un coût",
  "Cost Allocation": "Répartition des coûts",
  "Delete cost": "Supprimer le coût",
  "Edit cost": "Modifier le coût",
  "Allocation Amount": "Montant de l'allocation",
  "Shared ": "Partagé",
  "FTE hourly cost": "Coût horaire de l'ETP",
  "Save ": "Sauvegarder",
  "Cancel ": "Annuler",
  "Time Saved per Item": "Temps gagné par élément",
  "Time saved for each completed item of this process (in minutes)":
  "Temps gagné pour chaque élément achevé de ce processus (en minutes)",
  "Time Saved per Execution": "Temps gagné par exécution",
  " Time saved for each execution of this process excluding the time saved from every item (in minutes)":
  " Temps gagné pour chaque exécution de ce processus, à l'exclusion du temps gagné pour chaque élément (en minutes)",
  "Cost Shared": "Coûts partagés",
  "Cost Amount": "Coût Montant",
  "Are you sure you want to update the parameters ?":
    "Voulez-vous vraiment modifier ces paramètres ?",
  "Are you sure you want to create this schedule ?":
    "Êtes-vous sûr de vouloir créer ce programme?",
  "Are you sure you want to update this schedule ?":
    "Êtes-vous sûr de vouloir mettre a jour ce programme?",
  "The schedule was created successfully":
    "Le programme a été créé avec succès !",
  "Failed to create the schedule! Please try again later":
    "Échec de la création du programme ! Veuillez réessayer plus tard",
  "Failed to update parameters": "Échec de la mise à jour des paramètres",
  "Are you sure you want to disable this schedule ?":
       "Êtes-vous sûr de vouloir désactiver ce programme ?",
  "Are you sure you want to enable this schedule ?":
       "Êtes-vous sûr de vouloir activer ce programme ?",
  "button.deactivate.confirm":
       "Désactiver",
    "button.activate.confirm":
       "Activer",
  hours: "heures",
  Hourly: "Par Heure",
  Daily: "Quotidien",
  Weekly: "Hebdomadaire",
  Monthly: "Mensuel",
  Yearly: "Annuel",
  Trimestrial: "Trimestriel",
  Quarterly: "Quadrimestriel",
  Name: "Nom",
  "Start Date": "Date de début",
  "Are you sure you want to save the changes ?":
    "Voulez-vous vraiment enregistrer les modifications ?",
  "Failed to modify schedule": "Échec de la modification du programme",
  "Are you sure you want to disregard the changes ?":
    "Voulez-vous vraiment ignorer les modifications ?",
  "Changes disregarded": "Modifications ignorées",
  "Are you sure you want to delete this exception ?":
    "Voulez-vous vraiment masquer cette exception ?",
  "Are you sure you want to show this exception ?":
    "Voulez-vous vraiment afficher cette exception ?",
  "Are you sure you want to delete these exception ?":
    "Voulez-vous vraiment supprimer ces exceptions ?",
  "Exception Deleted Successfully": "Exception masquée avec succès!",
  "Exception Restored Successfully": "Exception restaurée avec succès!",
  "Exceptions Deleted Successfully": "Exceptions masquées avec succès",
  "Are you sure you want to delete": "Êtes-vous sûr de vouloir masquer",
  "Are you sure you want to show": "Êtes-vous sûr de vouloir afficher",
  "Are you sure you want to show X exceptions?":
    "Êtes-vous sûr de vouloir afficher {{count}} exception(s) ?",
  exceptions: "exception(s)",
  "exceptions successfully deleted": "exceptions masqués avec succès",
  "X exceptions successfully restored":
    "{{count}} exception(s) restaurées avec succès",

  // rpa qual
  Accepted: "Acceptée",
  accepted: "Acceptée",
  Rejected: "Rejetée",
  rejected: "Rejetée",
  Submitted: "Envoyée",
  submitted: "envoyée",
  Pending: "En attente",
  pending: "en attente",
  PENDING: "En attente",
  DELETED: "Supprimée",
  MISSED: "Manquée",
  MISSED_P: "En attente (Manquée)",
  PENDING_SR: "En attente SR",
  Draft: "Brouillon",
  "Not submitted": "Non envoyée",
  "Are you sure?": "Êtes-vous sûr ?",
  "You won't be able to revert this!":
    "Vous ne pourrez plus revenir en arrière !",
  warning: "Avertissement",
  "Yes, submit it!": "Oui, envoyer !",
  "Yes, save it!": "Oui, sauvegarder !",
  "Submit later": "Valider plus tard",
  Back: "Retour",
  Result: "Résultat",
  result: "Résultat",
  "Impact/Feasibility Matrix": "Matrice de Faisabilité/Impact",
  Feasibility: "Feasabilité fonctionnelle",
  next: "Suivant",
  "Red zone": "Zone rouge",
  "Impact and feasibility are limited": "Impact et faisabilité limités",
  "The process is likely to be reprioritized":
    "Le processus susceptible d'être repriorisé",
  "Yellow zone": "Zone jaune",
  "Here either the impact or the feasibility is relevant":
    "Ici, faisabilité ou impact pertinent",
  "Depending on the case and arbitration, the process could be deprioritized":
    "Selon le cas et l'arbitrage, le processus pourrait être dépriorisé",
  "Green zone": "Zone verte",
  "Both impact and feasibility show some great signs":
    "L'impact et la faisabilité montrent tous deux de grands signes",
  "The process is likely to be prioritized":
    "Processus susceptible d'être priorisé",
  Roi: "Taux d'amortissement",
  "Saving (per year)": "Estimation des gains (par an)",
  "FTE Gains": "Gains ETP",
  "FTE Costs": "Coûts ETP",
  "Total in $": "Total en $",
  "Developement costs": "Estimation des couts de développement (en j/h)",
  Developer: "Développeur",
  "Business analyst": "Business analyst",
  "Running costs (per year)": "Coûts de fonctionnement (par an)",
  "Running costs in $": "Coûts de fonctionnement en $",
  "Payback period (year)": "Période de remboursement (année)",
  "Yes, accept it": "Oui, accepter",
  "Yes, reject it": "Oui, rejeter",
  "Yes, delete it": "Oui, supprimer",
  NAME: "NOM",
  "CREATED BY": "CRÉÉ PAR",
  "Date created": "Date de création",
  "Last update": "Date de la dernière modification",
  Edit: "Modifier",
  Exit: "Quitter",
  "Home - RPA Qualification Tool By Novelis innovation":
    "Accueil - Outil de qualification RPA par Novelis innovation",
  "RPA Opportunities": "Opportunités RPA",
  "No opportunities found !": "Aucune opportunité trouvée!",
  "create.opportunity": "Créer une opportunité",
  "No cases found !": "Aucun cas trouvé !",

  "Tool for discovering, evaluating and classifying business processes":
    "Outil de découverte, d'évaluation et de classification des processus métiers",
  "Sign in": "S'identifier",
  "Invalid email address": "Adresse électronique invalide",
  "Email Address": "Adresse électronique",
  "Password is required": "Mot de passe requis",
  "Password must contains at least 8 characters":
    "Le mot de passe doit contenir au moins 8 caractères",
  Password: "Mot de passe ",
  "No network": "Pas de réseau",
  "Please verify your internet connection and try again !":
    "Veuillez vérifier votre connexion Internet et réessayer !",
  OK: "OK",
  "New process": "Nouveau Processus",
  "Edit process": "Modifier le processus",
  "Page not found": "Page non trouvée",
  "Sorry, the page you are looking for cannot be found !":
    "Désolé, la page que vous recherchez est introuvable !",
  Reject: "Rejeter",
  Submit: "Envoyer",
  "rpaQual.action.submit": "Valider",
  submit: "envoyer",
  "rpaqual.submit": "Valider",
  Accept: "Accepter",
  "assessment.submit": "Soumettre",
  "Select a Department": "Sélectionnez une catégorie",
  "Select a robot": "Sélectionner un robot",
  "name your process": "Nommer votre processus",
  "describe briefly your process": "Décrivez brièvement votre processus ",

  "What is the value that would drive a round of process executions":
    "Quelle est la valeur qui motiverait une série d'exécutions",

  "Select if process is rule-based":
    "Choisissez si le processus est basé sur des règles",

  "Select if process is standardized":
    "Sélectionnez si le processus est standardisé",

  "Select if data used in the process is structured ":
    "Sélectionnez si les données sont structurées",

  "Select if testing will be possible": "Choisissez si le test sera possible ",

  "the documentation level that fits the most with the context of your process":
    "le niveau de documentation qui correspond le mieux au contexte de votre processus",

  "what authentication mode is applied for the systems in scope":
    "quel mode d'authentification est appliqué pour les systèmes concernés",

  "Time required for a single but complete process execution":
    "Temps nécessaire à l'exécution d'un processus unique mais complet",

  "Time granularity of the above mentionned time":
    "Granularité temporelle du temps mentionné ci-dessus",

  "eg: Weekly=52, Monthly=12, Daily=220 ":
    "ex : Hebdomadaire=52, Mensuel=12, Quotidien=220 ",

  "This field is populated automatically from the details time spent below":
    "Ce champ est rempli automatiquement à partir des détails du temps passé ci-dessous",

  "Assess re-usability to the best of your knowledge":
    "Évaluer la possibilité de réutilisation au mieux de vos connaissances",

  "Explain what are the benefits expected, e.g. better quality":
    "Expliquer quels sont les avantages attendus, par exemple une meilleure qualité",

  "Select the sentence that fits the most with the context of your process":
    "Sélectionnez la phrase qui correspond le mieux au contexte de votre processus",

  "Select the number of steps": "Sélectionnez le nombre d'étapes",

  "number of screens that change during the execution ":
    " nombre d'écrans qui changent pendant l'exécution",

  "number of  the business rules governing the process":
    "nombre de règles commerciales régissant le processus",

  "number of business applications involved in the process":
    "nombre de demandes d'entreprises impliquées dans le processus",

  "whether unstructured data or any OCR processing will be processed":
    "si des données non structurées ou tout traitement OCR seront traités",

  "communication with a database to establish or any sql commands will be executed":
    "la communication avec une base de données à établir ou toute commande sql sera exécutée",

  "if any Powershell script to install or any powershell code to execute in the command line":
    "si un script Powershell à installer ou un code PowerShell à exécuter dans la ligne de commande",

  "if the robots will have a Windows account and communicate by email or Teams":
    "si les robots disposeront d'un compte Windows et communiqueront par e-mail ou via Teams",

  "if there is a need to create VB scripts or run macros directly on blue prism ":
    "s'il est nécessaire de créer des scripts VB ou d'exécuter des macros directement sur Blue Prism ",

  "if there is a possibility to use web services or to expose them for other processes":
    "s'il existe une possibilité d'utiliser les services web ou de les exposer pour d'autres processus",

  "if the process interacts with web applications via browsers":
    "si le processus interagit avec des applications web via des navigateurs",

  "if the process interacts with Windows applications installed on the computer":
    "si le processus interagit avec les applications Windows installées sur l'ordinateur",

  "if  there is any application that we access via an emulator ":
    "s'il existe une application à laquelle on accède via un émulateur ",

  "if  there is any application that we access via the cloud":
    "s'il existe une application à laquelle nous accédons via le cloud",

  "the number of steps": " Nombre d'étapes ",
  Comment: "Commentaire",

  // scheduler
  "Are you sure you want to disregard this schedule ?":
    "Voulez-vous vraiment ignorer ce programme ?",
  "Schedule disregarded!": "Programme annulé !",
  "Are you sure you want to execute this process?":
    "Êtes-vous sûr de vouloir exécuter ce processus ?",
  "Feasibility/Impact": "Faisabilité/Impact",

  // kpi
  January: "Janvier",
  February: "Février",
  March: "Mars",
  April: "Avril",
  May: "Mai",
  June: "Juin",
  July: "Juillet",
  August: "Août",
  September: "Septembre",
  October: "Octobre",
  November: "Novembre",
  December: "Decembre",

  "Weeks ago": "Semaines passées",
  "hours ago": "il y a #NB# heures",
  "1 hour ago": "il y a une heure",

  "7 Weeks ago": "7 semaines passées",
  "6 Weeks ago": "6 semaines passées",
  "5 Weeks ago": "5 semaines passées",
  "4 Weeks ago": "4 semaines passées",
  "3 Weeks ago": "3 semaines passées",
  "2 Weeks ago": "2 semaines passées",
  "Last Week": "La semaine dernière",

  Monday: "Lundi",
  Tuesday: "Mardi",
  Wednesday: "Mercredi",
  Thursday: "Jeudi",
  Friday: "Vendredi",
  Saturday: "Samedi",
  Sunday: "Dimanche",

  "My Cases": "Mes Dossiers",
  "Process Exceptions": "Exceptions des processus",
  "Item Exceptions": "Exceptions des items",
  "My Processes": "Mes processus",
  "Choose frequency": "Choisissez la fréquence",
  "Default Alerts": "Notifications par défaut",
  "View default alerts": "Affichage des notifications par défaut",
  "Edit default alerts": "Modification des notifications par défaut",
  "Delete default alert": "Suppression des notifications par défaut",
  "Add default alert": "Ajouter une notification par défaut ",
  "Robot Administration": "Administration des robots",
  "Manage robot": "Gérer le robot",
  "Custom Alerts": "Notifications personnalisées ",
  "View custom alerts": "Affichage les notifications personnalisées",
  "Edit custom alerts": "Modification des notifications personnalisées",
  "Delete custom alert": "Suppression des notifications personnalisées",
  "Add custom alert": "Ajouter une notification personnalisée",

  Analytics: "Analytique",
  Administration: "Administration",
  "Alerts Management": "Gestion des notifications",
  "Process Assessment": "Qualification des processus",
  "title.processassessment": "QUALIFICATION DES PROCESSUS",
  "Delete Schedule": "Supprimer le programme",
  Schedules: "Plannings",
  "No options": "Aucune option",
  DONE: "Terminé",
  UNKNOWN: "Inconnu",
  Loading: "Chargement",
  RUNNING: "En cours",
  Running: "En cours",
  Disregard: "Ignorer",
  EXCEPTION: "Exception",
  "Retry Exceptions": "Réessayer les exceptions",
  "Exception Type": "Type d'exception",
  "Show less": "Afficher moins",
  "Filter List": "Filtrer la liste",
  "Process Mining": "Exploration de processus",
  Definition: "Définition",
  Profile: "Profil",
  "Choose your timezone": "Choisir votre fuseau horaire",
  "IT Review": "Complexité",

  "Total executions": "Total des exécutions",
  "Number of process executions": "Nombre d'exécutions de processus",
  "Latest activities": "Dernières activités",

  "Creation date (Descending)": "Date de création (décroissant)",
  "Creation date (Ascending)": "Date de création (croissant)",
  "Exception Time (Descending)": "Date de l'exception (décroissant)",
  "Exception Time (Ascending)": "Date de l'exception  (croissant)",
  "End date (Descending)": "Date de fin (décroissant)",
  "End date (Ascending)": "Date de fin (croissant)",
  "Creation date": "Date de création",
  "Exception date": "Date de l'exception",
  "Sort By": "Trier par",
  "Document Name": "Nom du document",
  "Loaded Time": "Temps Chargé",
  "Document Status": "Statut du document",
  Preview: "Aperçu",
  "The document was sent to the orchestrator":
    "Le document a été envoyé à l'orchestrateur",
  "The process has started successfully": "Le processus a démarré avec succès",
  "Are you sure you want to delete this document ?":
    "Êtes-vous sûr de vouloir supprimer ce document ?",
  "Document Deleted Successfully": "Document supprimé avec succès",
  "Show empty fields": "Afficher les champs vides",
  "Are you sure you want to delete this Opportunity ?":
    "Voulez-vous vraiment supprimer cette opportunité ?",
  "Opportunity deleted successfully": "Opportunité supprimée avec succès",

  // Added By Zineb

  START: "DÉMARRER",
  start: "Démarrer",
  Start: "Démarrer",
  Of: "de",
  FOREVER: "TOUJOURS",
  "Are you sure you want to delete this schedule ?":
    "Êtes-vous sûr de vouloir supprimer ce programme ? ",
  "Are you sure you want to delete this frequency ?": "Êtes-vous sûr de vouloir supprimer cette fréquence ?",
  "Schedule Deleted Successfully": " Programme supprimé avec succès",
  "save as draft": "sauvegarder comme brouillon",
  "Save as draft": "Sauvegarder comme brouillon",
  "Save draf": "Sauvegarder le brouillon",
  calculate: "calculer",
  Calculate: "Calculer",
  "Document Details": "Détails du document",
  "document details": "détails du document",
  "document overview": "aperçu du document",
  "Document Overview": "Aperçu du document",

  // Process Assessment Help By Zineb

  "Can a defined rule be applied to each step of this process?":
    "Une règle définie peut-elle être appliquée à chaque étape de ce processus?",
  "To what extent is the process standard across locations? The more optimized & centrally managed the process is, the easier the automation tests & implementation will be.":
    "Dans quelle mesure le processus est-il standard dans tous les sites ? Plus le processus est optimisé et centralisé, plus les tests et la mise en œuvre de l'automatisation seront faciles.",
  "Are the process inputs provided in a structured manner?":
    "Les entrées du processus sont-elles fournies de manière structurée ?",
  "Is a full data set availbale to test this process ?":
    "Un ensemble de données complet est-il disponible pour tester ce processus ?",
  "What is the level of IT environment complexity?":
    "Quel est le niveau de complexité de l'environnement IT ?",
  "What level of documentation exists to support this process?":
    "Quel niveau de documentation existe-t-il pour appuyer ce processus?",
  "What is scale of the security challenge for authentication?":
    "Quelle est l'ampleur du défi que représente l'authentification en matière de sécurité ?",

  "What is the Time required for a single but complete process execution?":
    "Quel est le temps nécessaire à l'exécution d'un processus unique mais complet ?",
  "What does an execution represent?":
    "Que représente une exécution ?",
  "What is the frequency which this process occures?":
    "Quelle est la fréquence à laquelle ce processus se produit ?",
  "What is the value that would drive a round of process executions?":
    "Quelle est la valeur qui conduirait à une série d'exécutions de processus ?",
  "Please indicate the nature of the driver for the occurrence of the process (number of company codes, accounts, postings, etc.)":
    "Veuillez indiquer la nature du driver pour l'occurrence du processus (nombre de sociétés, de comptes, d'écritures, etc.)",
  "Weekly=52, Monthly=12, Daily=220, etc. For specific frequencies, please add the details of your calculations in the comment section":
    "Hebdomadaire = 52, Mensuel = 12, Quotidien = 220, etc. Pour des fréquences spécifiques, veuillez ajouter les détails de vos calculs dans la section commentaire",

  "This field is populated automatically from the details from the Time Spent Section":
    "Ce champ est rempli automatiquement à partir des données de la section « temps passé » pour une exécution",
  "Select gravity of benefits and mention in the comment area what are the benefits expected, e.g. better quality, productivity gains, reduced lead time etc.":
    "Sélectionnez la sévérité des avantages et mentionnez dans la zone de commentaires les avantages attendus, par exemple une meilleure qualité, des gains de productivité, une réduction des délais, etc. ",
  "To what extent is this same process performed worldwide?":
    "Dans quelle mesure ce même processus est-il appliqué dans le monde entier ?",
  "Are systems/tool changes planned that would impact this process? (i.e. new report development)":
    "Des modifications sont-elles prévues sur les systèmes et outils, qui pourraient avoir un impact sur ce processus? (p. ex., élaboration de nouveaux rapports)",

  "Select the number of steps (Clicks, navigation, reading and writing in each application window), if necessary precise in comments":
    "Sélectionnez le nombre d'étapes (Clics, navigation, lecture et écriture dans chaque fenêtre d'application), si nécessaire précisez en commentaires",
  "Select the number of windows (screens) that change during the execution of the process, if necessary precise in comments":
    "Sélectionnez le nombre de fenêtres (écrans) qui changent pendant l'exécution du processus, si nécessaire précisez dans les commentaires",
  "Select the number of  the business rules governing the process, if necessary precise in comments":
    "Sélectionnez le nombre de règles de gestion qui gouvernent le processus, si nécessaire précisez dans les commentaires",
  "Select the number of business applications involved in the process (Number of objects to be created), if necessary precise in comments":
    "Sélectionnez le nombre d'applications métier impliquées dans le processus (Nombre d'objets à créer), si nécessaire précisez dans les commentaires",
  "Select whether unstructured data (PDF, Excel, e-mail, Txt file) or any OCR processing will be processed, if necessary precise in comments":
    "Choisissez si des données non structurées (PDF, Excel, e-mail, fichier Txt) ou tout traitement OCR seront traités, si nécessaire précisez dans les commentaires",
  "Select whether a communication with a database to establish or any sql commands will be executed if necessary precise in comments":
    "Choisissez si une communication avec une base de données à établir ou toute commande sql sera exécutée si nécessaire  précisez dans les commentaires",
  "Select if there is any Powershell script to install or any powershell code to execute in the command line, if necessary precise in comments":
    "Sélectionnez s'il y a un script Powershell à installer ou un code PowerShell à exécuter dans la ligne de commande, si nécessaire  précisez dans les commentaires",
  "Select if the robots will have a Windows account and communicate by email or Teams, if necessary precise in comments":
    "Choisissez si les robots auront un compte Windows et communiqueront par courrier électronique ou par Teams, si nécessaire  précisez dans les commentaires",
  "Select if there is a need to create VB scripts or run macros directly on blue prism if necessary precise in comments":
    "Sélectionnez s'il est nécessaire de créer des scripts VB ou d'exécuter des macros directement sur BluePrism si nécessaire précisez dans les commentaires",
  "Select if there is a possibility to use web services or to expose them for other processes, if necessary precise in comments":
    "Sélectionnez s'il existe une possibilité d'utiliser les services web ou de les exposer à d'autres processus, si nécessaire précisez dans les commentaires",
  "Select if the process interacts with web applications via browsers (IE, Chrome, Firefox), if necessary precise in comments":
    "Indiquer si le processus interagit avec des applications web via les navigateurs (IE, Chrome, Firefox), si nécessaire précisez dans les commentaires",
  "Select if the process interacts with Windows applications installed on the computer, if necessary precise in comments":
    "Indiquer si le processus interagit avec les applications Windows installées sur l'ordinateur, si nécessaire précisez dans les commentaires",
  "Select if  there is any application that we access via an emulator (IBM, AS400, HUB 5250), if necessary precise in comments":
    "Sélectionnez s'il existe une application à laquelle nous accédons via un émulateur (IBM, AS400, HUB 5250), si nécessaire précisez dans les commentaires",
  "Select if  there is any application that we access via the cloud (image), if necessary precise in comments":
    "Sélectionnez s'il existe une application à laquelle nous accédons via le Cloud (image), si nécessaire  précisez dans les commentaires",
  "Select if this process can be automated without the use of RPA technologies":
    "Sélectionnez si ce processus peut être automatisé sans l'utilisation de la technologie RPA",

  "Are you sure you want to save this case as a draft ?":
    "Êtes-vous sûr de vouloir sauvegarder ce dossier comme brouillon ?",
  "Case saved as a draft": "Dossier sauvegardé comme brouillon",
  "Are you sure you want to save and submit this case later ?":
    "Êtes-vous sûr de vouloir sauvegarder et soumettre ce dossier plus tard ?",
  "Case saved but not submitted": "Dossier sauvegardé mais non soumis",
  "Are you sure you want to save and submit this case ?":
    "Êtes-vous sûr de vouloir sauvegarder et soumettre ce dossier ?",
  "Case saved and submitted for approval":
    "Dossier sauvegardé et soumis pour approbation",
  "rpaQual.transition.accepted":
    "Êtes-vous sûr de vouloir accepter ce dossier comme une opportunité d'automatisation ?",
  "Case accepted as an opportunity": "Dossier accepté comme opportunité",
  "Are you sure you want to reject this case ?":
    "Êtes-vous sûr de vouloir rejeter ce dossier ?",
  "Case rejected": "Dossier rejeté",
  "This case is not yet submitted. Are you sure you want to delete it ?":
    "Ce dossier n'a pas encore été soumis. Êtes-vous sûr de vouloir le supprimer ?",
  "Case deleted successfully": "Dossier supprimé avec succès",
  "Case archived successfully": "Dossier archivé avec succès",
  "This case is submitted and awaiting approval. Are you sure you want to delete it ?":
    "Ce dossier est soumis et en attente d'approbation. Êtes-vous sûr de vouloir le supprimer ?",
  "This case is an accepted opportunity for automation. Are you sure you want to delete it ?":
    "Ce dossier est une opportunité d'automatisation acceptée. Êtes-vous sûr de vouloir le supprimer ?",
  "Are you sure you want to delete this case ?":
    "Êtes-vous sûr de vouloir supprimer ce dossier ?",
  "Your progress will be lost, are you sure you want to exit ?":
    "Votre avancement sera perdu, êtes-vous sûr de vouloir quitter ?",
  "Total in man-days": "Total en jours-homme",
  "Running costs in man-days": "Frais de maintenance en jours-homme",
  months: "mois",
  Months: "Mois",
  Weeks: "Semaines",
  Years: "Années",
  days: "jours",
  weeks: "semaines",
  years: "années",
  left: "de plus",
  ago: "Depuis {{value}} {{unit}}",
  Logout: "Se déconnecter",
  "Execution Count": "Exécutions",
  "Exception.count": "Exceptions",
  process: "Processus",
  "point.amortissage.negative": "Dans {{value}} {{unit}}",
  SearchProcess: "Recherche de processus",
  searchUser: "Rechercher un utilisateur",
  status: "Statut",
  sortBy: "Trier par",
  "Execution per": "Executé par",
  "Failed To create User": "Échec de la création de l'utilisateur",
  "Failed To create Scheduler": "Échec de la création du programme",
  "user.keycloak.exists": "L'utilisateur existe déjà en keycloak",
  "user.email.exists": "L'utilisateur existe déjà",
  "User with same email already exists": "Un utilisateur avec cet email exite déjà",
  "user.update.fail": "La mise à jour de l'utilisateur a échoué",
  "user.update.success": "L’utilisateur a été mis à jour avec succès!",
  deleteUsersActionMessage:
    "Êtes vous sûr de vouloir supprimer ces utilisateurs?",
  "profile.update-password.current-password-required":
    "Le mot de passe actuel est requis",
  "profile.update-password.new-password-required":
    "Un nouveau mot de passe est requis",
  "profile.update-password.password-required": "Mot de passe requis",
  "profile.update-password.password-not-matched":
    "Les mots de passe ne sont pas identiques.",
  "profile.update-password.current-password": "Mot de passe actuel",
  "profile.update-password.new-password": "Nouveau mot de passe",
  "profile.update-password.confirm-password": "Confirmer le mot de passe",
  "profile.update-password.reset-password": "Réinitialiser le mot de passe",
  "profile.update-password.password-reset": "Réinitialiser le mot de passe",
  "profile.update-password.password-reset-success":
    "Votre mot de passe à été changé avec succès",
  "profile.update-password.password-reset-done": "Terminer",
  "profile.update-password.update-password": "Modifier le mot de passe",
  "profile.update-password.email": "Votre email actuel",
  "profile.update-password.tokenError":
    "Jeton non valide, le mot de passe est déjà mis à jour!",
  "profile.update-password.password-email-send":
    "Un e-mail a été envoyé. Veuillez vérifier votre e-mail.",
  "token.validation.noteExist":
    "Demande de changement de mot de passe déjà envoyée",

  "Are you sure you want to start this process ?":
    "Êtes-vous sur de vouloir démarrer ce processus ?",
  "Process CompletedItems": "Items terminés",
  "Completed time": "Heure de fin",
  "traited item": "Items traités",
  "Items Count": "Items traités",
  "process.devCost.edit.cancel":
    "Vos modifications seront perdues, êtes-vous sûr de vouloir annuler?",
  "process.devCost.edit.confirm":
    "Voulez-vous vraiment enregistrer vos modifications?",
  "process.status.pending": "EN ATTENTE",
  draft: "BROUILLON",
  "not submitted": "Non envoyée",
  "no.exception.message": "Aucune exception détectée",
  "show.all.exception.button": "Afficher tout",
  manageUsers: "Gestion des utilisateurs",
  "assessment.Accounting & Finance": "Comptabilité et Finance",
  "assessment.Compliance": "Conformité",
  "assessment.Customer Service": "Service clientèle",
  "assessment.Purchasing": "Achat",
  "assessment.IT Operations": "Opérations informatiques",
  "assessment.Maintenance": "Maintenance",
  "assessment.Sales": "Ventes",
  "assessment.Marketing": "Commercialisation",
  "assessment.Research and Development": "Recherche et développement",
  "assessment.Production": "Production",
  "assessment.Human Resources Management ": "Gestion des ressources humaines ",
  "assessment.Software Development": "Développement de logiciels",
  "assessment.Other": "Autre",
  "assessment.Seconds": "Secondes",
  "assessment.Minutes": "Minutes",
  "assessment.Hours": "Heures",
  "assessment.Months": "Mois",
  "assessment.Years": "Années",
  "assessment.Days": "Journées",
  "assessment.Weeks": "Semaines",
  "orchestrator.management.title": "Gestion des orchestrateurs",
  "orchestrator.management.menu.title": "Orchestrateurs",
  "robot.management.title": "Gestion des robots",
  "robot.management.menu.title": "Robots",
  "orchestrator.management.deleteOrchestratorsActionMessage":
    "Êtes-vous sûr de vouloir supprimer les orchestrateurs sélectionnés ?",
  "orchestrator.management.enaDes.confirmMsg":
    "Etes-vous sûr de vouloir activer l'orchestrateur ? La connexion à votre environnement RPA sera réinitialisée",
  "orchestrator.management.status": "Statut",
  "orchestrator.management.name": "Nom",
  "orchestrator.management.solutionProvider": "Editeur RPA",
  "orchestrator.management.isActionable": "Actionnable",
  "orchestrator.management.orchestratorIp": "IP d'orchestrateur",
  "orchestrator.management.isAcionableToggle":
    "Êtes-vous sûr que cet orchestrateur n'est pas actionnable ?",
  "orchestrator.management.editHeader": "Modifier l'orchestrateur",
  "orchestrator.management.addHeader": "Ajouter un orchestrateur",
  "orchestrator.management.displayHeader": "Détails d'orchestrateur",
  "orchestrator.management.formControl.required": "Champ obligatoire",
  "orchestrator.management.formControl.orchestratorId": "Identifiant",
  "orchestrator.management.formControl.orchestratorName": "Nom d'orchestrateur",
  "orchestrator.management.formControl.orchestratorName.information":
    "Le nom de l'orchestrateur",
  "orchestrator.management.formControl.description.information":
    "Toutes les informations dont vous avez besoin à ajouter ici",
  "orchestrator.management.formControl.orchestratorIp.information":
    "IP publique ou privé de la machine de l'orchestrateur",
  "orchestrator.management.formControl.sessionNumber.information":
    "Le nombre de sessions autorisées dans la licence BP active actuelle",
  "orchestrator.management.formControl.databaseServer.information":
    "Le nom d'hôte du serveur de base de données",
  "orchestrator.management.formControl.serverPort.information":
    "Cela doit correspondre au port d'écoute configuré sur le serveur SQL",
  "orchestrator.management.formControl.databaseName.information":
    "Le nom de la base de données utilisée par le BluePrism",
  "orchestrator.management.formControl.dbUsername.information":
    "Le nom d'utilisateur de la base de données à utiliser lors de la connexion.L'utilisateur doit avoir des autorisations DB_DATAREADER et DB_DATAWRITER sur la base de données BluePrism",
  "orchestrator.management.formControl.dbPassword.information":
    "Le mot de passe du nom d'utilisateur utilisé lors de la connexion",
  "orchestrator.management.formControl.apiClientId.information": "L'identifiant de l'application fourni par UiPath lors de la création",
  "orchestrator.management.formControl.apiClientSecret.information": "Le secret de l'application fourni par UiPath lors de la création",
  "orchestrator.management.formControl.organizationName.information": "Le nom de l'organisation de l'orchestrateur UiPath",
  "orchestrator.management.formControl.tenantName.information": "Le nom du locataire à partir duquel les données seront récupérées",
  "orchestrator.management.formControl.orchestratorApiName.information": "Le nom de l'orchestrateur UiPath",
  "orchestrator.management.formControl.apiClientId": "ID de l'application API",
  "orchestrator.management.formControl.apiClientSecret": "Secret de l'application API",
  "orchestrator.management.formControl.solutionProvider": "Editeur RPA",
  "orchestrator.management.formControl.orchestratorIp": "IP d'orchestrateur",
  "orchestrator.management.formControl.yes": "Oui",
  "orchestrator.management.formControl.no": "Non",
  "orchestrator.management.formControl.orchestratorId.actionnable":
    "Actionnable",
  "orchestrator.management.formControl.apiPath": "API path",
  "orchestrator.management.formControl.organizationName": "Nom de l'organisation Uipath",
  "orchestrator.management.formControl.tenantName": "Nom du locataire Uipath",
  "orchestrator.management.formControl.orchestratorApiName": "Nom de l'orchestrateur Uipath",
  "orchestrator.management.save.confirmMsg":
    "Tu es sûr de vouloir sauver cet orchestrateur ?",
  "orchestrator.management.isAcionableToggle.confirm": "Oui, je suis sûr",
  "orchestrator.button.update": "Mettre à jour",
  "orchestrator.delete.discard":
    "Êtes-vous sûr de vouloir ignorer ces modifications ?",
  "orchestrator.management.isNotAcionableToggle":
    "Êtes-vous sûr de vouloir rendre cet orchestrateur actionnable ?",
  "orchestrator.management.isNotAcionableToggle.confirm": "Rendre actionnable",
  "orchestrator.management.enaDes.button.confirm": "Activer",
  "orchestrator.management.desaDes.confirmMsg":
    "Êtes-vous sûr de vouloir désactiver l'orchestrateur ? La connexion à votre environnement RPA sera perdue.",
  "orchestrator.management.desaDes.button.confirm": "Désactiver",
  "orchestrator.management.formControl.description": "Description",
  "orchestrator.management.formControl.databaseServer":
    "Serveur de base de données",
  "orchestrator.management.databaseServer": "Serveur de base de données",
  "orchestrator.management.formControl.serverPort": "Port",
  "orchestrator.management.serverPort": "Port",
  "orchestrator.management.formControl.databaseName":
    "Nom de la base de données",
  "orchestrator.management.formControl.dbUsername": "Nom d'utilisateur",
  "orchestrator.management.formControl.dbPassword": "Mot de passe",
  "orchestrator.management.formControl.invalidPort":
    "Le numéro de port doit être compris entre 1 et 65,535",
  "orchestrator.management.testConnection": "Test connexion",
  "orchestrator.management.testConnection.successMessage":
    "Connexion a réussie",
  "orchestrator.management.testConnection.failureMessage":
    "Connexion a échouée",
  "Are you sure you want to accept this case as an opportunity for automation ?":
    "Êtes-vous sûr de vouloir accepter ce cas comme une opportunité d'automatisation ?",
  "Are you sure you want to save the changes?":
    "Êtes-vous sûr de vouloir enregistrer les modifications?",
  "Schedule saved successfully": "Fréquence enregistrée avec succès",
  "Frequency Deleted Successfully": "Fréquence supprimée avec succès",
  "Schedule modified successfully": "Le programme a été modifié avec succès !",
  "Failed to update the schedule! Please try again later":
    "Impossible de mettre à jour le calendrier!Veuillez réessayer plus tard",
  "user.enaDes.confirmMsg":
    "Êtes-vous sûr de vouloir supprimer l'utilisateur ?",
  "Create schedule": "Créer le programme",
  "Edit schedule": "Modifier le programme",
  "Confirm frequency": "Confirmer la fréquence",
  "Update frequency": "Mettre à jour la fréquence",
  "Disregard changes": "Ignorer",
  "Save changes": "Sauvegarder les modifications",
  apply: "APPLIQUER",
  reset: "RÉINITIALISER",
  processes: "Processus",
  ACTIVE: "Actif",
  DISABLE: "Inactif",
  DISABLED: "Inactif",
  Disabled: "Désactiver",
  IDLE: "Prêt",
  WORKING: "En cours d'exécution",
  OFFLINE: "Déconnecté",
  Idle: "Prêt",
  Working: "En cours d'exécution",
  Offline: "Déconnecté",
  Missing: "Manquant",
  "Process status": "Statut du processus",
  "timeUnite.millis": " ms",
  "timeUnite.sec": " sec",
  "timeUnite.min": " min",
  "timeUnite.hrs": " HR",
  "timeUnite.days": " jr",
  "timeUnite.manDay": "j/h",
  "timeUnite.fte": "ETP",
  "Choose icon": "Choisissez l'icône",
  "Edit Icon": "Modifier l'icone",
  "Process Occurence": "Items terminés",
  "Process Item": "Items de processus",
  export: "exporter",
  "user.management.title": "Gestion des utilisateurs",
  "user.management.menu.title": "Utilisateurs",
  "user.management.add.button": "Créer un utilisateur",
  "user.management.delete.confirmMsg":
    "Êtes-vous sûr de vouloir supprimer cet utilisateur ?",
  "user.management.deleteUsersActionMessage":
    "Êtes-vous sûr de vouloir supprimer les utilisateurs sélectionnés ?",
  "user.management.enaDes.confirmMsg":
    "Êtes-vous sûr de vouloir activer cet utilisateur ?",
  "user.management.enaDes.button.confirm": "Activer",
  "user.management.desaDes.confirmMsg":
    "Êtes-vous sûr de vouloir désactiver cet utilisateur ?",
  "user.management.desaDes.button.confirm": "Désactiver",
  depuis: "Depuis",
  "depuis la premiere execution": "la première exécution trouvée",
  "kpi.parameter.be": "Inclure les données depuis",
  "kpi.parameter.includeException":
    "Inclure les exceptions métier des items dans les KPI financiers",
  "kpi.parameter.executionsIncludeException":
    "Compatibiliser les executions en exceptions dans les KPIs financiers",
  "kpi.parameter.invalidRate": "Taux non valide",
  "kpiParam.update.success": "Paramètres mis à jour avec succès",
  "kpiParam.update.error": "Échec de la mise à jour des paramètres KPI",
  "kpi.parameter.includeSince.tooltip":
    "Choisissez quand SmartRoby commencera à utiliser et à afficher les données de processus dans les modules et les indicateurs de performance clés dans tout le portail.",
  "kpi.header.success.rate.tooltip":
    "Le taux de réussite est calculé pour les items de processus. Les résultats sont basés sur les paramètres configurés pour chaque processus et le filtre de la période du tableau de bord.",
  "kpi.header.exception.rate.tooltip":
    "Le taux d'exception est calculé pour les items de processus. Les résultats sont basés sur les paramètres configurés pour chaque processus et le filtre de la période du tableau de bord.",
  "kpi.parameter.includeExceptFinancial.tooltip":
    "Choisissez le pourcentage de temps économisé par élément à inclure pour chaque exception commerciale.",
  "kpi.parameter.includeExceptFinancialKPI.tooltip":
    "Choisissez le pourcentage de temps économisé par exécution à inclure pour chaque exception de processus.",
  "kpi.parameter.breakEvenCalculationPeriod.tooltip":
    "Choisissez le temps d'exécution historique à prendre en compte lors de l'estimation du seuil de rentabilité de ce processus.",
  // ROUTES
  "routes.home": "Accueil",
  "routes.orchestrators": "orchestrateurs",
  "routes.orchestrators.processAssessment": "Évaluation des Processus",
  "routes.orchestrators.processAssessment.rpa": "RPA",
  "routes.orchestrators.processAssessment.rpa.view": "voir",
  "routes.orchestrators.processAssessment.rpa.create": "Ajouter",
  "routes.orchestrators.processAssessment.rpa.edit": "Modifier",
  "routes.orchestrators.smartRobots": "smart Robots",
  "routes.orchestrators.myProcesses": "Mes processus",
  "routes.orchestrators.smartRobots.documents": "documents",
  "routes.orchestrators.smartRobots.documents.details": "details",
  "routes.orchestrators.administration": "administration",
  "routes.orchestrators.administration.users": "Utilisateurs",
  "routes.orchestrators.administration.users.add": "Ajouter",
  "routes.orchestrators.administration.users.edit": "Modifier",
  "routes.orchestrators.administration.users.view": "Voir",
  "routes.orchestrators.protected": "privée",
  "routes.orchestrators.protected.user": "Utilisateur",
  "routes.orchestrators.protected.user.profile": "profile",
  "routes.orchestrators.administration.orchestrators": "orchestrateurs",
  "routes.orchestrators.administration.orchestrators.add": "Ajouter",
  "routes.orchestrators.administration.orchestrators.edit": "Modifier",
  "routes.orchestrators.administration.orchestrators.view": "Voir",
  "routes.orchestrators.smartocr": "smart ocr",
  "routes.orchestrators.smartocr.identity_card": "Carte d'identité",
  "routes.orchestrators.smartocr.passport": "passport",
  "routes.orchestrators.smartocr.driver_licence": "Permis de conduire",
  "routes.orchestrators.smartocr.car_registration": "immatriculation véhicule",
  "routes.orchestrators.smartocr.green_card": "green_card",
  "routes.orchestrators.smartocr.rib": "rib",
  "routes.orchestrators.smartocr.invoice": "facture",
  "routes.orchestrators.smartocr.purchase_order": "ordre d'achat",
  "routes.orchestrators.smartocr.tax_notice": "avis d'impo.",
  "routes.orchestrators.smartocr.receipt": "reçu",
  "routes.orchestrators.smartocr.edf": "edf",
  "routes.orchestrators.smartocr.payroll_statemenet": "fiche de paie",
  "routes.orchestrators.exceptions": "exceptions",
  "routes.orchestrators.exceptions.processes": "Exceptions des processus",
  "routes.orchestrators.process-occurrence": "Occurrences des processus",
  "routes.orchestrators.exceptions.items": "Exceptions des items",
  // ALERT MANAGEMENT ROUTES
  "routes.orchestrators.alertsManagement": "Gestion des notifications",
  "routes.orchestrators.alertsManagement.defaultAlerts":
    "Notifications par défaut",
  "routes.orchestrators.alertsManagement.customAlerts":
    "Notifications personnalisées",
  "routes.orchestrators.alertsManagement.defaultAlerts.add": "Ajouter",
  "routes.orchestrators.alertsManagement.defaultAlerts.edit": "Modifier",
  "routes.orchestrators.alertsManagement.defaultAlerts.view": "Voir",
  "routes.orchestrators.alertsManagement.customAlerts.add": "Ajouter",
  "routes.orchestrators.alertsManagement.customAlerts.edit": "Modifier",
  "routes.orchestrators.alertsManagement.customAlerts.view": "Voir",
  "exceptions.item.tab.title": "Exceptions des items",
  "exceptions.processes.tab.title": "Exceptions des processus",
  "routes.orchestrators.process-occurence": "occurrence du processus",
  "routes.orchestrators.profile": "profile",
  "routes.orchestrators.administration.fleets": "fleets",
  "routes.orchestrators.administration.fleets.add": "Ajouter",
  "routes.orchestrators.administration.fleets.edit": "Modifier",
  "routes.orchestrators.administration.fleets.view": "Voir",
  "routes.orchestrators.analytics": "Analytique",
  "save draft": "Sauvegarder",
  "user.title.editHeader": "Modifier l'utilisateur",
  "fleet.management.status": "Statut",
  "fleet.management.companyName": "Société",
  "fleet.management.email": "Email",
  "fleet.management.clientSince": "Groupe créé depuis",
  "fleet.management.title": "Gestion des groupes",
  "fleet.management.menu.title": "Groupes",
  "fleet.management.add.button": "Créer un groupe",
  "fleet.management.formControl.invalidDate": "Format de date non valide",
  "fleet.management.delete.confirmMsg":
    "Êtes-vous sûr de vouloir supprimer ce client ?",
  "fleet.management.deleteClientsActionMessage":
    "Êtes-vous sûr de vouloir supprimer les clients sélectionnés",
  "fleet.management.enaDes.confirmMsg":
    "Êtes-vous sûr de vouloir activer ce client ?",
  "fleet.management.enaDes.button.confirm": "Activer",
  "fleet.management.desaDes.confirmMsg":
    "Êtes-vous sûr de vouloir désactiver ce client ?",
  "fleet.management.desaDes.button.confirm": "Désactiver",
  "fleet.management.searchClient": "Rechercher un flot",
  "fleet.management.addHeader": "Ajouter un client",
  "fleet.management.editHeader": "Modifier un client",
  "fleet.management.displayHeader": "Détails du client",
  "fleet.management.formControl.required": "Champ obligatoire",
  "fleet.management.formLabel.companyName": "Nom de la société",
  "fleet.management.formControl.email": "Mauvais format d'e-mail",
  "fleet.management.formControl.phone":
    "Le format du numéro de téléphone n'est pas conforme",
  "user.management.formControl.phone": "Mauvais format de téléphone",
  "fleet.management.formLabel.email": "Email",
  "fleet.management.formLabel.phoneNumber": "Numéro de téléphone",
  "fleet.management.formLabel.vatNumber": "Numéro de TVA",
  "fleet.management.formControl.vatNumber.notValid":
    "La valeur doit être un nombre",
  "fleet.management.formLabel.address": "Adresse complète",
  "fleet.management.formLabel.subscription": "Abonnement",
  "fleet.management.formLabel.clientSince": "Client depuis",
  "fleet.button.update": "Mettre à jour",
  "fleet.button.save": "Ajouter",
  "fleet.button.cancel": "Annuler",
  "fleett.update.confirmMsg":
    "Êtes-vous sûr de vouloir mettre à jour les informations de ce client ?",
  "fleet.save.confirmMsg": "Êtes-vous sûr de vouloir ajouter ce client ?",
  "fleet.delete.discard":
    "Êtes-vous sûr de vouloir abandonner vos modifications ?",
  "fleet.button.discard": "Abandonner",
  "fleet.formControl.updateSuccess":
    "Les informations du client ont été mises à jour avec succès",
  "fleet.formControl.updateError":
    "Erreur lors de la mise à jour des informations du le client",
  "fleet.formControl.saveError": "Erreur lors de l'ajout d'un nouveau client",
  "orchestrator.formControl.updateSuccess":
    "Les informations d'orchestrateur ont été mises à jour avec succès ",
  "user.management.formLabel.fleet": "Groupe",
  "processesSelector.availableProcess": "Processus disponibles",
  "processesSelector.assignedProcess": "Processus assignés",
  "processTagSelector.assignedProcessTags": "Processus & tags assignés",
  "tagSelector.availableTags": "Tags disponibles",
  "user.formControl.saveError":
    "Erreur lors de l'ajout d'un nouveau utilisateur",
  "user.formControl.updateError":
    "Erreur lors de la mise à jour des informations du l'utilisateur",
  "user.formControl.updateSuccess":
    "Les informations de l'utilisateur ont été mises à jour avec succès",
  "user.formControl.saveSuccess": "Nouvel utilisateur ajouté avec succès",
  "fleet.formControl.saveSuccess": "Nouveau client ajouté avec succès",
  "orchestrator.formControl.saveSuccess":
    "Nouveau orchestrateur ajouté avec succès",
  "user.permissions.title": "Autorisations de l'utilisateur",
  "user.permission.save": "sauvegarder",
  "user.permissions.template.createBtn": "Créer un modèle",
  "user.add.template": "Modèle",
  "user.permission.confirmSave":
    "Êtes-vous sûr de vouloir mettre à jour les autorisations des utilisateurs?",
  "user.permission.confirmDelete":
    "Êtes-vous sûr de vouloir supprimer ce modèle?",
  "user.permission.saveAsTemplace": "Sauvegarder et créer un modèle",
  "user.permission.saveAsTemplaceTitle": "Nouveau modèle",
  "user.permission.templateCreate.success": "Modèle créé avec succès",
  "user.permission.templateDelete.success": "Modèle supprimé avec succès",
  "user.permission.templateCreate.error": "Nom de modèle existe déjà",
  "permission.action.applyTemplate": "Appliquer un modèle",
  "user.permissions.template.name": "Nom de modèle",
  "user.permissions.default": "Défaut",
  "user.add.permissions": "Autorisations",
  "fleet.formControl.unassignProcessError":
    "Le processus est déjà attribué à {{users}} utilisateurs",
  "fleet.management.delete.warning":
    "La suppression d'un client entraîne la suppression automatique de tous ses utilisateurs et la désaffectation de ses processus !",
  "user.administration.fleet": "Groupe",
  "user.administration.lastLogin": "Dernière connexion",
  "tooltip.action.enable": "Activer",
  "tooltip.action.disable": "Désactiver",
  "tooltip.action.permissions": "Autorisation",
  "user.management.formControl.required": "Champ obligatoire",
  "user.management.formControl.email": "Mauvais format d'e-mail",
  "fleet.management.formControl.siret.notValid": "SIRET non valide",
  "fleet.management.formControl.siren.notValid": "SIREN non valide",
  "fleet.formSection.fleetInfo": "Informations générales",
  "fleet.formSection.clientParams": "Paramètres du client",
  "fleet.formSection.subscriptionInfo": "Infos sur l'abonnement",
  "fleet.formSection.clientParameters": "Paramètres groupe",
  "fleet.management.formLabel.rate": "Taux",
  "fleet.management.formLabel.overConsumptionRate": "Taux de surconsommation",
  "fleet.management.formLabel.subscriptionPeriod": "Période d'abonnement",
  "fleet.management.formLabel.paymentTerm": "Délai de paiement",
  "fleet.formSection.processes": "Processus",
  "fleet.formSection.processesTags": "Processus et tags",
  "fleet.formSection.summary": "Résumé de l'abonnement actif",
  "fleet.management.formLabel.rateUnit": "€/heure",
  "fleet.management.formLabel.subscriptionPeriodUnit": "mois",
  "fleet.management.formLabel.siret": "Siret",
  "fleet.management.formLabel.siren": "Siren",
  "fleet.management.formLabel.manDayValue": "1 Jour-homme (j/h)",
  "fleet.management.formLabel.fullTime": "1 Employé à temps plein (ETP)",
  "fleet.management.formLabel.bePeriod":
    "Période de calcul du point d'amortissement",
  "fleet.management.formLabel.recommendedOccupancy": "L'occupation recommandée",
  "fleet.management.be.period.1": "Mois dernier",
  "fleet.management.be.period.2": "3 derniers mois",
  "fleet.management.be.period.3": "6 derniers mois",
  "fleet.management.be.period.4": "L'année dernière",
  "fleet.add.previous": "Précédent",
  "process.filter.client": "Client",
  "process.enabled": "Affiché",
  "process.filter.showAll": "Afficher les ({{count}}) processus masqués",
  Unremarkable: "< 5 Ans",
  "fleet.management.formControl.number":
    "Le format du numéro de TVA n'est pas conforme",
  "fleet.management.formControl.occupancyNumber": "L'occupation recommandée doit être un nombre",
  "fleet.management.formControl.occupancyHigherThan": "L'occupation recommandée ne peut pas être supérieure à 24",
  "fleet.management.formControl.occupancyLowerThan": "L'occupation recommandée ne peut pas être inférieure à 0",
  "fleet.management.hoursPerDay": "Heures par jour",
  "reporting.copyToClipboard": "Copier dans le presse-papier",
  "reporting.process": "Processus",
  "reporting.kpi": "KPI",
  "reporting.reportType": "Type de rapport",
  "reporting.date": "Date",
  "reporting.table": "Tableau",
  "reporting.lineChart": "Graphique linéaire",
  "reporting.pieChart": "Diagramme circulaire",
  "reporting.barChar": "Diagramme à bandes",
  "reporting.columnChart": "Graphique de colonne",
  "reporting.totalExecution": "Nombre d'exécutions",
  "reporting.totalException": "Nombre d'exceptions",
  "reporting.timeSaved": "Temps économisé",
  "reporting.executionTime": "Temps d'exécution du processus",
  "reporting.itemExecutionTime": "Temps d'exécution des items",
  "reporting.breakEven": "Point d'amortissement",
  "reporting.roi": "Taux d'amortissement",
  "reporting.roiPercentage": "Taux d'amortissement",
  "reporting.roiShort": "Taux d'amortissement",
  "reporting.occupancyRate": "Taux d'occupation",
  "reporting.executedProcesses": "Nombre d'exécutions",
  "reporting.save": "Exporter PNG",
  "reporting.copyClipboard": "Copier dans le presse-papier",
  "reporting.exportPDF": "Exporter PDF",
  "reporting.clear": "Effacer",
  "reporting.from": "De",
  "reporting.to": "À",
  "reporting.successCount": "Nombre d'items traités avec succès",
  "reporting.exceptionCount": "Nombre d'items en exceptions",
  "reporting.reportTitle": "entre {{from}} et {{to}}",
  "reporting.and": " et",
  for: "pour",
  "Results for": "Résultats pour",
  "reporting.cumul": "Résultat cumulatif",
  "Process launcher": "Lanceur de processus",
  "Execution detail": "Détails de l'exécution",
  "Business Exception": "Exception fonctionnelle",
  "System Exception": "Exception du système",
  Unspecified: "Non spécifié",
  Internal: "Interne",
  "Process.list.names.by.orchestrator": "Processus",
  "Robot.list.names.by.orchestrator": "Robots",
  "fleet.management.formLabel.effectiveStartDate": "Date de début effective",
  "rpaQual.transition.delete": "Supprimer",
  "rpaQual.transition.deleteMsg":
    "Êtes-vous sûr de vouloir supprimer cette opportunité?",
  "rpaQual.button.delete":
    "Êtes-vous sûr de vouloir supprimer cette qualification?",
  "rpaQual.transition.reconsider": "Reconsidérer",
  "rpaQual.transition.reconsiderMsg":
    "Êtes-vous sûr de vouloir reconsidérer ce cas?",
  "rpaQual.transition.archive": "Archiver",
  "rpaQual.transition.archiveMsg":
    "Êtes-vous sûr de vouloir archiver cette affaire?",
  "rpaQual.process.archived.success": "Cas archivé",
  "rpaQual.process.roadMap.success": "Ajouté à la feuille de route",
  "rpaQual.process.reconsider.success": "Opportunité reconsidérée",
  "rpaQual.transition.addToRoadMap": "Ajouter à la feuille de route",
  "rpaQual.transition.addToRoadMapMsg":
    "Êtes-vous sûr de vouloir ajouter ce cas à votre feuille de route?",
  "rpaQual.filter.show": "Inclure archivée",
  "rpaQual.display.nonArchived": "Pas archivée",
  "rpaQual.display.archived": "Archivée",
  "rpaQual.display.all": "Tout",
  "breadcrumbs.label.details": "Détails",
  "rpaQual.opportunity.list.empty": "La liste des opportunités est vide !",
  "rpaQual.case.list.empty": "",
  "rpaQual.status.submitted": "En attente",
  "rpaQual.status.reconsider": "En attente",
  "rpaQual.status.accepted": "Acceptée",
  "rpaQual.status.draft": "Brouillon",
  "rpaQual.status.not submitted": "Non validée",
  "rpaQual.status.rejected": "Rejetée",
  "rpaQual.status.roadMap": "RoadMap",

  "filter.graph": "Graphe",
  "filter.export": "Exporter",

  // Analytics - help
  "help.process":
    "Veuillez choisir le(s) processus concerné(s) par l'analytique",
  "help.tags": "Veuillez choisir le(s) tag(s) concerné(s) par les analyses",
  "help.kpi": "Veuillez choisir le(s) KPI(s)",
  "help.reportType":
    "La liste des types de rapport affichée est basée sur le(s) KPI(s) choisis",

  "Select All": "Sélectionner tout",
  // permissions

  "User Administration": "Administration des utilisateurs",
  "Client Administration": "Administration des clients",
  "Orchestrator Administration": "Administration des orchestrateurs",
  "Processes launcher": "Lanceur des processus",
  "Start process": "Démarrer un processus",
  "Schedule process": "Programmer un processus",
  "KPI parameters": "Paramètres",
  "Financial Parameters": "Paramètres financiers",
  "Edit process details": "Modifier les details du processus",
  "Enable/Disable process": "Afficher / masquer les processus",
  "Item exceptions": "Exceptions des items",
  "Delete item exceptions": "Masquer les exceptions des items",
  "Retry item exceptions with changed data":
    "Relancer les exceptions des items avec des données modifiées",
  "Retry item exceptions": "Relancer les exceptions des items",
  Workflow: "Flux de travail",
  "Process execution exceptions": "Exceptions des processus",
  "Delete process execution exceptions":
    "Suppression des exceptions des processus",
  "View process exceptions": "Afficher les exceptions des processus",
  "Delete process exceptions": "Masquer les exceptions des processus",
  "View item exceptions": "Afficher les exceptions des items",
  "Add opportunity": "Ajouter une opportunitée",
  "Accept / Reject / Reconsider": "Accepter / Rejeter / Reconsidérer",
  "Archive / Backlog": "Archive / Backlog",
  "Delete opportunity": "Suppression des opportunitées",
  "Add client": "Ajout des clients",
  "Manage client": "Gestion des clients",
  "Delete client": "Suppression des clients",
  "Add user": "Ajout des utilisateurs",
  "Edit user": "Modification des utilisateurs",
  "Delete user": "Suppression des utilisateurs",
  "User Permissions": "Permissions des utilisateurs",
  "Add Orchestrators": "Ajout des orchestrateurs",
  "Edit Orchestrators": "Modification des orchestrateurs",
  "Delete Orchestrators": "Suppression des orchestrateurs",

  "process-occurence.cutsomdate": "Date personnalisée",
  "exception.analytics.by.process": "Exceptions par processus",
  "exception.analytics.by.type": "Résumé des types d'exceptions",
  "exception.analytics.by.reason": "Principales raisons d’exceptions",
  "exception.analytics.by.trend": "Tendance des exceptions",

  "Execution Time (Descending)": "Durée d'exécution (décroissant)",
  "Execution Time (Ascending)": "Durée d'exécution (croissant)",

  "no.process.message": "Aucun processus détecté",

  "no.completedItems.message": "Aucun item terminé détecté",
  "no.pendingItems.message": "Aucun item en attente détecté",
  "no.execution.message": "Aucune exécution de processus trouvée",
  "no.scheduler.message": "Aucun programme trouvé",
  "no.type.message": "Aucun type trouvé",

  "no.orchestrator.message": "Pas d'orchestrateurs pour le moment",
  "no.robot.message": "Pas de robots pour le moment",
  "no.fleets.message": "Pas de groupes pour le moment",
  "no.users.message": "Pas d'utilisateurs pour le moment",
  "no.robots.message": "Pas de robots pour le moment",
  "no.items.exception.message": "Aucune exception des items trouvée",
  "no.process.exception.message": "Aucune exception de processus trouvée",
  "no.analytics.message": "Aucun processus détecté",
  "no.planned.execution.message": "Aucune exécution planifiée trouvée",
  "no.activities.message": "Aucune activité trouvée",
  "select.all": "Sélectionner tout",
  Clear: "Vider",

  "add.client.form.vat.number.email.company.name.already.exist":
    "Un client avec la même adresse e-mail, numéro de TVA et nom de la société existe déjà",
  "add.client.form.vat.number.email.already.exist":
    "Un client avec la même adresse e-mail et le même numéro de TVA existe déjà",
  "add.client.form.vat.number.company.name.already.exist":
    "Un client avec le même numéro de TVA et nom de la société existe déjà",
  "add.client.form.email.company.name.already.exist":
    "Un client avec la même adresse e-mail et nom de la société existe déjà",
  "add.client.form.email.already.exist":
    "Un client avec la même adresse e-mail existe déjà",
  "add.client.form.vat.number.already.exist":
    "Un client avec le même numéro de TVA existe déjà",
  "add.client.form.company.name.already.exist":
    "Un client avec le même nom de la société existe déjà",
  "restriction.form.MinimumExecutionFrequency":
    "La Fréquence d'exécution minimale",
  "add.client.form.vat.number.email.company.name.siren.already.exist":
    "Un client avec la même adresse e-mail, numéro de TVA, nom de la société et SIREN existe déjà",
  "add.client.form.vat.number.email.siren.already.exist":
    "Un client avec la même adresse e-mail, numéro de TVA et SIREN existe déjà",
  "add.client.form.vat.number.company.name.siren.already.exist":
    "Un client avec le même numéro de TVA, nom de la société et SIREN existe déjà",
  "add.client.form.vat.number.siren.already.exist":
    "Un client avec le même numéro de TVA et le même SIREN existe déjà",
  "add.client.form.email.company.name.siren.already.exist":
    "Un client avec la même adresse e-mail, nom de la société et SIREN existe déjà",
  "add.client.form.email.siren.already.exist":
    "Un client avec la même adresse e-mail et le même SIREN existe déjà",
  "add.client.form.company.name.siren.already.exist":
    "Un client avec le même nom de la société et le même SIREN existe déjà",
  "add.client.form.siren.already.exist":
    "Un client avec le même SIREN existe déjà",

  // Robot Management
  "robot.management.name": "Nom",
  "robot.management.formControl.actionsRunning": "Actions en cours",
  "robot.management.formControl.processesRunning": "Processus en cours",
  "robot.management.formControl.robotName": "Nom du robot",
  "robot.management.formControl.robotOriginalName":
    "Nom de la ressource d'origine",
  "robot.management.formControl.statusDescription": "Descriptif du statut",
  "robot.management.formControl.robotId": "Id robot",
  "robot.management.formControl.unitsAllocated": "Unités attribuées",
  "robot.management.solutionProvider": "Fournisseur de solution",
  "robot.management.displayStatus": "Statut",
  "robot.management.orchestratorName": "Orchestrateur",
  "robot.management.attributeName": "Type du robot",
  "robot.management.processesRunning": "Processus en cours",
  "robot.management.robotId": "Id robot",
  "robot.management.robotName": "Nom du robot",
  "Routes.orchestrators.administration.robots": "Robots",
  "robot.management.displayHeader": "Details du robot",
  "robot.management.enable.confirmMsg":
    "Êtes-vous sûr de vouloir activer cet utilisateur ?",
  "robot.management.disable.confirmMsg":
    "Êtes-vous sûr de vouloir désactiver cet utilisateur ?",
  "robot.management.disable.button.confirm": "Désactiver",
  "robot.management.enable.button.confirm": "Activer",
  "robot.button.update": "Mettre à jour",
  "robot.button.cancel": "Annuler",
  "robot.button.discard": "Ignorer",
  "robot.update.confirmMsg":
    "Êtes-vous sûr de vouloir mettre à jour les détails de ce robot ?",
  "robot.update.discard":
    "Êtes-vous sûr de vouloir ignorer ces modifications ?",
  "robot.modify": "Modifier",
  "routes.orchestrators.administration.robots.view": "Voir",
  "routes.orchestrators.administration.robots.edit": "Détails",
  "routes.orchestrators.administration.robots": "Robots",
  "administration.detail.entity.processes.title": "Processus",
  // ALERT MANAGEMENT
  "alerts.default.tab.title": "Notifications par défaut",
  "alerts.custom.tab.title": "Notifications personnalisées",

  // DEFAULT ALERTS TABLE HAEDER CELLS
  "alert.status": "Statut",
  "alert.alertName": "Nom",
  "default.alert.event": "Événement",
  "alert.condition": "Condition",
  "alert.type": "Type de notification",
  "report.type": "Type de rapport",

  "no.alerts.message": "Aucune notification trouvée !",
  "alerts.default.addButton": "Créer une notification",

  // CUSTOM ALERTS TABLE HAEDER CELLS
  "custom.alert.KPI": "KPI",
  "custom.alert.process": "Processus",

  "alerts.custom.addButton": "Ajouter Notification",
  "alert.management.delete.confirmMsg":
    "Voulez-vous vraiment supprimer cette notification?",

  // Add & Edit headers alert
  "alerts.addHeader": "Ajouter une notification",
  "alerts.editHeader": "Modifier la notification",
  "alerts.displayHeader": "Détails de la notification",

  // error messages
  "alerts.management.required.fields": "Champs requis",

  // confirmation buttons
  "alerts.management.button.update": "mettre à jour",
  "alerts.management.button.add": "ajouter",
  "alerts.management.button.cancel": "annuler",
  "alerts.management.button.save": "sauvegarder",
  "alerts.management.button.exit": "quitter",
  "alerts.management.button.discard": "ignorer",

  "alerts.management.trigger": "Déclencheur",
  "alerts.management.triggers": "Déclencheurs",
  "alerts.management.notifications": "Notifications",
  "alerts.management.subscribers": "Abonnés",
  "alerts.management.name.placeholder": "Entrez le nom de la notification",
  "alerts.management.name.label": "Nom de la notification *",
  "alerts.management.condition.label": "Valeur *",
  "alerts.management.alertDescription.placeholder":
    "Entrer la description de la notification",
  "alerts.management.alertDescription.label": "Description",
  "alerts.management.when.placeholder": "Quand *",
  "alerts.management.operator.placeholder": "Est *",
  "alerts.management.process.placeholder": "De *",
  "alerts.management.of.placeholder": "De *",

  "alerts.management.email.invalidEmail": "Format d'adresse e-mail invalide",
  "alerts.management.email.invalidEmails": "Format d'adresses e-mail invalides",
  "alerts.management.email.invalidPhoneNumber":
    "Format de numéro de téléphone invalide",
  "alerts.management.email.invalidPhoneNumbers":
    "Format des numéros de téléphone invalide",
  "alerts.management.severity": "Type de notification *",
  "alerts.management.save.confirmMsg":
    "Voulez-vous vraiment enregistrer cette notification ?",
  "alerts.management.update.confirmMsg":
    "Voulez-vous vraiment mettre à jour les informations de la notification ?",
  "alerts.management.delete.discard":
    "Voulez-vous vraiment annuler vos modifications ?",
  "Alert Frequency": "Fréquence",
  "alert.management.formControl.saveSuccess":
    "Nouvelle notification a été ajoutée avec succès",
  "alert.management.formControl.delete":
    "Notification a été supprimé",
  "alert.management.formControl.updateSuccess":
    "Les informations de la notification ont été  mises à jour avec succès",
  "alert.management.enable.email.notification":
    "Êtes-vous sûr de vouloir activer les notifications par email ?",
  "alert.management.disable.email.notification":
    "Êtes-vous sûr de vouloir désactiver les notifications par email ?",
  "alert.management.enable.sms.notification":
    "Êtes-vous sûr de vouloir activer les notifications par sms ?",
  "alert.management.disable.sms.notification":
    "Êtes-vous sûr de vouloir désactiver les notifications par sms ?",
  "New opportunity created": "Nouvelle opportunité créée",
  "Opportunity accepted": "Opportunité Acceptée",
  "Opportunity rejected": "Opportunité rejetée",
  "Opportunity added to the road map":
    "Opportunité ajoutée à la feuille de route",
  "Opportunity archived": "Opportunité archivée",
  "Process activated": "Processus activé",
  "Process disabled": "Processus désactivé",
  "Orchestrator enabled": "Orchestrateur activé",
  "Orchestrator disabled": "Orchestrateur désactivé",
  "Orchestrator actionable": "Orchestrateur rendu actionnable",
  "Orchestrator not actionable": "Orchestrateur n’est pas actionnable",
  "Schedule added": "Programme ajouté",
  "Execution Time": "Durée d'exécution ",
  "Process execution duration": "Durée d'exécution du processus",
  "Total Executions": "Nombre total d'exécutions",
  "Success Rate": "Taux de réussite",
  "Exceptions Rate": "Taux d'exception",
  "Items exception rate": "Taux d'exception des items",
  "Time Saved": "Temps économisé",
  "Number of items completed with exceptions":
    "Nombre d'éléments complétés avec des exceptions",
  "Number of completed items": "Nombre d'items complétés",
  "Number of process executions with exceptions":
    "Nombre d'exécutions de processus avec exceptions",
  "Number of process executions completed successfully":
    "Nombre d'exécutions de processus terminées avec succès",
  "create.scheduler": "Créer un programme",
  "Edit Scheduler": "Modifier le programme",
  "Choose Process": "Choisir le processus",
  "Choose Robot": "Choisir le robot",
  "First day of month": "Premier jour du mois",
  "Last day of month": "Dernier jour du mois",
  "Day number": "Numéro du jour",
  "alert.management.email.helper":
    "Vous pouvez valider l'ajout de votre e-mail en cliquant sur Entrée ou sur Espace.",
  "alert.management.sms.helper":
    "Vous pouvez valider l'ajout de votre numéro de téléphone en cliquant sur Entrée ou sur Espace.",
  "alert.management.trigger.helper":
    "En cas de déclencheurs multiples, tous les déclencheurs doivent être vrais pour que la notification soit envoyé",
  Active: "Actif",
  info: "Information",
  error: "Erreur",
  EQUAL: "ÉGALE",
  TRUE: "VRAI",
  FALSE: "FAUX",
  "Export as a Pdf": "Exporter au format PDF",
  "GREATER THAN": "Supérieure à",
  "LESS THAN": "Inférieure à",
  "GREATER OR EQUAL TO": "Supérieure ou égale à",
  "LESS OR EQUAL TO": "Inférieur ou égal à",
  "alerts.management.alertName.maxLength.error":
    "Ce champs ne doit pas dépasser 55 caractères!",
  "alerts.management.alertDescription.maxLength.error":
    "Ce champs ne doit pas dépasser 65535 caractères!",
  "no.process.planned": "Pas de processus planifiés",
  orchestrator: "Orchestrateur",
  "start.process.no.robot.assigned": "Pas de robots assignés",
  Client: "Client",
  "kpi.header.breakEven.tooltip":
    "Le point d'amortissement s'estime sur la base de données historiques. Il répond à la question quand l'investissement dans les processus est-il pleinement acquis ? Les résultats sont basés sur les paramètres financiers configurés pour chaque processus et le filtre de la période du tableau de bord.",
  "kpi.header.roi.tooltip":
    "Répond à la question combien de l'investissement initial a déjà été gagnée ? Les résultats sont calculés en se basant sur les paramètres financiers configurés pour chaque processus et le filtre de la période du tableau de bord.",
  "kpi.header.breakEven.tooltip.nodata":
    "En attente de plus d'exécutions pour estimer le point d'amortissement.",
  "kpi.header.roi.tooltip.nodata":
    "En attente de plus d'exécutions pour calculer la rentabilité.",
  "dashboard.graph.treatedItems": "Items terminés",
  "dashboard.graph.pendingItems": "Items en attente",
  "dashboard.graph.exceptionItems": " Items en exception",
  "dashboard.graph.totalItems": "Tous les items",
  "Submitting...": "Soumission...",
  "robot.management.deleteRobotActionMessage": "Les éléments sélectionnés auront leur visibilité modifiée en cachée ou visible.",
  "NOT VALIDATED": "NON VALIDÉE",
  "tooltip.action.hide": "Masquer",
  "tooltip.action.show": "Afficher",
  "tooltip.action.showHiddenItems": "Afficher masqués",
  "exception.analytics.typeNotFound": "Type d'exception non spécifié",
  "orchestrator.management.formControl.sessionNumber":
    "Nombre de sessions autorisées",
  "profile.user.general.title": "Informations générales",
  "profile.user.general.security": "Sécurité",
  "rpa.export.rpa": "RPA",
  "rpa.export.qualification": "QUALIFICATION",
  "rpa.create.businessApplication": "Listez les applications ici",
  "add.schedule.same.date.constraint": "Un programme avec la même date de début existe déjà",
  "reporting.date.errorFuture":
    "La valeur doit être supérieure à {{dateValue}}",
  "reporting.date.errorPast":
    "La valeur devrait être inférieure à {{dateValue}}",
  "user.add.admin": "Utilisateur administrateur",
  "Items status by process": "Statut des items par processus",
  "Current Period": "Période actuelle",
  "Average Handling Time": "Temps de traitement moyen",
  "Last Period": "Période précédente",
  "use intervals": "Utiliser des intervalles",
  "use cumulative": "Utilisation cumulative",
  "please enter at least one email to enable email notification":
    "Veuillez saisir au moins une adresse e-mail pour activer la notification par e-mail.",
  "please enter at least one phone number to activate the sms notification":
    "Veuillez saisir au moins un numéro de téléphone pour activer la notification par sms.",
  Exception: "Exception",
  Success: "Succès",
  "The calculation of the KPIs will be based on an interval":
    "Le calcul des KPI sera basé sur un intervalle.",
  "Calculation of the KPIs for the whole time":
    "Calcul des KPI pour l'ensemble du temps.",
  "Receive alerts from the following date":
    "Recevez des notifications à partir de la date suivante.",
  "Stop receiving alerts from the following date":
    "Ne plus recevoir de notifications à partir de la date suivante.",
  "dashboard.tooltip.timesaved":
    "Ce graphe présente la répartition du temps économisé (en heures) sur la période sélectionnée dans le filtre de la période du tableau de bord. Les résultats sont calculés en fonction des paramètres financiers configurés pour chaque processus et du filtre de processus du tableau de bord.",
  "dashboard.tooltip.processOverview":
    "Ce graphe montre la distribution du temps d'exécution de chaque processus par rapport à tous les processus. Les résultats sont basés sur le filtre de la période du tableau de bord.",
  "dashboard.tooltip.itemsProcess":
    "Ce graphe montre la répartition du statut des items pour les principaux processus. Les résultats sont basés sur le filtre de la période du tableau de bord.",
  "dashboard.tooltip.executionsProcess":
    "Ce graphe présente la répartition des exécutions de processus sur la période sélectionnée dans le filtre de la période du tableau de bord.",
  "dashboard.tooltip.averageTime":
    "Le temps de traitement moyen (AHT) est le temps moyen qu'il a fallu pour exécuter un item dans ce processus pendant la période sélectionnée dans le filtre de la période.",
  STOPPED: "Arrêté",
  COMPLETED: "Complété",
  DEBUGGING: "Débogage",
  ARCHIVED: "Archivé",
  STOPPING: "Arrêt",
  WARNING: "Avertissement",
  "status.running.with.warning": "En cours avec un avertissement",
  "process.history.duration": "Durée d'exécution",
  "process.history.completedItems": "Items terminés",
  "process.history.exceptionItems": "Items d'exceptions",
  "process.history.robot": "Robot",
  AUTOMATIC: "Automatique",
  MANUAL: "Manuel",

  "Process data mapping": "Mappage des données",
  "Are you sure you want to update the mapping ?":
    "Êtes-vous sûr de vouloir mettre à jour le mapping ?",
  "no.data-mapping.message":
    "Le mapping ne peut être effectué qu'après le traitement du premier item du processus.",
  Data: "Donnée",
  "New name": "Nouveau nom",
  Display: "Afficher",
  "display.in.items.list": "Afficher dans la liste des éléments",
  "Orchestrator configuration": "Configuration de l'orchestrateur",
  "SmartRoby will add columns and create views and triggers on your orchestrator database":
    "SmartRoby ajoutera des colonnes et créera des vues et des déclencheurs sur la base de données de votre orchestrateur.",
  "Initialize connectors to synchronize data between your orchestrator and SmartRoby":
    "Initialiser les connecteurs pour synchroniser les données entre votre orchestrateur et SmartRoby.",
  "sync.Execute": "Exécuter",
  "sync.Initialize": "Initialiser",
  "Database configuration": "Configuration de la base de données",
  "Orchestrator/SmartRoby synchronization":
    "Synchronisation Orchestrator/SmartRoby",
  "Orchestrator status": "Statut de l'Orchestrateur",
  "sync.Refresh": "Actualiser",
  "sync.General": "Général",
  "Database connection": "Connexion à la base de données",
  "Synchronization engine": "Moteur de synchronisation",
  "sync.Synchronization": "Synchronisation",
  "sync.process": "Processus",
  "sync.execution": "Exécutions",
  "sync.resource": "Ressources",
  "sync.schedule": "Plannings",
  "sync.work_queue_item": "Items",
  "Number of items with Business Exceptions":
    "Nombre des items avec Business Exceptions",
  "Number of items with System/Internal Exception":
    "Nombre des items avec System/Internal Exception",
  "Number of process executions with Business Exceptions":
    "Nombre d'exécutions de processus avec Business Exceptions",
  "Number of process executions with System/Internal Exception":
    "Nombre d'exécutions de processus avec System/Internal Exception",
  "sync.deleted_record": "Suppression des enregistrements",
  "sync.license": "Licences",
  "robot.management.hide.confirmMsg":
    "Êtes-vous sûr de vouloir masquer ce robot?",
  "robot.management.show.confirmMsg":
    "Êtes-vous sûr de vouloir afficher ce robot?",
  "filter.clear": "Videz les filtres",
  Reasons: "Raisons",
  allowedSessionsExceededForAnOrchestrator:
    "{{parallelExecutions}} exécutions parallèles ont été trouvées alors que l'orchestrateur : '{{OrhcestratorName}}' n'a que {{allowedSessions}} session(s) autorisée(s).",
  "List of conflicts for": "Liste des conflits pour ",
  concurrentExecutionInTheSameRobotMessage:
    "Plus d'une exécution a été trouvée dans le robot : '{{robotName}}' à {{executionDate}} entre {{plannerStart}} et {{plannerEnd}}. Les autres exécutions planifiées commenceront après l'achèvement de la première selon cet ordre : ",
  "Number of pending items": "Nombre des items en attente",
  "kpi.parameter.allowScheduling": "Peut être programmé",
  "kpi.parameter.allowManual": "Peut être lancé manuellement",
  "kpi.parameter.retryable": "Les exceptions peuvent être relancées",
  "kpi.parameter.retryableWithData": "Les exceptions peuvent être relancées avec des données modifiées",
  "kpiParam.reset.success": "Paramètres réinitialisés avec succès",
  "kpiParam.reset.error": "Échec de la réinitialisation des paramètres de KPI",
  "kpiParam.schedule.warning":
    "Ce processus a déjà des programmes. Si vous poursuivez, ils seront tous supprimés.",
  "Warnings Management": "Gestion des conflits",
  "processStore.year": "an",
  "processStore.years": "ans",
  "can.be.edited.for.retry": "Peut être modifié pour une relance",
  retry: "Relancer",
  "retry-dialog.title.one-item": "Relancer l'exception de l'item",
  "retry-dialog.title.multiple-items":
    "Relancer {{count}} exceptions des items",
  "retry-dialog.text.one-item":
    "Êtes-vous sûr de vouloir relancer cette exception? L'item sera relancé lors de la prochaine exécution du processus.",
  "retry-dialog.text.multiple-items":
    "Êtes-vous sûr de vouloir relancer {{count}} exceptions? les items seront relancés lors de la prochaine exécution de leurs processus.",
  "retry-dialog.pending-alert":
    "Une relance en attente pour cet item existe déjà",
  "retry-dialog.not-retryable": "Vous avez selectionné des exceptions qui appartiennent à des processus dont les exceptions ne peuvent pas être relancées",
  "retry-dialog.radio.same-data": "Relancer avec les mêmes données",
  "retry-dialog.radio.changed-data": "Relancer avec les donnés modifiées",
  "retry-dialog.table.name": "Nom",
  "retry-dialog.table.old-value": "Ancienne valeur",
  "retry-dialog.table.new-value": "Nouvelle valeur",
  "retry-dialog.message.success.one-item":
    "La relance de l'item est créée avec succès",
  "retry-dialog.message.success.multiple-items":
    "{{count}} relances des items sont créées avec succès",
  "retry-dialog.message.error.one-item":
    "Échec de la création de la relance de l'item",
  "retry-dialog.message.error.multiple-items":
    "Échec de la création des relances des items",
  "restriction.form.hour.maxValue.error":
    "La valeur doit être inférieure ou égale à 23.",
  "restriction.form.min.sec.maxValue.error":
    "La valeur doit être inférieure ou égale à 59.",
  attempt: "Tentative",
  "attempt.chip.tooltip": "Ceci est la {{attempt}} tentative de cet item",
  "exception-workflow.status.ready": "Prêt",
  "exception-workflow.status.taken": "Assigné à {{firstname}} {{lastname}}",
  "exception-workflow.status.done": "Terminé",
  "exception-workflow.action.take": "Prendre",

  "reporting.processExecutions": "Exécutions des processus",
  "reporting.itemsProcessed": "Items traités",
  "reporting.itemsCompleted": "Items terminés",
  "reporting.successRate": "Taux de réussite des items",
  "reporting.exceptionRate": "Taux d'exception des items",
  "reporting.itemsBusinessExceptions": "Items d'exception métier",
  "reporting.itemsSystemExceptions": "Items d'exception système",

  "exception-workflow.action.assign": "Assigner",
  "exception-workflow.action.release": "Libérer",
  "exception-workflow.action.reopen": "Réouvrir",
  "exception-workflow.action.markAsDone": "Terminer",
  "exception-workflow.log.take":
    "Pris par <b>{{user.firstname}} {{user.lastname}}</b>",
  "exception-workflow.log.assign":
    "Assigné à <b>{{targetUser.firstname}} {{targetUser.lastname}}</b> par <b>{{user.firstname}} {{user.lastname}}</b>",
  "exception-workflow.log.markAsDone":
    "Terminé par <b>{{user.firstname}} {{user.lastname}}</b>",
  "exception-workflow.log.release":
    "Libéré par <b>{{user.firstname}} {{user.lastname}}</b>",
  "exception-workflow.log.reopen":
    "Réouvert par <b>{{user.firstname}} {{user.lastname}}</b>",
  "exception-workflow.snackbar.success": "L'action a été effectuée avec succès",
  "exception-workflow.snackbar.error:": "Échec de l'exécution de l'action",
  "exception-workflow.confirm.take":
    "Êtes-vous sûr de vouloir prendre cette exception?",
  "exception-workflow.confirm.assign":
    "Êtes-vous sûr de vouloir assigner cette exception à {{selectedUser.firstName}} {{selectedUser.lastName}}?",
  "exception-workflow.confirm.markAsDone":
    "Êtes-vous sûr de vouloir terminer cette exception?",
  "exception-workflow.confirm.release":
    "Êtes-vous sûr de vouloir libérer cette exception?",
  "exception-workflow.confirm.reopen":
    "Êtes-vous sûr de vouloir réouvrir cette exception?",
  "exception-workflow.open": "Flux de travail",
  "exception-workflow.open-bulk": "Flux de travail",
  "exception-workflow.no-bulk-action-possible":
    "Aucune action n'est possible pour la combinaison d'exceptions sélectionnée",
  "exception-workflow.selected-message":
    "{{count}} exceptions sont sélectionnées",
  "exception-workflow.confirm.bulk-take":
    "Êtes-vous sûr de vouloir prendre {{count}} exceptions?",
  "exception-workflow.confirm.bulk-assign":
    "Êtes-vous sûr de vouloir assigner {{count}} exceptions à {{selectedUser.firstName}} {{selectedUser.lastName}}?",
  "exception-workflow.confirm.bulk-markAsDone":
    "Êtes-vous sûr de vouloir terminer {{count}} exceptions?",
  "exception-workflow.confirm.bulk-release":
    "Êtes-vous sûr de vouloir libérer {{count}} exceptions?",
  "exception-workflow.confirm.bulk-reopen":
    "Êtes-vous sûr de vouloir réouvrir {{count}} exceptions?",
  "exception-workflow.choose-user": "Choisir un utilisateur",
  "exception-workflow.snackbar.success.take":
    "L'exception a été prise avec succès",
  "exception-workflow.snackbar.success.assign":
    "L'exception a été assignée avec succès",
  "exception-workflow.snackbar.success.assign.notify":
    "Le destinataire a été notifié",
  "exception-workflow.snackbar.success.release":
    "L'exception a été libérée avec succès",
  "exception-workflow.snackbar.success.markAsDone":
    "L'exception a été terminée avec succès",
  "exception-workflow.snackbar.success.reopen":
    "L'exception a été réouverte avec succès",
  "exception-workflow.snackbar.success.bulkTake":
    "Les exceptions ont été prises avec succès",
  "exception-workflow.snackbar.success.bulkAssign":
    "Les exceptions ont été assignées avec succès",
  "exception-workflow.snackbar.success.bulkRelease":
    "Les exceptions ont été libérées avec succès",
  "exception-workflow.snackbar.success.bulkMarkAsDone":
    "Les exceptions ont été terminées avec succès",
  "exception-workflow.snackbar.success.bulkReopen":
    "Les exceptions ont été réouvertes avec succès",
  "processe.schedules.onDay.first": " - Au {{startDay}}er",
  "processe.schedules.onDay.second": " - Au {{startDay}}ème",
  "processe.schedules.onDay.third": " - Au {{startDay}}ème",
  "processe.schedules.onDay": " - Au {{startDay}}ème",
  // error 404
  "Go to the home page":
    "Aller à la page d'accueil",
  "Sorry the page you are looking for could not be found":
    "Désolé, la page que vous recherchez n'a pas pu être trouvée",
  "orchestrator.license.originalId": "ID de licence originale",
  "orchestrator.license.starts": "Commence",
  "orchestrator.license.expires": "Expire",
  "orchestrator.license.installedOn": "Installé",
  "orchestrator.license.maxConcurrentSessions": "Nombre des sessions",
  "orchestrator.license.status": "Statut",
  "orchestrator.license.status.ACTIVE": "Active",
  "orchestrator.license.status.EXPIRED": "Expirée",
  "orchestrator.license.status.NOT_YET_ACTIVE": "Pas encore active",
  "A license is one month away from expiry": "Une licence expire dans un mois",
  "A license is two months away from expiry": "Une licence expire dans deux mois",
  "tooltip.action.license": "Licences",
  "Orchestrator licenses": "Licences d'orchestrateur",
  "process.laststage": "DERNIÈRE PHASE: {{lastStage}}",
  "analytics.preset.button.label": "Préréglages",
  "analytics.preset.load": "Charger un préréglage",
  "analytics.preset.create": "Créer un préréglage",
  "analytics.preset.noPresets": "Pas de préréglage enregistré",
  "analytics.preset.dialog.label": "Nouveau préréglage",
  "analytics.preset.dialog.field.label": "Nom du préréglage",
  "analytics.preset.dialog.field.submit": "Créer",
  "analytics.preset.dialog.delete.label": "Supprimer préréglage",
  "analytics.preset.dialog.delete.text": "Êtes vous sûr de supprimer ce préréglage ?",
  "analytics.preset.dialog.delete.submit": "Supprimer préréglage",
  "robot.tooltip.hide": "Êtes-vous sûr de vouloir masquer {{count}} robot(s)",
  "robot.tooltip.unhide": "Êtes-vous sûr de vouloir afficher {{count}} robot(s)",
  "export.confirmation.radio.grouped": "Exporter avec les détails de l'article regroupés.",
  "export.confirmation.radio.columns": "Exporter avec les détails de l'article dans les colonnes.",
  "export.confiramtion.warnning": "Pour activer cette option, vous devez sélectionner un processus.",
  FINISHED: "TERMINÉ",
  EXPORTING: "EXPORTATION",
  COLLECTING: "COLLECTE",
  "exporting.title": "Fichiers d'exportation",
  "exporting.no.data": "Aucun fichier à télécharger",
  "exporting.btn.download.all": "Télécharger tous",
  "exporting.btn.delete.all": "Supprimer tous",
  Executions: "Exécutions des processus",
  "Executions history": "Historique des exécutions",
  "routes.orchestrators.executions": "Exécutions des processus",
  "no.executions.message": "Aucune exécution trouvée",
  Robot: "Robot",
  Trigger: "Déclencheur",
  MANUALLY: "Manuellement",
  SCHEDULED: "Programmé",
  KEEP: "Garder dans la file SR",
  DELETE: "Supprimer de la file SR",
  Dates: "Dates",
  "Launching date": "Date de lancement",
  "Launching date (Descending)": "Date de lancement (décroissant)",
  "Launching date (Ascending)": "Date de lancement (croissant)",
  "queue.management.title": "Gestion de la file SR",
  "SR Queues": "Files SR",
  "queue.management.choose.robot": "Certaines planifications ne sont affectées à aucun robot, lors de leur migration vers l'orchestrateur d'origine,Elles seront affectées à :",
  "queue.management.status": "Statut",
  "queue.management.name": "Nom",
  "queue.management.creation-date": "Date de création",
  "queue.management.active-since": "Active depuis",
  "queue.management.nb-licences": "Nombre de licences",
  "queue.management.orchestrator": "Orchestrateur",
  "queue.management.search": "Chercher",
  "queue.management.new": "Créer une file SR",
  "queue.management.add": "Ajouter la file SR",
  "queue.management.delete.confirm-msg": "Voulez-vous vraiment supprimer la file SR sélectionnée ?",
  "queue.management.bulk-delete.confirm-msg": "Voulez-vous vraiment supprimer les files SR sélectionnées ?",
  "queue.management.enable.confirm-msg": "Voulez-vous vraiment activer cette file SR?",
  "queue.management.enable.confirm": "Activer la file SR",
  "queue.management.disable.confirm-msg": "Voulez-vous vraiment désactiver cette file SR?",
  "queue.management.disable.confirm": "Désactiver la file SR",
  "queue.management.section.info": "Informations générales",
  "queue.management.section.processes": "Processus",
  "queue.management.section.robots": "Robots",
  "queue.management.form.save.success": "La file SR a été créée avec succès",
  "queue.management.form.update.success": "La file SR a été mise à jour avec succès",
  "queue.management.form.save.error": "Échec de la création de la file SR",
  "queue.management.form.update.error": "Échec de la mise à jour de la file SR",
  "queue.management.form.validation.required": "Ce champ est requis",
  "queue.management.form.validation.negative-nb-licences": "Le nombre de licences ne peut pas être négatif",
  "queue.management.update": "Mettre à jour la file SR",
  "queue.management.save.confirm-msg": "Voulez-vous vraiment créer cette file SR ?",
  "queue.management.update.confirm-msg": "Voulez-vous vraiment mettre à jour cette file SR ?",
  "queue.management.reset.confirm-msg": "Voulez-vous vraiment supprimer toutes les exécutions manquées de cette file SR ?",
  "restriction.form.missedExecutionsPolicy": "Politique relative aux exécutions manquées",
  "restriction.userGuide.missedExecutionsPolicy": "Spécifie si une exécution manquée doit être conservée ou supprimée de la file SR.",
  "queue.management.assign.process.part1": "Vous êtes sur le point de déléguer l'exécution de {{this_process}} aux files SR. Pour garantir les performances attendues, veuillez vous abstenir de planifier et de lancer des exécutions en dehors de SmartRoby.",
  "queue.management.assign.process.part2": "Une planification a été trouvée pour {{this_process}} {{at_its}} orchestrateur d'origine, souhaitez-vous la migrer pour l'utiliser avec les files SR ?",
  "queue.management.assign.process.part2_plural": "{{nbSchedules}} planifications ont été trouvées pour {{this_process}} {{at_its}} orchestrateur d'origine, souhaitez-vous les migrer pour les utiliser avec les files SR ?",
  "queue.management.unassign.process.part1": "Vous êtes sur le point d'annuler la délégation de l'exécution de {{this_process}} depuis les files SR. À l'avenir, toutes les exécutions et planifications peuvent provenir de SmartRoby ou de votre orchestrateur d'origine.",
  "queue.management.unassign.process.part2": "Une planification de files SR a été trouvée, souhaitez-vous la migrer vers l'orchestrateur d'origine ?",
  "queue.management.unassign.process.part2_plural": "{{nbSchedules}} planifications de files SR ont été trouvées, souhaitez-vous les migrer vers l'orchestrateur d'origine ?",
  thisProcess: "ce processus",
  thisProcess_plural: "ces processus",
  atIts: "sur son",
  atIts_plural: "sur leur",
  some: "Des",
  "robot-selector.available-robots": "Robots disponibles",
  "robot-selector.assigned-robots": "Robots assignés",
  "queue.management.form.validation.nb-licences-bigger-than-remaining": "Il reste {{count}} licences dans l'orchestrateur sélectionné",
  "queue.management.no-data": "Aucune file SR trouvée",
  "queue.management.open-queue": "Ouvrir dans la file SR",
  "queue.management.reset-queue": "Réinitialiser la file SR",
  "execution.manager.title": "File SR",
  "queue reseted successfully": "La file SR a été réinitialisée avec succès.",
  "error while reseting queue": "Échec de la réinitialisation de la file SR.",
  "Add SR Queue": "Ajouter une file SR",
  "Edit SR Queue": "Modifier une file SR",
  "Delete SR Queue": "Supprimer une file SR",
  "process.assigned.srqueue.tooltip": "Ce processus est affecté à une file SR.",
  "number of runing executions singular": "Une exécution est déjà en cours",
  "number of runing executions plural": "Certaines exécutions sont déjà en cours",
  "not enough licenses":
    ", vous n'avez pas de licences disponibles. Veuillez contacter votre administrateur RPA.",
  "check.connectivity":
    "Veuillez vérifier la connectivité au serveur d'applications {{serverName}}, il semble que la connexion ait été perdue.",
  "deleted by sr policy": "Une planification est supprimée par la politique des files SR",
  "execution is deleted": "Une exécution de la file SR a été supprimée manuellement",
  "execution is missed": "Une exécution de la file SR est manquante",
  "missed raison": "Manquée à cause de",
  UNAVAILABLE_ROBOTS: "Robots indisponibles",
  NOT_ENOUGH_LICENCES: "Pas assez de licences",
  BP_UNREACHABLE: "BP inaccessible",
  BP_UNRESPONSIVE: "BP ne répond pas",
  "cant perform multi queue migration": "Certaines planifications de files SR ont été trouvées pour les files sélectionnées. Elles ne peuvent pas être migrées vers l'orchestrateur d'origine.",
  "something went wrong": "Une erreur s'est produite lors du traitement de votre requête , veuillez réessayer.",
  Continue: "Continuer",
  "robot.formControl.succesUpdate": "Les informations du robot ont été mises à jour avec succès",
  "Something.Wrong": "Quelque chose ne va pas",
  "export.successful.notification": "Votre fichier a été ajouté à la section des fichiers d'exportation et est maintenant prêt à être consulté et téléchargé. L'ajout a réussi.",
  revoke: "révoquer",
  "division.formSection.divisionInfo": "Informations sur la division",
  "division.formSection.divisionProcess": "Processus de la division",
  "division.formSection.divisionUser": "Utilisateurs de la division",
  "division.formSection.divisionAdmin": "Administrateurs de division",
  "division.name": "Nom de la division",
  "division.description": "Description",
  "division.fleet": "Nom du groupe",
  "division.cancel": "Annuler",
  "division.previous": "Précédent",
  "division.save": "Enregistrer",
  "division.next": "Suivant",
  "divisions.module.add.button": "Nouvelle division",
  "usersSelector.availableUsers": "Utilisateurs disponibles",
  "usersSelector.assignedUsers": "Utilisateurs assignés",
  "usersSelector.availableAdmins": "Administrateurs disponibles",
  "usersSelector.assignedAdmins": "Administrateurs assignés",
  "divisions.module.title": "Administration des divisions",
  "divisions.status": "Statut",
  "divisions.division": "Division",
  "divisions.fleet": "Groupe",
  "divisions.creation.date": "Date de création",
  "division.form.updateSuccess": "Division mise à jour avec succès",
  "division.form.addSuccess": "Division ajoutée avec succès",
  "divisions.module.delete.confirmMsg": "Êtes-vous sûr de vouloir supprimer cette division ?",
  "divisions.module.deleteClientsActionMessage": "Êtes-vous sûr de vouloir supprimer toutes ces divisions ?",
  "divisions.module.enaDes.confirmMsg": "Êtes-vous sûr de vouloir activer cette division ?",
  "divisions.module.enaDes.button.confirm": "Activer",
  "divisions.module.desaDes.button.confirm": "Désactiver",
  "divisions.module.desaDes.confirmMsg": "Êtes-vous sûr de vouloir désactiver cette division ?",
  "divisions.module.searchClient": "Rechercher une division",
  "division deleted successfully": "Division supprimée avec succès",
  "no.division.message": "Aucune division trouvée.",
  "fleet.formSection.divisions": "Divisions",
  "divisionsSelector.availableDivision": "Divisions disponibles",
  "divisionsSelector.assignedDivision": "Divisions assignées",
  "custom.kpi.item": "Items",
  "custom.kpi.process": "Processus",
  "custom.kpi.processExecution": "Exécutions",
  "custom.kpi.pending": "En attente",
  "custom.kpi.completed": "Terminés avec succès",
  "custom.kpi.completedWithException": "Terminés avec une exception",
  "custom.kpi.completedWithBusinessException": "Terminés avec une exception business",
  "custom.kpi.completedWithIntSysException": "Terminés avec une exception système/interne",
  "custom.kpi.executionTimeHours": "Temps d'exécution (heures)",
  "custom.kpi.timeSavedHours": "Temps économisé (heures)",
  "reporting.createKpi": "Créer un nouveau KPI",
  "custom.kpi.dialog.create.label": "Nouveau KPI",
  "custom.kpi.dialog.field.name": "Nom du KPI",
  "custom.kpi.dialog.field.element": "Pour chaque",
  "custom.kpi.dialog.field.detail": "Détail",
  "custom.kpi.dialog.field.allocation": "Allouer",
  "custom.kpi.dialog.field.unitofmesure": "Unité de mesure",
  "custom.kpi.formControl.required": "Champ requis",
  "custom.kpi.formControl.pattern": "Veuillez saisir un nombre",
  "custom.kpi.dialog.create.field.submit": "CRÉER LE KPI",
  "custom.kpi.dialog.delete.label": "Supprimer le KPI",
  "custom.kpi.dialog.delete.text": "Êtes-vous sûr de vouloir supprimer ce KPI ?",
  "custom.kpi.dialog.delete.submit": "Supprimer le KPI",
  "custom.kpi.dialog.create.label.edit": "Modifier le KPI",
  "custom.kpi.dialog.create.field.edit": "MODIFIER LE KPI",
  "fleets.fleet": "Groupe",
  "no.fleet.message": "Aucun groupe pour le moment",
  "instance.owner": "Propriétaire de l'instance",
  "instance.owner.confirmMsg": "Êtes-vous sûr de vouloir procéder à l'attribution d'un nouveau propriétaire de l'instance ?/nCette action entraînera la perte du statut de propriétaire de l'instance pour votre groupe.",
  "instance.owner.can.not.be.disabled": "Le propriétaire de l'instance ne peut pas être désactivé",
  "orchestrator.management.formControl.isPrivate": "Orchestrator privé",
  private: "Privée",
  "fleet.with.no.users": "Ce groupe ne possède aucun utilisateur, impossible de le rendre propriétaire de l'instance",
  "fleet.with.no.admins": "Ce groupe ne possède aucun administrateur, impossible de le désigner comme propriétaire de l'instance",
  "user.fleet.disabled.message": "Le groupe de cet utilisateur est désactivé, impossible d'activer cet utilisateur.",
  "instance.owner.only.active.admin.can.not.be.disabled": "Le seul administrateur actif du propriétaire de l'instance ne peut pas être désactivé",
  "search.available.processes": "rechercher les processus disponibles",
  "search.assigned.processes": "rechercher les processus attribués",
  "search.available.tags": "rechercher les tags disponibles",
  "search.available.users": "rechercher les utilisateurs disponibles",
  "search.assigned.users": "rechercher les utilisateurs attribués",
  "search.available.divisions": "rechercher les divisions disponibles",
  "search.assigned.divisions": "rechercher les divisions attribuées",
  "search.available.robots": "rechercher les robots disponibles",
  "search.assigned.robots": "rechercher les robots attribués",
  "drag.and.drop.here": "Glisser-déposer ici",
  Tags: "Tags",
  "tag.management.title": "Administration des tags",
  "tag.management.add.button": "Créer un tag",
  "tag.management.name": "Nom",
  "tag.management.formControl.desc": "description",
  "tag.status": "Statut",
  "tag.name": "Nom du Tag",
  "tag.description": "Description du Tag",
  "tag.icon": "Icone",
  "tag.management.hide.confirmMsg": "Êtes-vous sûr de vouloir supprimer le tag ? Toutes les données seront définitivement perdues.",
  "no.tags.message": "Aucun tag pour le moment",
  "tag.management.add": "ajouter",
  "tag.management.update": "modifier",
  "tag.add.previous": "Précédent",
  "tag.management.save.confirm-msg": "Voulez-vous vraiment créer ce tag ?",
  "tag.management.update.confirm-msg": "Voulez-vous vraiment mettre à jour ce tag ?",
  "tag.form.addSuccess": "tag ajouté avec succès",
  "tag.deleted.success": "Tag(s) supprimé avec succès.",
  "tag.form.next": "suivant",
  "tag.management.form.save.success": "Le tag a été créée avec succès",
  "tag.management.form.update.success": "Le tag a été mise à jour avec succès",
  "tag.management.form.save.error": "Échec de la création du tag",
  "tag.management.form.update.error": "Échec de la mise à jour du tag",
  "tag.form.name": "Nom du tag",
  "tag.form.description": "Description du tag",
  "tooltip.action.archive": "Archivé",
  "archive.confirm.msg": "Le(s) tag(s) sélectionné(s) sera/seront archivé(s)",
  "tag.management.btn.confirm": "Archiver",
  "tag.archived.success": "Tag(s) archivé avec succès",
  "tag.management.deleteTagActionMessage": "Tag(s) archivés avec succès",
  "tag.management.archived": "Archivé",
  "wrong.file.type": "Type de fichier invalide",
  "invalid license file": "Fichier de licence invalide",
  "invalid signature": "Signature invalide",
  "empty file": "Fichier vide",
  "wrong file extension": "Extension de fichier invalide",
  "missing file": "Fichier manquant",
  "the submitted license file is invalid": "Le fichier de licence soumis est invalide",
  "the signature of the license is invalid": "La signature de la licence est invalide",
  "the submitted file is empty": "Le fichier soumis est vide",
  "we only accept .lic files": "Nous n'acceptons que les fichiers .lic",
  "please upload a license file": "Veuillez télécharger un fichier de licence",
  activation: "Activation",
  license: "Licence",
  "import your license here": "Importer votre licence ici...",
  activate: "Activer",
  "licence.id": "Licence ID",
  "licence.licensee": "Licencié",
  "licence.start.at": "Débute le",
  "licence.end.at": "Se termine le",
  "licence.validation.valid": "Votre licence est valide.",
  "licence.validation.expire": "Votre licence a expiré. Veuillez la renouveler.",
  "licence.goback.btn": "Retour à la page d'accueil",
  "productivity.hrs": "Productivité",
  Occupancy: "Occupation (%)",
  "dashboard.monitoring.mode": "Mode de surveillance",
  "HRS Saved": "HRS Enregistrées",
  "Robot Productivity": "Productivité des robots",
  "Process Activity": "Activité du processus",
  "Working Time (HRS)": "Temps de travail (HRS)",
  "Processed Items": "Éléments traités",
  "HRS Runtime": "Temps d'exécution (HRS)",
  mins: "mins",
  "recently.used.processes": "Processus les plus utilisés",
  "process.total.cost": "Coût total du processus",
  "scheduler.is.required": "Le programme est obligatoire",
  "filter by": "Filtre par",
  showing: "Affichage de",
  to: "à",
  from: "sur",
  entries: "entrées",
  "Data mapping": "Mapping de données",
  Temps: "Temps",
  "server Type": "Type de serveur",
  "tooltip.action.close": "Fermer",
  "try.active.alert": "Le processus {{processName}} est masqué, pour activer cette notification veuillez l'afficher.",
  "process.start.restriction": "Le processus que vous lancez n'est pas censé fonctionner aujourd'hui en fonction de ses SLA, êtes-vous sûr de vouloir continuer ?",
  "process.start.require.input": "Ce processus nécessite un démarrage avec une entrée",
  "process.inputs.button.reset": "Réinitialiser",
  "process.inputs.button.save": "Ajouter",
  "process.inputs.save.blank.values.title": "Certains paramètres avec des valeurs d'entrée vides ont été détectés.",
  "process.inputs.save.blank.values.message": "Êtes-vous sûr de vouloir démarrer ces sessions avec les valeurs fournies ?",
  Now: "Maintenant",
  "Automation Impact": "Impact de l'automatisation",
  ERRORS_NO_DATA_AVAILABLE: "Pas de données disponibles",
  "queue.management.robot.has.pendingExecutions": "Vous avez {{count}} exécutions en attente en utilisant ce robot, veuillez les supprimer si vous souhaitez poursuivre cette opération.",
  "queue.management.robot.has.scheduled.pendingExecutions": "{{count}} exécutions sont programmées, veuillez vérifier les plannings attribués à ce robot.",
  "occupancy.waiting.more": "En attente de plus d'exécutions pour calculer l'occupation du robot",
  "select.process": "Sélectionnez un processus",
  "changes.saved.successfully": "Modifications enregistrées avec succès",
  "changes.saving.failed": "Échec de l'enregistrement des modifications",
  "process.owner": "Propriétaire du processus",
  "end date cannot be earlier than the start date": "La date de fin ne peut pas être antérieure à la date de début",
  "end date cannot be in the future": "La date de fin ne peut pas être dans le futur",
  "no.period.message": "Pas de période trouvée",
  "no.status.message": "Aucun statut trouvé",
  "pending.executions.warning": "Attention : Ce processus a des exécutions en attente. Les supprimer maintenant les effacera toutes.",
  "calendar.formControl.saveRiskSuccess": "Marge de risque ajoutée avec succès",
  "export.confirm.msg.delete.all": "Êtes vous sûr de vouloir supprimer tous les fichiers ?",
  "export.confirm.msg.delete.one": "Êtes vous sûr de vouloir supprimer ce fichier ?",
  on: "En",
  language: "Langue",
  "Choose.the.checking.frequency": "Choisissez la fréquence de vérification",
  "robots.show.hidden": "Afficher les robots cachés",
  "exceptions.show.hidden": "Afficher les exceptions cachées",
  "user.logo.allowed.extensions": "Nous n'acceptons que les extensions jpg, jpeg, png et bmp",
  "allowed.extensions": "Nous n'acceptons que les extensions {{extensions}}",
  "max.size.allowed": "La taille de fichier maximale autorisée est de {{size}} Mo",
  "items.pending.locked": "Cet item est en cours de traitement",
  "export.limit": "Il est possible d'exporter uniquement les 100 000 premiers enregistrements, mais pour des résultats plus précis, vous avez la possibilité de préciser vos critères de recherche.",
  "queue.create.update.restriction": "Chaque file d'attente doit avoir au moins 1 licence, 1 processus assigné et 1 robot assigné.",
  "custom.kpi.dialog.field.type": "Type de KPI",
  FINANCIAL_KPI: "KPI Financier",
  OPERATIONAL_KPI: "KPI Opérationnel",
  "reporting.costSaved": "Coûts économisés",
  "reporting.robotProductivity": "Productivité des robots",
  "reporting.avgHandlingTime": "Temps moyen de traitement",
  "max.datamap.reached": "Vous pouvez choisir seulement 3 champs à afficher dans la liste des éléments",
  "retry.mark.done": "Réessayer et marquer comme terminé",
  "fleet.management.formLabel.currency": "Devise",
  "tooltip.visit.link": "Visiter le lien",
  "tooltip.edit.link": " Modifier le lien ",
  "tooltip.delete.link": "Supprimer le lien",
  "no.link.message": "Aucun lien trouvé",
  "confirm.delete.link": "Êtes-vous sûr de vouloir supprimer ce lien ?",
  "add.link": "Insérer un nouveau lien",
  "create.link": "Créer lien",
  "confirm.add.link": "Êtes-vous sûr de vouloir créer ce lien ?",
  "insert.link": "Insérer",
  "link.validate.url": "Veuillez saisir une URL valide",
  "link.hypertextUrl": "Lien hypertexte du document externe",
  "link.name": "Nom",
  "link.url": "Lien",
  "link.description": "Description",
  "link.delete.success": "Lien supprimé avec succès",
  "link.add.success": "Lien créé avec succès",
  "link.update.success": "Lien modifié avec succès",
  "link.add.failure": "La création du lien a échoué ! Veuillez réessayer plus tard",
  "link.update.failure": "Échec de la mise à jour du lien ! Veuillez réessayer plus tard",
  "link.tab": "documentation externe",
  "View external documentation": "Afficher la documentation externe",
  "Manage external documentation": "Gérer la documentation externe",
  "Description (ascending)": "Description (croissant)",
  "Description (descending)": "Description (décroissant)",
  "Name (ascending)": "Nom (croissant)",
  "Name (descending)": "Nom (décroissant)",
  "disconnection.period": "Pendant plus de (minutes)",
  "help.exception.type": "Veuillez choisir un type d'exception ou un KPI",
  "audit.object": "Objet",
  "audit.user": "Utilisateur",
  "audit.action": "Action",
  "audit.time.filter": "Filtre temporel",
  "audit.sort.by": "Trier par",
  Audit: "Audit",
  "audit.module": "Module",
  "audit.times.stamp": "Horodatage",
  "audit.search.logs": "Rechercher des journaux",
  "no.object.message": "Aucun objet trouvé",
  "no.user.message": "Aucun utilisateur trouvé",
  "no.action.message": "Aucune action trouvée",
  "no.time.filter.message": "Aucun filtre temporel trouvé",
  "no.sort.message": "Aucun tri trouvé",
  "audit.module.descending": "Module (Décroissant)",
  "audit.module.ascending": "Module (Croissant)",
  "audit.user.descending": "Utilisateur (Décroissant)",
  "audit.user.ascending": "Utilisateur (Croissant)"
};
