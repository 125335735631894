import { makeStyles } from "@material-ui/core/styles";
import { fontFamily } from "../../style/orchestratorTheme";

export default makeStyles((theme) => ({
  rootGlobal: {
    width: "100%",
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(2),
  },
  filter: {
    paddingTop: theme.spacing(1.5),
    gap: "1.5rem",
  },
  toggleMenu: {
    paddingTop: 15,
  },
  userName: {
    fontFamily,
  },
}));
