import React, { useEffect } from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { MinimumRoiRate } from "../../../util/constants";
import { formtNumberByLanguage, numberFloatFormat, numberRoiFloatFormat } from "util";
import { handleRequestMonitoringMode } from "../dashboardUtils";
import { fetchDashboardHeader } from "../../../redux/actions/services/dashboard";
import ProfitabilityRatio from "../../../assets/DashboardStatesIcons/profitabilityratio.svg"
import BreakEven from "../../../assets/DashboardStatesIcons/breakevent.svg"
import Exceptions from "../../../assets/DashboardStatesIcons/exceptioncount.svg"
import StateItem from "./StateItem";
import useStyles from "../style";

export default function DashboardStates({ xs }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const filterDashboard = useSelector(({ filterDashboard }) => filterDashboard);
  const refreshTime = filterDashboard?.monitoringMode;
  const fetchIntervalRef = React.useRef(null);
  const [kpiData, setKpiData] = React.useState();
  const [loading, setLoading] = React.useState(false);

  const breakEvenContent = (
    { breakEvenUnit, breakEvenValue },
    roiPercentage
  ) => {
    if (!breakEvenUnit || roiPercentage < MinimumRoiRate) {
      return "--";
    }

    return breakEvenValue > 0
      ? `${t("ago", {
          value: numberRoiFloatFormat(breakEvenValue),
          unit: `${t(breakEvenUnit)}`,
        })}`
      : `${t("point.amortissage.negative", {
          value: numberRoiFloatFormat(breakEvenValue),
          unit: `${t(breakEvenUnit)}`,
        })}`;
  };

  const getRate = (data) => (data ? `${numberFloatFormat(data)}%` : "---");

  const roiContent = (roiPercentage) => {
    if (isNaN(roiPercentage) || roiPercentage < MinimumRoiRate) return "--";
    return getRate(roiPercentage);
  };

  const states = [
    {
      label: "Roi",
      tooltipTitle: t("kpi.header.roi.tooltip"),
      state: (kpiData && roiContent(kpiData?.roiPercentage)) || "--",
      icon: ProfitabilityRatio,
      xs: 4,
    },
    {
      label: "Break-even",
      tooltipTitle: t("kpi.header.breakEven.tooltip"),
      state:
        (kpiData
          && kpiData?.breakEven
          && breakEvenContent(kpiData?.breakEven, kpiData?.roiPercentage))
        || "--",
      icon: BreakEven,
      xs: 4,
    },
    {
      label: "header.kpi.exceptions",
      tooltipTitle: t("header.kpi.exceptions"),
      state: formtNumberByLanguage(kpiData?.todayExceptions?.todayItemExceptions) || "--",
      icon: Exceptions,
      xs: 4,
      pushTo: "/items/itemsExceptions"
    },
  ];

  const fetchDashboardStates = () => {
    setLoading(true);
    dispatch(fetchDashboardHeader(filterDashboard)).then((res) => {
      setKpiData(res.data);
      setLoading(false);
    });
  }

  useEffect(() => {
    fetchDashboardStates()
  }, [filterDashboard]);

  useEffect(() => {
    handleRequestMonitoringMode(refreshTime, fetchIntervalRef, fetchDashboardStates)
  }, [refreshTime])

  return (
    <Box
      component={Grid}
      item
      container
      xs={xs}
      className={`${classes.container} ${classes.statesContainer}`}
      minHeight={90}
    >
      {states.map((state, index) => (
        <StateItem state={state} isLoading={loading} key={index} isLast={states.length === index + 1} />
      ))}
    </Box>
  );
}
