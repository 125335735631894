import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { connect, useDispatch, useSelector } from "react-redux";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import {
  debounce,
} from "@material-ui/core";
import {
  handleDisplayedLabel,
  formatDatePickerByLanguage,
} from "../../../../util";
import {
 subWeeks, subMonths, subYears, isAfter
} from "date-fns";
import { format } from "date-fns-tz";
import { SearchFilter } from "../../../../components/Filter";
import { Duration } from "util/constants";
import ClearFilter from "components/ClearFilter";
import { initFilter, updateFilter } from "redux/slices/executionsSlice";
import NoDataMenu from "../../../../components/NoData/NoDataMenu";
import CustomSelectField from "../../../../components/FormFields/CustomSelectField";
import DateFilter from "../../../Services/components/DateFilter";
import CustomAutoComplete from "../../../../components/FormFields/CustomAutoComplete";
import ShowMoreFilters from "../../../../components/ShowMoreFilters";
import ExportButton from "../../../../components/ExportButton";
import Box from "@material-ui/core/Box";
import useStyles from "../style";
import { useQuery } from "@redux-requests/react";
import { FETCH_ALL_EXECUTIONS } from "../../../../redux/constants";

const dateFormat = "yyyy/MM/dd";

const triggerItems = [
  { id: 1, label: "MANUALLY", value: "MANUALLY" },
  { id: 2, label: "SCHEDULED", value: "SCHEDULED" },
]

function Filter(props) {
  const {
processes, status, robots, columns, setShowCustomDate, showCustomDate, tags
} = props;
  const classes = useStyles();
  const [statusVar, setStatusVar] = React.useState([]);
  const [robot, setRobot] = React.useState([]);
  const [process, setProcess] = React.useState([]);
  const [trigger, setTrigger] = React.useState([]);
  const [searchText, setSearchText] = React.useState(null);
  const [defaultFilter, setDefaultFilter] = React.useState(true);
  const [selectedDurationValue, setSelectedDurationValue] = React.useState("ALL_TIME");
  const [sort, setSort] = React.useState(columns[0]);
  const [tag, setTag] = useState([]);
  const [fromSelectedDate, setFromDate] = React.useState(
    format(new Date(), dateFormat)
  );
  const [toSelectedDate, setToDate] = React.useState(
    format(new Date(), dateFormat)
  );

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const executionsFilter = useSelector(({ executionsFilter }) => executionsFilter);
  const executionsData = useQuery({ type: FETCH_ALL_EXECUTIONS }).data;
  const [invalidStartDate, setInvalidStartDate] = useState(false)
  const [invalidEndDate, setInvalidEndDate] = useState(false)
  const [futureEndDate, setFutureEndDate] = useState(false)
  const [futureStartDate, setFutureStartDate] = useState(false)
  const handleChangeSort = (value = columns[1]) => {
    const sortValue = value || columns[1];
    setSort(sortValue);
    props.handleRequestSort(sortValue);
    dispatch(updateFilter({ ...executionsFilter, order: sortValue }));
  }

  const handleChangeStatus = (event) => {
    setStatusVar(event);
    props.handleChangeStatus(event);
    dispatch(updateFilter({ ...executionsFilter, status: event }));
  };

  const handleChangeRobot = (event) => {
    setRobot(event);
    props.handleChangeRobot(event);
    dispatch(updateFilter({ ...executionsFilter, robot: event }));
  };

  const handleChangeTrigger = (newTriggers) => {
    const values = newTriggers?.map(({ value }) => value)
    setTrigger(values);
    props.handleChangeTrigger(values);
    dispatch(updateFilter({ ...executionsFilter, trigger: values }));
  };

  const handleChangeProcess = (event) => {
    setProcess(event);
    props.handleChangeProcess(event);
    dispatch(updateFilter({ ...executionsFilter, process: event }));
  };

  const onSelectDurationValue = (value) => {
    setSelectedDurationValue(value);
    if (value !== "CUSTOM") {
      const now = new Date();
      let toDate = format(now, dateFormat);
      let fromDate;
      switch (value) {
        case Duration.DAY:
          fromDate = toDate;
          break;
        case Duration.WEEK:
          fromDate = format(subWeeks(now, 1), dateFormat);
          break;
        case Duration.MONTH:
          fromDate = format(subMonths(now, 1), dateFormat);
          break;
        case Duration.YEAR:
          fromDate = format(subYears(now, 1), dateFormat);
          break;
        case Duration.ALL_TIME:
          fromDate = null;
          toDate = null;
          break;
        default:
          break;
      }
      setToDate(toDate);
      setFromDate(fromDate);
      setShowCustomDate(false);
      props.handleChangeDates(fromDate, toDate);
      dispatch(updateFilter({
        ...executionsFilter,
        executionToDate: toDate,
        executionFromDate: fromDate,
        executionSelectedDurationValue: value
      }));
    } else {
      const now = new Date();
      const newFromDate = executionsFilter.executionFromDate || format(now, dateFormat);
      const newToDate = executionsFilter.executionToDate || format(now, dateFormat);
      setToDate(newToDate);
      setShowCustomDate(true);
      setFromDate(newFromDate);
      dispatch(updateFilter({
        ...executionsFilter,
        executionSelectedDurationValue: value
      }));
    }
  };
  const onFromDateChange = (date) => {
    const currentDate = new Date();
    if (isAfter(date, currentDate)) {
      setFutureStartDate(true)
    } else if (format(date, dateFormat) > toSelectedDate) {
      setInvalidStartDate(true)
    } else {
    setFromDate(date);
    dispatch(updateFilter({ ...executionsFilter, executionFromDate: format(date, dateFormat) }));
    props.handleChangeDates(date, toSelectedDate);
    }
  };
  const onToDateChange = (date) => {
    const currentDate = new Date();
    if (isAfter(date, currentDate)) {
      setFutureEndDate(true)
    } else if (format(date, dateFormat) < fromSelectedDate) {
      setInvalidEndDate(true)
    } else {
      setFutureEndDate(false)
      setInvalidEndDate(false)
      setInvalidStartDate(false)
      setToDate(date);
      dispatch(updateFilter({ ...executionsFilter, executionToDate: format(date, dateFormat) }));
      props.handleChangeDates(fromSelectedDate, date);
    }
  };

  const handleFilterValueChange = (e) => {
    const { value } = e.target;
    setSearchText(value);
    debouncer(value);
  };

  React.useEffect(() => {
    setSearchText(executionsFilter.searchText);
    setProcess(executionsFilter.process);
    setRobot(executionsFilter.robot);
    setStatusVar(executionsFilter.status);
    setTrigger(executionsFilter.trigger);
    setSort(executionsFilter.order);
    setTag(executionsFilter.tag)
    setSelectedDurationValue(executionsFilter.executionSelectedDurationValue);
    if (executionsFilter.executionFromDate) {
      setFromDate(executionsFilter.executionFromDate);
    }
    if (executionsFilter.executionToDate) {
      setToDate(executionsFilter.executionToDate);
    }
    onSelectDurationValue(executionsFilter.executionSelectedDurationValue);
  }, []);

  const debouncer = React.useCallback(
    debounce((nextValue) => {
      dispatch(updateFilter({ ...executionsFilter, searchText: nextValue }));
      props.handleChangeSearchText(nextValue);
    }, 500),
    []
  );
  const clearFilter = () => {
    setStatusVar([]);
    setRobot([]);
    setTrigger([]);
    setProcess([]);
    setSelectedDurationValue("ALL_TIME");
    setFromDate(null);
    setTag([]);
    setToDate(null);
    setSearchText("");
    setShowCustomDate(false);
    // to clear the redux executionsFilter
    dispatch(initFilter());
    setSort(columns[0]);
    props.handleClearFilter();
  };

  const handleDisplayedOptionLabel = (label, keepCaseLabel = false, translateLabel = true) => handleDisplayedLabel(translateLabel ? t(label) : label, keepCaseLabel)

  const handleChangeTagsFilter = (value) => {
    setTag(value);
    props.handleChangetags(value);
    dispatch(updateFilter({ ...executionsFilter, tag: value }));
  };

  return (
    <Grid container className={classes.filterRoot}>
      <Grid container item xs={12} className={classes.filter} spacing={1}>
        <Grid container item alignItems="flex-end" xs={2}>
          <FormControl fullWidth className={classes.autoComplete}>
            <SearchFilter
              callback={handleFilterValueChange}
              fullWidth
              placeHolder={t("Search")}
              value={searchText}
            />
          </FormControl>
        </Grid>
        <Grid item xs={2}>
          <FormControl fullWidth className={classes.autoComplete}>
            <CustomAutoComplete
              multiple
              options={processes || []}
              optionLabel="processDescription.processDisplayName"
              value={processes?.filter(({ id }) => process?.map(({ id }) => id)?.includes(id))}
              noOptionsNode={<NoDataMenu message={t("no.process.message")} />}
              onChange={handleChangeProcess}
              label={t("Process")}
              handleDisplayedOptionLabel={handleDisplayedOptionLabel}
            />
          </FormControl>
        </Grid>
        <Grid item xs={2}>
          <FormControl fullWidth className={classes.autoComplete}>
            <CustomAutoComplete
                multiple
                options={robots || []}
                optionLabel="robotDisplayName"
                value={robots?.filter(({ id }) => robot?.map(({ id }) => id)?.includes(id))}
                onChange={handleChangeRobot}
                label={t("Robot")}
                handleDisplayedOptionLabel={handleDisplayedOptionLabel}
                translateLabel={false}
                keepCaseLabel
            />
          </FormControl>
        </Grid>
        <Grid item xs={2}>
          <FormControl fullWidth className={classes.autoComplete}>
            <CustomAutoComplete
              multiple
              options={status || []}
              optionLabel="statusLabel"
              value={status?.filter(({ id }) => statusVar?.map(({ id }) => id)?.includes(id))}
              onChange={handleChangeStatus}
              label={t("Status")}
            />
          </FormControl>
        </Grid>
        {!defaultFilter && (
          <>
            <Grid item xs={2}>
              <FormControl fullWidth className={classes.autoComplete}>
                <CustomAutoComplete
                  multiple
                  options={tags || []}
                  optionLabel="name"
                  value={tags?.filter(({ id }) => tag?.map(({ id }) => id)?.includes(id))}
                  noOptionsNode={<NoDataMenu message={t("no.tags.message")} />}
                  onChange={handleChangeTagsFilter}
                  label={t("Tags")}
                />
              </FormControl>
            </Grid>
            <Grid item xs={2}>
              <FormControl fullWidth className={classes.autoComplete}>
                <CustomAutoComplete
                  multiple
                  options={triggerItems}
                  optionLabel="label"
                  value={triggerItems?.filter(({ value }) => trigger?.includes(value))}
                  onChange={handleChangeTrigger}
                  label={t("Trigger")}
                />
              </FormControl>
            </Grid>
            <Grid item xs={2}>
              <CustomSelectField
                key={sort}
                options={columns || []}
                value={columns.find(({ label }) => label === sort?.label)}
                onChange={(e) => handleChangeSort(e.target.value)}
                variant="standard"
                label={t("Sort By")}
                formControlClassName={classes.autoComplete}
                customOptionLabel={(option) => handleDisplayedLabel(t(option?.label))}
                isCustom
              />
            </Grid>
            <DateFilter
              onFromDateChange={onFromDateChange}
              fromDate={fromSelectedDate}
              onToDateChange={onToDateChange}
              toDate={toSelectedDate}
              selectedDuration={selectedDurationValue}
              onSelectDurationValue={onSelectDurationValue}
              showCustomDate={showCustomDate}
              handleSelectPeriodChange={(event) => onSelectDurationValue(event.target.value)}
              dateFormat={formatDatePickerByLanguage()}
              formControlClassName={classes.autoComplete}
              invalidEndDate={invalidEndDate}
              invalidStartDate={invalidStartDate}
              futureEndDate={futureEndDate}
              futureStartDate={futureStartDate}
              xs={2}
              isCustom
            />
          </>
        )}
        <Grid item container xs={2} alignItems="flex-end">
          <Grid item>
            <ShowMoreFilters
              handleShowMore={() => setDefaultFilter((prevState) => !prevState)}
              showAll={!defaultFilter}
            />
          </Grid>
          <Grid item>
            <ClearFilter clearFilter={clearFilter} label={t("filter.clear")} />
          </Grid>
        </Grid>
        {executionsData?.resultsCount > 0 && (
        <Box className={classes.actionButtons}>
          <ExportButton
              loading={props.exportLoading}
              onClick={() => props.exportFilteredExecutions()}
          />
        </Box>
        )}
      </Grid>
    </Grid>
  );
}

export default connect(null, {})(Filter);
Filter.defaultProps = {
  isDefault: false
}
