import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({

  field: {
    fontWeight: 700,
    wordWrap: "break-word",
  },
  value: {
    wordWrap: "break-word",
    "& a": {
      textDecoration: "none",
      color: "inherit"
    }
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  dialogContent: {
    marginTop: theme.spacing(3),
  },
  dataGrid: {
    marginTop: theme.spacing(1),
    alignItems: "center",
  },
  icon: {
    fontSize: 20,
    color: theme.custom.color.color6
  },
  linkIcon: {
    fontSize: 16,
    opacity: 0.8,
    marginBottom: -3,
    marginLeft: 3
  }
}));
