import i18n from "../../../../../../i18n";
import moment from "moment/moment";

// eslint-disable-next-line import/prefer-default-export
export const makeDay = (startDate, t, dayNameView = false) => {
    const { language } = i18n;
    const startDay = new Date(startDate).getDate()
    if (dayNameView && startDate) {
        return ` ${t("on")} ${moment(startDate).locale(language).format("dddd")}`
    }
    if (startDate) {
        if (startDay === 1 || (startDay === 21 && language === "en")) {
            return t("processe.schedules.onDay.first", { startDay });
        }
        if (startDay === 2 || (startDay === 22 && language === "en")) {
            return t("processe.schedules.onDay.second", { startDay });
        }
        if (startDay === 3 || (startDay === 23 && language === "en")) {
            return t("processe.schedules.onDay.third", { startDay });
        }

        return t("processe.schedules.onDay", { startDay });
    }

    return "";
};
