import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(() => ({
  xlsFile: {
    fontSize: "50px",
    color: "#1F8A70",
  },
  refreshBtn: {
    fontSize: "0.7rem",
  },
  nodataIcon: {
    fontSize: "80px",
    color: "gray"
  }
}));
