import React from "react";
import Dialog from "@material-ui/core/Dialog";

const CustomDialog = (props) => {
  const {
 open, onClose, children, maxWidth, paperStyle
} = props;
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={maxWidth}
      {...props}
      PaperProps={{
        square: true,
        ...paperStyle,
      }}
    >
      {children}
    </Dialog>
  );
};

export default CustomDialog;
