import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  pending: {
    color: theme.custom.color.pending,
    backgroundColor: theme.custom.color.pendingBackground,
    borderColor: theme.custom.color.pending,
  },
  running: {
    color: theme.custom.color.running,
    backgroundColor: theme.custom.color.runningBackground,
    borderColor: theme.custom.color.running,
  },
  unknown: {
    color: theme.custom.color.unknown,
    backgroundColor: theme.custom.color.unknownBackground,
    borderColor: theme.custom.color.unknown,
  },
  done: {
    color: theme.custom.color.done,
    backgroundColor: theme.custom.color.doneBackground,
    borderColor: theme.custom.color.done,
  },
  stopped: {
    color: theme.custom.color.stopped,
    backgroundColor: theme.custom.color.stoppedBackground,
    borderColor: theme.custom.color.stopped,
  },
  archived: {
    color: theme.custom.color.archived,
    backgroundColor: theme.custom.color.archivedBackground,
    borderColor: theme.custom.color.archived,
  },
  stopping: {
    color: theme.custom.color.stopping,
    backgroundColor: theme.custom.color.stoppingBackground,
    borderColor: theme.custom.color.stopping,
  },
  warning: {
    color: theme.custom.color.warning,
    backgroundColor: theme.custom.color.warningBackground,
    borderColor: theme.custom.color.warning,
  },
  warningIcon: {
    color: theme.custom.color.warning,
    width: "1.5rem",
    height: "1.5rem",
    paddingLeft: "0.2rem",
  },
  status: {
    borderRadius: "1rem",
    padding: "10px 8px",
    fontSize: 10,
    fontWeight: 600,
    textTransform: "uppercase",
    maxWidth: "fit-content",
  },
  statusDisabled: {
    opacity: 0.7
  },
  customWarningStatus: {
    padding: "10px 2px",
  },
  warningLabel: {
    display: "flex",
    alignItems: "center"
  }
}));
