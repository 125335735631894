import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    priorityChip: {
        backgroundColor: theme.custom.color.staticInput,
        width: 30,
        height: 30,
        marginInline: "8px",
        border: 0,
        borderRadius: 50
    },
    priorityLabel: {
        color: theme.palette.primary.main,
        fontWeight: 700,
        fontSize: 10,
        paddingInline: 0
    }
}))
