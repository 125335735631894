import {
    ASSIGN_EXCEPTION,
    BULK_ASSIGN_EXCEPTION,
    BULK_MARK_EXCEPTION_AS_DONE,
    BULK_RELEASE_EXCEPTION,
    BULK_REOPEN_EXCEPTION,
    BULK_TAKE_EXCEPTION,
    EXCEPTION_ASSIGN_NOTIFY,
    FETCH_EXCEPTION_WORKFLOW_HISTORY,
    FETCH_WORK_QUEUE_ITEM_PROCESS_ITEM_EXCEPTION,
    MARK_EXCEPTION_AS_DONE,
    RELEASE_EXCEPTION,
    REOPEN_EXCEPTION,
    TAKE_EXCEPTION
} from "../../constants";
import { URL_EXCEPTION_WORKFLOW, URL_PROCESSES } from "../../constants/endpoints";

export const fetchExceptionWorkflowHistory = (itemId) => ({
    type: FETCH_EXCEPTION_WORKFLOW_HISTORY,
    request: {
        url: `${URL_EXCEPTION_WORKFLOW}/${itemId}/history`,
        method: "get",
    },
});
export const bulkReopenException = (itemsIds) => {
    const params = { itemsIds: itemsIds.join(",") };
    return {
        type: BULK_REOPEN_EXCEPTION,
        request: {
            url: `${URL_EXCEPTION_WORKFLOW}/reopen`,
            method: "post",
            params,
            headers: { "Content-Type": "application/json" },
        },
    };
};
export const bulkMarkExceptionAsDone = (itemsIds) => {
    const params = { itemsIds: itemsIds.join(",") };
    return {
        type: BULK_MARK_EXCEPTION_AS_DONE,
        request: {
            url: `${URL_EXCEPTION_WORKFLOW}/markAsDone`,
            method: "post",
            params,
            headers: { "Content-Type": "application/json" },
        },
    };
};

export const reopenException = (itemId) => ({
    type: REOPEN_EXCEPTION,
    request: {
        url: `${URL_EXCEPTION_WORKFLOW}/${itemId}/reopen`,
        method: "post",
        headers: { "Content-Type": "application/json" },
    },
});
export const bulkReleaseException = (itemsIds) => {
    const params = { itemsIds: itemsIds.join(",") };
    return {
        type: BULK_RELEASE_EXCEPTION,
        request: {
            url: `${URL_EXCEPTION_WORKFLOW}/release`,
            method: "post",
            params,
            headers: { "Content-Type": "application/json" },
        },
    };
};

export const markExceptionAsDone = (itemId) => ({
    type: MARK_EXCEPTION_AS_DONE,
    request: {
        url: `${URL_EXCEPTION_WORKFLOW}/${itemId}/markAsDone`,
        method: "post",
        headers: { "Content-Type": "application/json" },
    },
});
export const assignNotify = (userId, exceptionsNumber) => {
    const params = { exceptionsNumber };
    return {
        type: EXCEPTION_ASSIGN_NOTIFY,
        request: {
            url: `${URL_EXCEPTION_WORKFLOW}/assignNotify/${userId}`,
            method: "post",
            params,
            headers: { "Content-Type": "application/json" },
        },
    };
};

export const releaseException = (itemId) => ({
    type: RELEASE_EXCEPTION,
    request: {
        url: `${URL_EXCEPTION_WORKFLOW}/${itemId}/release`,
        method: "post",
        headers: { "Content-Type": "application/json" },
    },
});
export const bulkAssignException = (itemsIds, userId) => {
    const params = { itemsIds: itemsIds.join(",") };
    return {
        type: BULK_ASSIGN_EXCEPTION,
        request: {
            url: `${URL_EXCEPTION_WORKFLOW}/assign/${userId}`,
            method: "post",
            params,
            headers: { "Content-Type": "application/json" },
        },
    };
};
export const bulkTakeException = (itemsIds) => {
    const params = { itemsIds: itemsIds.join(",") };
    return {
        type: BULK_TAKE_EXCEPTION,
        request: {
            url: `${URL_EXCEPTION_WORKFLOW}/take`,
            method: "post",
            params,
            headers: { "Content-Type": "application/json" },
        },
    };
};

export const assignException = (itemId, userId) => ({
    type: ASSIGN_EXCEPTION,
    request: {
        url: `${URL_EXCEPTION_WORKFLOW}/${itemId}/assign/${userId}`,
        method: "post",
        headers: { "Content-Type": "application/json" },
    },
});
export const takeException = (itemId) => ({
    type: TAKE_EXCEPTION,
    request: {
        url: `${URL_EXCEPTION_WORKFLOW}/${itemId}/take`,
        method: "post",
        headers: { "Content-Type": "application/json" },
    },
});
export const fetchProcessesNameException = (fleetIds = []) => {
    const ids = Array.isArray(fleetIds) ? fleetIds.join() : fleetIds;
    return {
        type: FETCH_WORK_QUEUE_ITEM_PROCESS_ITEM_EXCEPTION,
        request: {
            url: `${URL_PROCESSES}items?fleet=${ids}`,
        },
    };
};
