import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";

export const StyledTable = withStyles(() => ({
    root: {
        borderCollapse: "separate",
        borderSpacing: "0 10px"
    }
}))(Table);

export default function CustomTable(props) {
    return (
      <StyledTable {...props}>
        {props?.children}
      </StyledTable>
    )
}
