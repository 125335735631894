import { useState, useEffect, useRef } from "react";

const useResizeObserver = () => {
  const tabsRef = useRef(null);
  const [indicatorWidth, setIndicatorWidth] = useState("");

  const handleTabsWidthChange = () => {
    setIndicatorWidth(tabsRef.current.offsetWidth);
  };

  useEffect(() => {
    const tabsElement = tabsRef.current;

    if (!tabsElement) {
      return;
    }

    const resizeObserver = new ResizeObserver(handleTabsWidthChange);
    resizeObserver.observe(tabsElement);

    // eslint-disable-next-line consistent-return
    return () => resizeObserver.disconnect();
  }, []);

  return { tabsRef, indicatorWidth };
};

export default useResizeObserver;
