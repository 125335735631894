import React from "react";
import { Chip, Tooltip } from "@material-ui/core";
import { useTranslation, withTranslation } from "react-i18next";
import moment from "moment";
import useStyles from "./style";
import "moment/locale/fr";
import "moment/locale/es";
import "moment/locale/pl";

function AttemptChip({
  attempt, i18n, handleAttempClick, originalItemId,
}) {
  const { t } = useTranslation();
  const classes = useStyles();

  const attemptWithOrdinal = moment.localeData(i18n.language).ordinal(attempt);

  return (
    <Tooltip title={t("attempt.chip.tooltip", { attempt: attemptWithOrdinal })}>
      <Chip
        label={attemptWithOrdinal}
        size="small"
        className={classes.chip}
        clickable={attempt > 1}
        onClick={() => attempt > 1 && handleAttempClick && handleAttempClick(originalItemId)}
      />
    </Tooltip>
  );
}

export default withTranslation()(AttemptChip);
