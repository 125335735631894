import moment from "moment";

export const getDayGridRowStart = (day, start) => {
  let hourStart = 0;
  let minute = 0;
  if (day === moment(start).format("dddd, MMMM D yyyy")) {
    hourStart = parseInt(moment(start).hour());
    minute = parseInt(moment(start).minute());
  }
  return minute >= 30 ? hourStart * 2 + 2 : hourStart * 2 + 1;
};

export const getWeekGridRowStart = (start) => {
  const hourStart = moment(start).hour();
  const minute = moment(start).minute();
  return minute >= 30 ? hourStart * 2 + 2 : hourStart * 2 + 1;
};

export const getSpanNumber = (start, end) => {
  // Calculate the difference between date in minutes
  const diffMinutes = Math.floor(end - start) / 6e4;
  // Divide the difference by 30, because each span occupies 30min
  return Math.ceil(diffMinutes / 30);
};
export const timeSlots = [
  {
    label: "00min",
    value: 0,
  },
  {
    label: "15min",
    value: 15,
  },
  {
    label: "30min",
    value: 30,
  },
  {
    label: "45min",
    value: 45,
  },
  {
    label: "1h00min",
    value: 60,
  },
  {
    label: "1h15min",
    value: 75,
  },
  {
    label: "1h30min",
    value: 90,
  },
  {
    label: "1h45min",
    value: 105,
  },
  {
    label: "2h00min",
    value: 120,
  },
];
