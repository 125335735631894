import React from "react";
import Box from "@material-ui/core/Box"
import PageHeader from "../../../components/PageHeader";
import UserTable from "./UserTable";
import useStyles from "../../../components/TableComponents/style"

export default function UserManagement() {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box className={classes.paper}>
        <PageHeader title="user.management.title" />
        <UserTable />
      </Box>
    </Box>
  );
}
