import { createSlice } from "@reduxjs/toolkit";

const initialFilter = {
  searchCriteria: "",
  status: [],
  types: [],
  fleets: [],
  divisions: [],
  pageNo: 0,
  pageSize: 5,
};

const alertFilter = {
  currModule: "defaultAlerts",
  defaultAlerts: initialFilter,
  customAlerts: initialFilter,
};

const filterListAlert = createSlice({
  name: "filterAlerts",
  initialState: alertFilter,
  reducers: {
    updateFilter: (state, action) => (state = action.payload), // eslint-disable-line
    initFilter: (state) => ({
      ...state,
      [state.currModule]: alertFilter[state.currModule],
    }),
    updateModule: (state, action) => ({
      ...state,
      currModule: action.payload?.module || alertFilter.currModule,
    }),
  },
});

export const { initFilter, updateFilter, updateModule } = filterListAlert.actions;
export default filterListAlert.reducer;
