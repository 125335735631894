import CustomDialog from "../../CustomDialog";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import ErrorOutlineOutlinedIcon from "@material-ui/icons/ErrorOutlineOutlined";
import SaveOutlinedIcon from "@material-ui/icons/SaveOutlined";
import HighlightOffOutlinedIcon from "@material-ui/icons/HighlightOffOutlined";
import CustomCloseButton from "../../CustomCloseButton";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import get from "lodash/get";
import useStyles from "./style";
import {
  updateProcessIcon,
  fetchProcesses,
} from "../../../../../redux/actions/services";
import clsx from "clsx";
import DeleteIcon from "@material-ui/icons/Delete";
import CustomButton from "../../../../../components/CustomButton";

const IconList = ({
  isOpen, setOpen, _process, target, setIcon, setSelectedProcessIcon
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [selectedIcon, setSelectedIcon] = useState(-1);

  const closeDialog = () => {
    setOpen(false);
    setSelectedIcon(null)
  };
  const reset = () => {
    handleSelectIcon(null);
  }
  const handleSelectIcon = (icon) => {
    if (target === "tags") {
      setIcon(!icon ? icon : images[icon]);
      closeDialog()
      return;
    }
    dispatch(updateProcessIcon(get(_process, "id"), { icon })).then(() => {
      dispatch(fetchProcesses());
      setSelectedProcessIcon(icon);
      closeDialog();
    });
  };

  const importAll = (r) => {
    const images = {};
    r.keys().forEach((item) => {
      const fileName = item.replace(/^.*[\\/]/, "");
      if (!images[fileName]) {
        images[fileName] = r(item);
      }
    });
    return images;
  };

  const imageContext = require.context(
    "../../resources/svg",
    false,
    /\.(png|jpe?g|svg)$/
  );
  const images = importAll(imageContext);

  const rows = [];
  let counter = 0;
  // eslint-disable-next-line guard-for-in, no-restricted-syntax
  for (const image in images) {
    const row = (
      <Grid container xs={3} justify="center" key={counter}>
        <IconButton
        onClick={() => setSelectedIcon(image)}
        className={clsx({
          [classes.selected]: selectedIcon === image,
        })}
        >
          <img
            alt="process icon"
            src={images[image]}
            className={classes.image}
          />
        </IconButton>
      </Grid>
    );
    rows.push(row);
    counter += 1;
  }

  return (
    <CustomDialog
      onClose={closeDialog}
      aria-label="customized-dialog-title"
      open={isOpen}
      disableBackdropClick
      className={classes.dialogIconsContainer}
      scroll="paper"
      PaperProps={{
        square: true,
      }}
      maxWidth="xs"
    >
      <DialogTitle className={classes.dialogTitle}>
        <Grid container justify="center">
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
            className={classes.iconsListHeader}
          >
            <Grid
                container
                item
                direction="row"
                alignItems="center"
                xs={8}
                spacing={1}
              >
              <Grid item>
                <ErrorOutlineOutlinedIcon className={classes.closeBtnIcon} />
              </Grid>
              <Grid item>
                <Typography variant="h6">{t("Choose icon")}</Typography>
              </Grid>
            </Grid>
            <Grid item>
              <CustomCloseButton
              className={classes.closeBtnIcon}
              onClick={closeDialog}
              />
            </Grid>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent dividers>
        <Grid container xs={12} className={classes.iconsContainer}>
          <Grid container justify="center">
            {rows}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <CustomButton
          view="cancel"
          onClick={closeDialog}
          className={classes.btnCancel}
          startIcon={<HighlightOffOutlinedIcon />}
        >
          {t("Cancel")}
        </CustomButton>
        <CustomButton
            view="cancel"
            onClick={reset}
            className={classes.btnCancel}
            startIcon={<DeleteIcon />}
        >
          {t("reset")}
        </CustomButton>
        <CustomButton
          view="primary"
          startIcon={<SaveOutlinedIcon />}
          onClick={() => handleSelectIcon(selectedIcon)}
          disabled={selectedIcon === -1}
        >
          {t("Save")}
        </CustomButton>
      </DialogActions>
    </CustomDialog>
  );
};

export default IconList;
