import React from "react";
import { useLocation } from "react-router-dom";
import { CustomTextField } from "../FormFields/CustomAutoComplete";
import { useTheme } from "@material-ui/core/styles";
import { ReactComponent as SearchIcon } from "../../assets/common/searchicon.svg";
import useStyles from "./style"

export const SearchFilter = ({
  callback,
  placeHolder,
  style,
  value,
  fullWidth = true,
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const handleUrlParamChange = (e) => {
    callback(e);
  };

  return (
    <CustomTextField
        color="secondary"
        id="standard-adornment-search"
        type="text"
        fullWidth={fullWidth}
        value={value}
        onChange={(event) => handleUrlParamChange(event)}
        placeholder={placeHolder}
        InputProps={{ endAdornment: <SearchIcon className={style} stroke={theme.custom.color.chartColor2} height={15} /> }}
        className={classes.textField}
      />
  );
};

export const useQuery = () => new URLSearchParams(useLocation().search);
