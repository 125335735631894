import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";

import { ThemeProvider } from "@material-ui/core/styles";
import App from "./App";
import { configureStore } from "./redux/store";
import * as serviceWorker from "./serviceWorker";

import orchestratorTheme from "./style/orchestratorTheme";
import "./i18n";
import "./style/utilities.css";
import "./index.css";
import "./style/flag-icon.min.css"

function Main() {
  return (
    <ThemeProvider theme={orchestratorTheme}>
      <Router>
        <App />
      </Router>
    </ThemeProvider>
  );
}
const store = configureStore();

ReactDOM.render(
  <Provider store={store}>
    <Main />
  </Provider>,
  document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
