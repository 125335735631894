import React, { useEffect } from "react";
import Avatar from "@material-ui/core/Avatar"
import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import Box from "@material-ui/core/Box"
import Stepper from "@material-ui/core/Stepper"
import Step from "@material-ui/core/Step"
import StepLabel from "@material-ui/core/StepLabel"
import Tooltip from "@material-ui/core/Tooltip"
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm, FormProvider } from "react-hook-form";

import MuiDialogActions from "@material-ui/core/DialogActions";
import PersonIcon from "@material-ui/icons/Person";
import DateFnsAdapter from "@date-io/date-fns";
import get from "lodash/get";
import CustomTab, { useIndicatorStyle } from "pages/Services/components/CustomTab";
import Tabs from "@material-ui/core/Tabs";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import ConfirmMessage from "../../../../components/ConfirmMessage";
import CustomCloseButton from "pages/Services/components/CustomCloseButton";
import {
  fetchFleet,
  createFleet,
  editFleet,
  fetchSubscriptions,
  fetchPaymentTerms,
  fetchFleetProcesses,
  fetchUnassignedProcesses,
  assignProcessesToFleet,
  validateUnassignProcessFromFleet,
  fetchCurrentUser,
} from "../../../../redux/actions/services/index";
import ImageProfil from "../../ImageProfile";
import ProcessSelector from "../../ProcessSelector/index.js";
import FleetInfo from "./FleetInfo";
import FleetParameter from "./FleetParameters";
import {
  defaultDaysPerYear,
  defaultManDayHours,
  defaultRecommendedOcccupancy,
  isFleetAdministrator,
} from "../../../../util/index.js";
import { toast } from "react-toastify";
import CustomButton from "../../../../components/CustomButton";
import CircularLoader from "../../../../components/Loaders/CircularLoader";
import useStyles from "./style.js";

const FleetForm = () => {
  const dateFns = new DateFnsAdapter();
  const today = dateFns.date();
  const classes = useStyles();
  const dispatch = useDispatch();
  const methods = useForm({
    mode: "onChange",
    defaultValues: {
      companyName: "",
      siret: "",
      siren: "",
      vatNumber: "",
      phoneNumber: "",
      email: "",
      address: "",
      daysPerYear: 228,
      hoursPerManDay: 8,
      recommendedOccupancy: 17,
      clientSince: today,
      fleetSubscription: {
        effectiveStartDate: today,
      },
      instanceOwner: false,
      currency: "EUR"
    },
  });
  const {
    handleSubmit, formState: { isDirty }, setValue, trigger, clearErrors,
  } = methods;

  const history = useHistory();

  const { t } = useTranslation();
  const { idFleet, mode = "add" } = useParams();

  const subscriptions = useSelector(
    ({ requests }) => requests.queries.FETCH_SUBSCRIPTIONS
      && requests.queries.FETCH_SUBSCRIPTIONS.data,
  );
  const paymentTerms = useSelector(
    ({ requests }) => requests.queries.FETCH_PAYMENT_TERMS
      && requests.queries.FETCH_PAYMENT_TERMS.data,
  );
  const processes = useSelector(
    ({ requests }) => requests.queries.FETCH_PROCESSES && requests.queries.FETCH_PROCESSES.data,
  );

  const userProcesses = useSelector(
    ({ requests }) => requests.queries.FETCH_FLEET_PROCESSES
      && requests.queries.FETCH_FLEET_PROCESSES.data,
  );

  const currentUser = useSelector(({ requests }) => get(requests, "queries.FETCH_CURRENT_USER.data"));

  const [availableProcesses, setAvailableProcesses] = React.useState([]);
  const [assignedProcesses, setAssignedProcesses] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [logo, setLogo] = React.useState(null);
  const [logoPath, setLogoPath] = React.useState();
  const [activeStep, setActiveStep] = React.useState(0);
  const [fleetDataLoading, setFleetDataLoading] = React.useState(false);
  const getImageURL = (path) => `${process.env.REACT_APP_DOMAIN}api/admin${path}`;
  useEffect(() => {
    dispatch(fetchSubscriptions()).then((response) => {
      if (mode === "add") {
        setValue("fleetSubscription.volume", response.data[0].volume);

        setValue("fleetSubscription.rate", response.data[0].rate);
        setValue(
          "fleetSubscription.overConsumptionRate",
          response.data[0].overConsumptionRate,
        );
        setValue(
          "fleetSubscription.subscriptionPeriod",
          response.data[0].subscriptionPeriod,
        );
      }
    });
    dispatch(fetchPaymentTerms()).then((response) => {
      setValue("fleetSubscription.paymentTerm", response.data[0].frequency);
    });

    if (mode !== "add") {
      setFleetDataLoading(true);
      dispatch(fetchFleet(idFleet)).then((response) => {
        setValue("fleetId", response?.data?.id);
        setValue("companyName", response?.data?.companyName);
        setValue("phoneNumber", response?.data?.phoneNumber);
        setValue("address", response?.data?.address);
        setValue("vatNumber", response?.data?.vatNumber);
        setValue("email", response?.data?.email);
        setValue("siren", response?.data?.siren);
        setValue("siret", response?.data?.siret);
        setValue("beCalculationPeriod", response?.data?.beCalculationPeriod);
        setValue("instanceOwner", response?.data?.instanceOwner);
        setValue(
          "hoursPerManDay",
          response?.data?.hoursPerManDay || defaultManDayHours,
        );
        setValue(
          "daysPerYear",
          response?.data?.daysPerYear || defaultDaysPerYear,
        );
        setValue(
          "recommendedOccupancy",
          response?.data?.recommendedOccupancy || defaultRecommendedOcccupancy,
        );

        // setValue("subscription", response.data.subscription.code);
        setValue(
          "clientSince",
          dateFns.date(response?.data?.clientSince, "yyyy/MM/DD"),
        );

        if (response?.data?.fleetSubscription) {
          setValue(
            "fleetSubscription.volume",
            response?.data?.fleetSubscription?.volume,
          );
          setValue(
            "fleetSubscription.rate",
            response?.data?.fleetSubscription?.rate,
          );
          setValue(
            "fleetSubscription.overConsumptionRate",
            response?.data?.fleetSubscription?.overConsumptionRate,
          );
          setValue(
            "fleetSubscription.paymentTerm",
            response?.data?.fleetSubscription?.paymentTerm,
          );
          setValue(
            "fleetSubscription.subscriptionPeriod",
            response?.data?.fleetSubscription?.subscriptionPeriod,
          );
          setValue(
            "fleetSubscription.effectiveStartDate",
            new Date(response?.data?.fleetSubscription?.effectiveStartDate),
          );
          setValue("currency", response?.data?.currency);
        }

        setLogoPath(response?.data?.logo);
        setFleetDataLoading(false);
      });
    }
  }, [idFleet, dispatch, setValue, mode]);

  useEffect(() => {
    dispatch(fetchUnassignedProcesses());
  }, [dispatch]);
  useEffect(() => {
    if (idFleet) dispatch(fetchFleetProcesses(idFleet));
  }, [idFleet, dispatch]);
  useEffect(() => {
    setAvailableProcesses(processes?.list ?? []);
  }, [processes]);
  useEffect(() => {
    if (idFleet) setAssignedProcesses(userProcesses?.list ?? []);
  }, [userProcesses, idFleet]);

  const [openMsgCancel, setOpenMsgCancel] = React.useState(false);

  const redirectToList = () => history.push("/userAdministration/groups");

  const handleRejectCancelForm = () => setOpenMsgCancel(false);
  const handleAcceptCancelForm = () => {
    setOpenMsgCancel(false);
    redirectToList();
  };

  const handleCancel = () => {
    if (mode === "view" || !isDirty) {
      redirectToList();
      return;
    }
    setOpenMsgCancel(true);
  };

  const steps = [
    "fleet.formSection.fleetInfo",
    // "fleet.formSection.subscriptionInfo",
    "fleet.formSection.clientParameters",
    "fleet.formSection.processes",
  ];

  const disableFields = !(mode === "add" || mode === "edit");

  const [openMsgConfirm, setOpenMsgConfirm] = React.useState(false);

  const onSubmit = (data) => {
    const dataToSubmit = {
      ...data,
      // subscription: subscriptions.find((sub) => sub.code === data.subscription),
      clientSince: data.clientSince,
      hoursPerManDay: data?.hoursPerManDay || defaultManDayHours,
      daysPerYear: data?.daysPerYear || defaultDaysPerYear,
      recommendedOccupancy: data?.recommendedOccupancy || defaultRecommendedOcccupancy,
      fleetSubscription: {
        ...data.fleetSubscription,
        paymentTermLabel: paymentTerms.find(
          (paymentTerm) => paymentTerm.frequency === data.fleetSubscription.paymentTerm,
        ).label,
        subscriptionLabel: subscriptions.find(
          (subscription) => subscription.volume === data.fleetSubscription.volume,
        ).label,
        effectiveStartDate: data.fleetSubscription.effectiveStartDate
      },
    };
    setIsLoading(true);
    if (mode === "add") {
      dispatch(
        createFleet(dataToSubmit, logo, (res) => {
          if (res?.status === 200) {
            if (res.data?.id) {
              dispatch(assignProcessesToFleet(res.data.id, assignedProcesses.map((element) => element.id).join(",")));
            }
            setOpenMsgConfirm(false);
            setIsLoading(false);
            history.push({
              pathname: "/userAdministration/groups",
            });
            toast.success(t("fleet.formControl.saveSuccess"))
          }
        }),
      )
        .then((res) => {
          if (res?.response?.status === 409) {
            toast.error(t(res.response?.data?.detail))
          } else if (res.response.status !== "OK") {
            toast.error(t(res.response?.data?.message))
          }
          setIsLoading(false);
        })
        .catch(() => {});
    } else if (mode === "edit") {
      const modifyDataToSubmit = {
        ...dataToSubmit,
        fleetSubscription: {
          ...dataToSubmit.fleetSubscription,
          id: idFleet,
        },
      };

      dispatch(
        editFleet(idFleet, modifyDataToSubmit, logo, (res) => {
          if (res.status === 200) {
            setOpenMsgConfirm(false);
            if (res.data?.id) {
              dispatch(assignProcessesToFleet(res.data.id, assignedProcesses.map((element) => element.id).join(",")));
              setIsLoading(false);
              history.push({
                pathname: "/userAdministration/groups",
              });
              toast.success(t("fleet.formControl.updateSuccess"))
              dispatch(fetchCurrentUser());
            }
          }
        }),
      )
        .then((res) => {
          const status = res?.response?.status;
          const respData = res.response?.data;
          if (status === 400 || status === 422) {
            toast.error(t(respData?.title))
          }
          else if (status === 409) {
            toast.error(t(respData?.detail))
          } else if (status !== "OK") {
            toast.error(t(respData.message))
          }
          setIsLoading(false);
        })
        .catch(() => {});
    }
  };

  const handleStepClick = async (step) => {
    if (activeStep !== step && mode === "edit") {
      const result = await trigger();
      if (result) setActiveStep(step);
    }
  };

  const handleSaveClick = async () => {
    const result = await trigger();
    if (result) {
      if (activeStep === 2) {
        setOpenMsgConfirm(true);
      }
    /* to enable Sub info change to setActiveStep((prevActiveStep) => prevActiveStep + 1) */
      if (activeStep < 2) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    }
  };
  const cancelConfirm = () => {
    setOpenMsgConfirm(false);
    setIsLoading(false);
  };
  const confirmSave = () => {
    handleSubmit(onSubmit)();
  };

  const onUnAssignProcess = (process) => dispatch(validateUnassignProcessFromFleet(process.id)).then(
    (res) => {
      if (res?.data !== 0) {
        toast.error(t("fleet.formControl.unassignProcessError", { users: res.data }))
        return false;
      }
      return true;
    },
  );

  const handleReset = () => {
    setActiveStep(0);
  };

  const getStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return fleetDataLoading ? (
          <CircularLoader height="100%" />
        ) : (
          <>
            <Grid
              item
              xs={12}
              container
              alignItems="center"
              justify="center"
              className={classes.avatarContainer}
            >
              {/* eslint-disable-next-line no-nested-ternary */}
              {mode === "add" || mode === "edit" ? (
                <ImageProfil
                  src={logoPath}
                  changeValue={setLogo}
                  disabled={disableFields}
                />
              ) : logoPath ? (
                <Avatar
                  alt="Fleet logo"
                  className={classes.large}
                  src={getImageURL(logoPath)}
                />
              ) : (
                <Avatar className={classes.large}>
                  <PersonIcon className={classes.avatarIcon} />
                </Avatar>
              )}
            </Grid>
            <FleetInfo
              disableFields={disableFields}
              clearErrors={clearErrors}
              today={today}
            />
          </>
        );
      // case 1:
      //   return (
      //     <SubscriptionInfo
      //       disableFields={disableFields}
      //       subscriptions={subscriptions}
      //       paymentTerms={paymentTerms}
      //       today={today}
      //     />
      //   );
      case 1:
        return (
          <FleetParameter
            disableFields={disableFields}
            clearErrors={clearErrors}
          />
        );
      case 2:
        return (
          <Grid item xs={12} style={{ height: "100%" }}>
            <ProcessSelector
              availableProcesses={availableProcesses}
              selectedProcesses={assignedProcesses}
              isDisabled={disableFields}
              setAvailableProcesses={setAvailableProcesses}
              setSelectedProcesses={setAssignedProcesses}
              onUnAssignProcess={onUnAssignProcess}
            />
          </Grid>
        );
      default:
        return "Submitting...";
    }
  };
  /* to enable Sub info change to setActiveStep((prevActiveStep) => prevActiveStep - 1) */
  const handleBack = () => {
    if (activeStep <= 0) handleCancel();
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleChange = (_, newValue) => {
    setActiveStep(newValue);
  };

  const tabsStyle = useIndicatorStyle();

  return (
    <div className={classes.root}>
      <form className={classes.fleetForm}>
        <Grid container alignItems="center" justify="center" xs={12}>
          <Grid container xs={12} item>
            <Grid container>
              <div className={classes.root}>
                {mode === "view" ? (
                  <Paper square>
                    <Grid container direction="row" xs={12}>
                      <Grid container direction="row" xs={11}>
                        <Tabs
                          id="process-tabs-page"
                          className={classes.tabSize}
                          value={activeStep}
                          indicatorColor="primary"
                          textColor="primary"
                          onChange={handleChange}
                          aria-label="switch tabs"
                          TabIndicatorProps={{
                            style: tabsStyle,
                          }}
                        >
                          {steps.map((label, index) => (
                            <CustomTab
                              key={label}
                              id="process-overview-tab"
                              value={index}
                              label={t(label)}
                            />
                          ))}
                        </Tabs>
                      </Grid>
                      <Grid container direction="row" xs={1} justify="flex-end">
                        <CustomCloseButton
                          className={classes.closeIcon}
                          aria-label="close"
                          onClick={handleCancel}
                        />
                      </Grid>
                    </Grid>
                  </Paper>
                ) : (
                  <>
                    <Grid
                      container
                      direction="row"
                      xs={1}
                      justify="flex-end"
                      className={classes.closebutton}
                    >
                      <CustomCloseButton
                        aria-label="close"
                        className={classes.closeIcon}
                        onClick={handleCancel}
                      />
                    </Grid>
                    <Stepper
                      activeStep={activeStep}
                      alternativeLabel
                      className={classes.stepper}
                    >
                      {steps.map((label, index) => (
                        <Step key={label}>
                          <StepLabel
                            style={{ cursor: "pointer" }}
                            onClick={() => handleStepClick(index)}
                          >
                            {t(label)}
                          </StepLabel>
                        </Step>
                      ))}
                    </Stepper>
                  </>
                )}

                <div>
                  {activeStep === steps.length ? (
                    <div>
                      <Typography className={classes.instructions}>
                        All steps completed
                      </Typography>
                      <CustomButton onClick={handleReset}>Reset</CustomButton>
                    </div>
                  ) : (
                    <div className={classes.formContainer}>
                      {/* <AdminPageHeader title={headerTitle} /> */}
                      {mode === "view" && isFleetAdministrator(currentUser) ? (
                        <Grid
                          container
                          justify="flex-end"
                          className={classes.editContainer}
                        >
                          <Tooltip title={t("Edit")}>
                            <IconButton
                              aria-label="modify"
                              onClick={() => {
                                history.push(
                                  `/userAdministration/groups/edit/${idFleet}`,
                                );
                              }}
                            >
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      ) : null}
                      <FormProvider {...methods}>
                        <form onSubmit={handleSubmit(onSubmit)}>
                          <Grid
                            container
                            item
                            xs={12}
                            justify="center"
                            className={classes.inputsContainer}
                            // style={{height:"430px",display:"flex",alignContent:"flex-start",overflow: "auto"}}
                          >
                            {/* to enable Sub info change to getStepContent(activeStep) */}
                            {getStepContent(activeStep)}
                          </Grid>
                          <Grid>
                            {(mode === "edit" && activeStep < steps.length)
                            || !disableFields ? (
                              <Grid
                                container
                                justify="center"
                                className={classes.nopadding}
                              >
                                <Grid
                                  item
                                  xs={12}
                                  className={classes.nopadding}
                                >
                                  <MuiDialogActions
                                    className={classes.modalActionButtons}
                                  >
                                    <Box
                                      ml="1rem"
                                      component={Button}
                                      variant="contained"
                                      size="medium"
                                      onClick={handleBack}
                                      className={classes.resetButton}
                                    >
                                      {activeStep <= 0
                                        ? t("Cancel")
                                        : t("fleet.add.previous")}
                                    </Box>
                                    {((mode === "view"
                                        && activeStep < steps.length - 1)
                                        || !disableFields) && (
                                        <CustomButton
                                          view="primary"
                                          size="medium"
                                          type="button"
                                          onClick={handleSaveClick}
                                        >
                                          {/* eslint-disable-next-line no-nested-ternary */}
                                          {activeStep >= steps.length - 1
                                            ? idFleet
                                              ? t("fleet.button.update")
                                              : t("fleet.button.save")
                                            : t("next")}
                                        </CustomButton>
                                    )}
                                  </MuiDialogActions>
                                </Grid>
                              </Grid>
                              ) : null}
                          </Grid>
                        </form>
                      </FormProvider>
                    </div>
                  )}
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </form>
      {openMsgConfirm && (
        <ConfirmMessage
          message={
            idFleet
              ? t("fleett.update.confirmMsg")
              : t("fleet.save.confirmMsg")
          }
          openStart={openMsgConfirm}
          onCancel={cancelConfirm}
          onConfirm={confirmSave}
          buttonCancel={t("fleet.button.cancel")}
          buttonConfirm={
            idFleet ? t("fleet.button.update") : t("fleet.button.save")
          }
          isLoading={isLoading}
        />
      )}

      {openMsgCancel && (
        <ConfirmMessage
          message={t("fleet.delete.discard")}
          openStart={openMsgCancel}
          onCancel={handleRejectCancelForm}
          onConfirm={handleAcceptCancelForm}
          buttonConfirm={t("fleet.button.discard")}
          buttonCancel={t("fleet.button.cancel")}
          isLoading={false}
        />
      )}

    </div>
  );
};
export default FleetForm;
