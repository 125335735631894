import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  root: {
    width: "100%",
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(2),
  },
  tabs: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
  },
  noPadding: {
    margin: "-24px",
  },
}));
