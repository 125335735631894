import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    search: "",
    objects: [],
    users: [],
    action: null,
    timeFilter: null,
    sortBy: null,
    pageNo: 0,
    pageSize: 5,
};

const auditFilters = createSlice({
    name: "auditFilters",
    initialState,
    reducers: {
        updateFilter: (state, action) => (state = action.payload), // eslint-disable-line
        initialFilter: (state) => (state = initialState), // eslint-disable-line
    },
});

export const { updateFilter, initialFilter } = auditFilters.actions;

export default auditFilters.reducer;
