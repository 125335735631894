import React, { useState, useEffect } from "react";
import {
  Grid
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import get from "lodash/get";
import { Duration } from "util/constants";
import SubPageContainer from "../../components/SubPageContainer";
import ItemExceptions from "./ItemExceptions";
import ProcessesExceptions from "./ProcessesExceptions";
import useStyles from "./style";
import { formatDateByLanguage, secondsToTime } from "../../util";
import { updateModule } from "../../redux/slices/exceptionFilter";

function ExceptionsPage(props) {
  const history = useHistory();
  const classes = useStyles();
  const { t } = useTranslation();
  const [selected, setSelected] = useState([]);
  const [exceptionsToShow, setExceptionsToShow] = useState([]);
  const [exceptionsToHide, setExceptionsToHide] = useState([]);
  const [detail, setDetail] = useState(null);
  const [logDetail, setLogDetail] = useState(null);
  const [validate, setValidate] = useState(false);
  const [messageConfirm, setMessageConfirm] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [openMsgConfirm, setOpenMsgConfirm] = useState(false);
  const isSelected = (name) => selected.indexOf(name) !== -1;
  const [exportLoading, setExportLoading] = React.useState(false);
  const [isLoadingConfirm, setIsLoadingConfirm] = React.useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (history.location.state && history.location.state.dialogMsg) {
      const state = { ...history.location.state };
      history.replace({ ...history.location, state });
    }
  }, [history]);

  const module = React.useMemo(() => history.location.pathname.split("/").slice(2, 3).join("/"));

  useEffect(() => {
    dispatch(updateModule({ module }));
  }, [module]);

  const [showAnalyticsContent, setShowAnalyticsContent] = useState(false);

  const getDateDifference = (s) => secondsToTime(s, t);

  const deleteDuplicationSelection = (toRemove) => {
    const newSelected = selected.filter((item) => !toRemove.includes(item));
    setSelected(newSelected);
    setExceptionsToShow((prvExceptionToshow) => prvExceptionToshow.filter((item) => !toRemove.includes(item)));
    setExceptionsToHide((prvExceptionsToHide) => prvExceptionsToHide.filter((item) => !toRemove.includes(item)));
  };

  const handleClick = (event, item) => {
    const selectedIndex = selected.indexOf(item?.id);
    let newSelected = [];
    let toShow = [];
    let toHide = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, item?.id);
      toShow = toShow.concat(exceptionsToShow, item?.id);
      toHide = toHide.concat(exceptionsToHide, item?.id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
      toShow = toShow.concat(exceptionsToShow.slice(1));
      toHide = toHide.concat(exceptionsToHide.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
      toShow = toShow.concat(exceptionsToShow.slice(0, -1));
      toHide = toHide.concat(exceptionsToHide.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
      toShow = toShow.concat(
        exceptionsToShow.slice(0, selectedIndex),
        exceptionsToShow.slice(selectedIndex + 1),
      );
      toHide = toHide.concat(
        exceptionsToHide.slice(0, selectedIndex),
        exceptionsToHide.slice(selectedIndex + 1),
      );
    }
    item.isDeleted ? setExceptionsToShow(toShow) : setExceptionsToHide(toHide);
    setSelected(newSelected);
  };

  const currentUser = useSelector(({ requests }) => get(requests, "queries.FETCH_CURRENT_USER.data"));

  return (
    <SubPageContainer>
      <div className={classes.rootTabs}>
        <Grid className={classes.firstGrid}>
          {props.page === "Process Item" && (
            <ItemExceptions
              getDateDifference={getDateDifference}
              logDetail={logDetail}
              setLogDetail={setLogDetail}
              validate={validate}
              setValidate={setValidate}
              detail={detail}
              setDetail={setDetail}
              getDate={formatDateByLanguage}
              deleteDuplicationSelection={deleteDuplicationSelection}
              selected={selected}
              setSelected={setSelected}
              isSelected={isSelected}
              messageConfirm={messageConfirm}
              setMessageConfirm={setMessageConfirm}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              handleClick={handleClick}
              openMsgConfirm={openMsgConfirm}
              setOpenMsgConfirm={setOpenMsgConfirm}
              currentUser={currentUser}
              exportLoading={exportLoading}
              setExportLoading={setExportLoading}
              showAnalyticsContent={showAnalyticsContent}
              setShowAnalyticsContent={setShowAnalyticsContent}
              setIsLoadingConfirm={setIsLoadingConfirm}
              isLoadingConfirm={isLoadingConfirm}
              exceptionsToShow={exceptionsToShow}
              exceptionsToHide={exceptionsToHide}
              setExceptionsToShow={setExceptionsToShow}
              setExceptionsToHide={setExceptionsToHide}
              durations={Duration.list}
              currentUserSet={props.currentUserItems}
            />
          )}
          {props.page === "Executions" && (
            <ProcessesExceptions
              getDateDifference={getDateDifference}
              logDetail={logDetail}
              setLogDetail={setLogDetail}
              validate={validate}
              setValidate={setValidate}
              detail={detail}
              setDetail={setDetail}
              getDate={formatDateByLanguage}
              deleteDuplicationSelection={deleteDuplicationSelection}
              selected={selected}
              setSelected={setSelected}
              isSelected={isSelected}
              messageConfirm={messageConfirm}
              setMessageConfirm={setMessageConfirm}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              handleClick={handleClick}
              openMsgConfirm={openMsgConfirm}
              setOpenMsgConfirm={setOpenMsgConfirm}
              currentUser={currentUser}
              exportLoading={exportLoading}
              setExportLoading={setExportLoading}
              showAnalyticsContent={showAnalyticsContent}
              setShowAnalyticsContent={setShowAnalyticsContent}
              setIsLoadingConfirm={setIsLoadingConfirm}
              isLoadingConfirm={isLoadingConfirm}
              exceptionsToShow={exceptionsToShow}
              exceptionsToHide={exceptionsToHide}
              setExceptionsToShow={setExceptionsToShow}
              setExceptionsToHide={setExceptionsToHide}
              durations={Duration.list}
            />
          )}
        </Grid>
      </div>
    </SubPageContainer>
  );
}

export default ExceptionsPage;
