/* eslint-disable no-nested-ternary */
import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import { useTranslation } from "react-i18next";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import CheckIcon from "@material-ui/icons/Check";
import { SUBMITTED } from "util/constants";
import Chart from "../Chart";
import { formtNumberByLanguage, round } from "../../util";
import CustomCloseButton from "pages/Services/components/CustomCloseButton";
import useStyles from "./style";
import ConfirmMessage from "../ConfirmMessage";
import { updateDevelopementCost } from "../../redux/actions/services";

const Result = ({ result, loadProcess }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const [editDevCost, setEditDevCost] = React.useState(false);
  const [devCost, setDevCost] = React.useState({});
  const [openMsgConfirm, setOpenMsgConfirm] = React.useState(false);
  const [openMsgCancel, setOpenMsgCancel] = React.useState(false);

  const data = [
    {
      id: t("Feasibility/Impact"),
      data: [
        {
          x: round(result.feasibility, 1),
          y: round(result.impact, 1),
        },
      ],
    },
  ];

  const editDevelopementCost = () => {
    setEditDevCost(true);
    setDevCost({ ...result });
  };
  const cancelConfirm = () => {
    setOpenMsgConfirm(false);
  };
  const cancelCancel = () => {
    setOpenMsgCancel(false);
  };

  const confirmCancel = () => {
    setOpenMsgCancel(false);
    setEditDevCost(false);
  };
  const confirmEdit = () => {
    setOpenMsgConfirm(false);
    dispatch(updateDevelopementCost(result.id, devCost)).then(() => {
      setEditDevCost(false);
      dispatch(loadProcess(result.id, history));
    });
  };

  return (
    <Grid container>
      <Grid item className={classes.marginBottom}>
        <Typography variant="h5" component="h3">
          {t("Impact/Feasibility Matrix")}
        </Typography>
      </Grid>
      <Grid container item alignItems="center" spacing={3}>
        <Grid item xs={12} md={6} id="feasability-graph">
          <Chart data={data} />
        </Grid>
        <Grid item container xs={12} md={6}>
          <Grid item xs={12}>
            <Typography
              className={classes.redZoneColor}
              variant="h5"
              component="h5"
            >
              {t("Red zone")}
            </Typography>
            <Typography
              className={classes.marginBottom}
              variant="h6"
              component="h6"
            >
              {t("Impact and feasibility are limited")}
              {" "}
              (
              {"<"}
              {" "}
              {formtNumberByLanguage(2.5)}
              /5)
              <br />
              {t("The process is likely to be reprioritized")}
            </Typography>
            <Divider />
          </Grid>
          <Grid item xs={12} className={classes.marginTop}>
            <Typography
              className={classes.yellowZoneColor}
              variant="h5"
              component="h5"
            >
              {t("Yellow zone")}
            </Typography>
            <Typography
              className={classes.marginBottom}
              variant="h6"
              component="h6"
            >
              {t("Here either the impact or the feasibility is relevant")}
              <br />
              {t(
                "Depending on the case and arbitration, the process could be deprioritized",
              )}
            </Typography>
            <Divider />
          </Grid>
          <Grid item xs={12} className={classes.marginTop}>
            <Typography
              className={classes.greenZoneColor}
              variant="h5"
              component="h5"
            >
              {t("Green zone")}
            </Typography>
            <Typography
              className={classes.marginBottom}
              variant="h6"
              component="h6"
            >
              {t("Both impact and feasibility show some great signs")}
              {" "}
              (
              {">"}
              {formtNumberByLanguage(2.5)}
              /5)
              <br />
              {t("The process is likely to be prioritized")}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid container item spacing={4} className={classes.displayNone}>
        <Grid item xs={12} md={4}>
          <Card variant="outlined" elevation={2} className={classes.resultCard}>
            <CardContent>
              <Typography
                variant="h5"
                component="h6"
                className={classes.cardHeader}
              >
                {t("Saving (per year)")}
              </Typography>
              <Box
                display="flex"
                justifyContent="space-between"
                marginBottom={1}
              >
                <Typography variant="h5" component="h5">
                  {t("FTE Gains")}
                </Typography>
                <Typography variant="h6" component="p">
                  {formtNumberByLanguage(round(result.fteGains, 2))}
                </Typography>
              </Box>
              <Box
                display="flex"
                justifyContent="space-between"
                marginBottom={1}
              >
                <Typography variant="h5" component="h5">
                  {t("FTE Costs")}
                </Typography>
                <Typography variant="h6" component="p">
                  {formtNumberByLanguage(round(result.fteCosts, 2))}
                </Typography>
              </Box>
              <Divider />
              <Box display="flex" justifyContent="space-between" marginTop={2}>
                <Typography
                  variant="h5"
                  component="h5"
                  className={classes.cardFooterKey}
                >
                  {t("Total in man-days")}
                </Typography>
                <Typography
                  variant="h6"
                  component="p"
                  className={classes.cardFooterValue}
                >
                  {formtNumberByLanguage(
                    round(result.fteCosts * result.fteGains, 2),
                  )}
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Card variant="outlined" elevation={2} className={classes.resultCard}>
            <CardContent>
              <Grid container xs={12} ve>
                <Grid container xs={editDevCost ? 10 : 11}>
                  <Typography
                    variant="h5"
                    component="h6"
                    className={classes.cardHeader}
                  >
                    {t("Developement costs")}
                  </Typography>
                </Grid>
                {!result.id
                || (result.id
                  && result?.status?.trim()?.toUpperCase() !== SUBMITTED) ? (
                    <Grid container xs={1} />
                  ) : editDevCost ? (
                    <>
                      <Grid container xs={1}>
                        <CustomCloseButton
                          edge="end"
                          aria-label="cancel"
                          onClick={() => setOpenMsgCancel(true)}
                        />
                      </Grid>
                      <Grid container xs={1}>
                        <IconButton
                          edge="end"
                          aria-label="edit"
                          onClick={() => setOpenMsgConfirm(true)}
                        >
                          <CheckIcon />
                        </IconButton>
                      </Grid>
                    </>
                  ) : (
                    <Grid container xs={1}>
                      <IconButton
                        edge="end"
                        aria-label="edit"
                        onClick={editDevelopementCost}
                      >
                        <EditOutlinedIcon fontSize="small" />
                      </IconButton>
                    </Grid>
                  )}
              </Grid>

              <Box
                display="flex"
                justifyContent="space-between"
                marginBottom={1}
              >
                <Typography variant="h5" component="h5">
                  {t("Developer")}
                </Typography>
                {editDevCost ? (
                  <TextField
                    id="developerCosts"
                    value={devCost.developerCosts}
                    type="number"
                    InputProps={{ inputProps: { min: 0, max: 999999 } }}
                    onChange={(e) => {
                      setDevCost({
                        ...devCost,
                        developerCosts: e.target.value,
                      });
                    }}
                  />
                ) : (
                  <Typography variant="h6" component="p">
                    {formtNumberByLanguage(round(result.developerCosts, 2))}
                  </Typography>
                )}
              </Box>
              <Box
                display="flex"
                justifyContent="space-between"
                marginBottom={1}
              >
                <Typography variant="h5" component="h5">
                  {t("Business analyst")}
                </Typography>
                {editDevCost ? (
                  <TextField
                    id="baCosts"
                    type="number"
                    InputProps={{ inputProps: { min: 0, max: 999999 } }}
                    value={devCost.baCosts}
                    onChange={(e) => {
                      setDevCost({ ...devCost, baCosts: e.target.value });
                    }}
                  />
                ) : (
                  <Typography variant="h6" component="p">
                    {formtNumberByLanguage(round(result.baCosts, 2))}
                  </Typography>
                )}
              </Box>
              <Divider />
              <Box display="flex" justifyContent="space-between" marginTop={2}>
                <Typography
                  variant="h5"
                  component="h5"
                  className={classes.cardFooterKey}
                >
                  {t("Total in man-days")}
                </Typography>
                <Typography
                  variant="h6"
                  component="p"
                  className={classes.cardFooterValue}
                >
                  {editDevCost
                    ? formtNumberByLanguage(
                      round(
                        (parseFloat(devCost.developerCosts) || 0)
                            + (parseFloat(devCost.baCosts) || 0),
                        2,
                      ),
                    )
                    : formtNumberByLanguage(
                      round(result.developerCosts + result.baCosts, 2),
                    )}
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item container xs={12} sm={6} md={4}>
          <Grid item xs={12} className={classes.marginBottom}>
            <Card
              variant="outlined"
              elevation={2}
              className={classes.resultCard}
            >
              <CardContent>
                <Typography
                  variant="h5"
                  component="h6"
                  className={classes.cardHeader}
                >
                  {t("Running costs (per year)")}
                </Typography>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  marginTop={2}
                >
                  <Typography
                    variant="h5"
                    component="h5"
                    className={classes.cardFooterKey}
                  >
                    {t("Running costs in man-days")}
                  </Typography>
                  <Typography
                    variant="h6"
                    component="p"
                    className={classes.cardFooterValue}
                  >
                    {formtNumberByLanguage(round(result.runningCosts, 2))}
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card
              variant="outlined"
              elevation={2}
              className={classes.paybackSimple}
            >
              <CardContent>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography
                    variant="h5"
                    component="h5"
                    className={classes.cardFooterKey}
                  >
                    {t("Payback period (year)")}
                  </Typography>
                  <Typography
                    variant="h5"
                    component="p"
                    className={classes.cardFooterValue}
                  >
                    {round(result.payback || result.roi, 2) >= 5
                      ? `>= 5 ${t("processStore.years")}`
                      : round(result.payback || result.roi, 2) < 5
                        && round(result.payback || result.roi, 2) >= 3
                        ? `< 5 ${t("processStore.years")}`
                        : round(result.payback || result.roi, 2) < 3
                        && round(result.payback || result.roi, 2) >= 1
                          ? `< 3 ${t("processStore.years")}`
                          : round(result.payback || result.roi, 2) < 1
                        && round(result.payback || result.roi, 2) >= 0
                            ? `< 1 ${t("processStore.year")}`
                            : t("Unremarkable")}
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
      {openMsgConfirm && (
        <ConfirmMessage
          message={t("process.devCost.edit.confirm")}
          openStart={openMsgConfirm}
          onCancel={cancelConfirm}
          onConfirm={confirmEdit}
          buttonConfirm={t("Yes")}
          buttonCancel={t("No")}
        />
      )}
      {openMsgCancel && (
        <ConfirmMessage
          message={t("process.devCost.edit.cancel")}
          openStart={openMsgCancel}
          onCancel={cancelCancel}
          onConfirm={confirmCancel}
          buttonConfirm={t("Yes")}
          buttonCancel={t("No")}
        />
      )}
    </Grid>
  );
};

export default Result;
