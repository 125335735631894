import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    stepper: {
        "& .MuiStepIcon-active": {
            color: "#27ae60",
        },
        "& .MuiStepIcon-completed": {
            color: "#27ae60",
        },
        paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(4),
    },
    formContainer: {
        height: 450
    },
    closeButton: {
        marginLeft: "auto",
    },
    formActions: {
        display: "flex",
        gap: "1rem"
    },
    root: {
        width: "100%",
        background: "transparent",
        // margin: -theme.spacing(3),
    },
    paper: {
        width: "100%",
        marginBottom: theme.spacing(2),
        padding: theme.spacing(0, 4, 2, 4),
    },
    rootGlobal: {
        width: "100%",
        paddingLeft: theme.spacing(2),
    },
}))
