import { Grid, TextField } from "@material-ui/core";
import React from "react";
import { Controller } from "react-hook-form";

export default function TextInputWithIcon({
  disableFields,
  errors,
  classes,
  control,
  Icon,
  name,
  rules,
  defaultValue = "",
  label,
  autoFocus,
  placeholder,
  InputLabelProps = {},
  fullWidth
}) {
  // const { t } = useTranslation();
  return (
    <Grid container item>
      <Grid item xs={1} className={classes.NotificationIcons}>
        <Icon color="primary" />
      </Grid>
      <Grid item xs={10} lg={8}>
        <Controller
          name={name}
          control={control}
          rules={rules}
          defaultValue={defaultValue}
          render={({ field }) => (
            <TextField
              fullWidth={fullWidth}
              {...field}
              name={name}
              id={name}
              label={label}
              autoFocus={autoFocus}
              disabled={disableFields}
              error={!!errors.alertName?.message}
              helperText={errors.alertName?.message}
              placeholder={placeholder}
              InputLabelProps={InputLabelProps}
            />
          )}
        />
      </Grid>
    </Grid>
  );
}
