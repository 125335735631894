import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Checkbox from "@material-ui/core/Checkbox";
import TrashIcon from "@material-ui/icons/Delete";
import FolderIcon from "@material-ui/icons/Folder";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import { useQuery } from "@redux-requests/react";
import { archiveTags, deleteTags } from "../../../../../redux/actions/services";
import { disabledFunctionality } from "../../../../../util/constants/PermitedFeature";
import { isFleetAdministrator } from "../../../../../util";
import ConfirmMessage from "../../../../../components/ConfirmMessage";
import { isPermitted } from "../../../../../components/HasPermission";
import StatusLegend from "../../../../../components/StatusLegend";
import useStyles from "../../../../../components/TableComponents/style";

export default function TagTableHeader({
  selected,
  setSelected,
  handleSelectedAll,
  checkedAll,
  checkedIndeterminate,
  fetchEntities,
  page,
  rowsPerPage,
  fetchedData,
  statusList,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [popUpMessage, setPopUpMessage] = useState("");
  const [openMsgConfirmArchive, setOpenMsgConfirmArchive] = useState(false);
  const handleCloseConfirmationPopup = () => {
    setIsLoading(false);
    setOpenDeletePopup(false);
  };

  const currentUser = useSelector(({ requests }) => get(requests, "queries.FETCH_CURRENT_USER.data"),);
  const { data } = useQuery({ type: fetchEntities().type });
  const onlyCurrentUserSelected = selected.length === 1 && selected[0] === Number(currentUser.id);
  const onlyCurrentUserInList = fetchedData.length === 1 && fetchedData[0].id === Number(currentUser.id);

  const deleteAll = (newSelected = selected) => {
    dispatch(
      deleteTags(
        newSelected,
        () => {
          setIsLoading(false);
          dispatch(fetchEntities({ pageNo: page, pageSize: rowsPerPage }));
          setSelected([]);
          handleCloseConfirmationPopup();
          toast.success(t("tag.deleted.success"));
        },
        () => {
          handleCloseConfirmationPopup();
          toast.error(t("something went wrong"));
        },
      ),
    );
  };

  const onClick = () => {
    setIsLoading(true);
    deleteAll(selected);
  };
  const closePopUp = () => {
    setOpenMsgConfirmArchive(false);
  };

  const onConfirmArchiveSuccess = () => {
    setOpenMsgConfirmArchive(false);
    setIsLoading(false);
    setSelected([]);
    dispatch(fetchEntities());
    toast.success(t("tag.archived.success"));
  };
  const onConfirmArchiveError = () => {
    setSelected([]);
    setOpenMsgConfirmArchive(false);
    setIsLoading(false);
    toast.error("Unexpected Error");
  };
  const confirmArchive = () => {
    setIsLoading(true);
    setOpenMsgConfirmArchive(true);
    dispatch(
      archiveTags(selected, onConfirmArchiveSuccess, onConfirmArchiveError),
    );
  };

  return (
    <Grid
      container
      xs={12}
      justify="space-between"
      className={classes.tableHeaderContainer}
    >
      <Grid container item xs={6}>
        {!disabledFunctionality
        && <StatusLegend
          statues={statusList.map((status) => status.code)}
        />}
      </Grid>
      <Grid container item xs={6} justify="flex-end" alignItems="center">
        {!disabledFunctionality
          && (isPermitted(currentUser, "Delete tag")
            || isFleetAdministrator(currentUser)) && (
            <Grid item>
              <Tooltip placement="top" title={t("tooltip.action.archive")}>
                <IconButton
                  aria-label="archive"
                  disabled={isEmpty(selected)}
                  onClick={(e) => {
                    e.stopPropagation();
                    setPopUpMessage(t("archive.confirm.msg"));
                    setOpenMsgConfirmArchive(true);
                  }}
                  size="small"
                >
                  <FolderIcon className={isEmpty(selected) ? classes.rowActionButtons : classes.deleteIcon}
                  />
                </IconButton>
              </Tooltip>
            </Grid>
          )}
        {(isFleetAdministrator(currentUser)
          || isPermitted(currentUser, "Delete tag"))
          && data?.totalElements > 0 && (
            <>
              <Grid item>
                <Tooltip placement="top" title={t("tooltip.action.delete")}>
                  <IconButton
                    aria-label="delete"
                    disabled={
                      isEmpty(selected)
                      || onlyCurrentUserSelected
                      || onlyCurrentUserInList
                    }
                    onClick={() => {
                      setOpenDeletePopup(true);
                    }}
                    size="small"
                  >
                    <TrashIcon className={isEmpty(selected) ? classes.rowActionButtons : classes.deleteIcon}
                    />
                  </IconButton>
                </Tooltip>
                <ConfirmMessage
                  message={t("tag.management.deleteTagActionMessage")}
                  openStart={openDeletePopup}
                  onCancel={handleCloseConfirmationPopup}
                  onConfirm={onClick}
                  buttonConfirm={t("Delete")}
                  buttonCancel={t("Cancel")}
                  isLoading={isLoading}
                />
              </Grid>
              <Grid item>
                <Checkbox
                  checked={checkedAll && !onlyCurrentUserInList}
                  className={classes.rowActionButtons}
                  indeterminate={
                    checkedIndeterminate && !onlyCurrentUserSelected
                  }
                  onChange={(e) => {
                    handleSelectedAll(e.target.checked);
                  }}
                  disabled={onlyCurrentUserInList}
                  size="small"
                />
              </Grid>
            </>
          )}
        <ConfirmMessage
          message={popUpMessage}
          openStart={openMsgConfirmArchive}
          onCancel={() => {
            closePopUp();
            handleCloseConfirmationPopup();
          }}
          onConfirm={confirmArchive}
          buttonConfirm={t("tag.management.btn.confirm")}
          buttonCancel={t("Cancel")}
          isLoading={isLoading}
        />
      </Grid>
    </Grid>
  );
}
