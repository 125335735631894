import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  dialogPaper: {
    width: "75%",
    height: "80%"
  },
  dialogTitle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0 16px",
  },
  listBox: {
    // height: "calc(50% - 8px)",
    // borderRadius: "4px",
  },
  image: {
    maxWidth: 25,
  },
  processName: {
    marginLeft: "20px",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "24px",
    color: theme.custom.color.color2,
  },
  list: {
    overflow: "auto",
    maxHeight: "calc(100vw - 1430px)",
    padding: 0,
    marginTop: "20px"
  },
  sectionTitle: {
    color: theme.palette.secondary.main,
    fontWeight: "700",
    fontSize: "18px",
  },
  sectionTitleBox: {
    display: "flex",
    justifyContent: "space-between",
  },
  tableBox: {
    height: "100%",
    borderRadius: "4px",
  },
  tableContainer: {
    overflow: "auto",
  },
  lineContentValue: {
    color: theme.palette.primary.main,
    fontWeight: "600",
    fontSize: "12px",
    [theme.breakpoints.up("xl")]: {
      fontSize: "15px",
    },
  },
  mainDialog: {
    padding: "20px 16px",
    height: "100%",
  },
  cardBox: {
    backgroundColor: theme.custom.color.containerBackground,
    marginBottom: "15px",
    borderRadius: "1px",
    padding: "10px 4px 6px",
  },
  TAbHeader: {
    backgroundColor: theme.custom.color.staticInput,
    "& th": {
      fontSize: "14px",
      color: theme.custom.color.color2,
      fontWeight: "700",
      padding: "17px",
    },
  },
  TAbBody: {
    fontSize: "14px",
    color: theme.custom.color.color2,
    fontWeight: "400",
  },

  headerTitle: {
    color: theme.custom.color.color2,
    fontSize: "22px",
    fontWeight: "600",
  },
  deleteButton: {
    color: theme.custom.color.color6,
  },
  chipDefined: {
    color: theme.custom.color.color6,
    borderColor: theme.custom.color.color6,
    backgroundColor: theme.custom.color.color6Light,
    fontWeight: "700",
    fontSize: "14px",
    borderRadius: "27px",
    border: "1.5px",
    borderStyle: "solid",
    width: "38px",
  },
  chipCount: {
    color: theme.custom.color.chartColor2,
    backgroundColor: theme.custom.color.chartColor2Light,
    fontWeight: "700",
    fontSize: "18px",
    borderRadius: "27px",
    borderStyle: "solid",
    border: 0,
    padding: "13px",
  },
  divider: {
    background: theme.custom.color.dividerColor
  },
  listItem: {
    padding: "4px",
  },
  checkIcon: {
    color: theme.custom.color.color1,
    marginBottom: "0.9rem"
  },
  uncheckIcon: {
    color: theme.custom.color.color4,
    marginBottom: "0.9rem"
  },
  tabRow: {
    borderBottom: 0
  }
}));
