import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import useStyles from "./style.js";

export default function PageHeader({ title }) {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Grid container className={classes.pageHeaderContainer}>
      <Grid item>
        <Typography
          variant="h5"
          color="secondary"
          className={classes.pageHeaderTitle}
        >
          {t(title)}
        </Typography>
      </Grid>
    </Grid>
  );
}
