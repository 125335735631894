import {
    FETCH_DASHBOARD_HANDLING_TIME,
    FETCH_DASHBOARD_HEADER, FETCH_DASHBOARD_PROCESSES_ACTIVITY,
    FETCH_DASHBOARD_ROBOT_PRODUCTIVITY,
    FETCH_DASHBOARD_TIME_SAVED,
} from "../../constants";
import { URL_DASHBOARD } from "../../constants/endpoints";
import { formatDateTimeIsoParam } from "../../../util";

export const fetchDashboardHeader = (filter = {}) => {
    const {
        selectedDurationValue: period, process, fromDate, toDate, tags
    } = filter;
    return {
        type: FETCH_DASHBOARD_HEADER,
        request: {
            url: `${URL_DASHBOARD}header`,
            params: {
                period,
                processIds: process?.join(),
                from: formatDateTimeIsoParam(fromDate),
                to: formatDateTimeIsoParam(toDate),
                tagsIds: tags?.join(),
            },
        },
    };
};

export const fetchDashboardTimeSaved = (filter = {}) => {
    const {
        selectedDurationValue: period, process, fromDate, toDate, tags, by
    } = filter;
    return {
        type: FETCH_DASHBOARD_TIME_SAVED,
        request: {
            url: `${URL_DASHBOARD}time-saved`,
            params: {
                period,
                processIds: process?.join(),
                from: formatDateTimeIsoParam(fromDate),
                to: formatDateTimeIsoParam(toDate),
                tagsIds: tags?.join(),
                by
            },
        },
    };
};

export const fetchDashboardRobotProductivity = (filter = {}) => {
    const {
        selectedDurationValue: period, process, fromDate, toDate, tags, maxProcess
    } = filter;
    return {
        type: FETCH_DASHBOARD_ROBOT_PRODUCTIVITY,
        request: {
            url: `${URL_DASHBOARD}robot-productivity`,
            params: {
                period,
                processIds: process?.join(),
                from: formatDateTimeIsoParam(fromDate),
                to: formatDateTimeIsoParam(toDate),
                tagsIds: tags?.join(),
                maxProcess
            },
        },
    };
};

export const fetchDashboardProcessesActivity = (filter = {}) => {
    const {
        selectedDurationValue: period, process, fromDate, toDate, tags, maxProcess
    } = filter;
    return {
        type: FETCH_DASHBOARD_PROCESSES_ACTIVITY,
        request: {
            url: `${URL_DASHBOARD}process-activity`,
            params: {
                period,
                processIds: process?.join(),
                from: formatDateTimeIsoParam(fromDate),
                to: formatDateTimeIsoParam(toDate),
                tagsIds: tags?.join(),
                maxProcess
            },
        },
    };
};

export const fetchDashboardHandlingTime = (filter = {}) => {
    const {
        selectedDurationValue: period, process, fromDate, toDate, tags, maxProcess
    } = filter;
    return {
        type: FETCH_DASHBOARD_HANDLING_TIME,
        request: {
            url: `${URL_DASHBOARD}avg-handling-time`,
            params: {
                period,
                processIds: process?.join(),
                from: formatDateTimeIsoParam(fromDate),
                to: formatDateTimeIsoParam(toDate),
                tagsIds: tags?.join(),
                maxProcess
            },
        },
    };
};
