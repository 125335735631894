import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column", // Modifie l'orientation des lignes du formulaire en colonne
  },

  margin: {
    margin: theme.spacing(1),
  },
  actions: {
   paddingRight: 60,
   fontSize: "0.8rem"
  },

  hide: {
    visibility: "hidden"
  },
  show: {
  visibility: "visible"
  },
  cancel: {
    backgroundColor: "grey",
    color: "white",
  },

  timesaved: {
    display: "flex",
    marginTop: 20,
    gap: "20px",
  },

  timeSavedButton: {
    minWidth: 100,
    minHeight: 37,
  },

  timeLabel: {
    color: theme.palette.primary.main,
    fontSize: "1rem",
    fontWeight: 600,
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(2)
  },
  timeItem: {
    color: theme.palette.primary.main,
    fontSize: "1rem",
    fontWeight: 600,
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(2)
  },
  timeExecution: {
    color: theme.palette.primary.main,
    fontSize: "1rem",
    fontWeight: 600,
    marginBottom: theme.spacing(2),
    marginTop: -10
  },
  fte: {
    color: theme.palette.primary.main,
    fontSize: "1rem",
    fontWeight: 600,
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(1)
  },

  totalCost: {
    color: theme.palette.primary.main,
    fontSize: "2rem",
    fontWeight: 700,
  },

  totalCostIcon: {
    color: theme.custom.color.chartColor1,
    fontSize: "5rem",
    fontWeight: "bold",
    marginRight: theme.spacing(2.5),
  },

  AddCost: {
    backgroundColor: "darkblue",
    color: "white",
  },

  formGrid: {
    marginTop: "10px",
  },

  select: {
    width: "100%",
  },

  textField: {
    width: "100%",
  },

  costTextField: {
    margin: theme.spacing(0, 1),
    "& .MuiMenuItem-root": {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
  },

  costWeightField: {
    width: "100%",
    color: theme.custom.color.color6
  },

  custom_select: {
    width: "100%" /* ou toute autre valeur de largeur initiale */,
  },

  SelectProcessField: {
    width: "100%",
  },

  allocationAmount: {
    display: "flex",
    marginLeft: "auto",
    alignItems: "center",
  },

  sharedAllocation: {
    display: "flex",
    alignItems: "center",
  },

  shared: {
    marginTop: "0px",
  },

  sharedGrid: {
    display: "flex",
    alignItems: "center",
    columnGap: 5,
  },

  sharedGridAllocatedAmount: {
    display: "flex",
    alignItems: "center",
    columnGap: 5,
  },

  sharedGridWeight: {
    display: "flex",
    alignItems: "center",
    columnGap: 5,
    marginLeft: "0px",
  },

  selected: {
    border: `2px solid ${theme.custom.color.lightPurple}`,
    borderRadius: "50px",
    marginInline: 0,
    padding: "8px 20px",
  },

  noSelected: {
    border: `2px solid ${theme.custom.color.runningBackground}`,
    borderRadius: "50px",
    padding: "8px 20px",
  },

  listItem: {
    padding: "0",
  },

  table: {
    height: "50%",
    marginTop: 0,
  },

  list: {
    display: "inline",
  },

  Add: {
    marginLeft: theme.spacing(4),
  },

  container: {
    backgroundColor: "darkblue",
    color: "white",
  },

  pageHeaderContainer: {
    padding: theme.spacing(2, 3),
  },

  addCostButton: {
    padding: theme.spacing(2, 50),
  },

  date: {
    height: 30
  },

  endDate: {
    height: 30
  },
  title: {
    color: theme.palette.primary.main,
    fontSize: "1.5rem",
    fontWeight: 800,
  },
  subTitle: {
    color: theme.palette.primary.main,
    fontSize: "1rem",
  },
  parentContainer: {
    backgroundColor: theme.palette.background.default,
    paddingTop: 15,
    paddingLeft: 30,
    paddingRight: 30,
    paddingBottom: 20,
    margin: 20,
    marginTop: 0,
  },
  divider: {
    height: 220,
    width: 3,
    backgroundColor: theme.custom.color.runningBackground,
    margin: "auto 50px auto 0",
    opacity: 0.5,
  },
  costsTable: {
    boxShadow: "none",
    borderRadius: 0,
    border: `1px solid ${theme.custom.color.runningBackground}`,
    maxHeight: 500
  },
  costsTableHeaderCell: {
    paddingTop: "10px",
    paddingBottom: "10px",
    color: theme.palette.primary.main,
    fontWeight: "bold",
  },
  visibilityIcon: {
    color: theme.custom.color.chartColor1,
  },
  editIcon: {
    color: theme.palette.primary.main,
  },
  deleteIcon: {
    color: theme.custom.color.color6,
  },
  deleteBtnContainer: {
    marginTop: theme.spacing(2)
  },
  addButton: {
    padding: "5px 20px",
  },
  addButtonText: {
    textTransform: "capitalize",
    whiteSpace: "nowrap",
    fontSize: 14,
    fontWeight: 800,
    color: theme.palette.primary.main,
  },
  sharedCostContainer: {
    backgroundColor: theme.custom.color.containerBackground,
    paddingLeft: 20,
    height: "100%",
  },
  addButtonIcon: {
    marginRight: theme.spacing(-1),
    fontSize: 16,
  },
  input: {
    fontSize: "0.875rem",
    width: "100%"
  },
  dialogBody: {
    overflowY: "visible",
    marginBottom: 20
  },
  inputDate: {
    fontSize: "14px",
    width: "100px",
  },
  flexEnd: {
    alignItems: "flex-end",
  },
  popupBtnCancel: {
    marginBottom: "16px"
  },
  popupBtnSave: {
    marginBottom: "16px",
    marginRight: "16px"
  },
  sharedCostWrapper: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    marginTop: 10,
  },
  CostAllocationText: {
    fontWeight: "600",
    color: "dark-grey"
  },
  disabled: {
    color: "#ccc",
    fontWeight: "700"
  },
  enabled: {
    color: "#8f8f8f",
    fontWeight: "700"
  },
  amountTitle: {
    width: "170px"
  },
  customAutoCompleteInput: {
    padding: theme.spacing(0.5, 0),
  },
  autoComplete: {
    marginBottom: theme.spacing(2),
    width: "77%",
  },
  inputDateIcon: {
    cursor: "pointer"
  },
  sharedDeleteGrid: {
    height: 70,
    display: "flex",
    alignItems: "end"
  }
}));

export default useStyles;
