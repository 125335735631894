import React from "react";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import useStyle from "./style";

const CustomCloseButton = ({ ariaLabel, className, onClick }) => {
  const classes = useStyle();
  const combinedClasses = `${className} ${classes.customColor}`
  return (
    <IconButton
      aria-label={ariaLabel}
      className={combinedClasses}
      onClick={onClick}
    >
      <CloseIcon />
    </IconButton>
  ) };

export default CustomCloseButton;
