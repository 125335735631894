import React from "react";
import IconButton from "@material-ui/core/IconButton";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import CheckRoundedIcon from "@material-ui/icons/CheckRounded";
import { CopyToClipboard } from "react-copy-to-clipboard";

export default function CopyToClipboardButton({
  text,
  className,
}) {
  const [copied, setCopied] = React.useState(false);

  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  }

  return (
    <CopyToClipboard text={text} onCopy={handleCopy}>
      <IconButton
className={className}
onClick={(e) => {
        e.stopPropagation();
      }}>
        {copied ? (
          <CheckRoundedIcon fontSize="inherit" />
        ) : (
          <FileCopyIcon fontSize="inherit" />
        )}
      </IconButton>
    </CopyToClipboard>
  )
}
