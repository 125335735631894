import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import Typography from "@material-ui/core/Typography"
import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { formatDateByLanguage, formtNumberByLanguage } from "util";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { fetchOrchestrator } from "../../../../redux/actions/services";
import CustomCloseButton from "pages/Services/components/CustomCloseButton";
import useStyles from "./style";

function OrchestratorLicense() {
  const history = useHistory();
  const { idOrchestrator } = useParams();
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const onClose = () => {
    history.push("/robotAdministration/orchestrators");
  };
  const onSuccess = (result) => {
    setData(result?.data?.licenses);
    setIsLoaded(true);
  };
  const [data, setData] = React.useState([]);
  const [isLoaded, setIsLoaded] = React.useState(false);

  const getLicenseList = (idOrchestrator) => {
    setIsLoaded(false);
    dispatch(fetchOrchestrator(idOrchestrator, onSuccess));
  };

  React.useEffect(() => {
    getLicenseList(idOrchestrator);
  }, []);

  return (
    <Grid container>
      <Grid container xs={6} justify="flex-start">
        <Box mt={2} ml={2}>
          <Typography variant="h6">{t("Orchestrator licenses")}</Typography>
        </Box>
      </Grid>
      <Grid container xs={6} justify="flex-end">
        <CustomCloseButton aria-label="close" onClick={onClose} />
      </Grid>
      <Grid item xs={12} classes={classes.root}>
        <TableContainer>
          <Table stickyHeader classes={{ root: classes.rootGlobal }}>
            <TableHead>
              <TableRow>
                {headCells.map((row) => (
                  <TableCell>
                    <Typography variant="h3" className={classes.tableCellHead}>
                      {t(`orchestrator.license.${row.id}`)}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoaded
                && data?.map((row) => (
                  <TableRow>
                    <TableCell>
                      <Typography variant="h3" className={classes.tableCell}>
                        {formtNumberByLanguage(row.originalId)}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography className={classes.tableCell}>
                        {formatDateByLanguage(row.starts)}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography className={classes.tableCell}>
                        {formatDateByLanguage(row.expires)}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography className={classes.tableCell}>
                        {formatDateByLanguage(row.installedOn, true)}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography className={classes.tableCell}>
                        {formtNumberByLanguage(row.maxConcurrentSessions)}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography className={classes.tableCell}>
                        {t(`orchestrator.license.status.${row.status}`)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}

const headCells = [
  {
    id: "originalId",
    disablePadding: false,
    sortable: false,
  },
  {
    id: "starts",
    disablePadding: false,
    sortable: false,
  },
  {
    id: "expires",
    disablePadding: false,
    sortable: false,
  },
  {
    id: "installedOn",
    disablePadding: false,
    sortable: false,
  },
  {
    id: "maxConcurrentSessions",
    disablePadding: false,
    sortable: false,
  },
  {
    id: "status",
    disablePadding: false,
    sortable: false,
  },
];

export default OrchestratorLicense;
