import React from "react";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { Badge, IconButton, Tooltip } from "@material-ui/core";
import isEmpty from "lodash/isEmpty";
import ConfirmMessage from "components/ConfirmMessage";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchRobotList,
  editStatusRobot
} from "../../../../redux/actions/services";
import { useTranslation } from "react-i18next";
import { robotStatus } from "util";

function RobotStatusAction({
  selected,
  setSelected,
  items,
  classes
}) {
  const [openHidePopup, setopenHidePopup] = React.useState(false);
  const [openUnhidePopup, setopenUnhidePopup] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [selectedHidden, setSelectedHidden] = React.useState([])
  const [selectedUnhidden, setSelectedUnhidden] = React.useState([])
  const dispatch = useDispatch();
  const filter = useSelector(({ adminFilter }) => adminFilter);
  const { t } = useTranslation();

  const getNumberOfSelectedRobots = () => {
    const hide = [];
    const unhide = [];
    selected.forEach((s) => {
      const item = items.find((itm) => itm.id === s)
      if (item.isActive === robotStatus.ACTIVE) {
        hide.push(s);
      }
      if (item.isActive === robotStatus.DISABLE) {
        unhide.push(s);
      }
    })
    setSelectedUnhidden(unhide)
    setSelectedHidden(hide)
  }
  React.useEffect(() => {
    getNumberOfSelectedRobots();
  }, [selected.join()])

  const handleCloseConfirmationPopup = () => {
    setIsLoading(false);
    setopenHidePopup(false);
    setopenUnhidePopup(false);
  };
  const onClick = () => {
    if (openHidePopup) {
      robotCallback(editStatusRobot, "show");
    }
    if (openUnhidePopup) {
      robotCallback(editStatusRobot, "hide");
    }
  }

  const robotCallback = (callback, action) => {
    if (callback) {
      setIsLoading(true);
      dispatch(
        callback(action === "hide" ? selectedUnhidden : selectedHidden, () => {
          setIsLoading(false);
          dispatch(fetchRobotList({ pageNo: filter[filter.currModule].page, pageSize: filter[filter.currModule].rowsPerPage, showDeleted: filter[filter.currModule].showDeleted }));
          setSelected([]);
          handleCloseConfirmationPopup();
        })
      );
    }
  };

  const confirmationMsg = (status) => {
    if (status) return t("robot.tooltip.hide", { count: selectedHidden.length })
    return t("robot.tooltip.unhide", { count: selectedUnhidden.length })
  }

  return (
    <>
      {!isEmpty(selectedHidden) && (
        <Tooltip placement="top" title={confirmationMsg(true)}>
          <IconButton
            className={classes.hideIcon}
            aria-label="delete"
            disabled={isEmpty(selectedHidden)}
            onClick={() => {
              setopenHidePopup(true);
            }}
            size="small"
          >
            <Badge badgeContent={selectedHidden.length}>
              <VisibilityOffIcon className={classes.hideIcon} />
            </Badge>
          </IconButton>
        </Tooltip>
      )}
      {!isEmpty(selectedUnhidden) && (
        <Tooltip placement="top" title={confirmationMsg(false)}>
          <IconButton
            className={classes.showIcon}
            aria-label="delete"
            disabled={isEmpty(selectedUnhidden)}
            onClick={() => {
              setopenUnhidePopup(true);
            }}
            size="small"
          >
            <Badge badgeContent={selectedUnhidden.length}>
              <VisibilityIcon className={classes.showIcon} />
            </Badge>
          </IconButton>
        </Tooltip>
      )}
      <ConfirmMessage
        message={confirmationMsg(openHidePopup, openUnhidePopup)}
        openStart={openHidePopup || openUnhidePopup}
        onCancel={handleCloseConfirmationPopup}
        onConfirm={onClick}
        buttonConfirm={t("Accept")}
        buttonCancel={t("Cancel")}
        isLoading={isLoading}
      />
    </>
  );
}

export default RobotStatusAction;
