import {
  CardContent,
  CardHeader,
  Grid,
  List,
  ListItem,
  Typography,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import { colors } from "util";
import { LINE_CHART_TOOLTIP_OPTIONS } from "../../../../Dashboard/dashboardUtils";

function ExceptionsTypes({
  classes, data, t, width, height, legendOpts, formatExceptionGraphNumbers, getExceptionPercentage, layout
}) {
  const [kpi, setKpi] = useState({});
  const [legend, setLegend] = useState();
  const pricipalLabels = ["Business Exception", "Internal", "System Exception", "Unspecified"];

  const formatData = () => {
    if (data?.labels) {
      const indexToCumul = [];
      data.labels = data?.labels.map((label, index) => {
        if (label === "exception.analytics.typeNotFound") {
          return t(label);
        }
        if (!pricipalLabels.includes(label)) {
          indexToCumul.push(index);
          return t("Others");
        }
        return label;
      });
      let sum = 0;
      data.values = data?.values.filter((value, index) => {
        if (indexToCumul.includes(index)) {
          sum += value;
        }
        return !indexToCumul.includes(index);
      });
      data.values.push(sum);
      data.labels = [...new Set(data.labels)];
    }
    const labels = data.labels ? data.labels : [];
    const datasets = [
      {
        label: labels,
        data: data.values?.filter((l) => l !== 0),
        backgroundColor: colors,
        borderWidth: 1,
      },
    ];
    return {
      labels,
      datasets,
    };
  };

  useEffect(() => {
    setKpi(formatData());
  }, [data]);

  const onRefChange = React.useCallback((node) => {
    if (node !== null) {
      setLegend(node.chartInstance.generateLegend());
    }
  }, [kpi]);

  return (
    <div className={classes.container}>
      <CardHeader
        title={t("exception.analytics.by.type")}
        titleTypographyProps={{
          variant: "h3",
          style: { fontWeight: "bold" },
        }}
        classes={{
          action: classes.select,
        }}
      />

      <CardContent className={classes.cardContent}>
        <Grid
          item
          container
          alignItems="center"
          justify="center"
          direction="column"
          spacing={1}
          style={{ height: "calc(100% - 50px)" }}
        >
          <Grid item xs className={classes.graphContainer}>
            <Bar
              ref={onRefChange}
              width={width}
              height={height}
              data={kpi}
              options={{
                legend: legendOpts,
                layout,
                legendCallback: () => (
                  <List
                      className={classes.chartLegend}
                      style={{ paddingLeft: "8px" }}
                    >
                    {kpi?.labels
                        && kpi?.labels.map((label, index) => (
                          <ListItem
                            key={`legend-item-exception-type-${index}`}
                            className={classes.noPadding}
                          >
                            <div>
                              <span
                                style={{
                                  backgroundColor:
                                    kpi.datasets[0].backgroundColor[
                                      index
                                    ],
                                  width: "10px",
                                  height: "10px",
                                  borderRadius: "50%",
                                  position: "absolute",
                                  left: 0,
                                  top: 0,
                                }}
                              />
                              {" "}
                              &nbsp;&nbsp;
                              <Typography
                                component="span"
                                className={classes.chartItemLegendItem}
                                style={{
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis",
                                  maxWidth: `${width * 1.33}px`,
                                  position: "absolute",
                                  left: 20,
                                  top: "-4px",
                                }}
                              >
                                {kpi?.labels && t(kpi?.labels[index])}
                              </Typography>
                            </div>
                          </ListItem>
                        ))}
                  </List>
                  ),
                tooltips: {
                  mode: "x-axis",
                  callbacks: {
                    label(tooltipItem) {
                      return `${formatExceptionGraphNumbers(tooltipItem, kpi)} - ${getExceptionPercentage(tooltipItem, kpi)}`;
                    },
                  },
                  ...LINE_CHART_TOOLTIP_OPTIONS
                },
                scales: {
                  xAxes: [
                    {
                      gridLines: {
                        display: false,
                      },
                      display: false,
                      stacked: false,
                    },
                  ],
                  yAxes: [
                    {
                      gridLines: {
                        display: false,
                      },
                      display: true,
                      stacked: false,
                    },
                  ],
                },
              }}
            />
            <div className={classes.legendContainer}>{legend}</div>
          </Grid>
        </Grid>
      </CardContent>
    </div>
  );
}

export default ExceptionsTypes;
