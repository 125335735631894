import React, { useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
 Card, Grid, Paper, Typography
} from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import { useTranslation } from "react-i18next";
import useStyles from "./style.js";
import clsx from "clsx";
import { getStatusBGColor } from "../../../util";
import { StatusCircle } from "../../../components/StatusCircle";
import SearchField from "../../../components/EmptyDropSection/SearchField";
import { isEmpty } from "lodash";
import EmptyDropSection from "../../../components/EmptyDropSection";
import DataNotFound from "../../../components/DataNotFound";

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

/**
 * Moves an item from one list to another list.
 */
const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  display: "flex",
  alignItems: "center",
  padding: ` ${grid}px ${grid * 2}px`,
  margin: `0 0 ${grid}px 0`,
  boxShadow:
    "0px 1px 2px 0px rgb(172,186,255)",
  fontWeight: 400,
  fontSize: 14,
  borderRadius: "2px",
  // change background colour if dragging
  background: "#FFFFFF",

  // styles we need to apply on draggables
  ...draggableStyle,
});

const RobotSelector = ({
  availableRobots,
  selectedRobots,
  isDisabled,
  setAvailableRobots,
  setSelectedRobots,
  onAssignRobot,
  onUnAssignRobot,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [availableSearchTerm, setAvailableSearchTerm] = useState("")
  const [assignedSearchTerm, setAssignedSearchTerm] = useState("")

  const getList = (id) => (id === "droppable" ? availableRobots : selectedRobots);

  const validateDragDrop = (
    source,
    destination,
    droppableSource,
    droppableDestination
  ) => {
    const sourceClone = Array.from(source);
    const [removed] = sourceClone.splice(droppableSource.index, 1);
    if (droppableDestination.droppableId === "droppable") {
      if (onUnAssignRobot) return onUnAssignRobot(removed);
    } else if (onAssignRobot) return onAssignRobot(removed);
    return true;
  };

  const onDragEnd = async (result) => {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      const items = reorder(
        getList(source.droppableId),
        source.index,
        destination.index
      );

      // let state = { items };
      if (source.droppableId === "droppable2") {
        setSelectedRobots(items);
      } else {
        setAvailableRobots(items);
      }
    } else {
      const result = move(
        getList(source.droppableId),
        getList(destination.droppableId),
        source,
        destination
      );
      setAvailableRobots(result.droppable);
      setSelectedRobots(result.droppable2);

      const res = await validateDragDrop(
        getList(source.droppableId),
        getList(destination.droppableId),
        source,
        destination
      );
      if (!res) {
        setSelectedRobots(getList(source.droppableId));
        setAvailableRobots(getList(destination.droppableId));
      }
    }
  };
  const isBothEmpty = isEmpty(selectedRobots) && isEmpty(availableRobots);
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Grid item container xs={12}>
        <Grid
          item
          container
          className={clsx(classes.dropabbleTitle, classes.robotContainer)}
        >
          <Typography className={classes.droppableTitle}>{t("robot-selector.available-robots")}</Typography>
        </Grid>
        <Grid item className={classes.robotSeparator} />
        <Grid
          item
          container
          className={clsx(classes.dropabbleTitle, classes.robotContainer)}
        >
          <Typography className={classes.droppableTitle}>{t("robot-selector.assigned-robots")}</Typography>
        </Grid>
        <Grid item container xs={12}>
          <SearchField
              searchTerm={availableSearchTerm}
              setSearchTerm={setAvailableSearchTerm}
              placeholder={t("search.available.robots")}
              disabled={isEmpty(availableRobots)}
              justify="flex-start"
          />
          <SearchField
              searchTerm={assignedSearchTerm}
              setSearchTerm={setAssignedSearchTerm}
              placeholder={t("search.assigned.robots")}
              disabled={isEmpty(selectedRobots)}
              justify="flex-end"
          />
        </Grid>
      </Grid>
      <Grid item container xs={12}>
        <Grid item className={classes.robotContainer}>
          <Card className={classes.card}>
            <Droppable droppableId="droppable" className={classes.droppableContainer}>
              {(provided) => (
                <div
                  ref={provided.innerRef}
                  className={classes.droppableContainer}
                >
                  {/* eslint-disable-next-line no-nested-ternary */}
                  {isBothEmpty ? <DataNotFound iconHeight={40} />
                      : isEmpty(availableRobots)
                          ? <EmptyDropSection />
                  : availableRobots?.map((item, i) => ({ ...item, i })).filter((item) => (availableSearchTerm.trim() !== "" ? item?.robotDisplayName?.toLowerCase()?.includes(availableSearchTerm.trim()) : true))?.map((item) => (
                    <Draggable
                      key={`available-robot-draggable-${item.id}`}
                      draggableId={`available-robot-draggable-${item.id}`}
                      index={item.i}
                      isDragDisabled={isDisabled}
                    >
                      {(provided, snapshot) => (
                        <Paper
                          style={{ background: "none", boxShadow: "none" }}
                        >
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                          >
                            <Tooltip title={t((item?.displayStatus) || "OFFLINE")}>
                              <Grid item>
                                <StatusCircle
                                  customSpace={classes.statusProps}
                                  statusBGColor={getStatusBGColor(
                                    item?.displayStatus
                                  )}
                                />
                              </Grid>
                            </Tooltip>
                            <span>{item.robotDisplayName}</span>
                          </div>
                        </Paper>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </Card>
        </Grid>
        <Grid item className={classes.robotSeparator} />
        <Grid
          item
          container
          justify="flex-end"
          className={classes.robotContainer}
        >
          <Card style={{ width: "100%" }}>
            <Droppable droppableId="droppable2">
              {(provided) => (
                <div
                  ref={provided.innerRef}
                  className={classes.droppableContainer}
                >
                  {/* eslint-disable-next-line no-nested-ternary */}
                  {isBothEmpty ? <DataNotFound iconHeight={40} />
                      : isEmpty(selectedRobots)
                          ? <EmptyDropSection />
                      : (
                    selectedRobots?.map((item, i) => ({ ...item, i })).filter((item) => (assignedSearchTerm.trim() !== "" ? item?.robotDisplayName?.toLowerCase()?.includes(assignedSearchTerm.trim()) : true))?.map((item) => (
                      <Draggable
                      key={`selected-robot-draggable-${item.id}`}
                      draggableId={`selected-robot-draggable-${item.id}`}
                      index={item.i}
                      isDragDisabled={isDisabled}
                    >
                        {(provided, snapshot) => (
                          <Paper
                          style={{ background: "none", boxShadow: "none" }}
                        >
                            <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                          >
                              <Tooltip title={t((item?.displayStatus) || "OFFLINE")}>
                                <Grid item>
                                  <StatusCircle
                                  customSpace={classes.statusProps}
                                  statusBGColor={getStatusBGColor(
                                    item?.displayStatus
                                  )}
                                  />
                                </Grid>
                              </Tooltip>
                              {item.robotDisplayName}
                            </div>
                          </Paper>
                      )}
                      </Draggable>
                  )))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </Card>
        </Grid>
      </Grid>
    </DragDropContext>
  );
};

export default RobotSelector;
