/* eslint-disable block-scoped-var */
/* eslint-disable no-var */
/* eslint-disable vars-on-top */
import Box from "@material-ui/core/Box";
import Checkbox from "@material-ui/core/Checkbox";
import Chip from "@material-ui/core/Chip";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import React, { useEffect, useState } from "react";
import CalendarToday from "@material-ui/icons/CalendarToday";
import ReplayRoundedIcon from "@material-ui/icons/ReplayRounded";
import isNull from "lodash/isNull";
import isUndefined from "lodash/isUndefined";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import clsx from "clsx";
import {
  formtNumberByLanguage,
  fromatInputOnBlure,
  fromatInputOnFocus,
  handleInputChanges,
} from "../../../../util";
import {
  fetchProcessRestriction,
  checkIfPrioriryAlreadyExists,
  saveProcessRestriction,
  fetchProcessExecutionByProcess,
  updateprocessdescription,
  refreshQueuesFromDB,
  updateProcessRobots,
  fetchUnavailablePriorities
} from "../../../../redux/actions/services";
import EstimationComputing from "../Process/components/ExecutionTimeComputer";
import RobotSelector from "../../../Administration/RobotSelector";
import SlaItemTitle from "./SlaItemTitle";
import CustomAutoComplete, { CustomTextField } from "../../../../components/FormFields/CustomAutoComplete";
import CustomSelectField from "../../../../components/FormFields/CustomSelectField";
import CircularLoader from "../../../../components/Loaders/CircularLoader";
import { disabledFunctionality } from "../../../../util/constants/PermitedFeature";
import { range } from "lodash";
import CustomButton from "../../../../components/CustomButton";
import Tooltip from "@material-ui/core/Tooltip";
import ButtonBase from "@material-ui/core/ButtonBase";
import ErrorOutlineRoundedIcon from "@material-ui/icons/ErrorOutlineRounded";
import useStyles from "../ModalService/style";

const DEFAULT_FROM_TIME = "00:00";
const DEFAULT_TO_TIME = "23:55";
const DEFAULT_ALLOWED_SESSIONS = 1;
const POLICY_LIST = ["KEEP", "DELETE"];

const SlaInfo = ({
  _process,
  availableRobots,
  setAvailableRobots,
  assignedRobots,
  setAssignedRobots,
  setProcess
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation();
  const [defaultIsSet, setDefaultIsSet] = useState(false);
  const [checked, setChecked] = useState(false);
  const [processExecution, setProcessExecution] = useState("");
  const [checkedhours, setCheckedHours] = useState(false);
  const [openEstimatedTimeComputingPopUp, setOpenEstimatedTimeComputingPopUp] = useState(false);
  const [processRestrictions, setProcessRestrictions] = useState(null);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [loading, setLoading] = useState(true);

  const priorityArray = range(1, 100).map((i) => ({ id: i, label: i }))

  const [availablePriorities, setAvailablePriorities] = React.useState(priorityArray);

  const processDescription = _process?.processDescription;
  useEffect(() => {
    dispatch(fetchProcessRestriction(_process?.id)).then((res) => {
      setProcessRestrictions(res?.data || null);
    });
    dispatch(fetchUnavailablePriorities(_process.id)).then((result) => {
      setAvailablePriorities(priorityArray.filter((p) => !result?.data.includes(Number(p.id))));
    })
  }, [_process]);
  useEffect(() => {
    dispatch(
      fetchProcessExecutionByProcess(
        _process?.id,
        0,
        1,
        "executionEndTime",
        "desc",
        [],
      ),
    ).then((res) => {
      setProcessExecution(res?.data);
      setLoading(false);
      // dispatch(fetchRobotsByOrchestrator(res?.data?.list[0]?.process.orchestrator?.id));
      // setAssignedRobots(res?.data?.list[0]?.process?.robots)
    });
  }, [_process]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    reset,
    setError,
    clearErrors,
    control,
    trigger,
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      priority: null,
      from: DEFAULT_FROM_TIME,
      to: DEFAULT_TO_TIME,
      estimationExecutaion: null,
      allowedSession: DEFAULT_ALLOWED_SESSIONS,
      frequency: null,
      occurence: null,
      frequencyOcurrenceType: null,
      missedExecutionsPolicy: null,
      missedExecutionsTime: null,
    },
  });

  const timeType = [
    { id: 1, label: t("Days"), value: "Days" },
    { id: 2, label: t("Hours"), value: "Hours" },
    { id: 3, label: t("Minutes"), value: "Minutes" },
    { id: 4, label: t("Seconds"), value: "Secondes" },
  ];

  const [days, setDays] = React.useState([
    {
      id: 1, label: t("Mon"), value: "1", checked: true,
    },
    {
      id: 2, label: t("Tue"), value: "2", checked: true,
    },
    {
      id: 3, label: t("Wed"), value: "3", checked: true,
    },
    {
      id: 4, label: t("Thu"), value: "4", checked: true,
    },
    {
      id: 5, label: t("Fri"), value: "5", checked: true,
    },
    {
      id: 6, label: t("Sat"), value: "6", checked: true,
    },
    {
      id: 7, label: t("Sun"), value: "7", checked: true,
    },
  ]);
  const updateDays = (data) => {
    if (data) {
      data = data.split(",");
      const newDays = days.map((day) => ({ ...day, checked: data.includes(day.value) }));
      setDays(newDays);
    }
  };

  useEffect(() => {
    if (processRestrictions && processExecution) {
      setValue(
        "priority",
        processRestrictions.priority
          ? processRestrictions.priority.toString()
          : null,
      );

      const from = processRestrictions.fromTime ?? DEFAULT_FROM_TIME;
      setValue("from", from);
      const to = processRestrictions.toTime ?? DEFAULT_TO_TIME;
      setValue("to", to);
      if (
        from.startsWith(DEFAULT_FROM_TIME)
        && to.startsWith(DEFAULT_TO_TIME)
      ) {
        setCheckedHours(true);
      }

      var day = 86400;
      var hour = 3600;
      var minute = 60;
      var totalseconds;
      if (
        !processRestrictions.executionEstimation
        && !defaultIsSet
        && processExecution.list[0]
      ) {
        totalseconds = processExecution.list[0].executionDuration;
        setDefaultIsSet(true);
        processRestrictions.executionEstimation = null;
      } else {
        totalseconds = processRestrictions.executionEstimation;
      }

      var daysout = Math.floor(totalseconds / day);
      var hoursout = Math.floor((totalseconds - daysout * day) / hour);
      var minutesout = Math.floor(
        (totalseconds - daysout * day - hoursout * hour) / minute,
      );
      var secondsout = totalseconds - daysout * day - hoursout * hour - minutesout * minute;
      setValue(
        "estimationExecution.days",
        processRestrictions.executionEstimation && daysout,
      );
      setValue(
        "estimationExecution.hours",
        processRestrictions.executionEstimation && hoursout,
      );
      setValue(
        "estimationExecution.minutes",
        processRestrictions.executionEstimation && minutesout,
      );
      setValue(
        "estimationExecution.seconds",
        processRestrictions.executionEstimation && secondsout,
      );
      setValue(
        "allowedSession",
        processRestrictions.allowedSession
          ? formtNumberByLanguage(processRestrictions.allowedSession)
          : DEFAULT_ALLOWED_SESSIONS,
      );
      setValue(
        "frequency",
        processRestrictions.frequency
          ? formtNumberByLanguage(processRestrictions.frequency)
          : null,
      );
      setValue(
        "occurence",
        processRestrictions.occurence
          ? formtNumberByLanguage(processRestrictions.occurence)
          : null,
      );
      setValue(
        "frequencyOcurrenceType",
        processRestrictions.frequencyType || processRestrictions.occurenceType
          ? processRestrictions.frequencyType
          || processRestrictions.occurenceType
          : null,
      );
      setValue(
        "missedExecutionsPolicy",
        processDescription?.missedExecutionsTime ? "KEEP" : "DELETE"
      );
      setValue(
        "missedExecutionsTime",
        processDescription?.missedExecutionsTime
          ? processDescription?.missedExecutionsTime : 0
      );

      if (processRestrictions.restrictionDays) {
        updateDays(processRestrictions.restrictionDays);
        if (processRestrictions.restrictionDays.length === 13) setChecked(true);
      } else {
        const newarray = days.map((e) => ({ ...e, checked: false }));
        setDays(newarray);
        setChecked(false);
      }
    } else if (!processRestrictions && processExecution) {
      const from = DEFAULT_FROM_TIME;
      setValue("from", from);
      const to = DEFAULT_TO_TIME;
      setValue("to", to);
      if (
        from.startsWith(DEFAULT_FROM_TIME)
        && to.startsWith(DEFAULT_TO_TIME)
      ) {
        setCheckedHours(true);
      }
      setValue("allowedSession", DEFAULT_ALLOWED_SESSIONS);

      totalseconds = processExecution.list[0]?.executionDuration;
      setDefaultIsSet(true);

      daysout = Math.floor(totalseconds / day);
      hoursout = Math.floor((totalseconds - daysout * day) / hour);
      minutesout = Math.floor(
        (totalseconds - daysout * day - hoursout * hour) / minute,
      );
      secondsout = totalseconds - daysout * day - hoursout * hour - minutesout * minute;
      setValue("estimationExecution.days", daysout);
      setValue("estimationExecution.hours", hoursout);
      setValue("estimationExecution.minutes", minutesout);
      setValue("estimationExecution.seconds", secondsout);
      const newarray = days.map((e) => ({ ...e, checked: true }));
      setDays(newarray);
      setChecked(true);
    }
  }, [processRestrictions, processExecution]);

  const handleClick = (val) => {
    const newDays = [...days];
    const day = newDays.find((e) => e.id === val);
    day.checked = !day.checked;
    setDays(newDays);
    const checkedDays = days.filter((day) => day.checked);
    setChecked(checkedDays.length === 7);
  };

  const timeSlots = Array.from(new Array(11 * 2))
    .map(
      (_, index) => `${index >= 2 ? `${Math.floor(index / 2)}h` : ""}${index % 2 === 0 ? "00min" : "30min"
        }`,
    )
    .splice(1);

  timeSlots.pop();

  const handleChange = (event) => {
    const newarray = days.map((e) => ({ ...e, checked: event.target.checked }));
    setDays(newarray);
    setChecked(event.target.checked);
  };

  const handleChangeHours = (event) => {
    if (event.target.checked) {
      setValue("from", DEFAULT_FROM_TIME);
      setValue("to", DEFAULT_TO_TIME);
    }
    setCheckedHours(event.target.checked);
  };

  const validateTo = () => {
    if (getValues("to") <= getValues("from")) {
      setError("to", {
        type: "custom",
        message: t("restriction.form.to.error") + getValues("from"),
      });
    } else clearErrors("to");
  };

  const handleReset = () => {
    reset();
    setValue("estimationExecution.days", null)
    setValue("estimationExecution.hours", null)
    setValue("estimationExecution.minutes", null)
    setValue("estimationExecution.seconds", null)
    const newarray = days.map((e) => ({ ...e, checked: false }));
    setDays(newarray);
    setChecked(false);
    setCheckedHours(false);
    setDefaultIsSet(false);
  };

  const joinDays = (days) => days
    .filter((d) => d.checked)
    .map((d) => d.value)
    .join(",");

  const estimationDateToSeconds = (estimatedExecution) => (
    Number(estimatedExecution.days) * 24 * 3600
    + Number(estimatedExecution.hours) * 3600
    + Number(estimatedExecution.minutes) * 60
    + Number(estimatedExecution.seconds)
  );

  const onSubmit = (data) => {
    const {
      days: estimationDays, hours, minutes, seconds
    } = data.estimationExecution;
    const estimation = ((!estimationDays && typeof estimationDays !== "number") && (!hours && typeof hours !== "number") && (!minutes && typeof minutes !== "number") && (!seconds && typeof seconds !== "number"))
        ? null : estimationDateToSeconds(data.estimationExecution)
    setConfirmLoading(true);
    const dataToSend = {
      from: moment(data.from, "HH:mm").format("HH:mm:ss"),
      to: moment(data.to, "HH:mm").format("HH:mm:ss"),
      restrictionDays: joinDays(days),
      priority: parseInt(data?.priority),
      executionEstimation: estimation,
      allowedSession: parseInt(data?.allowedSession),
      frequency: parseInt(data?.frequency),
      frequencyType: data?.frequencyOcurrenceType,
      occurence: data?.occurence,
      occurenceType: data?.frequencyOcurrenceType,
    };
    const policyUpdated = processDescription.missedExecutionsTime !== data?.missedExecutionsTime;
    if (policyUpdated) {
      processDescription.missedExecutionsTime = data?.missedExecutionsTime;
    }

    dispatch(
      checkIfPrioriryAlreadyExists(_process?.id, parseInt(data?.priority)),
    ).then((res) => {
      // Si le priorité n'existe pas
      if (res?.data === false) {
        Promise.all([
          dispatch(saveProcessRestriction(_process?.id, dataToSend)).then(
            () => {
              if (policyUpdated) {
                dispatch(
                  updateprocessdescription(_process?.id, processDescription)
                ).then(() => {
                  dispatch(refreshQueuesFromDB());
                });
              }
            }
          ),
          dispatch(
            updateProcessRobots(
              _process?.id,
              assignedRobots.map((ar) => ar.id).join(",")
            )
          ).then(() => {
            setProcess((prev) => ({ ...prev, robots: assignedRobots }))
          })
        ])
          .then(([resOfCall1, resOfCall2]) => {
            setConfirmLoading(false);
            if (resOfCall1?.error || resOfCall2?.error) {
              toast.error(t("sla.update.error"))
            } else {
              toast.success(t("sla.update.success"))
            }
          })
      } else {
        setError("priority", {
          type: "custom",
          message: t("priority.error"),
        });
      }
    });
  };

  const isFieldFull = (fieldKey) => !(isUndefined(watch(fieldKey)) || isNull(watch(fieldKey)))

  const createPrioriy = (priority) => ({ id: +priority, label: +priority })

  const onError = () => errors[Object.keys(errors)[0]]?.ref?.focus

  const periods = ["days", "hours", "minutes", "seconds"];
  const minVal = 0;
  let maxVal = 5;
  const estimatedExecutionField = (period) => {
    let maxValMessage = "";
    if (period === "days") {
      maxVal = Number.POSITIVE_INFINITY;
    } else if (period === "seconds" || period === "minutes") {
      maxVal = 59;
      maxValMessage = "restriction.form.min.sec.maxValue.error";
    } else if (period === "hours") {
      maxVal = 23;
      maxValMessage = "restriction.form.hour.maxValue.error";
    }
    return (
      <Grid item xs={5}>
        <CustomTextField
          {...register(`estimationExecution.${period}`, {
            pattern: {
              value: /^[0-9]*$/,
              message: t("restriction.form.numberInteger.error"),
            },
            min: {
              value: minVal,
              message: t("restriction.form.minValue.error"),
            },
            max: {
              value: maxVal,
              message: t(maxValMessage),
            },
          })}
          key={errors.estimationExecution?.[period]?.message}
          value={getValues(`estimationExecution.${period}`)}
          label={t(period.charAt(0).toUpperCase() + period.slice(1))}
          InputLabelProps={{
            shrink: isFieldFull(`estimationExecution.${period}`),
          }}
          error={!!errors.estimationExecution?.[period]}
          helperText={
            errors.estimationExecution?.[period]
            && errors.estimationExecution?.[period]?.message
          }
          onKeyUp={() => trigger(`estimationExecution.${period}`)}
          type="number"
          fullWidth
        />
      </Grid>
    );
  };

  return (
    <Grid container className={classes.slaContainer}>
      {loading ? (
        <CircularLoader />
          ) : (
            <form className={classes.root} onSubmit={handleSubmit(onSubmit, onError)}>
              <Grid item container xs={12} className={classes.rootContainer}>
                <Grid item container>
                  <Grid item xs={12} className={classes.slaSectionContainer}>
                    <SlaItemTitle title={t("Robots")} info={t("Robots")} />
                    <RobotSelector
                        availableRobots={availableRobots}
                        selectedRobots={assignedRobots}
                        setAvailableRobots={setAvailableRobots}
                        setSelectedRobots={setAssignedRobots}
                        isCustom
                    />
                  </Grid>
                  <Grid item xs={12} className={classes.stateContainer}>
                    <Grid item className={classes.slaSectionContainer}>
                      <SlaItemTitle title="restriction.form.estimatedExecutionDuration" info="restriction.userGuide.estimatedExecutionTime" />
                      <Box component={Grid} item xs={12} container justify="space-between" gridRowGap={10} mt={1}>
                        {periods.map((period) => estimatedExecutionField(period))}
                      </Box>
                      <Box mt={3}>
                        <CustomButton
                            view="primary"
                            onClick={() => setOpenEstimatedTimeComputingPopUp(true)}
                            size="small"
                        >
                          {t("Update using historical data")}
                        </CustomButton>
                      </Box>
                    </Grid>
                    {!disabledFunctionality && (
                    <Grid item className={classes.slaSectionContainer}>
                      <SlaItemTitle title="restriction.form.MinimumExecutionFrequency" info="restriction.userguide.frequencyOccurence" />
                      <Box component={Grid} item xs={12} container justify="space-between" gridRowGap={10} mt={1}>
                        <CustomTextField
                                {...register("frequency", {
                                  pattern: {
                                    value: /^[0-9]*$/,
                                    message: t("restriction.form.numberInteger.error"),
                                  },
                                })}
                                value={getValues("frequency")}
                                InputProps={{ inputProps: { min: minVal } }}
                                InputLabelProps={{
                                  shrink: isFieldFull("frequency"),
                                }}
                                label={t("restriction.form.frequency")}
                                fullWidth
                                error={!!errors.frequency}
                                helperText={errors.frequency && errors.frequency.message}
                                type="text"
                                onInput={(e) => handleInputChanges(e, false)}
                                onFocus={(e) => fromatInputOnFocus(e)}
                                onBlur={(e) => fromatInputOnBlure(e)}
                            />
                        <CustomTextField
                                {...register("occurence", {
                                  pattern: {
                                    value: /^[0-9]*$/,
                                    message: t("restriction.form.numberInteger.error"),
                                  },
                                })}
                                value={getValues("occurence")}
                                fullWidth
                                InputProps={{ inputProps: { min: minVal } }}
                                InputLabelProps={{
                                  shrink: isFieldFull("occurence"),
                                }}
                                label={t("restriction.form.occurence")}
                                error={!!errors.occurence}
                                helperText={errors.occurence && errors.occurence.message}
                                type="text"
                                onInput={(e) => handleInputChanges(e, false)}
                                onFocus={(e) => fromatInputOnFocus(e)}
                                onBlur={(e) => fromatInputOnBlure(e)}
                            />
                        <CustomSelectField
                                options={timeType}
                                optionLabel="label"
                                optionValue="value"
                                {...register("frequencyOcurrenceType")}
                                value={getValues("frequencyOcurrenceType")}
                                onChange={(event) => {
                                  clearErrors("frequencyOcurrenceType");
                                  setValue("frequencyOcurrenceType", event.target.value);
                                }}
                                InputLabelProps={{
                                  shrink: isFieldFull("frequencyOcurrenceType"),
                                }}
                                variant="standard"
                                label={t("Temps")}
                                labelClassName={classes.inputLabel}
                                error={!!errors.frequencyOcurrenceType}
                                helperText={errors.frequencyOcurrenceType && errors.frequencyOcurrenceType.message}
                                isCustom
                            />
                      </Box>
                    </Grid>
                    )}
                    <Box component={Grid} item className={classes.slaSectionContainer} display="flex" flexDirection="column" justifyContent="space-between">
                      <SlaItemTitle title="restriction.form.allowedSession" info="restriction.userGuide.allowedSession" />
                      <Box component={Grid} item xs={12} container justify="space-between" gridRowGap={10} mt={1} maxHeight={60}>
                        <CustomTextField
                            {...register("allowedSession", {
                              pattern: {
                                value: /^[0-9]*$/,
                                message: t("restriction.form.numberInteger.error"),
                              },
                            })}
                            value={getValues("allowedSession")}
                            fullWidth
                            InputProps={{ inputProps: { min: minVal } }}
                            InputLabelProps={{
                              shrink: isFieldFull("allowedSession"),
                            }}
                            label={t("orchestrator.management.formControl.sessionNumber")}
                            error={!!errors.allowedSession}
                            helperText={errors.allowedSession && errors.allowedSession.message}
                            type="text"
                            onInput={(e) => handleInputChanges(e, false)}
                            onFocus={(e) => fromatInputOnFocus(e)}
                            onBlur={(e) => fromatInputOnBlure(e)}
                        />
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
                <Grid item container>
                  <Grid item xs={12} className={classes.slaSectionContainer}>
                    <SlaItemTitle title="restriction.form.priority" info="restriction.userGuide.priority" />
                    <Box component={Grid} item xs={12} container justify="space-between" gridRowGap={10} mt={1}>
                      <Controller
                          {...register("priority")}
                          key={getValues("priority")}
                          control={control}
                          rules={{
                            required: t("restriction.form.priority.required.error"),
                            pattern: {
                              value: /^[0-9]*$/,
                              message: t("restriction.form.numberInteger.error"),
                            },
                            min: {
                              value: 0,
                              message: t("restriction.form.priority.minValue.error"),
                            },
                            max: {
                              value: 100,
                              message: t("restriction.form.priority.maxValue.error"),
                            },
                          }}
                          render={({ field }) => (
                            <CustomAutoComplete
                                  options={availablePriorities || []}
                                  value={createPrioriy(field?.value)}
                                  onChange={(newValue) => {
                                    field?.onChange(newValue?.id)
                                    setValue("priority", newValue?.id)
                                  }}
                                  optionLabel="label"
                                  label={t("restriction.form.priority.label")}
                                  disabled={_process.isDisabled}
                                  error={!!errors.priority}
                                  helperText={errors.priority && errors.priority.message}
                                  inputRef={field?.ref}
                                  fullWidth
                              />
                          )}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} className={clsx(classes.slaSectionContainer, !_process.queueDto && classes.queueContainerSpace)}>
                    <SlaItemTitle title="restriction.form.executionTimeLine" info="restriction.userGuide.executionTimeLine" />
                    <Grid item xs={12} className={classes.daysContainer}>
                      <Typography className={classes.executionTimeTitle}>{t("restriction.form.executionDay")}</Typography>
                      <Box display="flex" justifyContent="space-between">
                        {days.map((day) => (
                          <Chip
                                icon={(
                                  <CalendarToday
                                        fontSize="small"
                                        className={classes.chipIcon}
                                    />
                                )}
                                color={day.checked ? "secondary" : "default"}
                                label={day.label}
                                onClick={() => handleClick(day.id)}
                                className={classes.chip}
                            />
                        ))}
                      </Box>
                      <FormControlLabel
                          className={classes.checkboxContainer}
                          classes={{ label: classes.executionTimeLabel }}
                          control={(
                            <Checkbox
                                  checked={checked}
                                  onChange={handleChange}
                                  name="antoine"
                                  classes={{
                                    root: classes.checkboxRoot,
                                    checked: classes.checkboxChecked
                                  }}
                                  className={classes.checkboxNoPadding}
                              />
                          )}
                          label={t("restriction.form.allDays")}
                      />
                    </Grid>
                    <Grid item xs={12} className={classes.daysContainer}>
                      <Typography className={classes.executionTimeTitle}>{t("restriction.form.executionTime")}</Typography>
                      <Box display="flex" justifyContent="space-between" width="fit-content" gridColumnGap={25}>
                        <CustomTextField
                            {...register("from")}
                            label={t("restriction.form.from")}
                            disabled={checkedhours}
                            type="time"
                            inputProps={{
                              step: 300,
                              className: classes.executionTimeInput,
                            }}
                            customColor={checkedhours ? "custom.color.chartColor1" : "custom.color.chartColor2"}
                            valued={!checkedhours}
                        />
                        <CustomTextField
                            {...register("to", {
                              validate: (value) => value >= getValues("from"),
                            })}
                            label={t("restriction.form.to")}
                            type="time"
                            inputProps={{
                              step: 300,
                              className: classes.executionTimeInput
                            }}
                            customColor={checkedhours ? "custom.color.chartColor1" : "custom.color.chartColor2"}
                            disabled={checkedhours}
                            onBlur={validateTo}
                            error={!!errors.to}
                            valued={!checkedhours}
                        />
                      </Box>
                      <FormControlLabel
                          className={classes.checkboxContainer}
                          classes={{ label: classes.executionTimeLabel }}
                          control={(
                            <Checkbox
                                  checked={checkedhours}
                                  onChange={handleChangeHours}
                                  name="antoine"
                                  classes={{
                                    root: classes.checkboxRoot,
                                    checked: classes.checkboxChecked
                                  }}
                                  className={classes.checkboxNoPadding}
                            />
                          )}
                          label={`24 ${t("hours")}`}
                      />
                    </Grid>
                  </Grid>
                  {_process.queueDto && (
                  <Grid item xs={12} className={classes.slaSectionContainer}>
                    <SlaItemTitle title="restriction.form.missedExecutionsPolicy" info="restriction.userGuide.missedExecutionsPolicy" />
                    <Box component={Grid} item xs={12} container gridRowGap={10} mt={1} className={getValues("missedExecutionsPolicy") === "KEEP" ? classes.keepPolicyContainer : ""}>
                      <CustomSelectField
                          options={POLICY_LIST}
                          value={getValues("missedExecutionsPolicy")}
                          {...register("missedExecutionsPolicy", {
                            required: {
                              value: true,
                              message: t("queue.management.form.validation.required"),
                            },
                          })}
                          onChange={(event) => {
                            clearErrors("missedExecutionsPolicy");
                            setValue("missedExecutionsPolicy", event.target.value);
                            if (event.target.value === "DELETE") {
                              setValue("missedExecutionsTime", null);
                            }
                          }}
                          variant="standard"
                          customOptionLabel={(option) => t(option)}
                          labelClassName={classes.inputLabel}
                          error={!!errors.missedExecutionsPolicy}
                          helperText={errors.missedExecutionsPolicy && errors.missedExecutionsPolicy.message}
                          fullWidth
                          isCustom
                      />
                      {getValues("missedExecutionsPolicy") === "KEEP" && (
                      <>
                        <Typography variant="body2" component="span" className={classes.typography}>
                          {t("for")}
                        </Typography>
                        <CustomTextField
                                {...register("missedExecutionsTime", {
                                  required: {
                                    value: true,
                                    message: t("queue.management.form.validation.required"),
                                  },
                                })}
                                value={getValues("missedExecutionsTime")}
                                id="missedExecutionsTime"
                                type="number"
                                error={!!errors.missedExecutionsTime?.message}
                                onChange={(event) => {
                                  clearErrors("missedExecutionsTime");
                                  setValue("missedExecutionsTime", event.target.value);
                                }}
                                inputProps={{
                                  min: 0,
                                }}
                            />
                        <Typography variant="body2" component="span" className={classes.hourTypography}>
                          {t("hr")}
                          {
                            errors.missedExecutionsTime?.message
                              && (
                              <Tooltip title={t("queue.management.form.validation.required")} placement="right">
                                <ButtonBase>
                                  <ErrorOutlineRoundedIcon className={classes.infoIcon} />
                                </ButtonBase>
                              </Tooltip>
                              )
                          }
                        </Typography>
                      </>
                      )}
                    </Box>
                  </Grid>)}
                </Grid>
              </Grid>
              <Box component={Grid} item container xs={12} justify="flex-end" alignItems="flex-end" gridColumnGap={20} className={classes.modalActions}>
                <CustomButton
                      view="cancel"
                      startIcon={<ReplayRoundedIcon />}
                      disabled={confirmLoading}
                      onClick={handleReset}
                  >
                  {t("reset")}
                </CustomButton>
                <CustomButton
                      view="primary"
                      type="submit"
                      startIcon={confirmLoading && <CircularProgress size={20} />}
                      disabled={confirmLoading}
                  >
                  {t("apply")}
                </CustomButton>
              </Box>
            </form>
          )}
      <EstimationComputing
          setValues={setValue}
          openEstimatedTimeComputingPopUp={openEstimatedTimeComputingPopUp}
          processId={_process.id}
          setOpenEstimatedTimeComputingPopUp={setOpenEstimatedTimeComputingPopUp}
      />
    </Grid>

  );
};
export default SlaInfo;
// new componenet
