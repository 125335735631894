import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import useStyles from "./style.js";

export default function AlertsPageHeader({ title }) {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Grid container className={classes.pageHeaderContainer}>
      <Grid container item xs={8} justify="flex-start" alignItems="center">
        <Typography
          variant="h5"
          className={classes.pageHeader}
          color="secondary"
        >
          {t(title)}
        </Typography>
      </Grid>
      <Grid container item xs={4} justify="flex-end" alignItems="center" />
    </Grid>
  );
}
