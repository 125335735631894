import React, { useEffect, useMemo } from "react";
import Box from "@material-ui/core/Box";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import get from "lodash/get";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { Query } from "@redux-requests/react";
import { updateFilter } from "../../../../../redux/slices/adminFilterSlice";
import { isFleetAdministrator } from "util";
import { FETCH_ORCHESTRATORS } from "../../../../../redux/constants";
import {
  deleteOrchestrators,
  fetchOrchestrators,
} from "../../../../../redux/actions/services";
import AdminTableFilter from "../../../AdminTable/AdminTableFilter";
import CircularLoader from "../../../../../components/Loaders/CircularLoader";
import DataNotFound from "../../../../../components/DataNotFound";
import CustomPagination from "../../../../Services/components/CustomPagination";
import OrchestratorTableRow from "./OrchestratorTableRow";
import CustomTable from "../../../../../components/TableComponents/CustomTable";
import CustomTableContainer from "../../../../../components/TableComponents/CustomTableContainer";
import ConfirmMessage from "../../../../../components/ConfirmMessage";
import OrchestratorTableHeader from "./OrchestratorTableHeader";
import OrchestratorNotFound from "../../../../../assets/No_Orchestrator.svg";

const statusList = [
  { code: "ACTIVE", label: "ACTIVE" },
  { code: "DISABLE", label: "DISABLE" },
];

const sortByList = [
  { code: "name", label: "orchestrator.management.name" },
  {
    code: "sessionNumber",
    label: "orchestrator.management.formControl.sessionNumber",
  },
];
export default function OrchestratorsTable() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [showTableHeader, setShowTableHeader] = React.useState(true);
  const [openDeletePopup, setOpenDeletePopup] = React.useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = React.useState(false);
  const [countUsers, setCountUsers] = React.useState(0);
  const [selected, setSelected] = React.useState([]);
  const { t } = useTranslation();
  const [fetchedData, setFetchedData] = React.useState([]);
  const filter = useSelector(({ adminFilter }) => adminFilter);
  const currentUser = useSelector(({ requests }) => get(requests, "queries.FETCH_CURRENT_USER.data"),);
  const fetchEntities = fetchOrchestrators;
  const entityBaseUrl = "/robotAdministration/orchestrators";

  const handleCloseConfirmationPopup = () => {
    setOpenDeletePopup(false);
  };

  const handleChangeStatus = (event) => {
    const statuses = event?.map(({ code }) => code);
    dispatch(
      updateFilter({
        status: statuses,
        pageNo: 0,
      }),
    );
  };

  const handleChangeOrchestrator = (value) => {
    dispatch(
      updateFilter({
        orchestrator: value,
        pageNo: 0,
      }),
    );
  };

  const handleChangeFleet = (values) => {
    dispatch(
      updateFilter({
        fleets: values.map(({ id }) => id),
        pageNo: 0,
      }),
    );
  };

  const handleChangeDivisions = (values) => {
    dispatch(
      updateFilter({
        divisions: values.map(({ id }) => id),
        pageNo: 0,
      }),
    );
  };

  const handleChangeSortBy = (value) => {
    dispatch(
      updateFilter({
        sortBy: value?.code,
        pageNo: 0,
      }),
    );
  };
  const handleFilterValueChange = (value) => {
    dispatch(
      updateFilter({
        pageNo: 0,
        searchCriteria: value,
      }),
    );
  };

  const handleSelectedAll = (checked) => {
    const data = fetchedData.map((d) => d.id);
    if (!checked) {
      const filteredData = selected.filter((fd) => !data.includes(fd));
      setSelected(filteredData);
    } else {
      setSelected(data);
    }
  };

  const handleChangePage = (event, newPage) => {
    dispatch(
      updateFilter({
        pageNo: newPage,
      }),
    );
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(
      updateFilter({
        pageNo: 0,
        pageSize: parseInt(event.target.value, 10),
      }),
    );
  };

  const handleChangeShowDeleted = (event) => {
    dispatch(
      updateFilter({
        showDeleted: event.target.checked,
      }),
    );
  };

  const fetchEntitiesReset = () => {
    const pageNo = (countUsers - 1) % filter[filter.currModule].pageSize === 0
        ? filter[filter.currModule].pageNo - 1
        : filter[filter.currModule].pageNo;
    dispatch(
      updateFilter({
        pageNo: pageNo === -1 ? 0 : pageNo,
      }),
    );
    setSelected([]);
  };
  const isAllSelected = useMemo(
    () => fetchedData?.every((d) => selected?.includes(d.id)),
    [fetchedData, selected],
  );

  useEffect(() => {
    dispatch(fetchEntities({ ...filter[filter.currModule] }));
  }, []);

  useEffect(() => {
    dispatch(fetchEntities({ ...filter[filter.currModule] }));
  }, [dispatch, fetchEntities, filter, history.location.pathname]);

  const fetchEntitiesWithFilter = () => dispatch(fetchEntities({ ...filter[filter.currModule] }));

  const handleNextPage = (page) => {
    dispatch(
      updateFilter({
        pageNo: page + 1,
      }),
    );
  };
  const handlePrevious = (page) => {
    dispatch(
      updateFilter({
        pageNo: page - 1,
      }),
    );
  };

  const deleteAll = () => {
    setIsDeleteLoading(true);
    dispatch(
      deleteOrchestrators(
        selected,
        () => {
          setIsDeleteLoading(false);
          dispatch(
            fetchEntities({
              pageNo: filter[filter.currModule].pageNo,
              pageSize: filter[filter.currModule].pageSize,
            }),
          );
          setSelected([]);
          handleCloseConfirmationPopup();
          toast.success(t("orchestrator deleted successfully"));
        },
        () => {
          handleCloseConfirmationPopup();
          toast.error(t("something went wrong"));
        },
      ),
    );
  };
  return (
    <Box>
      <AdminTableFilter
        statusList={statusList}
        sortByList={sortByList}
        pageSize={filter.pageSize}
        fetchEntities={fetchEntities}
        searchLabel="Search"
        searchField="searchCriteria"
        handleChangeStatus={handleChangeStatus}
        handleChangeOrchestrator={handleChangeOrchestrator}
        handleFilterValueChange={handleFilterValueChange}
        handleChangeSortBy={handleChangeSortBy}
        handleChangeFleets={handleChangeFleet}
        handleChangeDivisions={handleChangeDivisions}
        entityBaseUrl={entityBaseUrl}
        showAddIcon={isFleetAdministrator(currentUser)}
        addButtonText={t("orchestrator.management.add.button")}
        handleChangeShowDeleted={handleChangeShowDeleted}
      />
      {showTableHeader && (
        <OrchestratorTableHeader
          selected={selected}
          setSelected={setSelected}
          handleSelectedAll={handleSelectedAll}
          checkedAll={
            fetchedData?.length > 0
            && fetchedData.every((d) => selected.includes(d.id))
          }
          checkedIndeterminate={
            fetchedData?.length > 0
            && !isAllSelected
            && fetchedData.some((d) => selected.includes(d.id))
          }
          fetchedData={fetchedData}
          fetchEntities={fetchEntities}
          page={filter[filter.currModule].pageNo}
          rowsPerPage={filter[filter.currModule].pageSize}
          statusList={statusList}
        />
      )}
      <CustomTableContainer>
        <CustomTable>
          <Query type={FETCH_ORCHESTRATORS} loadingComponent={CircularLoader}>
            {({ data }) => {
              if (!data?.content?.length) setCountUsers(0);
              setFetchedData(data?.content);
              const length = data.totalElements;
              setCountUsers(length);
              data?.content?.length > 0
                ? setShowTableHeader(true)
                : setShowTableHeader(false);
              return data?.content?.length > 0 ? (
                data.content?.map((row, index) => (
                  <OrchestratorTableRow
                    key={`orchestrator-table-${index}`}
                    row={row}
                    selected={selected}
                    setSelected={setSelected}
                    entityBaseUrl={entityBaseUrl}
                    fetchEntitiesReset={fetchEntitiesReset}
                    fetchEntities={fetchEntitiesWithFilter}
                  />
                ))
              ) : (
                <DataNotFound
                  message={t("no.orchestrator.message")}
                  icon={OrchestratorNotFound}
                />
              );
            }}
          </Query>
        </CustomTable>
      </CustomTableContainer>
      <ConfirmMessage
        message={t("orchestrator.management.deleteOrchestratorsActionMessage")}
        openStart={openDeletePopup}
        onCancel={handleCloseConfirmationPopup}
        onConfirm={deleteAll}
        buttonConfirm={t("Delete")}
        buttonCancel={t("Cancel")}
        isLoading={isDeleteLoading}
      />
      {showTableHeader && (
        <CustomPagination
          rowsPerPageOptions={[5, 10, 25]}
          count={countUsers ?? 0}
          page={filter[filter.currModule].pageNo}
          rowsPerPage={filter[filter.currModule].pageSize}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          onChangePage={handleChangePage}
          onNext={handleNextPage}
          onPrevious={handlePrevious}
        />
      )}
    </Box>
  );
}
