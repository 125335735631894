import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import useStyles from "../style";
import { useTranslation } from "react-i18next";
import SelectTextField from "../../../components/ReactHookField/SelectTextField";
import TextFieldForm from "../../../components/ReactHookField/TextFieldForm";
import DateInputs from "../DateInputs";
import { handleInputChanges } from "util";
import { formatInputOnBlur, formatInputOnFocus } from "../../../util";
import { useFormContext } from "react-hook-form";

const COST_CATEGORY = [
  { label: "Reccuring ", value: "recurring" },
  { label: "One shot", value: "one-shot" },
];

const COST_TYPE = [
  { label: "Exclusive  ", value: "exclusive" },
  { label: "Shared ", value: "shared" },
];

function CostAllocationForm({ isReadOnly, showDates, isEdit }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const form = useFormContext()
  return (
    <>
      <Grid
        container
        justify="flex-start"
        alignItems="center"
        className={classes.formGrid}
      >
        <Grid item xs={6}>
          <Typography className={classes.CostAllocationText} variant="subtitle1">{t("Cost Name")}</Typography>
        </Grid>
        <Grid item xs={6}>
          <TextFieldForm
            fullWidth
            fieldName="name"
            rules={{
              required: t("ERRORS_EMPTY_FIELD"),
            }}
            classes={classes.textField}
            disabled={isReadOnly}
          />
        </Grid>
      </Grid>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        className={classes.formGrid}
      >
        <Grid item xs={6}>
          <Typography className={classes.CostAllocationText} variant="subtitle1">{t("Cost Category")}</Typography>
        </Grid>
        <Grid item xs={6}>
          <SelectTextField
            fieldName="category"
            rules={{ required: t("ERRORS_EMPTY_FIELD") }}
            options={COST_CATEGORY.map(({ label, value }) => (
              <MenuItem value={value} key={value}>
                <Typography className={classes.CostAllocationText} variant="subtitle1" display="inline">
                  {t(label)}
                </Typography>
              </MenuItem>
            ))}
            className={classes.select}
            disabled={isReadOnly}
          />
        </Grid>
      </Grid>
      {showDates && <DateInputs isEdit={isEdit} isReadOnly={isReadOnly} />}
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        className={classes.formGrid}
      >
        <Grid item xs={6}>
          <Typography className={classes.CostAllocationText} variant="subtitle1" display="inline">
            {t("Cost Type")}
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <SelectTextField
            fieldName="type"
            rules={{ required: t("ERRORS_EMPTY_FIELD") }}
            options={COST_TYPE.map(({ label, value }) => (
              <MenuItem value={value} key={value}>
                <Typography className={classes.CostAllocationText} variant="subtitle1" display="inline">
                  {t(label)}
                </Typography>
              </MenuItem>
            ))}
            className={classes.select}
            disabled={isReadOnly}
          />
        </Grid>
      </Grid>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        className={classes.formGrid}
      >
        <Grid item xs={6}>
          <Typography className={classes.CostAllocationText} variant="subtitle1" display="inline">
            {t("Cost Amount")}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <TextFieldForm
            fullWidth
            fieldName="allocatedAmount"
            rules={{
              required: t("ERRORS_EMPTY_FIELD"),
              min: {
                value: 0.001,
                message: t("ERRORS_LESS_THAN_MIN_FIELD", { limit: "0.000" }),
              },
            }}
            classes={classes.textField}
            disabled={isReadOnly}
            onInput={(e) => handleInputChanges(e, 9)}
            onBlur={(field) => formatInputOnBlur(field, form)}
            onFocus={(field) => formatInputOnFocus(field, form)}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default CostAllocationForm;
